// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Support functions
import { getRGBA } from "../supportFunc/getRGBA";
// -------------------------------------------------
// Redux actions
import { setSingleTaskResponseByAlias } from "../redux/reducers/taskResponses";
// -------------------------------------------------
// Basic elements
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
// -------------------------------------------------
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// ------------------------------------------
// Support funct
import { getAliasContainingId } from "../supportFunc/getAliasContainingId";
// =================================================
// FUNCTIONAL COMPONENT
const FormGridContainer = (props) => {
  // =================================================
  // VARIABLES
  // -------------------------------------------------
  // Local state
  const [isCollapsed, setIsCollapsed] = useState(
    props.gridContainer.options.defaultCollapsed
  );
  const backgroundColor = getRGBA(
    props.gridContainer.layout.bgColor,
    props.gridContainer.layout.bgOpacity,
    props.gridContainer.options.noCard
  );
  // -------------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const isXS = useSelector((state) => state.ui.isXS);
  // Check if this grid container is enabled and in view
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.gridContainer._id]
  );
  const viewIdx = useSelector((state) => state.tickets.currentTicket.viewIdx);
  const responseCollection = useSelector(
    (state) => state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) => state.tickets.currentTicket.responseId
  );
  const isInView = useSelector((state) =>
    state.form.views[viewIdx].gridContainerIds.includes(props.gridContainer._id)
  );
  const doShow = (isEnabled && isInView) || (isEnabled && !isXS);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Handles setting a single response by its alias in the appropriate reducer
  const handleSetSingleResponse = (alias, value) => {
    switch (responseCollection) {
      case "taskResponses":
        dispatch(setSingleTaskResponseByAlias({ responseId, alias, value }));
        break;
      default:
        return;
    }
  };
  // -----------------------------------------------
  // When grid container is disabled, revert back to inital value(s)
  useEffect(() => {
    if (isEnabled === false) {
      props.gridContainer.gridItemList.forEach((gi) => {
        gi.articleList
          .filter((article) => article.category === "question")
          .forEach((article) => {
            article.columnList
              ? article.columnList.forEach((column) => {
                  article.rowList.forEach((row) => {
                    handleSetSingleResponse(
                      getAliasContainingId(
                        props.aliasList,
                        `${row._id}_${column._id}`
                      ),
                      article.type === "checkboxes-grid" ? false : null
                    );
                  });
                })
              : article.rowList
              ? article.rowList.forEach((row) => {
                  handleSetSingleResponse(
                    getAliasContainingId(props.aliasList, row._id),
                    article.type === "checkboxes" ? false : null
                  );
                })
              : handleSetSingleResponse(
                  getAliasContainingId(props.aliasList, article._id),
                  null
                );
          });
      });
    }
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // =================================================
  // RENDER COMPONENT
  // -------------------------------------------------
  return (
    doShow && (
      <>
        <Grid
          container
          className={props.gridContainer.options.isCollapsable ? "" : "pb-1"}
        >
          <Grid
            item
            xs={12}
            className={props.gridContainer.options.isCollapsable ? "px-2" : ""}
          >
            <Grid
              container
              className={
                props.gridContainer.options.isCollapsable &&
                (!isCollapsed || isXS)
                  ? "py-1 bg-gradient-secondary rounded-top"
                  : props.gridContainer.options.isCollapsable && isCollapsed
                  ? "py-1 bg-gradient-secondary rounded"
                  : "pb-1"
              }
              onClick={(e) =>
                props.gridContainer.options.isCollapsable &&
                setIsCollapsed(!isCollapsed)
              }
            >
              {/* ================================================== */}
              {/* PAGE TITLE */}
              {props.gridContainer.title && (
                <Grid
                  item
                  className={
                    props.gridContainer.options.isCollapsable
                      ? "ps-2 flex-grow-1"
                      : "flex-grow-1"
                  }
                >
                  <Typography
                    variant={props.gridContainer.title.variant}
                    color={props.gridContainer.title.color}
                    align={props.gridContainer.title.align}
                  >
                    {props.gridContainer.title.text}
                  </Typography>
                </Grid>
              )}
              {props.gridContainer.options.isCollapsable && (
                <Grid item className="flex-grow-0 pe-2">
                  <IconButton>
                    {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="mb-3">
          <Grid item xs={12}>
            {/* ================================================== */}
            {/* CONTENT */}
            <Collapse
              in={!isCollapsed || !props.gridContainer.options.isCollapsable}
            >
              {props.gridContainer.options.isCard ? (
                <Card
                  className={
                    props.gridContainer.options.hasShadow
                      ? "w-100 shadow"
                      : "w-100 shadow-none"
                  }
                  sx={{
                    backgroundColor:
                      backgroundColor === null ? "#FFFFFF" : backgroundColor,
                  }}
                >
                  <Grid
                    container
                    id="grid-container"
                    direction={props.gridContainer.layout.direction}
                    justifyContent={props.gridContainer.layout.justifyContent}
                    alignContent={props.gridContainer.layout.alignContent}
                    alignItems={props.gridContainer.layout.alignItems}
                    rowSpacing={
                      props.gridContainer.layout.direction === "row"
                        ? props.gridContainer.layout.rowSpacing
                        : { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }
                    }
                    columnSpacing={
                      props.gridContainer.layout.direction === "row"
                        ? props.gridContainer.layout.columnSpacing
                        : { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }
                    }
                    className={props.gridContainer.layout.classNames}
                    sx={{
                      minHeight: `${props.gridContainer.layout.minHeight}px`,
                      paddingLeft:
                        props.gridContainer.layout.padding.left === "0"
                          ? "1px"
                          : `${props.gridContainer.layout.padding.left}rem`,
                      paddingRight:
                        props.gridContainer.layout.padding.right === "0"
                          ? "1px"
                          : `${props.gridContainer.layout.padding.right}rem`,
                      paddingTop:
                        props.gridContainer.layout.padding.top === "0"
                          ? "1px"
                          : `${props.gridContainer.layout.padding.top}rem`,
                      paddingBottom:
                        props.gridContainer.layout.padding.bottom === "0"
                          ? "1px"
                          : `${props.gridContainer.layout.padding.bottom}rem`,
                      backgroundImage: `url("${props.gridContainer.layout.bgImage}")`,
                      backgroundOrigin:
                        props.gridContainer.layout.bgImageOrigin,
                      backgroundPosition:
                        props.gridContainer.layout.bgImagePosition,
                      backgroundRepeat:
                        props.gridContainer.layout.bgImageRepeat,
                      backgroundSize: props.gridContainer.layout.bgImageSize,
                    }}
                  >
                    {props.children}
                  </Grid>
                </Card>
              ) : (
                <Grid
                  container
                  id="grid-container"
                  direction={props.gridContainer.layout.direction}
                  justifyContent={props.gridContainer.layout.justifyContent}
                  alignContent={props.gridContainer.layout.alignContent}
                  alignItems={props.gridContainer.layout.alignItems}
                  rowSpacing={props.gridContainer.layout.rowSpacing}
                  columnSpacing={props.gridContainer.layout.columnSpacing}
                  className={props.gridContainer.layout.classNames}
                  sx={{
                    backgroundColor:
                      backgroundColor === null
                        ? "rgba(0, 0, 0, 0)"
                        : backgroundColor,
                    minHeight: `${props.gridContainer.layout.minHeight}px`,
                    paddingLeft:
                      props.gridContainer.layout.padding.left === "0"
                        ? "1px"
                        : `${props.gridContainer.layout.padding.left}rem`,
                    paddingRight:
                      props.gridContainer.layout.padding.right === "0"
                        ? "1px"
                        : `${props.gridContainer.layout.padding.right}rem`,
                    paddingTop:
                      props.gridContainer.layout.padding.top === "0"
                        ? "1px"
                        : `${props.gridContainer.layout.padding.top}rem`,
                    paddingBottom:
                      props.gridContainer.layout.padding.bottom === "0"
                        ? "1px"
                        : `${props.gridContainer.layout.padding.bottom}rem`,
                    backgroundImage: `url("${props.gridContainer.layout.bgImage}")`,
                    backgroundOrigin: props.gridContainer.layout.bgImageOrigin,
                    backgroundPosition:
                      props.gridContainer.layout.bgImagePosition,
                    backgroundRepeat: props.gridContainer.layout.bgImageRepeat,
                    backgroundSize: props.gridContainer.layout.bgImageSize,
                  }}
                >
                  {props.children}
                </Grid>
              )}
            </Collapse>
          </Grid>
        </Grid>
      </>
    )
  );
};
// =================================================
// EXPORT COMPONENT
export default FormGridContainer;
