// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../../redux/reducers/ui";
// -------------------------------------------------
// Component elements
import SurveysEditInfo from "./Surveys_EditInfo";
import TopDrawer from "../App_TopDrawer";
// -------------------------------------------------
// Basic elements
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
// -------------------------------------------------
// Icons
import Save from "@mui/icons-material/Save";
import Launch from "@mui/icons-material/Launch";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const SurveysContentInfo = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_ContentInfo",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const surveysStatus = useSelector((state) => state.surveys.status);
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  // -----------------------------------------------
  // Survey information keys
  const infoKeys = [
    { key: "officialName", label: t("Official name") },
    { key: "displayName", label: t("Display name") },
    { key: "acronym", label: t("Acronym") },
    { key: "createdAt", label: t("Created"), isDate: true },
    { key: "version", label: t("Version") },
    { key: "PDF", label: t("PDF") },
    { key: "DOI", label: t("DOI") },
  ];
  // -----------------------------------------------
  // Local state
  const [info, setInfo] = useState(props.info);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Handles opening the top drawer for changing survey info
  const handleEditSurveyInfo = () => {
    dispatch(toggleSecDrawer({ isOpen: true, id: "surveys_content-info" }));
  };
  // -----------------------------------------------
  // Resets local state upon closing of the drawer
  useEffect(() => {
    if (!secDrawerIsOpen) {
      setInfo(props.info);
    }
  }, [props.info, secDrawerIsOpen]);
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      {/* ================================================== */}
      {/* TOP DRAWER */}
      {info && (
        <TopDrawer
          id={"surveys_content-info"}
          title={t("Survey information")}
          buttons={
            <Button
              disabled={
                surveysStatus === "loading" ||
                props.disabled ||
                !info.officialName ||
                !info.displayName ||
                !info.acronym
              }
              color="inherit"
              startIcon={<Save />}
              className="m-2"
              onClick={() => props.handleMutateCurrentSurvey("info", info)}
            >
              {surveysStatus === "loading" ? (
                <CircularProgress size="1.5rem" className="text-light" />
              ) : (
                "Save"
              )}
            </Button>
          }
        >
          <SurveysEditInfo obj={info} setObj={setInfo} isLive={props.isLive} />
        </TopDrawer>
      )}
      {/* SECTION SHOWING CURRENT INFO */}
      <Card className="mb-3">
        <List disablePadding className="px-3 pt-2">
          <ListItem
            disablePadding
            secondaryAction={
              !props.disabled && (
                <Tooltip arrow title={t("Edit")} placement="top">
                  <IconButton
                    disabled={surveysStatus === "loading"}
                    edge="end"
                    onClick={handleEditSurveyInfo}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )
            }
          >
            <ListItemText
              primary={
                <Typography variant="h3">{t("Survey information")}</Typography>
              }
            />
          </ListItem>
        </List>
        <List disablePadding className="px-3">
          {infoKeys.map((item) => (
            <ListItem divider key={item.key}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm="auto">
                  <ListItemText
                    secondary={item.label.toUpperCase()}
                    className="min-width-125px"
                  />
                </Grid>
                <Grid item xs={9} sm="auto">
                  <Typography paragraph={false}>
                    {props.info[item.key] === null ||
                    props.info[item.key] === "" ? (
                      "-"
                    ) : item.isDate ? (
                      DateTime.fromISO(
                        props.info[item.key]
                      ).toRelativeCalendar()
                    ) : item.key === "PDF" ? (
                      <Link
                        href={props.info[item.key]}
                        variant="body2"
                        target="_blank"
                        rel="noopener"
                        underline="none"
                      >
                        <Launch fontSize="small" /> Link to PDF
                      </Link>
                    ) : (
                      props.info[item.key]
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
        {props.isLive && (
          <Alert severity="warning" className="m-3">
            {t(
              "There is response data associated with this survey. It can no longer be edited or deleted."
            )}
          </Alert>
        )}
      </Card>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysContentInfo;
