// ########################################
// FUNCTION
// ----------------------------------------
// Convert an array to a string
export const array2string = (arr) => {
  let str = "";
  for (let i = 0; i < arr.length; i++) {
    if (Array.isArray(arr[i])) {
      str += `${array2string(arr[i])} `;
    } else {
      str += arr[i] ? `${arr[i]} ` : "";
    }
  }
  return str;
};
