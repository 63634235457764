// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Redux
import { surveysSelectors } from "../../redux/reducers/surveys";
// -------------------------------------------------
// Components
import AppListItem from "../App_ListItem";
// -------------------------------------------------
// Basic elements
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
// -------------------------------------------------
// Icons
import EventAvailable from "@mui/icons-material/EventAvailable";
// =================================================
// FUNCTIONAL COMPONENT
const TaskListItemLater = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "tasks.Tasks_ListItemLater",
  });
  // =================================================
  // VARIABLES
  // -------------------------------------------------
  // Redux
  const currentSurvey = useSelector(
    (state) =>
      props.task && surveysSelectors.selectById(state, props.task.surveyId)
  );
  // =================================================
  // RENDER COMPONENT
  return currentSurvey ? (
    <AppListItem
      divider
      slideIn
      noButton={true}
      textPrimary={currentSurvey.name.display}
      textSecondary={
        <span>
          <EventAvailable fontSize="inherit" className="me-1" />
          <Typography variant="caption" color="textSecondary" className="me-2">
            {props.taskAvailability
              ? props.taskAvailability.str
              : t("Forever, ever, forever, ever?")}
          </Typography>
        </span>
      }
    />
  ) : (
    <AppListItem
      divider
      slideIn
      noButton={true}
      textPrimary={<Skeleton variant="text" />}
      textSecondary={<Skeleton variant="text" />}
    />
  );
};
// =================================================
// EXPORT COMPONENT
export default TaskListItemLater;
