// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Helper functions
import { str2color } from "../../supportFunc/str2color";
// -------------------------------------------------
// Redux
import { consumersSelectors } from "../../redux/reducers/consumers";
// -------------------------------------------------
// Basic elements
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import Alert from "@mui/material/Alert";
// =================================================
// FUNCTIONAL COMPONENT
const StudiesEditInvestigator = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "studies.Studies_EditInvestigator",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  const consumersStatus = useSelector((state) => state.consumers.status);
  const consumerList = useSelector((state) =>
    consumersSelectors.selectAll(state)
  );
  // -----------------------------------------------
  // Local State
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  // -----------------------------------------------
  // Data table
  const columnList = [
    { field: "userNumber", headerName: "PID", width: 75, type: "number" },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "createdAt",
      headerName: "Created on",
      width: 100,
      type: "dateTime",
      valueFormatter: (params) =>
        DateTime.fromISO(params.value).toFormat("dd LLL yyyy"),
    },
    {
      field: "lastSignInOn",
      headerName: "Last sign-in",
      width: 100,
      type: "dateTime",
      valueFormatter: (params) =>
        DateTime.fromISO(params.value).toFormat("dd LLL yyyy"),
    },
  ];
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  const stringAvatar = (id, name) => {
    const abbrev = name.trim().split(" ");
    return {
      sx: {
        bgcolor: str2color(id),
      },
      children:
        abbrev.length === 1
          ? `${name[0].toUpperCase()}`
          : `${name.split(" ")[0][0].toUpperCase()}${name
              .split(" ")[1][0]
              .toUpperCase()}`,
    };
  };
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (key, value) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      if (value === "") {
        newState[key] = null;
      } else {
        newState[key] = value;
      }
      return newState;
    });
  };
  // -----------------------------------------------
  // Parses the RegEx
  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };
  // -----------------------------------------------
  // Sets the search text as a RegEx
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };
  // -----------------------------------------------
  useEffect(() => {
    setSelectionModel(props.obj.userIdList);
    setRows(
      consumerList
        .filter((consumer) => consumer.deletedOn === null)
        .filter((consumer) => consumer.primaryRole === "participant")
        .filter(
          (consumer) =>
            consumer.studyEnrollmentList &&
            consumer.studyEnrollmentList[0] &&
            consumer.studyEnrollmentList[0].studyId === props.currentStudyId
        )
        .map((consumer) => {
          return {
            id: consumer._id,
            userNumber: consumer.userNumber,
            email: consumer.email,
            createdAt: consumer.createdAt,
            lastSignInOn: consumer.lastSignInOn,
          };
        })
    );
    return () => {
      setSearchText("");
      setRows([]);
      setSelectionModel([]);
    };
  }, [consumersStatus]); // eslint-disable-line react-hooks/exhaustive-deps
  // =================================================
  // SUB COMPONENTS
  // -----------------------------------------------
  const QuickSearchToolbar = () => {
    return (
      <GridToolbarContainer className="m-2">
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <Grid container direction="column" className="h-100">
      <Grid item>
        <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" className="font-weight-bold">
                {props.isNew
                  ? t("Add investigator")
                  : t("Edit investigator role")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {props.isNew ? (
                <FormControl fullWidth variant="filled">
                  <InputLabel color="secondary" sx={{ top: "-3px" }}>
                    {t("Select investigator")}
                  </InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    name="userId"
                    color="secondary"
                    value={props.obj.userId ? props.obj.userId : ""}
                    onChange={(e) => handleSetObj("userId", e.target.value)}
                  >
                    {props.investigators.map((investigator) => (
                      <MenuItem key={investigator._id} value={investigator._id}>
                        {investigator && investigator.email}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                props.currentInvestigator && (
                  <ListItem className="p-0">
                    <ListItemIcon>
                      <Avatar
                        {...stringAvatar(
                          props.currentInvestigator._id,
                          props.currentInvestigator.name
                            ? props.currentInvestigator.name
                            : props.currentInvestigator.email
                        )}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="body1" className="text-truncate">
                          {props.currentInvestigator.email}
                        </Typography>
                      }
                    />
                  </ListItem>
                )
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="filled">
                <InputLabel color="secondary" sx={{ top: "-3px" }}>
                  {t("Select role")}
                </InputLabel>
                <Select
                  fullWidth
                  size="small"
                  name="role"
                  color="secondary"
                  value={props.obj.role ? props.obj.role : ""}
                  onChange={(e) => handleSetObj("role", e.target.value)}
                >
                  {[
                    "principal-investigator",
                    "associate-investigator",
                    "assistant",
                    "therapist",
                    "monitor",
                  ].map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Alert severity="warning">
                <b>Work in progress - </b>Blinding is not functional yet.{" "}
              </Alert>
              <FormControlLabel
                control={
                  <Switch
                    name="isBlinded"
                    checked={props.obj.isBlinded}
                    color="secondary"
                    onChange={(e) =>
                      handleSetObj("isBlinded", e.target.checked)
                    }
                  />
                }
                label={
                  props.obj.isBlinded
                    ? t("Is blinded to group allocation")
                    : t("Is unblinded to group allocation")
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    name="isAllParticipants"
                    checked={props.obj.isAllParticipants}
                    color="secondary"
                    onChange={(e) =>
                      handleSetObj("isAllParticipants", e.target.checked)
                    }
                  />
                }
                label={
                  props.obj.isAllParticipants
                    ? t("Supervises all participants")
                    : t("Supervises a subset of participants")
                }
              />
            </Grid>
            {!props.obj.isAllParticipants && (
              <Grid item xs={12}>
                {props.obj.userIdList === null ||
                props.obj.userIdList.length === 0 ? (
                  <Typography variant="caption" className="mb-2">
                    {t("... no participant selected")}
                  </Typography>
                ) : (
                  <Typography variant="h4" className="mb-2">
                    {t("Supervisor of")}
                  </Typography>
                )}
                {selectionModel.map((consumerId) => (
                  <Chip
                    key={consumerId}
                    label={
                      consumerList.find(
                        (consumer) => consumer._id === consumerId
                      ).email
                    }
                    size="small"
                    className="me-2"
                  />
                ))}
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      {!props.obj.isAllParticipants && (
        <Grid item className="pb-2 flex-grow-1">
          <DataGrid
            checkboxSelection
            rows={rows}
            columns={columnList}
            selectionModel={selectionModel}
            components={{
              Toolbar: QuickSearchToolbar,
            }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (e) => requestSearch(e.target.value),
                clearSearch: () => requestSearch(""),
              },
            }}
            className="bg-white mx-2 h-100"
            onSelectionModelChange={(newSelectionModel) => {
              handleSetObj("userIdList", newSelectionModel);
              setSelectionModel(newSelectionModel);
            }}
          />
        </Grid>
      )}
      {!props.isNew && (
        <Grid item className="px-2 pb-2">
          <Button
            disabled={props.loading}
            fullWidth
            type="button"
            variant="contained"
            color="error"
            onClick={props.handleDeleteStudyInvestigator}
          >
            {t("Remove investigator from study")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
// =================================================
// EXPORT COMPONENT
export default StudiesEditInvestigator;
