// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Redux
import { setAlert } from "./redux/reducers/ui";
// -------------------------------------------------
// Contexts
import { useAuth } from "./contexts/auth";
// -------------------------------------------------
// Permissions page
import AuthPermissions from "./pages/AuthPermissions";
// =================================================
// EXPORT COMPONENT
export default function PrivateRoute(props) {
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  // Check if the user is an admin, but has no name yet
  if (
    user &&
    user.primaryRole &&
    user.primaryRole !== "participant" &&
    (!user.name || user.name === "")
  ) {
    dispatch(
      setAlert({
        type: "snackbar",
        variant: "info",
        message: "Please provide your name on the account page.",
      })
    );
  }
  // -------------------------------------------------
  // Context
  const { currentAuth, verifiedAuth } = useAuth();
  // -------------------------------------------------
  // Use location to redirect
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectAnonymous = searchParams.get("redirectAnonymous");
  const RedirectURI = redirectAnonymous
    ? `/${redirectAnonymous}${location.search}`
    : `/home${location.search}`;
  const VerifyEmailURI = `/ask-to-verify-email${location.search}`;
  // ===============================================
  // RENDER COMPONENT
  return !currentAuth ? (
    <Navigate replace to={RedirectURI} />
  ) : !verifiedAuth ? (
    <Navigate replace to={VerifyEmailURI} />
  ) : user && !user.consent ? (
    <AuthPermissions />
  ) : location.pathname === "/" &&
    user &&
    user.primaryRole &&
    user.primaryRole !== "participant" ? (
    <Navigate replace to="/studies" />
  ) : location.pathname === "/" ? (
    <Navigate replace to="/tasks" />
  ) : (
    props.children
  );
}
