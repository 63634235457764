// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import MarkdownText from "./MarkdownText";
// -------------------------------------------------
// Basic elements
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const ArticleQuestion = (props) => {
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      {props.text && props.text !== "" && (
        <Typography
          paragraph={
            props.variant === "button" ||
            props.variant === "caption" ||
            props.variant === "overline"
          }
          variant={props.variant}
          color={props.color}
          align={props.align}
          className={
            props.subquestion && props.subquestion.text
              ? "item-question font-weight-bold mb-0"
              : "item-question font-weight-bold mb-1"
          }
        >
          <MarkdownText>{props.text}</MarkdownText>
        </Typography>
      )}
      {props.subquestion && props.subquestion.text && (
        <Typography
          paragraph={
            props.variant === "button" ||
            props.variant === "caption" ||
            props.variant === "overline"
          }
          variant={props.subquestion.variant}
          color={props.subquestion.color}
          align={props.subquestion.align}
          className="mb-1"
        >
          <MarkdownText>{props.subquestion.text}</MarkdownText>
        </Typography>
      )}
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default ArticleQuestion;
