// =================================================
// IMPORT
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/users`;
// =================================================
// INIT STATE
const initialState = {
  currentUser: null,
  ghostUser: null,
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  errorMsg: null, // string | null
};
// =================================================
// ASYNC API ACTIONS
// -------------------------------------------------
// API fetch a user by its ID
export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({ requestingUser, userId }) => {
    const response = await client.get(`${apiurl}/${userId}`, requestingUser);
    return response.data;
  }
);
export const fetchUserByFirebaseId = createAsyncThunk(
  "user/fetchUserByFirebaseId",
  async ({ requestingUser, firebaseId }) => {
    const response = await client.get(
      `${apiurl}/firebase/${firebaseId}`,
      requestingUser
    );
    return response.data;
  }
);
// API post a new user object
export const postNewUser = createAsyncThunk(
  "user/postNewUser",
  async ({ requestingUser, body }) => {
    // Make the call to the database
    const response = await client.post(apiurl, requestingUser, body);
    // Return the response
    return response.data;
  }
);
// API patch a user object
export const patchCurrentUser = createAsyncThunk(
  "user/patchCurrentUser",
  async ({ socket, requestingUser, body }) => {
    try {
      // Make the call to the database
      const response = await client.patch(
        `${apiurl}/${body.data._id}`,
        requestingUser,
        body
      );
      // Invoke event on server
      socket &&
        socket.emit("patched-current-consumer", { userId: body.data._id });
      // Return the response
      return response.data;
    } catch (error) {
      console.error("Error patching current user:", error);
      throw error;
    }
  }
);
// =================================================
// DEFINE MUTATING ACTIONS
// -------------------------------------------------
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserError(state) {
      if (state.status === "failed") {
        state.status = "idle";
        state.errorMsg = null;
      }
    },
    setUserStatus(state, action) {
      state.status = action.payload.status;
    },
    updateCurrentUserWithKeyValue(state, action) {
      switch (action.payload.key) {
        case "consent":
          if (
            action.payload.value.bool &&
            !state.currentUser.consent.includes(
              action.payload.value.consentType
            )
          ) {
            state.currentUser.consent = [
              ...state.currentUser.consent,
              action.payload.value.consentType,
            ];
          } else if (
            !action.payload.value.bool &&
            state.currentUser.consent.includes(action.payload.value.consentType)
          ) {
            state.currentUser.consent.splice(
              state.currentUser.consent.indexOf(
                action.payload.value.consentType
              ),
              1
            );
          }
          break;
        default:
          state.currentUser[action.payload.key] = action.payload.value;
      }
    },
    toggleCloak(state, action) {
      if (action.payload.cloak) {
        state.ghostUser = state.currentUser;
        state.currentUser = action.payload.currentUser;
      } else {
        state.currentUser = state.ghostUser;
        state.ghostUser = null;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
        state.errorMsg = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.errorMsg = null;
        state.currentUser = action.payload.user;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
        state.currentUser = null;
      })
      .addCase(fetchUserByFirebaseId.pending, (state) => {
        state.status = "loading";
        state.errorMsg = null;
      })
      .addCase(fetchUserByFirebaseId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.errorMsg = null;
        state.currentUser = action.payload.user;
      })
      .addCase(fetchUserByFirebaseId.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
        state.currentUser = null;
      })
      .addCase(postNewUser.pending, (state) => {
        state.status = "loading";
        state.errorMsg = null;
      })
      .addCase(postNewUser.fulfilled, (state, action) => {
        state.errorMsg = null;
        state.currentUser = action.payload.user;
      })
      .addCase(postNewUser.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
        state.currentUser = null;
      })
      .addCase(patchCurrentUser.pending, (state) => {
        state.status = "loading";
        state.errorMsg = null;
      })
      .addCase(patchCurrentUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.errorMsg = null;
        state.currentUser = action.payload.user;
      })
      .addCase(patchCurrentUser.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const {
  resetUserError,
  setUserStatus,
  updateCurrentUserWithKeyValue,
  toggleCloak,
} = userSlice.actions;
// =================================================
// EXPORT DEFAULT
export default userSlice.reducer;
