// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
// -------------------------------------------------
// Components
import MarkdownText from "./MarkdownText";
import ArticleInstructions from "./ArticleInstructions";
// -------------------------------------------------
// Basic elements
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// -------------------------------------------------
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// =================================================
// FUNCTIONAL COMPONENT
const MediaText = (props) => {
  // ===============================================
  // RENDER COMPONENT
  return props.article.options && props.article.options.isCollapsable ? (
    <Accordion
      elevation={0}
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        border: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {props.article.title && props.article.title.text && (
          <Grid item>
            <Typography
              paragraph={
                props.article.title.variant === "button" ||
                props.article.title.variant === "caption" ||
                props.article.title.variant === "overline"
              }
              variant={props.article.title.variant}
              color={props.article.title.color}
              align={props.article.title.align}
            >
              {props.article.title.text}
            </Typography>
          </Grid>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="column"
          justifyContent={props.article.options.justifyContent}
        >
          <Grid item>
            {props.article.instructions &&
              props.article.instructions.text &&
              props.article.instructions.position === "top" && (
                <ArticleInstructions
                  text={props.article.instructions.text}
                  variant={props.article.instructions.variant}
                />
              )}
            {props.article.content && props.article.content.text && (
              <Typography
                paragraph={
                  props.article.content.variant === "button" ||
                  props.article.content.variant === "caption" ||
                  props.article.content.variant === "overline"
                }
                variant={props.article.content.variant}
                color={props.article.content.color}
                align={props.article.content.align}
              >
                <MarkdownText>{props.article.content.text}</MarkdownText>
              </Typography>
            )}
            {props.article.instructions &&
              props.article.instructions.text &&
              props.article.instructions.position === "bottom" && (
                <ArticleInstructions
                  text={props.article.instructions.text}
                  variant={props.article.instructions.variant}
                />
              )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  ) : (
    <Grid
      container
      direction="column"
      justifyContent={
        props.article.options
          ? props.article.options.justifyContent
          : "flex-start"
      }
      className="h-100"
    >
      {props.article.title && props.article.title.text && (
        <Grid item>
          <Typography
            paragraph={
              props.article.title.variant === "button" ||
              props.article.title.variant === "caption" ||
              props.article.title.variant === "overline"
            }
            variant={props.article.title.variant}
            align={props.article.title.align}
            className="mb-2"
            sx={{ color: props.article.title.color }}
          >
            {props.article.title.text}
          </Typography>
        </Grid>
      )}
      <Grid item>
        {props.article.instructions &&
          props.article.instructions.text &&
          props.article.instructions.position === "top" && (
            <ArticleInstructions
              text={props.article.instructions.text}
              variant={props.article.instructions.variant}
            />
          )}
        {props.article.content && props.article.content.text && (
          <Typography
            paragraph={
              props.article.content.variant === "button" ||
              props.article.content.variant === "caption" ||
              props.article.content.variant === "overline"
            }
            variant={props.article.content.variant}
            align={props.article.content.align}
            sx={{ color: props.article.content.color }}
          >
            <MarkdownText>{props.article.content.text}</MarkdownText>
          </Typography>
        )}
        {props.article.instructions &&
          props.article.instructions.text &&
          props.article.instructions.position === "bottom" && (
            <ArticleInstructions
              text={props.article.instructions.text}
              variant={props.article.instructions.variant}
            />
          )}
      </Grid>
    </Grid>
  );
};
// =================================================
// EXPORT COMPONENT
export default MediaText;
