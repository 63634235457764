// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
// -------------------------------------------------
// Basic elements
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Avatar from "@mui/material/Avatar";
// -------------------------------------------------
// Context
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Redux
import { patchCurrentUser } from "../redux/reducers/user";
import { surveysSelectors } from "../redux/reducers/surveys";
import {
  ticketsSelectors,
  deleteTicketsFromOneUserAndStudy,
  postTicketList,
} from "../redux/reducers/tickets";
import { consumersSelectors } from "../redux/reducers/consumers";
import { messagesSelectors } from "../redux/reducers/messages";
import {
  patchCurrentNotification,
  deleteCurrentNotification,
} from "../redux/reducers/notifications";
import { fetchStudyById } from "../redux/reducers/studies";
import { setAlert } from "../redux/reducers/ui";
// -------------------------------------------------
// Support functions
import { unshiftUserStudyEnrollment } from "../supportFunc/unshiftUserStudyEnrollment";
import { getTicketsForUserAndTimepoint } from "../supportFunc/getTicketsForUserAndTimepoint";
// =================================================
// FUNCTIONAL COMPONENT
const AppNotificationItem = (props) => {
  // -------------------------------------------------
  // Local state
  const [isLoading, setIsLoading] = useState(false);
  // -------------------------------------------------
  // Contexts
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -------------------------------------------------
  // Router
  const navigate = useNavigate();
  // ----------------------------------------
  // Redux
  const dispatch = useDispatch();
  const ticketEntities = useSelector((state) =>
    ticketsSelectors.selectEntities(state)
  );
  const surveyEntities = useSelector((state) =>
    surveysSelectors.selectEntities(state)
  );
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state)
  );
  const messagesEntities = useSelector((state) =>
    messagesSelectors.selectEntities(state)
  );
  const recipientList = useSelector((state) => state.messages.recipientList);
  const currentUser = useSelector((state) => state.user.currentUser);
  // ===============================================
  // Functions
  // ----------------------------------------
  const getActorName = (userId) => {
    let user = consumerEntities[userId];
    if (user === undefined) {
      return "<User not found>";
    }
    return user.name && user.name !== "" ? user.name : user.email;
  };
  // ----------------------------------------
  const getMessage = (recipientId) => {
    let recipient = recipientList.find((r) => r._id === recipientId);
    if (recipient === undefined) {
      return "<Recipient not found>";
    }
    let message = messagesEntities[recipient.messageId];
    if (message === undefined) {
      return "<Message not found>";
    }
    return message.message;
  };
  // ----------------------------------------
  const getSurveyName = (ticketId) => {
    let ticket = ticketEntities[ticketId];
    if (ticket === undefined) {
      return "<Ticket not found>";
    }
    let survey = surveyEntities[ticket.surveyId];
    if (survey === undefined) {
      return "<Survey not found>";
    }
    return survey.name.display;
  };
  // -----------------------------------------------
  // Deletes new-message notificiations if the message is read
  const deleteNotifIfIsRead = (notification) => {
    let recipient = recipientList.find((r) => r._id === notification.entityId);
    if (recipient === undefined) {
      return;
    }
    if (!recipient.isRead) {
      return;
    }
    dispatch(
      deleteCurrentNotification({
        requestingUser: currentAuth,
        notificationId: notification._id,
      })
    );
  };
  // -----------------------------------------------
  // Deletes tickets if they don't exist or are expired
  const deleteNotifIfExpired = (notification) => {
    if (notification && notification.expireAt === null) {
      return;
    }
    if (
      notification &&
      notification.expireAt &&
      DateTime.fromISO(notification.expireAt).diffNow().valueOf() > 0
    ) {
      return;
    }
    dispatch(
      deleteCurrentNotification({
        requestingUser: currentAuth,
        notificationId: notification._id,
      })
    );
  };
  // -----------------------------------------------
  // Unshifts the studyEnrollmentList field of the new recruits thereby enrolling them
  const handleEnroll = async (studyId) => {
    if (!currentUser) {
      return;
    }
    setIsLoading(true);
    dispatch(
      setAlert({
        type: "snackbar",
        variant: "info",
        message: "We're enrolling you, please wait...",
        duration: 10000,
      })
    );
    let newTicketList = [];
    let appendTickets;
    const res = await dispatch(
      fetchStudyById({ requestingUser: currentAuth, studyId })
    );
    const newStudy = res.payload.study;
    const currentEnrollment = currentUser.studyEnrollmentList[0];
    if (currentEnrollment) {
      // Delete any existing tickets
      dispatch(
        deleteTicketsFromOneUserAndStudy({
          socket,
          requestingUser: currentAuth,
          userId: currentUser._id,
          studyId: currentEnrollment.studyId,
        })
      );
    }
    // Add the study enrollment to the user object
    const newUser = unshiftUserStudyEnrollment(currentUser, newStudy);
    // Update the user object on the database
    await dispatch(
      patchCurrentUser({
        socket,
        requestingUser: currentAuth,
        body: {
          data: newUser,
        },
      })
    );
    // Create new ticket list
    for (
      var i = 0;
      i < newUser.studyEnrollmentList[0].timepointAssignmentList.length;
      i++
    ) {
      if (newUser.studyEnrollmentList[0].timepointAssignmentList[i].startDate) {
        appendTickets = getTicketsForUserAndTimepoint(
          newUser,
          newStudy,
          newUser.studyEnrollmentList[0].timepointAssignmentList[i].timepointId,
          "taskResponses"
        );
        if (appendTickets) {
          newTicketList = [...newTicketList, ...appendTickets];
        }
      }
    }
    if (newTicketList.length > 0) {
      await dispatch(
        postTicketList({
          socket,
          requestingUser: currentAuth,
          body: {
            data: newTicketList,
            meta: {
              userId: currentUser._id,
              ticketsToCreate: props.notification.data.ticketsToCreate,
            },
          },
        })
      );
    }
    // Enrollment is complete, delete notificaiton
    dispatch(
      deleteCurrentNotification({
        requestingUser: currentAuth,
        notificationId: props.notification._id,
      })
    );
    dispatch(
      setAlert({
        type: "snackbar",
        variant: "success",
        message: "You've been succesfully enrolled! Thanks",
        duration: 10000,
      })
    );
    setIsLoading(false);
  };
  // -----------------------------------------------
  // Use effect function delete notifications if necessary
  useEffect(() => {
    switch (props.notification.type) {
      case "messages":
        deleteNotifIfIsRead(props.notification);
        break;
      default:
        deleteNotifIfExpired(props.notification);
    }
  }, [props.notification, recipientList]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <Divider />
      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        className={
          !props.notification.isRead
            ? "p-3 pt-2 bg-list-item-selected"
            : "p-3 pt-2"
        }
        sx={{ maxWidth: "320px" }}
      >
        <Grid item className="flex-grow-0 pe-3">
          <Avatar
            className={!props.notification.isRead ? "bg-gradient-primary" : ""}
          >
            <Icon>
              {props.notification.type === "messages"
                ? "forum"
                : props.notification.type === "task-available"
                ? "assignment"
                : props.notification.type === "task-overdue"
                ? "assignment_late"
                : props.notification.type === "study-invite"
                ? "school"
                : props.notification.type === "new-registrations"
                ? "person"
                : null}
            </Icon>
          </Avatar>
        </Grid>
        <Grid item className="flex-grow-1">
          <Typography variant="subtitle1" className="d-inline">
            {props.notification.type === "messages"
              ? `${getActorName(props.notification.actorId)} wrote:`
              : props.notification.type === "task-available"
              ? "New task available"
              : props.notification.type === "task-overdue"
              ? "Task overdue"
              : props.notification.type === "study-invite"
              ? `You've been invited for the '${props.notification.data.studyAcronym.toLowerCase()}' study`
              : props.notification.type === "new-registrations"
              ? "New participant"
              : null}
          </Typography>
          <Typography
            variant="caption"
            color="GrayText"
            className="mt-1 d-inline float-end"
          >
            {DateTime.fromISO(props.notification.createdAt).toRelative({
              style: "short",
            })}
          </Typography>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            className="parent-hide-on-hover parent-show-on-hover"
            sx={{ width: "214px", whiteSpace: "nowrap" }}
          >
            <Grid
              item
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <Typography noWrap>
                {props.notification.type === "messages"
                  ? getMessage(props.notification.entityId)
                  : props.notification.type === "task-available"
                  ? getSurveyName(props.notification.entityId)
                  : props.notification.type === "task-overdue"
                  ? getSurveyName(props.notification.entityId)
                  : props.notification.type === "study-invite"
                  ? "Enroll now, or read more."
                  : props.notification.type === "new-registrations"
                  ? consumerEntities[props.notification.entityId] &&
                    consumerEntities[props.notification.entityId].email
                  : null}
              </Typography>
            </Grid>
            <Grid item className="pt-1">
              <Grid container wrap="nowrap" alignItems="center">
                {props.notification.actions &&
                  props.notification.actions.map((action, i) => (
                    <Grid item key={`action-${i}`} className="flex-grow-0">
                      <Button
                        size="small"
                        disabled={isLoading}
                        variant={action.variant}
                        color={action.color}
                        className="py-2 me-1"
                        sx={{ minWidth: "0px", lineHeight: "1" }}
                        onClick={async () => {
                          props.onClose();
                          if (action.actionURL) {
                            if (action.actionURL[0] === "@") {
                              switch (action.actionURL) {
                                case "@handleEnroll":
                                  await handleEnroll(
                                    props.notification.entityId
                                  );
                                  break;
                                default:
                                  return;
                              }
                            } else {
                              dispatch(
                                patchCurrentNotification({
                                  requestingUser: currentAuth,
                                  body: {
                                    data: {
                                      _id: props.notification._id,
                                      isRead: true,
                                    },
                                  },
                                })
                              );
                              navigate(action.actionURL);
                            }
                          }
                        }}
                      >
                        {action.label}
                      </Button>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default AppNotificationItem;
