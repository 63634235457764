// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
// -------------------------------------------------
// Redux
import { patchCurrentUser } from "../redux/reducers/user";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Component elements
import MainCard from "../components/App_MainCard";
// -------------------------------------------------
// Basic elements
import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
// -------------------------------------------------
// Icons
import Done from "@mui/icons-material/Done";
// =================================================
// FUNCTIONAL COMPONENT
const AuthPermissions = () => {
  const { t } = useTranslation("pages", { keyPrefix: "AuthPermissions" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  // -----------------------------------------------
  // Context state
  const { currentAuth, parseErrorMessage } = useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Local state
  const [doRedirect, setDoRedirect] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [agreeToContact, setAgreeToContact] = useState(
    currentUser && currentUser.consent === null
      ? true
      : currentUser &&
        currentUser.consent &&
        currentUser.consent.includes("agreeToContact")
      ? true
      : false
  );
  const [receiveNotifications, setReceiveNotifications] = useState(
    currentUser && currentUser.consent === null
      ? true
      : currentUser &&
        currentUser.consent &&
        currentUser.consent.includes("receiveNotifications")
      ? true
      : false
  );
  const [receiveNewsletters, setReceiveNewsletters] = useState(
    currentUser && currentUser.consent === null
      ? true
      : currentUser &&
        currentUser.consent &&
        currentUser.consent.includes("receiveNewsletters")
      ? true
      : false
  );
  // ===============================================
  // Functions
  // -----------------------------------------------
  // Executes when the user presses the form submit button
  const handleSetPermissions = async (e) => {
    // Prevent default updating
    e.preventDefault();
    // Set loading
    setIsLoading(true);
    // Update user in the database
    try {
      let consent = [];
      if (agreeToContact) {
        consent = [...consent, "agreeToContact"];
      }
      if (receiveNotifications) {
        consent = [...consent, "receiveNotifications"];
      }
      if (receiveNewsletters) {
        consent = [...consent, "receiveNewsletters"];
      }
      await dispatch(
        patchCurrentUser({
          socket,
          requestingUser: currentAuth,
          body: {
            data: { _id: currentUser._id, consent },
          },
        })
      );
      setIsLoading(false);
      setErrorMsg(null);
      setDoRedirect(true);
    } catch (error) {
      // An error occurred, set state
      setDoRedirect(false);
      setIsLoading(false);
      setErrorMsg(parseErrorMessage(error.response.data));
    }
  };
  // ===============================================
  // RENDER COMPONENT
  if (doRedirect) {
    return <Navigate to="/welcome" />;
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE} | {t("Set permissions")}
        </title>
      </Helmet>
      <MainCard isLoading={isLoading}>
        <Typography className="mb-2" variant="h1">
          {t("You're almost set")}
        </Typography>
        <Typography className="mb-3" variant="h3" color="textSecondary">
          {t("Please tell us your preferences and permissions")}
        </Typography>
        {errorMsg !== null && (
          <Alert severity="warning" className="align-items-center mb-3">
            {errorMsg}
          </Alert>
        )}
        <form noValidate onSubmit={handleSetPermissions}>
          <FormGroup column="true">
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isLoading || errorMsg !== null}
                  checked={agreeToContact}
                  color="secondary"
                  name="agree-to-contact"
                  onChange={(e) => {
                    setAgreeToContact(e.target.checked);
                  }}
                />
              }
              label={t(
                "I agree to be contacted to participate in research studies."
              )}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isLoading || errorMsg !== null}
                  checked={receiveNotifications}
                  color="secondary"
                  name="receive-notifications"
                  onChange={(e) => {
                    setReceiveNotifications(e.target.checked);
                  }}
                />
              }
              label={t("I would like to receive email notifications.")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isLoading || errorMsg !== null}
                  checked={receiveNewsletters}
                  color="secondary"
                  name="receive-newsletters"
                  onChange={(e) => {
                    setReceiveNewsletters(e.target.checked);
                  }}
                />
              }
              label={t("I would like to receive email newsletters.")}
            />
          </FormGroup>
          <LoadingButton
            fullWidth
            disabled={isLoading || errorMsg !== null}
            variant="contained"
            type="submit"
            startIcon={<Done />}
            color={isLoading || errorMsg !== null ? "secondary" : "primary"}
          >
            {t("Complete")}
          </LoadingButton>
        </form>
      </MainCard>
    </React.Fragment>
  );
};
// =================================================
// EXPORT COMPONENT
export default AuthPermissions;
