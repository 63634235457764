// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Redux actions
import { setSingleTaskResponseByAlias } from "../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../redux/reducers/previewResponses";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// ------------------------------------------
// Support funct
import { getAliasContainingId } from "../supportFunc/getAliasContainingId";
// =================================================
// FUNCTIONAL COMPONENT
const FormPage = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux, check if this page is enabled and in view
  const dispatch = useDispatch();
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.page._id]
  );
  const responseCollection = useSelector(
    (state) => state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) => state.tickets.currentTicket.responseId
  );
  const viewIdx = useSelector((state) => state.tickets.currentTicket.viewIdx);
  const isInView = useSelector((state) =>
    viewIdx !== null && viewIdx !== undefined
      ? state.form.views[viewIdx].pageId === props.page._id
      : false
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Handles setting a single response by its alias in the appropriate reducer
  const handleSetSingleResponse = (alias, value) => {
    switch (responseCollection) {
      case "taskResponses":
        dispatch(setSingleTaskResponseByAlias({ responseId, alias, value }));
        break;
      case "previewResponses":
        dispatch(setSinglePreviewResponseByAlias({ responseId, alias, value }));
        break;
      default:
        return;
    }
  };
  // -----------------------------------------------
  // When page is disabled, revert back to inital value(s)
  useEffect(() => {
    if (isEnabled === false) {
      props.page.gridContainerList.forEach((gc) => {
        gc.gridItemList.forEach((gi) => {
          gi.articleList
            .filter((article) => article.category === "question")
            .forEach((article) => {
              article.columnList
                ? article.columnList.forEach((column) => {
                    article.rowList.forEach((row) => {
                      handleSetSingleResponse(
                        getAliasContainingId(
                          props.aliasList,
                          `${row._id}_${column._id}`
                        ),
                        article.type === "checkboxes-grid" ? false : null
                      );
                    });
                  })
                : article.rowList
                ? article.rowList.forEach((row) => {
                    handleSetSingleResponse(
                      getAliasContainingId(props.aliasList, row._id),
                      article.type === "checkboxes" ? false : null
                    );
                  })
                : handleSetSingleResponse(
                    getAliasContainingId(props.aliasList, article._id),
                    null
                  );
            });
        });
      });
    }
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return isEnabled && isInView ? (
    <div>
      {props.page.title && (
        <Typography
          variant={props.page.title.variant}
          color={props.page.title.color}
          align={props.page.title.align}
          className="mb-3"
        >
          {props.page.title.text}
        </Typography>
      )}
      <Grid container className="p-0">
        {props.children}
      </Grid>
    </div>
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default FormPage;
