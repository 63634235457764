// =================================================
// IMPORT
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/supervision`;
// -------------------------------------------------
// Use 'createEntityAdapter' to store this slice in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  errorMsg: null,
});
// =================================================
// ASYNC API ACTIONS
export const fetchSupervisionById = createAsyncThunk(
  "supervision/fetchSupervisionById",
  async ({ requestingUser, supervisionId }) => {
    const response = await client.get(
      `${apiurl}/${supervisionId}`,
      requestingUser
    );
    return response.data;
  }
);
// -------------------------------------------------
// API fetch all supervision documents by the user id
export const fetchSupervisionListByUserId = createAsyncThunk(
  "supervision/fetchSupervisionListByUserId",
  async ({ requestingUser, userId }) => {
    const response = await client.get(
      `${apiurl}/userId/${userId}`,
      requestingUser
    );
    return response.data;
  }
);
// -------------------------------------------------
// API fetch all supervision documents from one user and study
export const fetchSupervisionFromOwnedStudies = createAsyncThunk(
  "supervision/fetchSupervisionFromOwnedStudies",
  async ({ requestingUser, userId }) => {
    const response = await client.get(
      `${apiurl}/study-user/${userId}`,
      requestingUser
    );
    return response.data;
  }
);
// -------------------------------------------------
// API patch a supervision object
export const patchCurrentSupervision = createAsyncThunk(
  "supervision/patchCurrentSuperVision",
  async ({ socket, requestingUser, body }) => {
    // Make the call to the database
    const response = await client.patch(
      `${apiurl}/${body.data._id}`,
      requestingUser,
      body
    );
    // Invoke event on server
    socket &&
      socket.emit("patched-current-supervision", {
        userId: response.data.supervision.userId,
        supervisionId: response.data.supervision._id,
      });
    // Return the response
    return response.data;
  }
);
// -------------------------------------------------
// API delete an supervision object by its study id
export const deleteSupervisionByStudyId = createAsyncThunk(
  "supervision/deleteSupervisionByStudyId",
  async ({ socket, requestingUser, studyId }) => {
    // Make the call to the database
    const response = await client.delete(
      `${apiurl}/study/${studyId}`,
      requestingUser
    );
    // Invoke event on server
    socket &&
      socket.emit("deleted-supervision-list", {
        supervisionIdList: response.data.supervisionIdList,
      });
    // Return the response
    return response.data;
  }
);
// -------------------------------------------------
// API delete an supervision object
export const deleteCurrentSupervision = createAsyncThunk(
  "supervision/deleteCurrentSupervision",
  async ({ socket, requestingUser, supervisionId }) => {
    // Make the call to the database
    const response = await client.delete(
      `${apiurl}/${supervisionId}`,
      requestingUser
    );
    // Invoke event on server
    socket &&
      socket.emit("deleted-supervision-list", {
        supervisionIdList: [response.data.supervisionId],
      });
    // Return the response
    return response.data;
  }
);
// =================================================
// DEFINE MUTATING ACTIONS
export const supervisionSlice = createSlice({
  name: "supervision",
  initialState,
  reducers: {
    resetSupervisionError(state) {
      if (state.status === "failed") {
        state.status = "idle";
        state.errorMsg = null;
      }
    },
    removeSupervisionById(state, action) {
      adapter.removeMany(state, action.payload.supervisionIdList);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSupervisionById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSupervisionById.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.supervision &&
          adapter.upsertOne(state, action.payload.supervision);
      })
      .addCase(fetchSupervisionById.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(fetchSupervisionListByUserId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSupervisionListByUserId.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.supervisionList &&
          adapter.upsertMany(state, action.payload.supervisionList);
      })
      .addCase(fetchSupervisionListByUserId.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(fetchSupervisionFromOwnedStudies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSupervisionFromOwnedStudies.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.supervisionList &&
          adapter.upsertMany(state, action.payload.supervisionList);
      })
      .addCase(fetchSupervisionFromOwnedStudies.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(patchCurrentSupervision.pending, (state) => {
        state.status = "loading";
      })
      .addCase(patchCurrentSupervision.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.supervision &&
          adapter.upsertOne(state, action.payload.supervision);
      })
      .addCase(patchCurrentSupervision.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(deleteSupervisionByStudyId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSupervisionByStudyId.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.supervisionIds &&
          adapter.removeMany(state, action.payload.supervisionIds);
      })
      .addCase(deleteSupervisionByStudyId.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(deleteCurrentSupervision.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCurrentSupervision.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.supervisionId &&
          adapter.removeOne(state, action.payload.supervisionId);
      })
      .addCase(deleteCurrentSupervision.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const { resetSupervisionError, removeSupervisionById } =
  supervisionSlice.actions;
// =================================================
// SELECTOR FUNCTIONS
// -------------------------------------------------
export const supervisionSelectors = adapter.getSelectors(
  (state) => state.supervision
);
// =================================================
// EXPORT DEFAULT
export default supervisionSlice.reducer;
