// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import MarkdownText from "./MarkdownText";
// -------------------------------------------------
// Basic elements
import Alert from "@mui/material/Alert";
// =================================================
// FUNCTIONAL COMPONENT
const ArticleInstructions = (props) => {
  // ===============================================
  // RENDER COMPONENT
  return (
    <Alert
      severity={props.variant}
      className="mb-2 p-0 px-2 align-items-center"
    >
      <MarkdownText>{props.text}</MarkdownText>
    </Alert>
  );
};
// =================================================
// EXPORT COMPONENT
export default ArticleInstructions;
