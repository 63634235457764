// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Redux actions
import { selectSingleResponseByAlias } from "../../redux/reducers/form";
// -------------------------------------------------
// Basic elements
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
// =================================================
// FUNCTIONAL COMPONENT
const CheckboxesGridCell = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias)
  );
  // ===============================================
  // RENDER COMPONENT
  return (
    <TableCell
      className="py-1 px-0 text-center"
      onClick={(e) =>
        !props.uneditable && props.handleSetSingleResponse(props.alias, !value)
      }
    >
      <Checkbox
        disabled={props.uneditable}
        checked={value}
        name={props.alias}
        color="secondary"
      />
    </TableCell>
  );
};
// =================================================
// EXPORT COMPONENT
export default CheckboxesGridCell;
