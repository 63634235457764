// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Redux
import { ticketsSelectors } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
// -------------------------------------------------
// Components
import ListItemLater from "./Tasks_ListItemLater";
// -------------------------------------------------
// Transitions
import Fade from "@mui/material/Fade";
// =================================================
// FUNCTIONAL COMPONENT
const TasksListToDoLater = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "tasks.Tasks_ListToDoLater" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const currentUser = useSelector((state) => state.user.currentUser);
  const ghostUser = useSelector((state) => state.user.ghostUser);
  const ticketList = useSelector((state) => ticketsSelectors.selectAll(state));
  const apiStatus = useSelector((state) => state.tickets.status);
  const ticketAvailabilityList = useSelector(
    (state) => state.tickets.ticketAvailabilityList
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Filters
  const userIdFilter = (task) => currentUser && task.userId === currentUser._id;
  const ghostFilter = (task) =>
    !ghostUser && task.completer !== "participant" ? false : true;
  const isCompletedFilter = (task) => task.isCompleted && !task.remainVisible;
  const toDoLaterFilter = (task) =>
    DateTime.fromISO(task.dateAvailable).diffNow().valueOf() > 0;
  const uniqueTaskFilter = (task, i, list) =>
    list.findIndex((t) => t.surveyId === task.surveyId) === i;
  const levelFilter = (task) =>
    parseInt(task.level) ===
      Math.min(
        ...ticketList
          .filter(ghostFilter)
          .filter((task) => !isCompletedFilter(task))
          .map((task) => task.level)
      ) || ghostUser;
  // ===============================================
  // RENDER COMPONENT
  return (
    <List>
      {ticketList
        .filter(userIdFilter)
        .filter(ghostFilter)
        .some(toDoLaterFilter) && (
        <>
          <ListSubheader className="border-bottom">
            <Typography variant="h5" className="my-2">
              { t("Later") }
            </Typography>
          </ListSubheader>
          {ticketList
            .filter(userIdFilter)
            .filter(ghostFilter)
            .filter(toDoLaterFilter)
            .filter(uniqueTaskFilter)
            .filter(levelFilter)
            .sort((a, b) => (a.dateAvailable < b.dateAvailable ? -1 : 1))
            .map((task) => (
              <ListItemLater
                key={task._id}
                task={task}
                surveyId={task.surveyId}
                taskAvailability={ticketAvailabilityList.find(
                  (item) => item._id === task._id
                )}
              />
            ))}
        </>
      )}
      {apiStatus === "succeeded" && (
        <Fade in={true}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item className="text-center">
              <img
                src="images/icon-train-end.png"
                alt={ t("End of the list for now") }
                className="w-50"
              />
            </Grid>
          </Grid>
        </Fade>
      )}
    </List>
  );
};
// =================================================
// EXPORT COMPONENT
export default TasksListToDoLater;
