// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// -------------------------------------------------
// Basic elements
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const FontSelector = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "form.FontSelector" });
  const [selectOpen, setSelectOpen] = useState(false);
  // ===============================================
  // RENDER COMPONENT
  return (
    <FormControl variant="filled" size="small" color="secondary">
      <InputLabel htmlFor="variant">{ t("Variant") }</InputLabel>
      <Select
        name="variant"
        value={props.selFont}
        className="answer-text-green"
        color="secondary"
        onOpen={() => setSelectOpen(true)}
        onClose={() => setSelectOpen(false)}
        onChange={props.setSelFont}>
        <MenuItem value="h1">
          <Typography variant={selectOpen ? "h1" : "body1"}>h1</Typography>
        </MenuItem>
        <MenuItem value="h2">
          <Typography variant={selectOpen ? "h2" : "body1"}>h2</Typography>
        </MenuItem>
        <MenuItem value="h3">
          <Typography variant={selectOpen ? "h3" : "body1"}>h3</Typography>
        </MenuItem>
        <MenuItem value="h4">
          <Typography variant={selectOpen ? "h4" : "body1"}>h4</Typography>
        </MenuItem>
        <MenuItem value="h5">
          <Typography variant={selectOpen ? "h5" : "body1"}>h5</Typography>
        </MenuItem>
        <MenuItem value="h6">
          <Typography variant={selectOpen ? "h6" : "body1"}>h6</Typography>
        </MenuItem>
        <MenuItem value="subtitle1">
          <Typography variant={selectOpen ? "subtitle1" : "body1"}>
            { t("Sub-title 1") }
          </Typography>
        </MenuItem>
        <MenuItem value="subtitle2">
          <Typography variant={selectOpen ? "subtitle2" : "body1"}>
            { t("Sub-title 2") }
          </Typography>
        </MenuItem>
        <MenuItem value="body1">
          <Typography variant={selectOpen ? "body1" : "body1"}>
            { t("Normal") }
          </Typography>
        </MenuItem>
        <MenuItem value="body2">
          <Typography variant={selectOpen ? "body2" : "body1"}>
            { t("Small") }
          </Typography>
        </MenuItem>
        <MenuItem value="caption">
          <Typography variant={selectOpen ? "caption" : "body1"}>
            { t("Smaller") }
          </Typography>
        </MenuItem>
        <MenuItem value="button">
          <Typography variant={selectOpen ? "button" : "body1"}>
            { t("All-caps 1") }
          </Typography>
        </MenuItem>
        <MenuItem value="overline">
          <Typography variant={selectOpen ? "overline" : "body1"}>
            { t("All-caps 2") }
          </Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
// =================================================
// EXPORT COMPONENT
export default FontSelector;
