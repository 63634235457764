// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
// -------------------------------------------------
// Component elements
import DropdownMenuGridItem from "./DropdownMenuGridItem";
// -------------------------------------------------
// Basic elements
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ItemAnswer from "./ItemAnswer";
// ------------------------------------------
// Support funct
import { getAliasContainingId } from "../../supportFunc/getAliasContainingId";
// =================================================
// FUNCTIONAL COMPONENT
const DropdownMenuGrid = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  // -----------------------------------------------
  // Setup the variable class for the table cell first colum width
  const useStyles = makeStyles(() => ({
    firstColumn: {
      padding: "0px",
      paddingRight: "8px",
      width: props.article.options.firstColumnWidth,
      textAlign: "right",
    },
  }));
  const classes = useStyles();
  // ===============================================
  // RENDER COMPONENT
  return !isXS ? (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className={classes.firstColumn}></TableCell>
          {props.article.columnList.map((column) => (
            <TableCell
              key={`col-headers_${props.article._id}_${column._id}`}
              className="p-1 text-center"
            >
              <ItemAnswer text={column.text} />
            </TableCell>
          ))}
        </TableRow>
        {props.article.rowList.map((row, i) => (
          <DropdownMenuGridItem
            key={row._id}
            doShow={props.doShow}
            uneditable={props.uneditable}
            isLast={i === props.article.rowList.length - 1}
            nItems={props.article.rowList.length}
            dependencies={props.dependencies}
            aliasList={props.aliasList}
            scoring={props.scoring}
            article={row}
            columnList={props.article.columnList}
            answerList={props.article.answerList}
            firstColumnWidth={props.article.options.firstColumnWidth}
            articleRequired={props.article.required}
            required={props.article.required || row.required}
            alias={getAliasContainingId(
              props.aliasList,
              `${props.article._id}_${row._id}`,
              true
            )}
          />
        ))}
      </TableBody>
    </Table>
  ) : (
    <Table>
      {props.article.rowList
        .filter((row) => row._id === props.rowId || props.doShow)
        .map((row) => (
          <DropdownMenuGridItem
            key={row._id}
            doShow={props.doShow}
            uneditable={props.uneditable}
            dependencies={props.dependencies}
            aliasList={props.aliasList}
            scoring={props.scoring}
            article={row}
            isFirstItem={row._id === props.article.rowList[0]._id}
            isLastItem={
              row._id ===
              props.article.rowList[props.article.rowList.length - 1]._id
            }
            columnList={props.article.columnList}
            answerList={props.article.answerList}
            firstColumnWidth={props.article.options.firstColumnWidth}
            articleRequired={props.article.required}
            required={props.article.required || row.required}
            alias={getAliasContainingId(
              props.aliasList,
              `${props.article._id}_${row._id}`,
              true
            )}
          />
        ))}
    </Table>
  );
};
// =================================================
// EXPORT COMPONENT
export default DropdownMenuGrid;
