import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";

import { toggleSecDrawer } from "../../redux/reducers/ui";

import {
  ListItem,
  ListItemText,
  Grid,
  Card,
  Typography,
  Button,
} from "@mui/material";

import TopDrawer from "../App_TopDrawer";

import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import Save from "@mui/icons-material/Save";

import CmsEditorEditInfo from "./CmsEditor_EditInfo";

const CmsEditorContentInfo = (props) => {
  // currentWebpage, handleSave, propsInfo
  const dispatch = useDispatch();
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);

  const handleEditWebpageInfo = () => {
    dispatch(toggleSecDrawer({ isOpen: true, id: "CmsEditor_content-info" }));
  };

  const [info, setInfo] = useState(props.info);

  useEffect(() => {
    setInfo(props.info);
  }, [props.info]);

  // -----------------------------------------------
  // Resets local state upon closing of the drawer
  useEffect(() => {
    if (!secDrawerIsOpen) {
      setInfo(info);
    }
  }, [info, secDrawerIsOpen]);

  return (
    <>
      {/* ================================================== */}
      {/* TOP DRAWER */}
      <TopDrawer
        id={"CmsEditor_content-info"}
        title="Webpage Information"
        buttons={
          <Button
            color="inherit"
            startIcon={<Save />}
            className="m-2"
            onClick={() => {
              props.handleSave(info);
              dispatch(
                toggleSecDrawer({ isOpen: false, id: "CmsEditor_content-info" })
              );
            }}
          >
            Save
          </Button>
        }
      >
        <CmsEditorEditInfo obj={info} setObj={setInfo} />
      </TopDrawer>

      {/* SECTION SHOWING CURRENT INFO */}
      <Card className="mb-3">
        <List disablePadding className="px-3 pt-2">
          <ListItem
            disablePadding
            secondaryAction={
              <Tooltip arrow title="Edit" placement="top">
                <IconButton edge="end" onClick={handleEditWebpageInfo}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            }
          >
            <ListItemText
              primary={<Typography variant="h3">Meta-data</Typography>}
            />
          </ListItem>
        </List>
        <List disablePadding className="px-3">
          <ListItem divider>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <ListItemText secondary="TITLE" className="min-width-125px" />
              </Grid>
              <Grid item xs={12} sm={8} md={9} lg={10}>
                <Typography paragraph={false}>{props.info.title}</Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem divider>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <ListItemText secondary="URL" className="min-width-125px" />
              </Grid>
              <Grid item xs={12} sm={8} md={9} lg={10}>
                <Typography paragraph={false}>{props.info.url}</Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem divider>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <ListItemText
                  secondary="DESCRIPTION"
                  className="min-width-125px"
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9} lg={10}>
                <Typography>{props.info.description}</Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem divider>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <ListItemText secondary="STATUS" className="min-width-125px" />
              </Grid>
              <Grid item xs={12} sm={8} md={9} lg={10}>
                <Typography paragraph={false}>{props.info.status}</Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem divider>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <ListItemText secondary="TYPE" className="min-width-125px" />
              </Grid>
              <Grid item xs={12} sm={8} md={9} lg={10}>
                <Typography paragraph={false}>{props.info.type}</Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem divider>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <ListItemText
                  secondary="LAST MODIFIED"
                  className="min-width-125px"
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9} lg={10}>
                <Typography paragraph={false}>
                  {DateTime.fromISO(props.info.updatedAt).toRelativeCalendar()}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Card>
    </>
  );
};

export default CmsEditorContentInfo;
