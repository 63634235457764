// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Redux
import { surveysSelectors } from "../../redux/reducers/surveys";
// -------------------------------------------------
// Components
import AppListItem from "../App_ListItem";
// -------------------------------------------------
// Basic elements
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
// -------------------------------------------------
// Icons
import NotificationsActive from "@mui/icons-material/NotificationsActive";
import EventAvailable from "@mui/icons-material/EventAvailable";
// =================================================
// FUNCTIONAL COMPONENT
const TaskListItemNow = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "tasks.Tasks_ListItemNow",
  });
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the URL search query to the ticket identifier
  const handleSetCurrentTask = (id) => {
    let el;
    el = document.querySelector("#scroll-top-app");
    if (el) {
      el.scrollIntoView({ block: "end" });
    }
    navigate(`/tasks?ticketId=${id}`, { replace: true });
  };
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // For navigating
  const navigate = useNavigate();
  // -----------------------------------------------
  // Redux
  const currentTicket = useSelector((state) => state.tickets.currentTicket);
  const currentSurvey = useSelector(
    (state) =>
      props.task && surveysSelectors.selectById(state, props.task.surveyId)
  );
  // ===============================================
  // RENDER COMPONENT
  return currentSurvey ? (
    <AppListItem
      divider
      slideIn={!props.task.isCompleted || props.task.remainVisible}
      isSelected={currentTicket && props.task._id === currentTicket._id}
      onClick={() => {
        handleSetCurrentTask(props.task._id);
      }}
      icon={
        props.task.dateDue &&
        DateTime.fromISO(props.task.dateDue).diffNow().valueOf() < 0 ? (
          <Tooltip
            title={t("This task is overdue, please fill out immediately.")}
            placement="right"
          >
            <NotificationsActive fontSize="small" className="text-danger" />
          </Tooltip>
        ) : !props.task.hasStarted ? (
          <Badge color="primary" variant="dot" className="mx-2" />
        ) : null
      }
      textPrimary={currentSurvey.name.display}
      textSecondary={
        <span>
          <EventAvailable fontSize="inherit" className="me-1" />
          <Typography variant="caption" color="textSecondary" className="me-2">
            {props.task.dateDue && props.taskAvailability
              ? props.taskAvailability.str
              : t("Any time")}
          </Typography>
        </span>
      }
    />
  ) : (
    <AppListItem
      divider
      slideIn
      isSelected={false}
      textPrimary={<Skeleton type="text" />}
      textSecondary={<Skeleton type="text" />}
    />
  );
};
// =================================================
// EXPORT COMPONENT
export default TaskListItemNow;
