// =================================================
// IMPORT
import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { evaluateSurveyLogic } from "../../supportFunc/evaluateSurveyLogic";
// =================================================
// INIT STATE
const initialState = {
  ticketId: null,
  validation: {},
  liveValidation: false,
  isEnabled: {},
  views: [],
  slideDirection: "left",
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  errorMsg: null,
};
// =================================================
// USE MIDDLEWARE TO GET ACCESS TO THE RESPONSE DATA
// -------------------------------------------------
// Set survey upon initialization of the form
export const initFormSurveyLogic = createAsyncThunk(
  "form/initFormSurveyLogic",
  (payload, thunk) => {
    const state = thunk.getState();
    const responseCollection = state.tickets.currentTicket.responseCollection;
    const responseId = state.tickets.currentTicket.responseId;
    const surveyId = state.tickets.currentTicket.surveyId;
    const responses = state[responseCollection].entities[responseId].data;
    let survey;
    if (
      state.surveys &&
      state.surveys.entities &&
      state.surveys.entities[surveyId]
    ) {
      survey = state.surveys.entities[surveyId];
    } else if (
      state.publicSurveys &&
      state.publicSurveys.entities &&
      state.publicSurveys.entities[surveyId]
    ) {
      survey = state.publicSurveys.entities[surveyId];
    }
    return { ...payload, responses, survey };
  }
);
// -------------------------------------------------
// Set survey logic after changing one single response
export const setFormSurveyLogicByAlias = createAsyncThunk(
  "form/setFormSurveyLogicByAlias",
  (payload, thunk) => {
    const state = thunk.getState();
    const responseCollection = state.tickets.currentTicket.responseCollection;
    const responseId = state.tickets.currentTicket.responseId;
    const surveyId = state.tickets.currentTicket.surveyId;
    const responses = state[responseCollection].entities[responseId].data;
    let survey;
    if (
      state.surveys &&
      state.surveys.entities &&
      state.surveys.entities[surveyId]
    ) {
      survey = state.surveys.entities[surveyId];
    } else if (
      state.publicSurveys &&
      state.publicSurveys.entities &&
      state.publicSurveys.entities[surveyId]
    ) {
      survey = state.publicSurveys.entities[surveyId];
    }
    return { ...payload, responses, survey };
  }
);
// =================================================
// DEFINE MUTATING ACTIONS
// -------------------------------------------------
export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    resetFormError(state) {
      if (state.status === "failed") {
        state.status = "idle";
        state.errorMsg = null;
      }
    },
    resetFormState(state) {
      state.status = "idle"; // indicate that the form is reset
      state.ticketId = null;
      state.validation = {};
      state.liveValidation = false;
      state.isEnabled = {};
      state.views = [];
      state.errorMsg = null;
    },
    setFormStatus(state, action) {
      state.status = action.payload.status;
    },
    setFormTicketId(state, action) {
      state.ticketId = action.payload.ticketId;
    },
    setViews(state, action) {
      if (action.payload.views === -1) {
        state.status = "failed";
        state.errorMsg = "Could not calculate survey views.";
      } else {
        state.views = action.payload.views;
      }
    },
    initValidationByAlias(state, action) {
      state.validation[action.payload.alias] = null;
    },
    appendValidation(state, action) {
      state.validation = {
        ...state.validation,
        ...action.payload.validation.msg,
      };
    },
    toggleLiveValidation(state, action) {
      state.liveValidation = action.payload.liveValidation;
    },
    setSlideDirection(state, action) {
      state.slideDirection = action.payload.slideDirection;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(initFormSurveyLogic.fulfilled, (state, action) => {
        // Check survey logic and update which ones are enabled
        state.isEnabled = evaluateSurveyLogic(
          action.payload.survey,
          action.payload.responses
        );
      })
      .addCase(setFormSurveyLogicByAlias.fulfilled, (state, action) => {
        // Check survey logic and update which ones are enabled
        state.isEnabled = evaluateSurveyLogic(
          action.payload.survey,
          action.payload.responses
        );
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const {
  resetFormError,
  resetFormState,
  setFormStatus,
  setFormTicketId,
  setViews,
  initValidationByAlias,
  appendValidation,
  toggleIsInvalid,
  toggleLiveValidation,
  setSlideDirection,
} = formSlice.actions;
// =================================================
// SELECTOR FUNCTIONS
// -------------------------------------------------
// Memoized selector of a single response by alias
export const selectSingleResponseByAlias = createSelector(
  [
    (state, alias) =>
      state.tickets.currentTicket &&
      state[state.tickets.currentTicket.responseCollection].entities[
        state.tickets.currentTicket.responseId
      ] &&
      state[state.tickets.currentTicket.responseCollection].entities[
        state.tickets.currentTicket.responseId
      ].data[alias],
  ],
  (value) => {
    // Check if the requested alias existed (will be 'undefined' if not)
    if (value === undefined) {
      return null;
    } else {
      return value;
    }
  }
);
// -------------------------------------------------
// Memoized selector of a single response by alias
export const selectMultipleResponsesByAlias = createSelector(
  [
    (state, alias) =>
      state.tickets.currentTicket &&
      state[state.tickets.currentTicket.responseCollection].entities[
        state.tickets.currentTicket.responseId
      ] &&
      Object.keys(
        state[state.tickets.currentTicket.responseCollection].entities[
          state.tickets.currentTicket.responseId
        ].data
      )
        .filter((key) => alias.some((a) => a === key))
        .map(
          (key) =>
            state[state.tickets.currentTicket.responseCollection].entities[
              state.tickets.currentTicket.responseId
            ].data[key]
        ),
  ],
  (values) => {
    // Check if each of the requested aliases existed (will be 'undefined' if not)
    if (!values || values.some((val) => val === undefined)) {
      return null;
    } else {
      return values;
    }
  }
);
// -------------------------------------------------
// Memoized selector of a multiple responses by alias starting with 'alias'
export const selectMultipleResponsesByAliasStartingWith = createSelector(
  [
    (state, alias) =>
      state.tickets.currentTicket &&
      state[state.tickets.currentTicket.responseCollection].entities[
        state.tickets.currentTicket.responseId
      ] &&
      Object.keys(
        state[state.tickets.currentTicket.responseCollection].entities[
          state.tickets.currentTicket.responseId
        ].data
      )
        .filter((key) => {
          const rgx = new RegExp(`^${alias}[a-z]`);
          return rgx.test(key);
        })
        .map(
          (key) =>
            state[state.tickets.currentTicket.responseCollection].entities[
              state.tickets.currentTicket.responseId
            ].data[key]
        ),
  ],
  (values) => {
    // Check if each of the requested aliases existed (will be 'undefined' if not)
    if (!values || values.some((val) => val === undefined)) {
      return null;
    } else {
      return values;
    }
  }
);
// =================================================
// EXPORT DEFAULT
export default formSlice.reducer;
