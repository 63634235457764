// ########################################
// FUNCTION
// ----------------------------------------
// Add leading zeros
export const addLeadingZeros = (number, targetLength) => {
  var sign = number < 0 ? "-" : "";
  var output = Math.abs(number).toString();
  while (output.length < targetLength) {
    output = "0" + output;
  }
  return sign + output;
};
