// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
// -------------------------------------------------
// Components
import SurveysEditLogicSelectOperatorMenu from "./Surveys_EditLogicSelectOperatorMenu";
import SurveysEditLogicSelectAnswerMenu from "./Surveys_EditLogicSelectAnswerMenu";
// -------------------------------------------------
// Basic elements
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import AddCircle from "@mui/icons-material/AddCircle";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteForever from "@mui/icons-material/DeleteForever";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditLogic = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "surveys.Surveys_EditLogic" });
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  const getCondition = (aliasId) => {
    const type = props.aliasList[aliasId].type;
    const answerList = props.aliasList[aliasId].answerList;
    const operator =
      type === "textbox" ||
      type === "textbox-long" ||
      type === "textbox-list" ||
      type === "textbox-grid"
        ? "CONT"
        : "EQ";
    const value =
      type === "textbox" ||
      type === "textbox-long" ||
      type === "textbox-list" ||
      type === "textbox-grid"
        ? ""
        : answerList
        ? answerList[0].value
        : null;
    return {
      aliasId,
      operator,
      value,
    };
  };
  // -----------------------------------------------
  const handleInit = () => {
    props.setObj([
      {
        itemList: [
          {
            condition: getCondition(Object.keys(props.aliasList)[0]),
            itemOperator: null,
          },
        ],
        groupOperator: null,
      },
    ]);
  };
  const handleAddItem = (idx) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[idx].itemList = [
        ...newState[idx].itemList,
        {
          condition: getCondition(Object.keys(props.aliasList)[0]),
          itemOperator: "AND",
        },
      ];
      return newState;
    });
  };
  const handleAddGroup = () => {
    props.setObj((prevState) => {
      let newState = [
        ...prevState,
        {
          itemList: [
            {
              condition: getCondition(Object.keys(props.aliasList)[0]),
              itemOperator: null,
            },
          ],
          groupOperator: "AND",
        },
      ];
      return newState;
    });
  };
  // -----------------------------------------------
  // Sets the item condition operator
  const handleSetConditionAlias = (groupIdx, itemIdx, aliasId) => {
    props.setObj((prevState) => {
      // Deep clone the object
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[groupIdx].itemList[itemIdx].condition = getCondition(aliasId);
      return newState;
    });
  };
  const handleSetConditionOperator = (groupIdx, itemIdx, operator) => {
    props.setObj((prevState) => {
      // Deep clone the object
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[groupIdx].itemList[itemIdx].condition.operator = operator;
      return newState;
    });
  };
  const handleSetConditionValue = (groupIdx, itemIdx, value) => {
    props.setObj((prevState) => {
      // Deep clone the object
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[groupIdx].itemList[itemIdx].condition.value = value;
      return newState;
    });
  };
  // -----------------------------------------------
  // Sets the logic item operator
  const handleSetItemOperator = (groupIdx, itemIdx, operator) => {
    props.setObj((prevState) => {
      // Deep clone the object
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[groupIdx].itemList[itemIdx].itemOperator = operator;
      return newState;
    });
  };
  // -----------------------------------------------
  // Sets the group operator
  const handleSetGroupOperator = (groupIdx, operator) => {
    props.setObj((prevState) => {
      // Deep clone the object
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[groupIdx].groupOperator = operator;
      return newState;
    });
  };
  // -----------------------------------------------
  // Deletes a logic item
  const handleDeleteLogicItem = (groupIdx, itemIdx) => {
    props.setObj((prevState) => {
      // Deep clone the object
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[groupIdx].itemList.splice(itemIdx, 1);
      if (newState[groupIdx].itemList.length === 0) {
        newState.splice(groupIdx, 1);
      }
      if (newState.length === 0) {
        newState = null;
      }
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <Typography variant="h3" className="mb-3">
        {props.obj ? t("Only show if") : t("Is always shown")}
      </Typography>
      {props.obj === null || props.obj.length === 0 ? (
        <Paper className="border border-secondary p-3 shadow-none">
          <Button
            fullWidth
            disabled={
              !props.aliasList || Object.keys(props.aliasList).length === 0
            }
            variant="outlined"
            size="small"
            color="inherit"
            startIcon={<AddCircle />}
            className="border-dashed"
            onClick={() => handleInit()}
          >
            { t("Add condition") }
          </Button>
        </Paper>
      ) : (
        props.obj.map((logicGroup, i) => (
          <React.Fragment key={`logic_group-${i}`}>
            {i > 0 && (
              <Grid item xs={12} className="ps-3 mb-3">
                <FormControl
                  fullWidth
                  variant="filled"
                  color="secondary"
                  size="small"
                >
                  <Select
                    hiddenLabel
                    name="item"
                    value={logicGroup.groupOperator}
                    className="answer-text-green fix-width-100px"
                    color="secondary"
                    onChange={(e) => handleSetGroupOperator(i, e.target.value)}
                  >
                    <MenuItem value="AND">{ t("AND") }</MenuItem>
                    <MenuItem value="OR">{ t("OR") }</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Paper className="border border-secondary p-3 mb-3 shadow-none">
              {logicGroup.itemList.map((logicItem, j) => (
                <React.Fragment key={`logic_group-${i}_item-${j}`}>
                  <Grid container className="mb-3">
                    {j > 0 && (
                      <Grid item xs={12} className="ps-3 mb-3">
                        <FormControl
                          fullWidth
                          variant="filled"
                          color="secondary"
                          size="small"
                        >
                          <Select
                            hiddenLabel
                            name="item"
                            value={logicItem.itemOperator}
                            className="answer-text-green fix-width-100px"
                            color="secondary"
                            onChange={(e) =>
                              handleSetItemOperator(i, j, e.target.value)
                            }
                          >
                            <MenuItem value="AND">{ t("AND") }</MenuItem>
                            <MenuItem value="OR">{ t("OR") }</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={3} className="pe-2">
                      <FormControl
                        fullWidth
                        variant="filled"
                        color="secondary"
                        size="small"
                      >
                        <Select
                          autoWidth
                          hiddenLabel
                          name="aliasId"
                          value={logicItem.condition.aliasId}
                          className="answer-text-green"
                          color="secondary"
                          onChange={(e) =>
                            handleSetConditionAlias(i, j, e.target.value)
                          }
                        >
                          {props.aliasList &&
                            Object.keys(props.aliasList).map(
                              (key) =>
                                props.aliasList[key].type !== "date-time" &&
                                props.aliasList[key].type !== "date" &&
                                props.aliasList[key].type !== "time" && (
                                  <MenuItem key={key} value={key}>
                                    <Chip
                                      label={
                                        <Typography
                                          variant="overline"
                                          color="textSecondary"
                                        >
                                          {props.aliasList[key].alias}
                                        </Typography>
                                      }
                                      size="small"
                                      className="me-2"
                                    />
                                    {props.aliasList[key].title}
                                  </MenuItem>
                                )
                            )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={5} className="pe-2">
                      {props.aliasList &&
                        props.aliasList[logicItem.condition.aliasId] && (
                          <FormControl
                            fullWidth
                            variant="filled"
                            color="secondary"
                            size="small"
                          >
                            <SurveysEditLogicSelectOperatorMenu
                              type={
                                props.aliasList[logicItem.condition.aliasId]
                                  .type
                              }
                              groupIdx={i}
                              itemIdx={j}
                              value={logicItem.condition.operator}
                              onChange={(e) =>
                                handleSetConditionOperator(i, j, e.target.value)
                              }
                            />
                          </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={3} className="pe-2">
                      {props.aliasList &&
                        props.aliasList[logicItem.condition.aliasId] && (
                          <SurveysEditLogicSelectAnswerMenu
                            operator={logicItem.condition.operator}
                            value={logicItem.condition.value}
                            answerList={
                              props.aliasList[logicItem.condition.aliasId]
                                .answerList
                            }
                            reverseValue={
                              props.aliasList[logicItem.condition.aliasId]
                                .reverseValue
                            }
                            onChange={(e) =>
                              handleSetConditionValue(i, j, e.target.value)
                            }
                          />
                        )}
                    </Grid>
                    <Grid item align="right" xs={1} className="pe-2">
                      <Tooltip arrow title={ t("Delete page") } placement="top">
                        <IconButton
                          edge="end"
                          onClick={() => handleDeleteLogicItem(i, j)}
                        >
                          <DeleteForever />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
              <Button
                fullWidth
                disabled={!props.aliasList || props.aliasList.length === 0}
                variant="outlined"
                size="small"
                color="inherit"
                startIcon={<AddCircle />}
                className="border-dashed"
                onClick={() => handleAddItem(i)}
              >
                { t("Add condition") }
              </Button>
            </Paper>
          </React.Fragment>
        ))
      )}
      {props.obj && (
        <Button
          fullWidth
          disabled={!props.aliasList || props.aliasList.length === 0}
          variant="outlined"
          size="small"
          color="inherit"
          startIcon={<AddCircle />}
          className="border-dashed"
          onClick={() => handleAddGroup()}
        >
          { t("Add group") }
        </Button>
      )}
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditLogic;
