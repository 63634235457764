// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
// -------------------------------------------------
// Support functions
import { unique } from "../../supportFunc/unique";
// -------------------------------------------------
// Component elements
import ItemQuestion from "./ItemQuestion";
import ItemAnswer from "./ItemAnswer";
import DropdownMenuGridCell from "./DropdownMenuGridCell";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  setFormSurveyLogicByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
// -------------------------------------------------
// Basic elements
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import Slide from "@mui/material/Slide";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const DropdownMenuGridItem = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id]
  );
  const isXS = useSelector((state) => state.ui.isXS);
  const slideDirection = useSelector((state) => state.form.slideDirection);
  // -----------------------------------------------
  // Setup the variable class for the table cell first colum width
  const useStyles = makeStyles(() => ({
    firstColumn: {
      padding: "0px",
      paddingRight: "8px",
      width: props.firstColumnWidth,
    },
  }));
  const classes = useStyles();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Handles setting a single response by its alias in the appropriate reducer
  const handleSetSingleResponse = async (alias, value) => {
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value })
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value })
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
  };
  // -----------------------------------------------
  useEffect(() => {
    // If the item is now disabled, remove any prior response
    if (!isEnabled) {
      props.alias.forEach((a) => {
        handleSetSingleResponse(a, null);
      });
      return;
    }
    // Clear any validation when component unmounts
    return () => {
      props.alias.forEach((a) => {
        dispatch(initValidationByAlias({ alias: a }));
      });
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // SUB-COMPONENT
  const MenuItems = () => {
    const groupList = unique(
      props.answerList.map((answer) => (answer.group ? answer.group : ""))
    );
    const itemList = (g) => {
      return props.answerList.map((answer, j) => {
        return !answer.group || answer.group === g ? (
          <MenuItem
            key={`dropdown-item_preview_${props.article._id}_${g}_${j}`}
            value={
              props.article.reverseValue
                ? props.answerList[props.answerList.length - j - 1].value
                : answer.value
            }
          >
            <div className="d-flex">
              {props.doShow && (
                <Chip
                  label={
                    <Typography variant="overline" color="textSecondary">
                      {props.article.reverseValue
                        ? props.answerList[props.answerList.length - j - 1]
                            .value
                        : answer.value}
                    </Typography>
                  }
                  variant={props.article.reverseValue ? "outlined" : "filled"}
                  size="small"
                  className="me-2"
                />
              )}
              <ItemAnswer text={answer.text} />
            </div>
          </MenuItem>
        ) : null;
      });
    };
    return groupList.map((group, i) => {
      return [
        <ListSubheader key={`dropdown-item_preview_${props.article._id}_${i}`}>
          {group}
        </ListSubheader>,
        itemList(group),
      ];
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (isEnabled || props.doShow) && !isXS ? (
    <>
      <TableRow className="answer-row">
        <TableCell className={classes.firstColumn}>
          <ItemQuestion
            item={props.article}
            required={
              !props.articleRequired &&
              !props.article.inputLabel &&
              props.required
            }
            text={props.article.text}
            doShow={props.doShow}
            dependencies={props.dependencies}
            aliasList={props.aliasList}
            scoring={props.scoring}
            alias={props.alias}
            justifyContent="flex-start"
            alignItems="center"
          />
        </TableCell>
        {props.columnList.map((column, i) => (
          <DropdownMenuGridCell
            key={`${props.article._id}_${column._id}`}
            doShow={props.doShow}
            uneditable={props.uneditable}
            isLastColumn={
              column._id === props.columnList[props.columnList.length - 1]._id
            }
            article={props.article}
            answerList={props.answerList}
            required={props.required}
            inputLabel={column.inputLabel}
            articleRequired={props.articleRequired}
            alias={Array.isArray(props.alias) ? props.alias[i] : props.alias}
          />
        ))}
      </TableRow>
      {props.doShow && props.isLast && (
        <TableRow>
          <TableCell className={classes.firstColumn} />
          <TableCell colSpan={props.nItems} className="p-2">
            <div className="shadow rounded">{MenuItems()}</div>
          </TableCell>
        </TableRow>
      )}
    </>
  ) : (isEnabled || props.doShow) && isXS ? (
    <Slide
      direction={slideDirection}
      in={true}
      appear={
        (slideDirection === "left" && !props.isFirstItem) ||
        (slideDirection === "right" && !props.isLastItem)
      }
    >
      <TableBody>
        <TableRow>
          <TableCell colSpan={2} className={classes.firstColumn}>
            <ItemQuestion
              name={props.alias[0]}
              item={props.article}
              required={
                !props.articleRequired &&
                !props.article.inputLabel &&
                props.required
              }
              text={props.article.text}
              doShow={props.doShow}
              dependencies={props.dependencies}
              aliasList={props.aliasList}
              scoring={props.scoring}
              alias={props.alias}
              justifyContent="flex-start"
              alignItems="center"
              classNames="p-2"
            />
          </TableCell>
        </TableRow>
        {props.columnList.map((column, i) => (
          <TableRow key={column._id}>
            <TableCell className={classes.firstColumn}>
              <ItemAnswer text={column.text} />
            </TableCell>
            <DropdownMenuGridCell
              doShow={props.doShow}
              uneditable={props.uneditable}
              isLastColumn={true}
              article={props.article}
              answerList={props.answerList}
              required={props.required}
              inputLabel={column.inputLabel}
              articleRequired={props.articleRequired}
              alias={Array.isArray(props.alias) ? props.alias[i] : props.alias}
            />
          </TableRow>
        ))}
        {props.doShow && props.isLastItem && (
          <TableRow>
            <TableCell className={classes.firstColumn} />
            <TableCell className="p-2">
              <div className="shadow rounded">{MenuItems()}</div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Slide>
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default DropdownMenuGridItem;
