// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
// -------------------------------------------------
// Component elements
import ItemQuestion from "./ItemQuestion";
import ArticleFormControl from "./ArticleFormControl";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import InputAdornment from "@mui/material/InputAdornment";
// =================================================
// FUNCTIONAL COMPONENT
const TextboxListItem = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [localValidation, setLocalValidation] = useState(null);
  const [localValue, setLocalValue] = useState("");
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias)
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id]
  );
  // -----------------------------------------------
  // Setup the variable class for the table cell first colum width
  const useStyles = makeStyles(() => ({
    firstColumn: {
      padding: "0px",
      paddingRight: "8px",
      width: props.firstColumnWidth,
    },
  }));
  const classes = useStyles();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize validation upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // Set local state
    setLocalValue(value !== null ? value : "");
    // If the item is now disabled, remove any prior response
    if (!isEnabled) {
      handleSetSingleResponse(props.alias, null);
      return;
    }
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
    // Clear any validation when component unmounts
    return () => {
      dispatch(initValidationByAlias({ alias: props.alias }));
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({
            responseId,
            alias,
            value,
            disablemetadata: true,
          })
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value })
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // If the item is disabled, we don't have to set 'hasStarted' or do validation
    if (!isEnabled) {
      return;
    }
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      })
    );
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // -------------------------------------------------
  // Render input adornments
  const inputAdornment =
    props.article.options &&
    props.article.options.startAdornment &&
    props.article.options.endAdornment
      ? {
          startAdornment: (
            <InputAdornment position="start">
              {props.article.options.startAdornment}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {props.article.options.endAdornment}
            </InputAdornment>
          ),
        }
      : props.article.options &&
        props.article.options.startAdornment &&
        !props.article.options.endAdornment
      ? {
          startAdornment: (
            <InputAdornment position="start">
              {props.article.options.startAdornment}
            </InputAdornment>
          ),
        }
      : props.article.options &&
        !props.article.options.startAdornment &&
        props.article.options.endAdornment
      ? {
          endAdornment: (
            <InputAdornment position="end">
              {props.article.options.endAdornment}
            </InputAdornment>
          ),
        }
      : {};
  // ===============================================
  // RENDER COMPONENT
  return isEnabled || props.doShow ? (
    <TableRow className="answer-row">
      <TableCell className={classes.firstColumn}>
        <ItemQuestion
          item={props.article}
          required={!props.articleRequired && props.required}
          text={props.article.text}
          doShow={props.doShow}
          dependencies={props.dependencies}
          aliasList={props.aliasList}
          scoring={props.scoring}
          alias={props.alias}
          justifyContent="flex-start"
          alignItems="center"
        />
      </TableCell>
      <TableCell className="py-1 px-0">
        <ArticleFormControl
          article={props.article}
          required={!props.articleRequired && props.required}
          isInvalid={localValidation && localValidation.isInvalid}
          validationMsg={localValidation && localValidation.msg[props.alias]}
        >
          <TextField
            disabled={props.uneditable}
            hiddenLabel={!props.article.inputLabel}
            name={props.alias}
            type={props.type}
            label={props.article.inputLabel}
            value={localValue}
            color="secondary"
            variant="filled"
            className="bg-white answer-text-green mt-1"
            InputProps={inputAdornment}
            inputProps={props.type === "number" ? { inputMode: "numeric" } : {}}
            sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
            onChange={(e) => {
              setLocalValue(e.target.value);
              handleSetSingleResponse(props.alias, e.target.value);
            }}
            onWheel={(e) => e.target.blur()}
          />
        </ArticleFormControl>
      </TableCell>
    </TableRow>
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default TextboxListItem;
