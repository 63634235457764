// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import MarkdownText from "./MarkdownText";
// -------------------------------------------------
// Basic elements
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const ItemAnswer = (props) => {
  // ===============================================
  // RENDER COMPONENT
  return (
    <Typography variant="body1" className={props.classNames}>
      <MarkdownText>{props.text}</MarkdownText>
    </Typography>
  );
};
// =================================================
// EXPORT COMPONENT
export default ItemAnswer;
