// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
import { unique } from "../../supportFunc/unique";
// -------------------------------------------------
// Component elements
import ArticleFormControl from "./ArticleFormControl";
import ItemAnswer from "./ItemAnswer";
// -------------------------------------------------
// Redux actions
import {
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import InputLabel from "@mui/material/InputLabel";
import TableCell from "@mui/material/TableCell";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const DropdownMenuGridCell = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "form.DropdownMenuGridCell",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [localValidation, setLocalValidation] = useState(null);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias)
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id]
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize validation upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value })
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value })
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      })
    );
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // ===============================================
  // SUB-COMPONENT
  const MenuItems = () => {
    const groupList = unique(
      props.answerList.map((answer) => (answer.group ? answer.group : ""))
    );
    const itemList = (g) => {
      return props.answerList.map((answer, j) => {
        return !answer.group || answer.group === g ? (
          <MenuItem
            key={`dropdown-item_${props.alias}_${g}_${answer.value}-${j}]`}
            value={
              props.article.reverseValue
                ? props.answerList[props.answerList.length - j - 1].value
                : answer.value
            }
          >
            <div className="d-flex">
              {props.doShow && (
                <Chip
                  label={
                    <Typography variant="overline" color="textSecondary">
                      {props.article.reverseValue
                        ? props.answerList[props.answerList.length - j - 1]
                            .value
                        : answer.value}
                    </Typography>
                  }
                  variant={props.article.reverseValue ? "outlined" : "filled"}
                  size="small"
                  className="me-2"
                />
              )}
              <ItemAnswer text={answer.text} />
            </div>
          </MenuItem>
        ) : null;
      });
    };
    return groupList.map((group, i) => {
      return [
        <ListSubheader key={`dropdown-item_${props.alias}_${group}_${i}`}>
          {group}
        </ListSubheader>,
        itemList(group),
      ];
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    (isEnabled || props.doShow) && (
      <TableCell
        className={props.isLastColumn ? "py-1 px-0" : "py-1 ps-0 pe-2"}
      >
        <ArticleFormControl
          article={props.article}
          required={!props.articleRequired && props.required}
          isInvalid={localValidation && localValidation.isInvalid}
          validationMsg={localValidation && localValidation.msg[props.alias]}
          hiddenLabel={props.inputLabel ? false : true}
        >
          {props.inputLabel && (
            <InputLabel
              id={`input-label_${props.alias}`}
              color="secondary"
              className={props.article.formLabel ? "top-24px" : ""}
            >
              {props.inputLabel}
            </InputLabel>
          )}
          <Select
            disabled={props.uneditable}
            name={props.alias}
            value={value ? value : ""}
            label={props.inputLabel ? props.inputLabel : false}
            labelId={props.inputLabel ? `input-label_${props.alias}` : ""}
            color="secondary"
            onChange={(e) =>
              handleSetSingleResponse(props.alias, e.target.value)
            }
            className="answer-text-green"
          >
            <MenuItem value="">
              <em>{value ? t("Clear") : t("Select")}</em>
            </MenuItem>
            {MenuItems()}
          </Select>
        </ArticleFormControl>
      </TableCell>
    )
  );
};
// =================================================
// EXPORT COMPONENT
export default DropdownMenuGridCell;
