// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Contexts
import { useAuth } from "../../contexts/auth";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../../redux/reducers/ui";
import { fetchSurveyTranslationsById } from "../../redux/reducers/surveys";
// -------------------------------------------------
// Component elements
import SurveysEditTranslate from "./Surveys_EditTranslate";
import TopDrawer from "../App_TopDrawer";
// -------------------------------------------------
// Basic elements
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
// -------------------------------------------------
// Icons
import Save from "@mui/icons-material/Save";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const SurveysContentTranslate = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Context
  const { currentAuth } = useAuth();
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const surveysStatus = useSelector((state) => state.surveys.status);
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  // -----------------------------------------------
  // Local state
  const [obj, setObj] = useState(props.translateSurvey);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Resets local state upon closing of the drawer
  useEffect(() => {
    if (!secDrawerIsOpen) {
      setObj(props.translateSurvey);
    }
  }, [secDrawerIsOpen, props.translateSurvey]);
  // ===============================================
  // FUNCTION: READ FROM DB
  const handleFetchCurrentSurveyTranslations = async () => {
    await dispatch(
      fetchSurveyTranslationsById({
        requestingUser: currentAuth,
        surveyId: props.surveyId,
        upsert: true,
      })
    );
    dispatch(toggleSecDrawer({ isOpen: true, id: "surveys_translate" }));
  };
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      {/* ================================================== */}
      {/* TOP DRAWER */}
      {obj && (
        <TopDrawer
          id="surveys_translate"
          title="Translate survey"
          width="80%"
          buttons={
            <Button
              disabled={surveysStatus === "loading"}
              color="inherit"
              startIcon={<Save />}
              className="m-2"
              onClick={() => props.handleMutateCurrentSurvey("translate", obj)}
            >
              {surveysStatus === "loading" ? (
                <CircularProgress size="1.5rem" className="text-light" />
              ) : (
                "Save"
              )}
            </Button>
          }
        >
          <SurveysEditTranslate obj={obj} setObj={setObj} />
        </TopDrawer>
      )}
      {/* SECTION SHOWING CURRENT INFO */}
      <Alert
        severity="info"
        name="checkme"
        className="shadow-sm mb-2 "
        icon={<Icon>language</Icon>}
        action={
          <Button
            color="inherit"
            onClick={handleFetchCurrentSurveyTranslations}
          >
            Translate
          </Button>
        }
      >
        The language you are editing this survey in is '
        {currentUser.locale.toUpperCase()}'.
      </Alert>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysContentTranslate;
