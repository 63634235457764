// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
// -------------------------------------------------
// Components
import AppRoot from "../components/App_Root";
import ResultsContent from "../components/Results_Content";
// -------------------------------------------------
// Redux
import { fetchStudyList } from "../redux/reducers/studies";
import { fetchSurveyList } from "../redux/reducers/surveys";
import { fetchConsumerList } from "../redux/reducers/consumers";
// -------------------------------------------------
// Basic elements
import Toolbar from "@mui/material/Toolbar";
// =================================================
// FUNCTIONAL COMPONENT
// TODO: Make some interface to inspect, edit and delete a single response
const Results = () => {
  const { t } = useTranslation("pages", { keyPrefix: "Results" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Contexts
  const { currentAuth } = useAuth();
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const studiesStatus = useSelector((state) => state.studies.status);
  const surveysStatus = useSelector((state) => state.surveys.status);
  const consumersStatus = useSelector((state) => state.consumers.status);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Upon render, get the studies list and set the redux state
  useEffect(() => {
    // Fetch all studies from DB if not done yet
    if (studiesStatus === "idle") {
      dispatch(fetchStudyList({ requestingUser: currentAuth }));
    }
    // Fetch all surveys from DB if not done yet
    if (surveysStatus === "idle" || surveysStatus === "partial") {
      dispatch(fetchSurveyList({ requestingUser: currentAuth }));
    }
    if (consumersStatus === "idle") {
      dispatch(fetchConsumerList({ requestingUser: currentAuth }));
    }
  }, [studiesStatus, surveysStatus, consumersStatus]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  return (
    <AppRoot>
      <Helmet>
        <title>{ process.env.REACT_APP_TITLE } | { t("Studies") }</title>
      </Helmet>
      <Toolbar />
      <ResultsContent />
    </AppRoot>
  );
};
// =================================================
// EXPORT COMPONENT
export default Results;
