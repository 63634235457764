// ########################################
// IMPORT
// ========================================
// Dependencies
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
// ========================================
// Contexts
import { AuthProvider } from "./contexts/auth";
import { SocketProvider } from "./contexts/socket";
import DateAdapter from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <SocketProvider>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <I18nextProvider i18n={i18next}>
              <App />
            </I18nextProvider>
          </LocalizationProvider>
        </SocketProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
