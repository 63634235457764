// ########################################
// IMPORT
// ========================================
// Dependencies
import React from "react";
import { useSelector } from "react-redux";
// ========================================
// Material UI
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
// ########################################
// FUNCTIONAL COMPONENT
const ScrollColumn = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  const isSM = useSelector((state) => state.ui.isSM);
  const currentPage = useSelector((state) => state.ui.currentPage);
  // ========================================
  // RETURN
  return (
    <Grid
      item
      xs={12}
      sm="auto"
      className={props.isLoading ? "flex-grow-0 opacity-75" : "flex-grow-0"}
    >
      {isXS ? (
        <Card>
          <Toolbar />
          {props.isLoading && (
            <LinearProgress
              color="secondary"
              className="rounded lin-prog-margin-4px"
            />
          )}
          {props.children}
        </Card>
      ) : (
        <Grid container direction="column" wrap="nowrap" className="vh-100">
          <Toolbar />
          <Grid item className="flex-grow-0 fix-width-226px">
            {!isXS && !isSM && (
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                wrap="nowrap"
              >
                <Grid item>
                  <Typography variant="h2" className="my-2">
                    {currentPage}
                  </Typography>
                </Grid>
                <Grid item>{props.header}</Grid>
              </Grid>
            )}
          </Grid>
          <Grid item className="flex-grow-1 fix-width-226px overflow-hidden">
            <Paper className="h-100 overflow-auto">
              {props.isLoading && (
                <LinearProgress
                  color="secondary"
                  className="rounded lin-prog-margin-4px"
                />
              )}
              {props.children}
            </Paper>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
// ########################################
// EXPORT
export default ScrollColumn;
