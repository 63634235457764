// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Components
import AppRoot from "../components/App_Root";
import ScrollColumn from "../components/App_ScrollColumn";
import ContentPanel from "../components/App_ContentPanel";
import InvestigatorsColumnContent from "../components/Investigators_ColumnContent";
import InvestigatorsContent from "../components/Investigators_Content";
import InvestigatorsInvite from "../components/investigators/Investigators_Invite.js";
// -------------------------------------------------
// Redux
import {
  togglePrimDrawer,
  toggleSecDrawer,
  setAlert,
} from "../redux/reducers/ui";
import {
  fetchConsumerList,
  consumersSelectors,
  patchCurrentConsumer,
} from "../redux/reducers/consumers";
import { fetchStudyList } from "../redux/reducers/studies";
import { deleteTicketsFromOneUserAndStudy } from "../redux/reducers/tickets.js";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// -------------------------------------------------
// Icons
import AddCircle from "@mui/icons-material/AddCircle";
// =================================================
// FUNCTIONAL COMPONENT
const Investigators = () => {
  const { t } = useTranslation("pages", { keyPrefix: "Investigators" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Contexts
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Local state
  const [currentConsumerId, setCurrentConsumerId] = useState(false);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const primDrawerIsOpen = useSelector((state) => state.ui.primDrawerIsOpen);
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  const currentRole = useSelector(
    (state) => state.user.currentUser && state.user.currentUser.primaryRole
  );
  const consumersStatus = useSelector((state) => state.consumers.status);
  const currentConsumer = useSelector((state) =>
    consumersSelectors.selectById(state, currentConsumerId)
  );
  const studiesStatus = useSelector((state) => state.studies.status);
  // -----------------------------------------------
  // Browser URL location
  const location = useLocation();
  const navigate = useNavigate();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Handles saving the current study to the database
  const handlePatchCurrentConsumer = async (consumerId, newRole) => {
    // Update the database
    await dispatch(
      patchCurrentConsumer({
        socket,
        requestingUser: currentAuth,
        body: {
          data: {
            _id: consumerId,
            primaryRole: newRole,
            studyEnrollmentList: [], // Unsubscribe from all studies
          },
        },
      })
    );
    // Delete any existing tickets
    dispatch(
      deleteTicketsFromOneUserAndStudy({
        socket,
        requestingUser: currentAuth,
        userId: consumerId,
        studyId: "any",
      })
    );
    // Close the drawer
    dispatch(toggleSecDrawer({ isOpen: false }));
    // If the admin role has been revoked, navigate to root
    if (newRole === "participant") {
      navigate("/investigators", {
        replace: true,
      });
    }
    // Provide feedback to the user
    dispatch(
      setAlert({
        type: "snackbar",
        variant: "success",
        message: t("Role saved"),
        duration: 6000,
      })
    );
  };
  // -----------------------------------------------
  // Upon render, get the studies list and set the redux state
  useEffect(() => {
    // Fetch all studies from DB if not done yet
    if (studiesStatus === "idle") {
      dispatch(fetchStudyList({ requestingUser: currentAuth }));
    }
    // Fetch all consumers from DB if not done yet
    if (consumersStatus === "idle") {
      dispatch(fetchConsumerList({ requestingUser: currentAuth }));
    }
    // Get the study-id from URL and get the current study
    if (consumersStatus === "succeeded") {
      const searchParams = new URLSearchParams(location.search);
      const consumerId = searchParams.get("consumerId");
      if (consumerId) {
        setCurrentConsumerId(consumerId);
        if (!primDrawerIsOpen) {
          dispatch(togglePrimDrawer({ isOpen: true }));
        }
      } else {
        setCurrentConsumerId(null);
        if (primDrawerIsOpen) {
          dispatch(togglePrimDrawer({ isOpen: false }));
        }
        if (secDrawerIsOpen) {
          dispatch(toggleSecDrawer({ isOpen: false }));
        }
      }
    }
  }, [studiesStatus, consumersStatus, location.search]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // SUB-COMPONENTS
  // -----------------------------------------------
  const ColumnHeader =
    currentRole && (currentRole === "superuser" || currentRole === "admin") ? (
      <Tooltip arrow title={t("Add investigator")} placement="top">
        <IconButton
          color="inherit"
          onClick={(e) => {
            dispatch(toggleSecDrawer({ isOpen: true, id: "invite-roles" }));
          }}
        >
          <AddCircle />
        </IconButton>
      </Tooltip>
    ) : null;
  // ===============================================
  // RENDER COMPONENT
  return (
    <AppRoot header={ColumnHeader}>
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE} | {t("Investigators")}
        </title>
      </Helmet>
      <InvestigatorsInvite
        handlePatchCurrentConsumer={handlePatchCurrentConsumer}
      />
      <Grid container className="w-100" wrap="nowrap">
        {/* Scroll column */}
        <ScrollColumn
          header={ColumnHeader}
          isLoading={consumersStatus === "loading"}
        >
          <InvestigatorsColumnContent currentConsumer={currentConsumer} />
        </ScrollColumn>
        {/* Content panel */}
        <ContentPanel
          title={currentConsumer ? currentConsumer.userNumber : null}
          isLoading={consumersStatus === "loading"}
        >
          <InvestigatorsContent
            currentConsumer={currentConsumer}
            handlePatchCurrentConsumer={handlePatchCurrentConsumer}
          />
        </ContentPanel>
      </Grid>
    </AppRoot>
  );
};
// =================================================
// EXPORT COMPONENT
export default Investigators;
