// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Support functions
import { array2string } from "../../supportFunc/array2string";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../../redux/reducers/ui";
// -------------------------------------------------
// Component elements
import SurveysEditScore from "./Surveys_EditScore";
import TopDrawer from "../App_TopDrawer";
// -------------------------------------------------
// Basic elements
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
// -------------------------------------------------
// Icons
import Add from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Save from "@mui/icons-material/Save";
import Link from "@mui/icons-material/Link";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const SurveysContentScoring = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_ContentScoring",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const surveysStatus = useSelector((state) => state.surveys.status);
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  // -----------------------------------------------
  // Local state
  const hasDependencies = props.obj
    ? (props.dependencies &&
        Object.keys(props.dependencies).some((dep) =>
          dep.includes(props.obj._id)
        )) ||
      props.scoring.some(
        (score) =>
          (score.arithmetic &&
            score.arithmetic.some((a) => a.aliasId.includes(props.obj._id))) ||
          (score.grouping && score.grouping.aliasId.includes(props.obj._id))
      )
    : false;
  const [idx, setIdx] = useState(props.idx);
  const [obj, setObj] = useState(props.obj);
  const [deleteMenuIsOpen, setDeleteMenuIsOpen] = useState(false);
  const [deleteMenuAnchor, setDeleteMenuAnchor] = useState(null);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Get scored aliases
  const getScoredAliasList = () => {
    const aliasList =
      props.scoring &&
      props.scoring.map(
        (score) =>
          (score.arithmetic &&
          score.arithmetic.some((a) => a.aliasId.includes(props.obj._id))
            ? score.alias
            : null) ||
          (score.grouping && score.grouping.aliasId.includes(props.obj._id)
            ? score.alias
            : null)
      );
    return array2string(aliasList);
  };
  // -----------------------------------------------
  // Handles opening the top drawer for adding survey scoring
  const handleAdd = () => {
    let newObj = {
      _id: uuid(),
      alias: "",
      method: "arithmetic",
      arithmetic: [],
      grouping: null,
    };
    setIdx(props.idx + 1);
    setObj(newObj);
    dispatch(toggleSecDrawer({ isOpen: true, id: newObj._id }));
  };
  // -----------------------------------------------
  // Handles opening the top drawer for changing survey scoring
  const handleEdit = (id) => {
    setIdx(props.idx);
    dispatch(toggleSecDrawer({ isOpen: true, id }));
  };
  // -----------------------------------------------
  // Resets local state upon closing of the drawer
  useEffect(() => {
    if (!secDrawerIsOpen) {
      setObj(props.obj);
    }
  }, [props.obj, secDrawerIsOpen]);
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      {/* ================================================== */}
      {/* TOP DRAWER */}
      {obj && (
        <TopDrawer
          id={obj._id}
          title={t("Edit scoring")}
          buttons={
            <Button
              disabled={
                surveysStatus === "loading" ||
                props.disabled ||
                (obj.method === "arithmetic" && obj.arithmetic.length < 2)
              }
              color="inherit"
              startIcon={<Save />}
              className="m-2"
              onClick={() =>
                props.handleMutateCurrentSurvey("scoring", obj, props.idx)
              }
            >
              {surveysStatus === "loading" ? (
                <CircularProgress size="1.5rem" className="text-light" />
              ) : (
                t("Save")
              )}
            </Button>
          }
        >
          <SurveysEditScore
            obj={obj}
            setObj={setObj}
            acronym={props.acronym}
            aliasList={
              props.aliasList &&
              Object.keys(props.aliasList)
                .filter(
                  (key) =>
                    props.aliasList[key].type !== "textbox" &&
                    props.aliasList[key].type !== "textbox-long" &&
                    props.aliasList[key].type !== "textbox-list" &&
                    props.aliasList[key].type !== "textbox-grid" &&
                    props.aliasList[key].type !== "checkboxes" &&
                    props.aliasList[key].type !== "checkboxes-list" &&
                    props.aliasList[key].type !== "checkboxes-grid" &&
                    props.aliasList[key].type !== "date-time" &&
                    props.aliasList[key].type !== "date" &&
                    props.aliasList[key].title !== "classification-label"
                )
                .filter((key) =>
                  props.aliasList[key].scoreIdx !== undefined
                    ? props.aliasList[key].scoreIdx < idx
                    : true
                )
                .reduce((res, key) => {
                  res[key] = props.aliasList[key];
                  return res;
                }, {})
            }
            dependencies={props.dependencies}
            scoring={props.scoring}
          />
        </TopDrawer>
      )}
      {/* SECTION SHOWING CURRENT ARTICLE */}
      <div>
        {!props.obj ? (
          <div className="mx-3 mb-3">{t("No scoring")}</div>
        ) : (
          <List disablePadding>
            <ListItem
              disablePadding
              name={props.obj._id}
              secondaryAction={
                !props.disabled && (
                  <div className="mt-4">
                    <Tooltip arrow title={t("Edit")} placement="top">
                      <span>
                        <IconButton
                          disabled={surveysStatus === "loading"}
                          edge="end"
                          className="me-1"
                          onClick={() => handleEdit(props.obj._id)}
                        >
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip arrow title={t("Copy")} placement="top">
                      <span>
                        <IconButton
                          disabled={surveysStatus === "loading"}
                          edge="end"
                          className="me-1"
                          onClick={() => props.handleCopy(props.idx, props.obj)}
                        >
                          <ContentCopy />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip
                      arrow
                      title={hasDependencies ? t("Can't delete") : t("Delete")}
                      placement="top"
                    >
                      <span>
                        <IconButton
                          disabled={
                            surveysStatus === "loading" || hasDependencies
                          }
                          edge="end"
                          onClick={(e) => {
                            setDeleteMenuIsOpen(true);
                            setDeleteMenuAnchor(e.currentTarget);
                          }}
                        >
                          <DeleteForever />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Menu
                      anchorEl={deleteMenuAnchor}
                      open={deleteMenuIsOpen}
                      onClose={() => {
                        setDeleteMenuIsOpen(false);
                        setDeleteMenuAnchor(null);
                      }}
                    >
                      <MenuItem onClick={() => props.handleDelete(props.idx)}>
                        {surveysStatus === "loading" ? (
                          <CircularProgress size="1.5rem" />
                        ) : (
                          "Delete"
                        )}
                      </MenuItem>
                    </Menu>
                  </div>
                )
              }
            />
            <div className="ms-3 mb-2">
              <Chip
                label={
                  <Typography variant="overline" color="textSecondary">
                    {`${props.acronym}_${props.obj.alias}`}
                  </Typography>
                }
                variant="filled"
                size="small"
                className="me-2"
              />
              {props.obj.method}
              {props.dependencies &&
                Object.keys(props.dependencies).some((dep) =>
                  dep.includes(props.obj._id)
                ) && (
                  <Chip
                    icon={<Link />}
                    label={t("dependent")}
                    size="small"
                    variant="outlined"
                    color="warning"
                    className="border-white ms-2"
                  />
                )}
              {props.scoring &&
                props.scoring.some(
                  (score) =>
                    (score.arithmetic &&
                      score.arithmetic.some(
                        (a) => a.aliasId === props.obj._id
                      )) ||
                    (score.grouping &&
                      score.grouping.aliasId.includes(props.obj._id))
                ) && (
                  <Chip
                    icon={<Link />}
                    label={t("scored in {{ scoredAliasList }}", {
                      scoredAliasList: getScoredAliasList(),
                    })}
                    size="small"
                    variant="outlined"
                    color="success"
                    className="border-white ms-2"
                  />
                )}
            </div>
            <Card className="mx-3 mb-2 p-2 shadow-none border border-secondary bg-light">
              {props.obj.method === "classification-value" ||
              props.obj.method === "classification-label" ? (
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      className="pe-2"
                    >
                      {t("Item")}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Chip
                      label={
                        props.aliasList[props.obj.grouping.aliasId] &&
                        props.aliasList[props.obj.grouping.aliasId].alias
                      }
                      variant="outlined"
                      size="small"
                      className="mb-2"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="overline" color="textSecondary">
                      {t("Categories")}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="overline" color="textSecondary">
                      {t("Boundaries")}
                    </Typography>
                  </Grid>
                  {props.obj.grouping.categoryList.map((group, i) => (
                    <React.Fragment key={`surveys_content-scoring_group-${i}`}>
                      <Grid item xs={4}>
                        {props.obj.method === "classification-value"
                          ? `'${group.value}'`
                          : `'${group.label}'`}
                      </Grid>
                      <Grid item xs={8}>
                        &ge; {group.min} &mdash; &lt; {group.max}
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              ) : props.obj.arithmetic ? (
                <>
                  {props.obj.arithmetic.some((o) => {
                    return (
                      o.aliasId !== "constant" &&
                      props.aliasList[o.aliasId].answerList &&
                      (props.aliasList[o.aliasId].answerList.length !==
                        Object.keys(o.values).length ||
                        props.aliasList[o.aliasId].answerList.some(
                          (a) => !Object.keys(o.values).includes(a.value)
                        ))
                    );
                  }) && (
                    <Alert severity="error" className="mb-1">
                      {t(
                        "Some score answer options do not match the question answer options!"
                      )}
                    </Alert>
                  )}
                  {props.obj.arithmetic.map((arit, i) => (
                    <React.Fragment
                      key={`surveys_content-scoring_arithmetic-${i}`}
                    >
                      {i > 0 && (
                        <>
                          &nbsp;
                          {arit.operator === "add"
                            ? "+"
                            : arit.operator === "subtract"
                            ? "–"
                            : arit.operator === "multiply"
                            ? "×"
                            : arit.operator === "divide"
                            ? "÷"
                            : "unknown"}
                          &nbsp;
                        </>
                      )}
                      {arit.weight !== 1 && `${arit.weight} × `}
                      <Chip
                        label={
                          arit.aliasId === "constant"
                            ? arit.values
                            : props.aliasList[arit.aliasId].alias
                        }
                        icon={
                          arit.values &&
                          arit.aliasId !== "constant" &&
                          Object.keys(arit.values).some(
                            (k, i) => k !== Object.values(arit.values)[i]
                          ) ? (
                            <CurrencyExchangeIcon className="ms-1" />
                          ) : null
                        }
                        variant="outlined"
                        size="small"
                        className="my-1"
                      />
                    </React.Fragment>
                  ))}
                </>
              ) : null}
              {props.obj.arithmetic &&
                props.obj.arithmetic.every((arit) => arit.values) && (
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <Typography
                        variant="overline"
                        color="textSecondary"
                        className="pe-2"
                      >
                        {t("Min score")}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {props.obj.arithmetic.reduce((psum, arit) => {
                        const m =
                          arit.values && arit.aliasId === "constant"
                            ? parseFloat(arit.values)
                            : arit.values
                            ? Math.min(
                                ...Object.values(arit.values).map((v) =>
                                  parseFloat(v)
                                )
                              )
                            : null;
                        return m === null
                          ? "unknown"
                          : psum === "unknown"
                          ? "unknown"
                          : arit.operator === "add"
                          ? psum + arit.weight * m
                          : arit.operator === "subtract"
                          ? psum - arit.weight * m
                          : arit.operator === "multiply"
                          ? psum * (arit.weight * m)
                          : arit.operator === "divide"
                          ? psum / (arit.weight * m)
                          : m;
                      }, 0)}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="overline"
                        color="textSecondary"
                        className="pe-2"
                      >
                        {t("Max score")}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {props.obj.arithmetic.reduce((psum, arit) => {
                        const m =
                          arit.values && arit.aliasId === "constant"
                            ? parseFloat(arit.values)
                            : arit.values
                            ? Math.max(
                                ...Object.values(arit.values).map((v) =>
                                  parseFloat(v)
                                )
                              )
                            : null;
                        return m === null
                          ? "unknown"
                          : psum === "unknown"
                          ? "unknown"
                          : arit.operator === "add"
                          ? psum + arit.weight * m
                          : arit.operator === "subtract"
                          ? psum - arit.weight * m
                          : arit.operator === "multiply"
                          ? psum * (arit.weight * m)
                          : arit.operator === "divide"
                          ? psum / (arit.weight * m)
                          : m;
                      }, 0)}
                    </Grid>
                  </Grid>
                )}
            </Card>
          </List>
        )}
        <div>
          <Divider className="mx-3 mb-3">
            {!props.disabled && (
              <ButtonGroup>
                <Button
                  disabled={
                    surveysStatus === "loading" ||
                    !props.aliasList ||
                    (props.aliasList &&
                      Object.keys(
                        Object.keys(props.aliasList)
                          .filter(
                            (key) =>
                              props.aliasList[key].type !== "textbox" &&
                              props.aliasList[key].type !== "textbox-long" &&
                              props.aliasList[key].type !== "textbox-list" &&
                              props.aliasList[key].type !== "textbox-grid" &&
                              props.aliasList[key].type !== "checkboxes" &&
                              props.aliasList[key].type !== "checkboxes-list" &&
                              props.aliasList[key].type !== "checkboxes-grid" &&
                              props.aliasList[key].type !== "date-time" &&
                              props.aliasList[key].type !== "date" &&
                              props.aliasList[key].title !==
                                "classification-label"
                          )
                          .reduce((res, key) => {
                            res[key] = props.aliasList[key];
                            return res;
                          }, {})
                      ).length === 0)
                  }
                  variant="outlined"
                  size="small"
                  color="inherit"
                  startIcon={<Add />}
                  className="border-dashed"
                  onClick={() => handleAdd()}
                >
                  {t("Add score")}
                </Button>
              </ButtonGroup>
            )}
          </Divider>
        </div>
      </div>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysContentScoring;
