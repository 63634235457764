// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
// -------------------------------------------------
// Component elements
import ArticleFormControl from "./ArticleFormControl";
import ItemAnswer from "./ItemAnswer";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// =================================================
// FUNCTIONAL COMPONENT
const RadioItem = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias)
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id]
  );
  const isXS = useSelector((state) => state.ui.isXS);
  // -----------------------------------------------
  // Local state
  const [localValidation, setLocalValidation] = useState(null);
  const isRow = isXS && props.forceRow ? true : !isXS && props.row;
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
    // Clear any validation when component unmounts
    return () => {
      dispatch(initValidationByAlias({ alias: props.alias }));
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value })
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value })
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      })
    );
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <ArticleFormControl
      article={props.article}
      required={props.required}
      isInvalid={localValidation && localValidation.isInvalid}
      validationMsg={localValidation && localValidation.msg[props.alias]}
    >
      <RadioGroup
        row={isRow}
        name={props.alias}
        value={value}
        className="justify-content-around flex-nowrap"
        onChange={(e) => handleSetSingleResponse(props.alias, e.target.value)}
      >
        {props.answerList.map((answer, i) => (
          <React.Fragment key={`${props.alias}_radio-${answer.value}`}>
            {!isRow && i === 0 && answer.group && answer.group !== "" ? (
              <Typography variant="body2" color="gray" fontWeight="600">
                {answer.group}
              </Typography>
            ) : !isRow &&
              i > 0 &&
              answer.group &&
              answer.group !== props.answerList[i - 1].group ? (
              <Typography
                variant="body2"
                color="gray"
                paragraph={isRow}
                fontWeight="600"
              >
                {answer.group}
              </Typography>
            ) : null}
            <FormControlLabel
              label={
                <>
                  {props.doShow && !isXS && props.row ? (
                    <>
                      {answer.text}
                      <Box className="d-flex justify-content-center">
                        <Chip
                          label={
                            <Typography
                              variant="overline"
                              color="textSecondary"
                            >
                              {answer.value}
                            </Typography>
                          }
                          size="small"
                          className="me-2"
                        />
                      </Box>
                    </>
                  ) : props.doShow ? (
                    <>
                      <div className="d-flex">
                        <Chip
                          label={
                            <Typography
                              variant="overline"
                              color="textSecondary"
                            >
                              {answer.value}
                            </Typography>
                          }
                          size="small"
                          className="me-2"
                        />
                        <ItemAnswer text={answer.text} />
                      </div>
                    </>
                  ) : (
                    <ItemAnswer text={answer.text} />
                  )}
                </>
              }
              value={answer.value}
              control={<Radio disabled={props.uneditable} color="secondary" />}
              labelPlacement={
                isXS && props.forceRow
                  ? "top"
                  : !isXS && props.row
                  ? "top"
                  : "end"
              }
              className={`answer-option m-0 ${
                isXS && props.forceRow
                  ? "border rounded bg-light p-2"
                  : isXS && i === props.answerList.length - 1
                  ? "border rounded bg-light p-2"
                  : isXS
                  ? "border rounded bg-light p-2 mb-2"
                  : ""
              } ${
                props.article.type !== "radio-vertical" ? "text-center" : ""
              }`}
            />
          </React.Fragment>
        ))}
      </RadioGroup>
    </ArticleFormControl>
  );
};
// =================================================
// EXPORT COMPONENT
export default RadioItem;
