import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import { NavLink } from "react-router-dom";
import Container from "@mui/material/Container";

const Footer = () => {
  return process.env.REACT_APP_TITLE === "Australian Sleep Registry" ? (
    <Container className="opacity-75">
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        className="p-4"
      >
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6">Address</Typography>
          <Typography variant="body2">2 Innovation Rd</Typography>
          <Typography variant="body2">Macquarie Park, NSW, 2113</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6">Contact</Typography>
          <Typography variant="body2">
            australiansleepregistry@gmail.com
          </Typography>
          <Typography variant="body2">(+61) 02 9805 3000</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6">Links</Typography>
          <Typography variant="body2">
            <Link
              rel="noreferrer"
              color="inherit"
              component={NavLink}
              to="/terms"
              style={{ textDecoration: "none" }}
            >
              Terms of Use
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link
              rel="noreferrer"
              color="inherit"
              component={NavLink}
              to="/privacy"
              style={{ textDecoration: "none" }}
            >
              Privacy Policy
            </Link>
          </Typography>
          <Typography variant="caption" className="d-block mt-3">
            Australian Sleep Registry © 2023
          </Typography>
        </Grid>
      </Grid>
    </Container>
  ) : (
    <footer className="footer">
      <Grid container spacing={2} className="p-4">
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Over ons</Typography>
          <Typography variant="body2">Algemene voorwaarden</Typography>
          <Typography variant="body2">Privacy</Typography>
          <Typography variant="body2">Disclaimer</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Nederlands Slaap Register</Typography>
          <Typography variant="body2">Meibergdreef 47</Typography>
          <Typography variant="body2">1105 BA Amsterdam</Typography>
          <Typography variant="body2">(+31) (0) 20 566 5500</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Contact</Typography>
          <Typography variant="body2">info@slaapregister.nl</Typography>
          <Typography variant="body2">
            Nederlands Slaap Register © 2023
          </Typography>
          <Typography variant="body2">Alle rechten voorbehouden.</Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
