// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Helper functions
import { str2color } from "../../supportFunc/str2color";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../../redux/reducers/ui";
import { consumersSelectors } from "../../redux/reducers/consumers";
import {
  supervisionSelectors,
  patchCurrentSupervision,
  deleteCurrentSupervision,
} from "../../redux/reducers/supervision";
// -------------------------------------------------
// Contexts
import { useAuth } from "../../contexts/auth";
import { useSocket } from "../../contexts/socket";
// -------------------------------------------------
// Component elements
import StudiesEditInvestigator from "./Studies_EditInvestigator";
import TopDrawer from "../App_TopDrawer";
// -------------------------------------------------
// Basic elements
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
// -------------------------------------------------
// Icons
import Save from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import Icon from "@mui/material/Icon";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const StudiesContentInvestigators = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "studies.Studies_ContentInvestigators",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Context
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  const studiesStatus = useSelector((state) => state.studies.status);
  const supervisionStatus = useSelector((state) => state.supervision.status);
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state)
  );
  const supervisionEntities = useSelector((state) =>
    supervisionSelectors.selectEntities(state)
  );
  // -----------------------------------------------
  // Local state
  const [localSupervision, setLocalSupervision] = useState(null);
  const [isNew, setIsNew] = useState(false);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  const getSupervisionByUserId = (userId) => {
    let supervision =
      Object.values(supervisionEntities).filter(
        (supervision) =>
          supervision.userId === userId && supervision.studyId === props.studyId
      ).length === 1
        ? Object.values(supervisionEntities).filter(
            (supervision) =>
              supervision.userId === userId &&
              supervision.studyId === props.studyId
          )[0]
        : {
            role: "no role",
            isAllParticipants: null,
            isBlinded: null,
          };
    return supervision;
  };
  // -----------------------------------------------
  const stringAvatar = (id, name) => {
    const abbrev = name.trim().split(" ");
    return {
      sx: {
        bgcolor: str2color(id),
      },
      children:
        abbrev.length === 1
          ? `${name[0].toUpperCase()}`
          : `${name.split(" ")[0][0].toUpperCase()}${name
              .split(" ")[1][0]
              .toUpperCase()}`,
    };
  };
  // -----------------------------------------------
  // Handles opening the top drawer for adding a new investigator
  const handleAddStudyInvestigator = () => {
    setIsNew(true);
    setLocalSupervision({
      _id: uuid(),
      userId: null,
      studyId: props.studyId,
      role: null,
      userIdList: [],
      isAllParticipants: true,
      isBlinded: false,
    });
    dispatch(
      toggleSecDrawer({ isOpen: true, id: "studies_content-investigators" })
    );
  };
  // -----------------------------------------------
  // Handles opening the top drawer for adding a new investigator
  const handleDeleteStudyInvestigator = async () => {
    props.handleMutateCurrentStudy("delete-investigator", localSupervision);
    await dispatch(
      deleteCurrentSupervision({
        socket,
        requestingUser: currentAuth,
        supervisionId: localSupervision._id,
      })
    );
    dispatch(toggleSecDrawer({ isOpen: false }));
  };
  // -----------------------------------------------
  // Handles opening the top drawer for changing survey info
  const handleEditStudyInvestigator = (userId) => {
    setIsNew(false);
    let supervision =
      Object.values(supervisionEntities).filter(
        (supervision) => supervision.userId === userId
      ).length > 0
        ? Object.values(supervisionEntities).filter(
            (supervision) =>
              supervision.userId === userId &&
              supervision.studyId === props.studyId
          )[0]
        : {
            _id: uuid(),
            userId,
            studyId: props.studyId,
            role: null,
            userIdList: [],
            isAllParticipants: true,
            isBlinded: false,
          };
    setLocalSupervision(supervision);
    dispatch(
      toggleSecDrawer({ isOpen: true, id: "studies_content-investigators" })
    );
  };
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      {/* ================================================== */}
      {/* TOP DRAWER */}
      <TopDrawer
        id={"studies_content-investigators"}
        title={t("Investigators")}
        buttons={
          <Button
            disabled={
              studiesStatus === "loading" ||
              supervisionStatus === "loading" ||
              !localSupervision ||
              !localSupervision.userId ||
              !localSupervision.role
            }
            color="inherit"
            startIcon={<Save />}
            className="m-2"
            onClick={async () => {
              if (isNew) {
                props.handleMutateCurrentStudy(
                  "add-investigator",
                  localSupervision
                );
              }
              await dispatch(
                patchCurrentSupervision({
                  socket,
                  requestingUser: currentAuth,
                  body: { data: localSupervision },
                })
              );
              dispatch(toggleSecDrawer({ isOpen: false }));
            }}
          >
            {studiesStatus === "loading" || supervisionStatus === "loading" ? (
              <CircularProgress size="1.5rem" className="text-light" />
            ) : (
              t("Save")
            )}
          </Button>
        }
      >
        {secDrawerIsOpen && (
          <StudiesEditInvestigator
            loading={
              studiesStatus === "loading" || supervisionStatus === "loading"
            }
            obj={localSupervision}
            setObj={setLocalSupervision}
            isNew={isNew}
            currentStudyId={props.studyId}
            currentInvestigator={
              consumerEntities &&
              localSupervision &&
              consumerEntities[localSupervision.userId]
            }
            investigators={Object.values(consumerEntities).filter(
              (consumer) =>
                consumer.primaryRole &&
                consumer.primaryRole !== "participant" &&
                !props.userIdList.some((id) => id === consumer._id)
            )}
            handleDeleteStudyInvestigator={handleDeleteStudyInvestigator}
          />
        )}
      </TopDrawer>
      {/* SECTION SHOWING CURRENT INVESTIGATORS */}
      <Typography variant="h3" className="mb-1">
        {t("Investigators")}
      </Typography>
      <List disablePadding className="mb-3">
        <Grid container alignItems="stretch" spacing={1}>
          {props.userIdList.map((userId) => (
            <Grid key={userId} item xs={12} sm={12} md={12} lg={6} xl={4}>
              <Card className="p-2 px-3">
                <ListItem className="p-0">
                  <ListItemIcon>
                    <Avatar
                      {...stringAvatar(
                        userId,
                        consumerEntities[userId] &&
                          consumerEntities[userId].name
                          ? consumerEntities[userId].name
                          : consumerEntities[userId].email
                      )}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1" className="text-truncate">
                        {consumerEntities[userId].email}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" className="text-truncate">
                        {getSupervisionByUserId(userId).role}
                      </Typography>
                    }
                  />
                  {getSupervisionByUserId(userId).isBlinded ? (
                    <Tooltip
                      arrow
                      title={t("Is blinded to group allocation")}
                      placement="top"
                    >
                      <Icon color="action" className="me-2">
                        visibility_off
                      </Icon>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      arrow
                      title={t("Is unblinded to group allocation")}
                      placement="top"
                    >
                      <Icon color="action" className="me-2">
                        visibility
                      </Icon>
                    </Tooltip>
                  )}
                  {getSupervisionByUserId(userId).isAllParticipants ? (
                    <Tooltip
                      arrow
                      title={t("Supervises all participants")}
                      placement="top"
                    >
                      <Icon color="action" className="me-2">
                        supervisor_account
                      </Icon>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      arrow
                      title={t("Supervises a subset of participants")}
                      placement="top"
                    >
                      <Icon color="action" className="me-2">
                        supervised_user_circle
                      </Icon>
                    </Tooltip>
                  )}
                  <Tooltip arrow title={t("Edit")} placement="top">
                    <IconButton
                      disabled={studiesStatus === "loading"}
                      edge="end"
                      onClick={() => handleEditStudyInvestigator(userId)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              </Card>
            </Grid>
          ))}
          <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <ListItemButton
              className="h-100 bg-light border-radius-4px border border-dashed border-2px shadow-none"
              onClick={() => handleAddStudyInvestigator()}
            >
              <ListItemIcon>
                <Avatar>+</Avatar>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" className="text-truncate">
                    {t("Add investigator")}
                  </Typography>
                }
              />
            </ListItemButton>
          </Grid>
        </Grid>
      </List>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default StudiesContentInvestigators;
