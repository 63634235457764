// =================================================
// IMPORT
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/webpages`;
// -------------------------------------------------
// Use 'createEntityAdapter' to store the response data in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  currentWebpageID: null,
  error: null,
});
// =================================================
// ASYNC API ACTIONS
// -------------------------------------------------
// API fetch all webpages
export const fetchWebpageList = createAsyncThunk(
  "webpages/webpageList",
  async ({ requestingUser }) => {
    const response = await client.get(apiurl, requestingUser);
    return response.data;
  }
);
// API patch a webpage object
export const patchCurrentWebpage = createAsyncThunk(
  "webpages/patchCurrentWebpage",
  async ({ socket, requestingUser, body }) => {
    // Make the call to the database
    const response = await client.patch(
      `${apiurl}/${body.data._id}`,
      requestingUser,
      body // Use the webpage object as the request body
    );
    // Invoke event on server
    socket &&
      socket.emit("patched-current-webpage", {
        webpageId: body.data._id,
      });
    // Return the response
    return response.data;
  }
);
// -------------------------------------------------
// API delete a webpage object
export const deleteCurrentWebpage = createAsyncThunk(
  "webpages/deleteCurrentWebpage",
  async ({ socket, requestingUser, webpageId }) => {
    // Make the call to the database
    const response = await client.delete(
      `${apiurl}/${webpageId}`,
      requestingUser
    );
    // Invoke event on server
    socket && socket.emit("deleted-current-webpage", { webpageId });
    // Return the response
    return response.data;
  }
);

// =================================================
// DEFINE MUTATING ACTIONS
export const webpagesSlice = createSlice({
  name: "webpages",
  initialState,
  reducers: {
    setCurrentWebpageId(state, action) {
      state.currentWebpageID = action.payload.currentWebpageID;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebpageList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWebpageList.fulfilled, (state, action) => {
        state.status = "succeeded";
        adapter.setAll(state, action.payload);
      })
      .addCase(fetchWebpageList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(patchCurrentWebpage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(patchCurrentWebpage.fulfilled, (state, action) => {
        state.status =
          state.maxStatus === "succeeded" ? "succeeded" : "partial";
        action.payload.survey &&
          adapter.upsertOne(state, action.payload.survey);
      })
      .addCase(patchCurrentWebpage.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(deleteCurrentWebpage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCurrentWebpage.fulfilled, (state, action) => {
        state.status =
          state.maxStatus === "succeeded" ? "succeeded" : "partial";
        action.payload.surveyId &&
          adapter.removeOne(state, action.payload.surveyId);
      })
      .addCase(deleteCurrentWebpage.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const { setCurrentWebpageID } = webpagesSlice.actions;
// =================================================
// EXPORT SELECTORS
export const webpageSelectors = adapter.getSelectors((state) => state.webpages);
// =================================================
// EXPORT REDUCER
export default webpagesSlice.reducer;
