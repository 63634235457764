// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";

// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// -------------------------------------------------
// Icons

import DeleteForever from "@mui/icons-material/DeleteForever";

// =================================================
// FUNCTIONAL COMPONENT
const CmsEditorHeader = (props) => {
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Local state
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  // -------------------------------------------------
  // Redux

  // ===============================================
  // RENDER COMPONENT
  // -------------------------------------------------
  return props.currentWebpage ? (
    <>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item className="pe-2">
          <Tooltip arrow title="Delete" placement="top">
            <span>
              <IconButton
                id="delete"
                color="inherit"
                onClick={(e) => {
                  setMenuIsOpen(true);
                  setMenuAnchor(e.currentTarget);
                }}
              >
                <DeleteForever />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>

      <Menu
        anchorEl={menuAnchor}
        open={menuIsOpen}
        onClose={() => {
          setMenuIsOpen(false);
          setMenuAnchor(null);
        }}
      >
        <MenuItem
          onClick={() => {
            props.handleDelete();
            setMenuIsOpen(false);
          }}
        >
          <ListItemIcon>
            <DeleteForever fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete forever</ListItemText>
        </MenuItem>
      </Menu>
    </>
  ) : null;
};
// =================================================
// EXPORT
export default CmsEditorHeader;
