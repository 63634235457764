// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useRef } from "react";
import { useSelector } from "react-redux";
// TinyMCE
import { Editor } from "@tinymce/tinymce-react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useState } from "react";

// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import Link from "@mui/material/Link";
// =================================================
// FUNCTIONAL COMPONENT
const CmsEditorEditFields = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Using useRef to store the editor content
  const editorContentList = useRef(props.obj.map((item) => item.content || ""));

  const handleEditorChange = (content, index) => {
    // Updating the ref without re-rendering the component
    editorContentList.current[index] = content;
  };

  const handleEditorBlur = (index) => {
    const newObj = [...props.obj];
    newObj[index] = {
      ...newObj[index],
      content: editorContentList.current[index],
    };
    props.setObj(newObj);
  };

  // Functions for the new field dialog
  const [open, setOpen] = useState(false);
  const [fieldName, setFieldName] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddField = () => {
    const newObj = [...props.obj, { fieldname: fieldName, content: "" }];
    props.setObj(newObj);
    handleClose();
  };

  // Functions to delete content fields
  const handleDeleteField = (index) => {
    const newObj = [...props.obj];
    newObj.splice(index, 1);
    props.setObj(newObj);
  };

  // ===============================================
  // RENDER COMPONENT
  return (
    <Paper className={isXS ? "m-2 p-3" : "m-2 p-3"}>
      <Grid container alignItems="center" justifyContent="center">
        {props.obj.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12}>
              <Typography variant="h4" className="font-weight-bold">
                {item.fieldname}
              </Typography>
              <Typography>
                <Link
                  component="button"
                  variant="body2"
                  underline="hover"
                  color="error"
                  onClick={(event) => {
                    event.preventDefault();
                    handleDeleteField(index);
                  }}
                >
                  delete
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Editor
                apiKey={process.env.REACT_APP_EDITOR_API_KEY}
                initialValue={editorContentList.current[index]}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: "code fullscreen",
                  toolbar:
                    " code | fullscreen | undo redo | bold italic underline | blocks | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ",
                }}
                onEditorChange={(content) => handleEditorChange(content, index)}
                onBlur={() => handleEditorBlur(index)}
                key={item.fieldname} // Unique key for each editor
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<AddIcon />}
      >
        Add a content field
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a new field</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Field Name"
            type="text"
            fullWidth
            value={fieldName}
            onChange={(e) => setFieldName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddField}>Add</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
// =================================================
// EXPORT COMPONENT
export default CmsEditorEditFields;
