// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Component elements
import SurveysEditScoreGrouping from "./Surveys_EditScoreGrouping";
import SurveysEditScoreArithmetic from "./Surveys_EditScoreArithmetic";
// -------------------------------------------------
// Basic elements
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Link from "@mui/icons-material/Link";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditScore = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditScore",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const scoringTypes = [
    { method: "arithmetic", label: "Arithmetic" },
    { method: "classification-value", label: "Categorize to values" },
    { method: "classification-label", label: "Categorize to labels" },
  ];
  const firstAliasId = Object.keys(props.aliasList)[0];
  const [alias, setAlias] = useState(props.obj.alias);
  const [arithmetic, setArithmetic] = useState(
    props.obj.arithmetic && props.obj.arithmetic.length > 0
      ? props.obj.arithmetic
      : [
          {
            aliasId: firstAliasId,
            pageId: props.aliasList[firstAliasId].pageId,
            gridContainerId: props.aliasList[firstAliasId].gridContainerId,
            gridItemId: props.aliasList[firstAliasId].gridItemId,
            articleId: props.aliasList[firstAliasId].articleId,
            rowId: props.aliasList[firstAliasId].rowId,
            weight: 1,
            operator: null,
            type: props.aliasList[firstAliasId].type,
            convertTo: "m",
            values: props.aliasList[firstAliasId].answerList
              ? props.aliasList[firstAliasId].answerList.reduce(
                  (result, answer) => {
                    return { ...result, [answer.value]: answer.value };
                  },
                  {}
                )
              : null,
          },
        ]
  );
  const [grouping, setGrouping] = useState(
    props.obj.grouping
      ? props.obj.grouping
      : {
          aliasId: firstAliasId,
          pageId: props.aliasList[firstAliasId].pageId,
          gridContainerId: props.aliasList[firstAliasId].gridContainerId,
          gridItemId: props.aliasList[firstAliasId].gridItemId,
          articleId: props.aliasList[firstAliasId].articleId,
          rowId: props.aliasList[firstAliasId].rowId,
          type: props.aliasList[firstAliasId].type,
          categoryList: [
            {
              min:
                props.aliasList[firstAliasId].type === "time"
                  ? "00:00"
                  : props.aliasList[firstAliasId].type === "duration"
                  ? "PT0S"
                  : "0",
              max:
                props.aliasList[firstAliasId].type === "time"
                  ? "12:00"
                  : props.aliasList[firstAliasId].type === "duration"
                  ? "PT0S"
                  : "1",
              label: "",
              feedback: "",
            },
          ],
        }
  );
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Whenever the value of any of the keys change, update the parent object
  useEffect(() => {
    props.setObj((prevState) => ({
      ...prevState,
      arithmetic,
      grouping,
    }));
  }, [props.obj.method, arithmetic, grouping]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Sets the object
  const handleSetObj = (key, value) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[key] = value;
      return newState;
    });
  };
  // Set the alias and perform text transform
  const handleSetAlias = (newValue) => {
    setAlias(newValue);
    handleSetObj("alias", newValue.toUpperCase().replace(/\W/g, "_"));
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <Box>
      <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
        <Typography variant="h4" className="d-inline">
          {t("Score type")}
        </Typography>
        {props.dependencies &&
          Object.keys(props.dependencies).some((dep) =>
            dep.includes(props.obj._id)
          ) && (
            <Chip
              icon={<Link className="ms-2" />}
              label={t("dependent")}
              size="small"
              variant="outlined"
              color="warning"
              className="ms-2 border-white"
            />
          )}
        {props.scoring &&
          props.scoring.some(
            (score) =>
              (score.arithmetic &&
                score.arithmetic.some((a) =>
                  a.aliasId.includes(props.obj._id)
                )) ||
              (score.grouping && score.grouping.aliasId.includes(props.obj._id))
          ) && (
            <Chip
              icon={<Link className="ms-2" />}
              label={t("scored")}
              size="small"
              variant="outlined"
              color="success"
              className="ms-2 border-white"
            />
          )}
        <FormControl
          fullWidth
          disabled={
            (props.dependencies &&
              Object.keys(props.dependencies).some((dep) =>
                dep.includes(props.obj._id)
              )) ||
            (props.scoring &&
              props.scoring.some(
                (score) =>
                  (score.arithmetic &&
                    score.arithmetic.some((a) =>
                      a.aliasId.includes(props.obj._id)
                    )) ||
                  (score.grouping &&
                    score.grouping.aliasId.includes(props.obj._id))
              ))
          }
          variant="filled"
          color="secondary"
          className="mb-3 mt-2"
        >
          <Select
            hiddenLabel
            name="method"
            value={props.obj.method}
            className="answer-text-green"
            color="secondary"
            onChange={(e) => handleSetObj(e.target.name, e.target.value)}
          >
            {scoringTypes.map((t) => (
              <MenuItem key={`article-type-${t.method}`} value={t.method}>
                {t.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h4" className="d-inline">
          {t("Alias")}
        </Typography>
        <TextField
          fullWidth
          hiddenLabel
          type="text"
          name="alias"
          variant="filled"
          value={alias}
          size="small"
          color="secondary"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ mr: 0}}>
                {`${props.acronym}_`}
              </InputAdornment>
            ),
          }}
          className="answer-text-green text-uppercase"
          onChange={(e) => handleSetAlias(e.target.value)}
        />
      </Paper>
      <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
        {props.obj.method === "classification-value" ||
        props.obj.method === "classification-label" ? (
          <SurveysEditScoreGrouping
            toLabel={props.obj.method === "classification-label"}
            obj={grouping}
            setObj={setGrouping}
            aliasList={props.aliasList}
            dependencies={props.dependencies}
            scoring={props.scoring}
          />
        ) : (
          <SurveysEditScoreArithmetic
            obj={arithmetic}
            setObj={setArithmetic}
            aliasList={props.aliasList}
            dependencies={props.dependencies}
            scoring={props.scoring}
          />
        )}
      </Paper>
    </Box>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditScore;
