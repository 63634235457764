// =================================================
// IMPORT
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/messages`;
// -------------------------------------------------
// Use 'createEntityAdapter' to store the studies in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  errorMsg: null,
});
// =================================================
// ASYNC API ACTIONS
// -------------------------------------------------
// API fetch one study by its id
export const fetchConversationById = createAsyncThunk(
  "conversations/fetchConversationById",
  async ({ requestingUser, conversationId }) => {
    const response = await client.get(
      `${apiurl}/${conversationId}`,
      requestingUser
    );
    return response.data;
  }
);
// -------------------------------------------------
// API fetch all owned conversations
// If SuperUser, will return all conversations
// Otherwise, will return only owned conversations
export const fetchConversationList = createAsyncThunk(
  "conversations/fetchConversationList",
  async ({ requestingUser, userId }) => {
    const response = await client.get(
      `${apiurl}/userId/${userId}`,
      requestingUser
    );
    return response.data;
  }
);
// -------------------------------------------------
// API patch a study object
export const patchCurrentConversation = createAsyncThunk(
  "conversations/patchCurrentConversation",
  async ({ socket, requestingUser, body }) => {
    // Make the call to the database
    const response = await client.patch(
      `${apiurl}/${body.data._id}`,
      requestingUser,
      body
    );
    // Invoke event on server
    socket &&
      socket.emit("patched-current-conversation", {
        conversationId: body.data._id,
        currentRecipientList: body.meta.currentRecipientList,
        removedRecipientList: body.meta.removedRecipientList,
      });
    // Return the response
    return response.data;
  }
);
// -------------------------------------------------
// API delete a study object
export const deleteCurrentConversation = createAsyncThunk(
  "conversations/deleteCurrentConversation",
  async ({ socket, requestingUser, conversationId }) => {
    // Make the call to the database
    const response = await client.delete(
      `${apiurl}/${conversationId}`,
      requestingUser
    );
    // Invoke event on server
    socket && socket.emit("deleted-current-conversation", { conversationId });
    // Return the response
    return response.data;
  }
);
// =================================================
// DEFINE MUTATING ACTIONS
export const conversationsSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    resetConversationsError(state) {
      if (state.status === "failed") {
        state.status = "idle";
        state.errorMsg = null;
      }
    },
    removeConversationById(state, action) {
      adapter.removeOne(state, action.payload.conversationId);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchConversationById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchConversationById.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.conversation &&
          adapter.upsertOne(state, action.payload.conversation);
      })
      .addCase(fetchConversationById.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(fetchConversationList.pending, (state) => {
        state.status = "loading";
        state.errorMsg = null;
      })
      .addCase(fetchConversationList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.errorMsg = null;
        action.payload.conversationList &&
          action.payload.conversationList.length > 0 &&
          adapter.upsertMany(state, action.payload.conversationList);
      })
      .addCase(fetchConversationList.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(patchCurrentConversation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(patchCurrentConversation.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.conversation &&
          adapter.upsertOne(state, action.payload.conversation);
      })
      .addCase(patchCurrentConversation.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(deleteCurrentConversation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCurrentConversation.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.conversationId &&
          adapter.removeOne(state, action.payload.conversationId);
      })
      .addCase(deleteCurrentConversation.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const { resetConversationsError, removeConversationById } =
  conversationsSlice.actions;
// =================================================
// SELECTOR FUNCTIONS
// -------------------------------------------------
export const conversationsSelectors = adapter.getSelectors(
  (state) => state.conversations
);
// =================================================
// EXPORT DEFAULT
export default conversationsSlice.reducer;
