import React, { useState, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import {
  patchCurrentWebpage,
  webpageSelectors,
} from "../../redux/reducers/webpages";
import { useSocket } from "../../contexts/socket";
import { useAuth } from "../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import { setAlert } from "../../redux/reducers/ui";

const AddWebpageDialog = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { socket } = useSocket();
  const { currentAuth } = useAuth();
  const navigate = useNavigate();
  const webpagesList = useSelector(webpageSelectors.selectAll);

  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [type, setType] = useState("page");
  const [contentListOption, setContentListOption] = useState("empty");
  const [selectedStudy, setSelectedStudy] = useState("");

  useEffect(() => {
    if (webpagesList.length > 0) {
      setSelectedStudy(webpagesList[0]._id);
    }
  }, [webpagesList]);

  const handleAddNewWebpage = async () => {
    onClose();
    const selectedStudyContentList = webpagesList.find(
      (webpage) => webpage._id === selectedStudy
    )?.contentList;

    const newPageData = {
      _id: uuid(),
      type,
      title: name,
      url,
      description: "New webpage description",
      status: "concept",
      contentList:
        contentListOption === "duplicate" ? selectedStudyContentList : [],
    };
    const res = await dispatch(
      patchCurrentWebpage({
        socket,
        requestingUser: currentAuth,
        body: { data: newPageData },
      })
    );
    if (res.payload) {
      navigate(`/cmseditor?webpageId=${res.payload.webpage._id}`, {
        replace: true,
      });
    }
    if (res.error) {
      console.error(res.error);
      dispatch(
        setAlert({
          type: "snackbar",
          variant: "error",
          message: res.error.message,
          duration: 6000,
        })
      );
    }
    onClose();
    setName("");
    setUrl("");
    setType("page");
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add new webpage</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          variant="filled"
          margin="dense"
          label="Name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          fullWidth
          variant="filled"
          margin="dense"
          label="URL"
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <FormControl fullWidth variant="filled" margin="dense">
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            labelId="type-label"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value="home">Home</MenuItem>
            <MenuItem value="page">Page</MenuItem>
            <MenuItem value="study">Study</MenuItem>
            <MenuItem value="news">News</MenuItem>
          </Select>
        </FormControl>

        {/* Option to duplicate content from another study */}
        <Typography variant="h5">Content:</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={contentListOption}
            onChange={(e) => setContentListOption(e.target.value)}
          >
            <FormControlLabel value="empty" control={<Radio />} label="Empty" />
            <Grid container alignItems="center">
              <FormControlLabel
                value="duplicate"
                control={<Radio />}
                label="Duplicate content from:"
              />
              {contentListOption === "duplicate" && (
                <Select
                  hiddenLabel
                  variant="filled"
                  value={selectedStudy}
                  onChange={(e) => setSelectedStudy(e.target.value)}
                >
                  {webpagesList.map((webpage) => (
                    <MenuItem key={webpage._id} value={webpage._id}>
                      {webpage.title}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Grid>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddNewWebpage} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWebpageDialog;
