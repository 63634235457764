// ########################################
// IMPORT
// ----------------------------------------
// Dependencies
const { v4: uuid } = require("uuid");
const { DateTime } = require("luxon");
// ########################################
// FUNCTIONS
// ========================================
// Generates tickets for one user and one study-timepoint
export const getTicketsForUserAndTimepoint = (
  participant,
  study,
  timepointId,
  responseCollection
) => {
  // Check if user is enrolled at all
  if (!participant.studyEnrollmentList[0].studyId) {
    return;
  }
  // Extract the timepoint from study and participant
  const studyTimepoint = study.timepointList.find(
    (tp) => tp._id === timepointId
  );
  if (!studyTimepoint) {
    return; // Timepoint identifier is not part of this study
  }
  const participantTimepoint = participant.studyEnrollmentList[0]
    .timepointAssignmentList
    ? participant.studyEnrollmentList[0].timepointAssignmentList.find(
        (tp) => tp.timepointId === timepointId
      )
    : null;
  if (!participantTimepoint) {
    return; // Participant is not enrolled in this study timepoint
  }
  // Check if user is assigned to a group in this timepoint
  const assignedGroup = isParticipantAssignedToTimepointGroup(
    participant.studyEnrollmentList[0].groupAssignmentList
      ? participant.studyEnrollmentList[0].groupAssignmentList[0]
      : null,
    studyTimepoint.groupIdList,
    study.groupList
  );
  // If participant is not assigned to a group in this timepoint, return
  if (!assignedGroup) {
    return;
  }
  // Get the timepoint start date for this participant
  const timepointStartDate = DateTime.fromISO(participantTimepoint.startDate);
  // If the timepoint has not been scheduled yet, return
  if (
    !timepointStartDate ||
    !timepointStartDate.isValid ||
    studyTimepoint.measurementList === null
  ) {
    return;
  }
  // For each survey and each repitition, create a ticket
  let tickets = [];
  studyTimepoint.measurementList.forEach((measurement) => {
    const repeat = Math.abs(measurement.repeat); // If repeat is indefinate (-1), create 'abs(-1)' = 1 ticket
    for (let k = 0; k < repeat; k++) {
      tickets.push({
        _id: uuid(),
        userId: participant._id,
        studyId: study._id,
        timepointId: timepointId,
        measurementId: measurement._id,
        groupId: assignedGroup._id,
        surveyId: measurement.surveyId,
        responseId: uuid(),
        completer: measurement.completer,
        remainVisible: measurement.remainVisible,
        remainEditable: measurement.remainEditable,
        level: measurement.level,
        entryNumber: k + 1,
        repeat: measurement.repeat,
        interval: measurement.interval,
        intervalUnit: measurement.intervalUnit,
        availability: measurement.availability,
        availabilityUnit: measurement.availabilityUnit,
        allowance: measurement.allowance,
        allowanceUnit: measurement.allowanceUnit,
        responseCollection: responseCollection,
        dateAvailable: DateTime.fromISO(participantTimepoint.startDate)
          .plus({ days: measurement.delay - 1 })
          .plus({
            [translateUnit(measurement.intervalUnit)]: k * measurement.interval,
          })
          .toISO({
            includeOffset: false,
            suppressSeconds: true,
          }),
        dateDue:
          measurement.availability === -1
            ? null
            : DateTime.fromISO(participantTimepoint.startDate)
                .plus({ days: measurement.delay - 1 })
                .plus({
                  [translateUnit(measurement.intervalUnit)]:
                    k * measurement.interval,
                })
                .plus({
                  [translateUnit(measurement.availabilityUnit)]:
                    measurement.availability,
                })
                .toISO({
                  includeOffset: false,
                  suppressSeconds: true,
                }),
        dateExpire:
          measurement.availability === -1
            ? null
            : DateTime.fromISO(participantTimepoint.startDate)
                .plus({ days: measurement.delay - 1 })
                .plus({
                  [translateUnit(measurement.intervalUnit)]:
                    k * measurement.interval,
                })
                .plus({
                  [translateUnit(measurement.availabilityUnit)]:
                    measurement.availability,
                })
                .plus({
                  [translateUnit(measurement.allowanceUnit)]:
                    measurement.allowance,
                })
                .toISO({
                  includeOffset: false,
                  suppressSeconds: true,
                }),
      });
    }
  });
  return tickets;
};
// ========================================
// Generates tickets for a measurement
export const getTicketsForMeasurement = (measurement) => {
  // Get the timepoint start date for this participant
  const timepointStartDate = DateTime.fromISO(measurement.timepointStartDate);
  // If the timepoint has not been scheduled yet, return
  if (!timepointStartDate || !timepointStartDate.isValid) {
    return;
  }
  // For each survey and each repitition, create a ticket
  let tickets = [];
  const repeat = Math.abs(measurement.repeat); // If repeat is indefinate (-1), create 'abs(-1)' = 1 ticket
  for (let k = 0; k < repeat; k++) {
    tickets.push({
      _id: uuid(),
      userId: measurement.userId,
      studyId: measurement.studyId,
      timepointId: measurement.timepointId,
      measurementId: measurement.measurementId,
      groupId: measurement.groupId,
      surveyId: measurement.surveyId,
      responseId: uuid(),
      completer: measurement.completer,
      remainVisible: measurement.remainVisible,
      remainEditable: measurement.remainEditable,
      level: measurement.level,
      entryNumber: k + 1,
      repeat: measurement.repeat,
      interval: measurement.interval,
      intervalUnit: measurement.intervalUnit,
      availability: measurement.availability,
      availabilityUnit: measurement.availabilityUnit,
      allowance: measurement.allowance,
      allowanceUnit: measurement.allowanceUnit,
      responseCollection: measurement.responseCollection,
      dateAvailable: timepointStartDate
        .plus({ days: measurement.delay - 1 })
        .plus({
          [translateUnit(measurement.intervalUnit)]: k * measurement.interval,
        })
        .toISO({
          includeOffset: false,
          suppressSeconds: true,
        }),
      dateDue:
        measurement.availability === -1
          ? null
          : timepointStartDate
              .plus({ days: measurement.delay - 1 })
              .plus({
                [translateUnit(measurement.intervalUnit)]:
                  k * measurement.interval,
              })
              .plus({
                [translateUnit(measurement.availabilityUnit)]:
                  measurement.availability,
              })
              .toISO({
                includeOffset: false,
                suppressSeconds: true,
              }),
      dateExpire:
        measurement.availability === -1
          ? null
          : timepointStartDate
              .plus({ days: measurement.delay - 1 })
              .plus({
                [translateUnit(measurement.intervalUnit)]:
                  k * measurement.interval,
              })
              .plus({
                [translateUnit(measurement.availabilityUnit)]:
                  measurement.availability,
              })
              .plus({
                [translateUnit(measurement.allowanceUnit)]:
                  measurement.allowance,
              })
              .toISO({
                includeOffset: false,
                suppressSeconds: true,
              }),
    });
  }
  return tickets;
};
// ========================================
// HELPER FUNCTIONS
// ----------------------------------------
// Translates time units for use in 'DateTime'
export const translateUnit = (u) => {
  switch (u) {
    case "h":
      return "hours";
    case "d":
      return "days";
    case "w":
      return "weeks";
    case "m":
      return "months";
    case "y":
      return "years";
    default:
      return "days";
  }
};
// ----------------------------------------
// Checks if the participant is assigned to a group of this timepoint
const isParticipantAssignedToTimepointGroup = (
  participantGroup,
  timepointGroupIds,
  currentGroupList
) => {
  // Init assigned group as empty object
  let assignedGroup = {};
  // Get the assigned group, only if there are study groups, and there are groups assigned to this timepoint
  if (currentGroupList && timepointGroupIds && timepointGroupIds.length > 0) {
    // Return false if the participant was not assigned to a group in this timepoint
    if (
      participantGroup &&
      timepointGroupIds.some((id) => id === participantGroup.groupId)
    ) {
      // The participant was assigned to a group in this timepoint
      // Extract the group label and id
      assignedGroup = currentGroupList.find(
        (group) => group._id === participantGroup.groupId
      );
    } else {
      return false; // participant was not assigned to this group
    }
  } else {
    // There are no groups defined in this study or timepoint, not applicable
    assignedGroup._id = null;
    assignedGroup.label = "n.a.";
  }
  return assignedGroup;
};
