// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
// -------------------------------------------------
// Icons
import Add from "@mui/icons-material/Add";
import DeleteForever from "@mui/icons-material/DeleteForever";
// =================================================
// FUNCTIONAL COMPONENT
const StudiesEditInfo = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "studies.Studies_EditGroups" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (e, idx) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[idx].label = e.target.value;
      return newState;
    });
  };
  const handleAddItem = () => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.splice(newState.length, 0, { _id: uuid(), label: "" });
      return newState;
    });
  };
  const handleDeleteItem = (idx) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.splice(idx, 1);
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h4" className="font-weight-bold">
            { t("Groups") }
          </Typography>
        </Grid>
        {props.obj.map((o, i) => (
          <React.Fragment key={o._id}>
            <Grid item xs={11}>
              <TextField
                fullWidth
                hiddenLabel
                size="small"
                type="text"
                name="group-item"
                value={o.label ? o.label : ""}
                color="secondary"
                variant="filled"
                className="bg-white answer-text-green mt-1 mb-2"
                onChange={(e) => handleSetObj(e, i)}
              />
            </Grid>
            <Grid item xs={1} align="right">
              <Tooltip arrow title={ t("Delete") } placement="right">
                <IconButton onClick={(e) => handleDeleteItem(i)}>
                  <DeleteForever />
                </IconButton>
              </Tooltip>
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12}>
          <Divider>
            <ButtonGroup>
              <Button
                variant="outlined"
                size="small"
                color="inherit"
                startIcon={<Add />}
                className="border-dashed"
                onClick={() => handleAddItem()}
              >
                { t("Add group") }
              </Button>
            </ButtonGroup>
          </Divider>
        </Grid>
      </Grid>
    </Paper>
  );
};
// =================================================
// EXPORT COMPONENT
export default StudiesEditInfo;
