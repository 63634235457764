// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
const { DateTime } = require("luxon");
// =================================================
// EXPORT FUNCTION
// -----------------------------------------------
// Returns a string indicating how much time is left to do a task, or when the task becomes available
export const getTicketAvailability = (ticket) => {
  const def = {
    _id: ticket._id,
    isCompleted: ticket.isCompleted,
    isOverdue: ticket.dateDue
      ? DateTime.fromISO(ticket.dateDue).diffNow().valueOf() < 0
      : false,
    isExpired: ticket.dateExpire
      ? DateTime.fromISO(ticket.dateExpire).diffNow().valueOf() < 0
      : false,
    completer: ticket.completer,
    toDoNow: ticket.dateExpire
      ? DateTime.fromISO(ticket.dateAvailable).diffNow().valueOf() < 0 &&
        DateTime.fromISO(ticket.dateExpire).diffNow().valueOf() > 0
      : DateTime.fromISO(ticket.dateAvailable).diffNow().valueOf() < 0,
  };
  // If 'ticket' does not have an end-date, return any time
  if (def.toDoNow && !ticket.dateDue) {
    return {
      ...def,
      refreshEvery: 24 * 60 * 60 * 1000,
      str: "Any time",
    };
  }
  // The ticket can be "future", "to-do now", "overdue", "expired", or "done"
  if (ticket.isCompleted) {
    // Ticket is completed:
    return {
      ...def,
      refreshEvery: 999999 * 24 * 60 * 60 * 1000,
      str: "Completed",
    };
  } else if (
    ticket.dateExpire &&
    DateTime.fromISO(ticket.dateExpire).diffNow().valueOf() < 0
  ) {
    // Ticket is expired:
    return {
      ...def,
      refreshEvery: 999999 * 24 * 60 * 60 * 1000,
      str: "Expired",
    };
  } else if (
    ticket.dateDue &&
    DateTime.fromISO(ticket.dateDue).diffNow().valueOf() < 0
  ) {
    // Ticket is overdue, but how much time is left before expiration?
    const timeLeft = DateTime.fromISO(ticket.dateExpire).diffNow().valueOf();
    if (timeLeft >= 2 * 24 * 60 * 60 * 1000) {
      // Expiry date is more than 2 days from now
      return {
        ...def,
        refreshEvery: 24 * 60 * 60 * 1000,
        str: `Expires on ${DateTime.fromISO(ticket.dateExpire).toFormat(
          "ccc d LLL"
        )}`,
      };
    } else if (timeLeft >= 1 * 24 * 60 * 60 * 1000) {
      // Expiry date is more than 1 day from now
      return {
        ...def,
        refreshEvery: 60 * 60 * 1000,
        str: `Expires tomorrow at ${DateTime.fromISO(
          ticket.dateExpire
        ).toFormat("t")}`,
      };
    } else if (timeLeft >= 2 * 60 * 60 * 1000) {
      // Expiry date is more than 2 hours from now
      return {
        ...def,
        refreshEvery: 60 * 60 * 1000,
        str: `${DateTime.fromISO(ticket.dateExpire)
          .diffNow()
          .toFormat("'Expires in 'h'h'")}`,
      };
    } else if (timeLeft >= 2 * 60 * 1000) {
      // Expiry date is more than 2 minutes from now
      return {
        ...def,
        refreshEvery: 60 * 1000,
        str: `${DateTime.fromISO(ticket.dateExpire)
          .diffNow()
          .toFormat("'Expires in 'm'm'")}`,
      };
    } else if (timeLeft >= 0) {
      // Expiry date is any second now (0-120 seconds)
      return {
        ...def,
        refreshEvery: 1000,
        str: `${DateTime.fromISO(ticket.dateExpire)
          .diffNow()
          .toFormat("'Expires in 's's'")}`,
      };
    } else {
      // Ticket is expired:
      return {
        ...def,
        refreshEvery: 999999 * 24 * 60 * 60 * 1000,
        str: "Expired",
      };
    }
  } else if (DateTime.fromISO(ticket.dateAvailable).diffNow().valueOf() < 0) {
    // Ticket is available, but how much time is left before it being overdue?
    const timeLeft = DateTime.fromISO(ticket.dateDue).diffNow().valueOf();
    if (timeLeft >= 2 * 24 * 60 * 60 * 1000) {
      // Due date is more than 2 days from now
      return {
        ...def,
        refreshEvery: 24 * 60 * 60 * 1000,
        str: `Until ${DateTime.fromISO(ticket.dateDue).toFormat("ccc d LLL")}`,
      };
    } else if (timeLeft >= 1 * 24 * 60 * 60 * 1000) {
      // Due date is more than 1 day from now
      return {
        ...def,
        refreshEvery: 60 * 60 * 1000,
        str: `Until tomorrow at ${DateTime.fromISO(ticket.dateDue).toFormat(
          "t"
        )}`,
      };
    } else if (timeLeft >= 2 * 60 * 60 * 1000) {
      // Due date is more than 2 hours from now
      return {
        ...def,
        refreshEvery: 60 * 60 * 1000,
        str: `${DateTime.fromISO(ticket.dateDue)
          .diffNow()
          .toFormat("h'h remaining'")}`,
      };
    } else if (timeLeft >= 2 * 60 * 1000) {
      // Due date is more than 2 minutes from now
      return {
        ...def,
        refreshEvery: 60 * 1000,
        str: `${DateTime.fromISO(ticket.dateDue)
          .diffNow()
          .toFormat("m'm remaining'")}`,
      };
    } else if (timeLeft >= 0) {
      // Due date is any second now (0-120 seconds)
      return {
        ...def,
        refreshEvery: 1000,
        str: `${DateTime.fromISO(ticket.dateDue)
          .diffNow()
          .toFormat("s's remaining'")}`,
      };
    } else {
      // Ticket is expired:
      return {
        ...def,
        refreshEvery: 999999 * 24 * 60 * 60 * 1000,
        str: "Expired",
      };
    }
  } else {
    // Ticket is not available yet, when is it available?
    const timeLeft = DateTime.fromISO(ticket.dateAvailable).diffNow().valueOf();
    const fullDateNow = DateTime.now();
    const fullDateThen = DateTime.fromISO(ticket.dateAvailable);
    let prefix;
    if (fullDateNow.toISODate() === fullDateThen.toISODate()) {
      prefix = "Today";
    } else if (
      fullDateThen.startOf("day") - fullDateNow.startOf("day") <=
      24 * 60 * 60 * 1000
    ) {
      prefix = "Tomorrow";
    } else {
      prefix = "dayaftertomorrow";
    }
    if (timeLeft >= 304 * 24 * 60 * 60 * 1000) {
      // Available date is more than 10 months from now
      return {
        ...def,
        refreshEvery: 24 * 60 * 60 * 1000,
        str: `On ${DateTime.fromISO(ticket.dateAvailable).toFormat(
          "ccc d LLL yyyy"
        )}`,
      };
    } else if (
      timeLeft >= 2 * 24 * 60 * 60 * 1000 ||
      prefix === "dayaftertomorrow"
    ) {
      // Available date is more than 2 days from now
      return {
        ...def,
        refreshEvery: 24 * 60 * 60 * 1000,
        str: `On ${DateTime.fromISO(ticket.dateAvailable).toFormat(
          "ccc d LLL"
        )}`,
      };
    } else if (timeLeft >= 1 * 24 * 60 * 60 * 1000) {
      // Available date is tomorrow: more than 1 day but less than 2 days from now
      return {
        ...def,
        refreshEvery: 60 * 60 * 1000,
        str: `${prefix} at ${DateTime.fromISO(ticket.dateAvailable).toFormat(
          "t"
        )}`,
      };
    } else if (timeLeft >= 2 * 60 * 60 * 1000) {
      // Available date is more than 2 hours from now
      return {
        ...def,
        refreshEvery: 60 * 60 * 1000,
        str: `${prefix} at ${DateTime.fromISO(ticket.dateAvailable).toFormat(
          "t"
        )}`,
      };
    } else if (timeLeft >= 2 * 60 * 1000) {
      // Available date is more than 2 minutes from now
      return {
        ...def,
        refreshEvery: 60 * 1000,
        str: `${prefix} at ${DateTime.fromISO(ticket.dateAvailable).toFormat(
          "t"
        )}`,
      };
    } else if (timeLeft >= 0) {
      // Available date is any second now (0-120 seconds)
      return {
        ...def,
        refreshEvery: 1000,
        str: `${prefix} at ${DateTime.fromISO(ticket.dateAvailable).toFormat(
          "t"
        )}`,
      };
    } else {
      // Ticket is expired:
      return {
        ...def,
        refreshEvery: 999999 * 24 * 60 * 60 * 1000,
        str: "Expired",
      };
    }
  }
};
