const { DateTime, Duration } = require("luxon");
// ===============================================
// SURVEY SCORING
// -----------------------------------------------
// Performs calculation of the scoring rules
export const evaluateScoring = (score, responses, aliasList, isEnabled) => {
  // Initialize result
  let result = null;
  let a, b, x, val, doubleCheck, itemIsDisabled;
  // If the scoring method is classification, then get the group value or label
  if (
    score.method === "classification-value" ||
    score.method === "classification-label"
  ) {
    // First check if the item is enabled
    itemIsDisabled = Object.keys(isEnabled).some(
      (key) => isEnabled[key] === false && score.grouping.aliasId.includes(key)
    );
    if (itemIsDisabled) {
      return null;
    }
    // Find the category for which the response data meets the criteria (min <= data < max)
    if (score.grouping.type === "time") {
      result = score.grouping.categoryList.find((cat) => {
        a = DateTime.fromISO(cat.min);
        b = DateTime.fromISO(cat.max);
        doubleCheck = b.valueOf() < a.valueOf();
        x = DateTime.fromISO(
          responses[aliasList[score.grouping.aliasId].alias]
        );
        return !x.isValid
          ? null
          : doubleCheck
          ? (x.valueOf() >= a.valueOf() &&
              x.valueOf() < b.plus({ days: 1 }).valueOf()) ||
            (x.valueOf() >= a.minus({ days: 1 }).valueOf() &&
              x.valueOf() < b.valueOf())
          : x.valueOf() >= a.valueOf() && x.valueOf() < b.valueOf();
      });
    } else if (score.grouping.type === "duration") {
      result = score.grouping.categoryList.find((cat) => {
        x = Duration.fromISO(
          responses[aliasList[score.grouping.aliasId].alias]
        );
        return !x.isValid
          ? null
          : x.valueOf() >= Duration.fromISO(cat.min).valueOf() &&
              x.valueOf() < Duration.fromISO(cat.max).valueOf();
      });
    } else {
      result = score.grouping.categoryList.find((cat) => {
        return (
          parseFloat(responses[aliasList[score.grouping.aliasId].alias]) >=
            parseFloat(cat.min) &&
          parseFloat(responses[aliasList[score.grouping.aliasId].alias]) <
            parseFloat(cat.max)
        );
      });
    }
    // Check if the response value meets the criteria, if so, get the label, otherwise set 'null'
    if (score.method === "classification-value") {
      result = result ? result.value : null;
    } else {
      result = result ? result.label : null;
    }
  } else if (
    // If the scoring method is arithmetic...
    score.method === "arithmetic"
  ) {
    // Check if the values are numeric, time or duration
    const isTime =
      score.arithmetic[0].aliasId !== "constant" &&
      aliasList[score.arithmetic[0].aliasId].type === "time";
    if (isTime) {
      // The elements are timepoints
      // First check that there are two and only two elements
      if (score.arithmetic.length !== 2) {
        return null;
      }
      // Check that both items are enabled
      itemIsDisabled =
        Object.keys(isEnabled).some(
          (key) =>
            isEnabled[key] === false &&
            score.arithmetic[0].aliasId.includes(key)
        ) ||
        Object.keys(isEnabled).some(
          (key) =>
            isEnabled[key] === false &&
            score.arithmetic[1].aliasId.includes(key)
        );
      // If any of the 2 items is disabled, return empty handed
      if (itemIsDisabled) {
        return null;
      }
      // The first element is always a DateTime object
      a = DateTime.fromISO(
        responses[aliasList[score.arithmetic[0].aliasId].alias]
      );
      // The second element can be a DateTime or Duration object
      b =
        aliasList[score.arithmetic[1].aliasId].type === "duration"
          ? Duration.fromISO(
              responses[aliasList[score.arithmetic[1].aliasId].alias]
            )
          : DateTime.fromISO(
              responses[aliasList[score.arithmetic[1].aliasId].alias]
            );
      // Check what unit is requested
      let shiftTo = ["days", "hours", "minutes", "seconds"];
      switch (score.arithmetic[1].convertTo) {
        case "d":
          shiftTo = ["days", "hours", "minutes", "seconds"];
          break;
        case "h":
          shiftTo = ["hours", "minutes", "seconds"];
          break;
        case "m":
          shiftTo = ["minutes", "seconds"];
          break;
        case "s":
          shiftTo = ["seconds"];
          break;
        default:
          shiftTo = ["days", "hours", "minutes", "seconds"];
      }
      // Execute the arithmetic
      result =
        !a.isValid || !b.isValid
          ? null
          : aliasList[score.arithmetic[1].aliasId].type === "duration" &&
            score.arithmetic[1].operator === "add"
          ? a.plus(b).toISOTime({
              includeOffset: false,
              suppressMilliseconds: true,
            })
          : aliasList[score.arithmetic[1].aliasId].type === "duration" &&
            score.arithmetic[1].operator === "subtract"
          ? a.minus(b).toISOTime({
              includeOffset: false,
              suppressMilliseconds: true,
            })
          : a.diff(b).valueOf() < 0
          ? a
              .plus({ days: 1 })
              .diff(b)
              .shiftTo(...shiftTo)
              .toISO()
          : a
              .diff(b)
              .shiftTo(...shiftTo)
              .toISO();
    } else {
      // Arithmetic of normal numbers
      result = score.arithmetic.reduce((result, obj) => {
        if (obj.values) {
          x =
            obj.aliasId === "constant"
              ? "n.a."
              : responses[aliasList[obj.aliasId].alias];
          val =
            obj.aliasId === "constant"
              ? parseFloat(obj.values)
              : parseFloat(obj.weight) * parseFloat(obj.values[x]);
          return x === null
            ? result
            : handleArithmetic(result, val, obj.operator);
        } else {
          x = responses[aliasList[obj.aliasId].alias];
          if (x !== null && obj.type === "duration") {
            x = Duration.fromISO(x).toFormat(obj.convertTo);
          }
          return x === null
            ? result
            : handleArithmetic(
                result,
                parseFloat(obj.weight) * parseFloat(x),
                obj.operator
              );
        }
      }, null);
    }
    // If the result is not null, then convert result to a string
    if (result !== null) {
      result = result.toString();
    }
  }
  return result;
};
// Handles computing two-element operations
const handleArithmetic = (a, b, operator) => {
  switch (operator) {
    case "add":
      return a + b;
    case "subtract":
      return a - b;
    case "multiply":
      return a * b;
    case "divide":
      return a / b;
    default:
      return b;
  }
};
