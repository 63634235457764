import React from "react";
import { List, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import AppListItem from "./App_ListItem";
import Icon from "@mui/material/Icon";

const WebpageList = ({ webpagesList, handlePageSelect, currentWebpageId }) => {
  const isWebpagesLoaded = webpagesList && webpagesList.length > 0;
  return (
    <List disablePadding>
      {isWebpagesLoaded ? (
        <>
          {/* HOME PAGES */}
          {webpagesList
            .filter((webpage) => webpage.type === "home")
            .map((webpage, index) => (
              <AppListItem
                divider
                slideIn
                isSelected={currentWebpageId === webpage._id}
                icon={<Icon>house</Icon>}
                textPrimary={webpage.title}
                key={webpage._id || index}
                onClick={() => handlePageSelect(webpage._id)}
              ></AppListItem>
            ))}
          {/* OTHER PAGES PAGES */}
          {webpagesList
            .filter((webpage) => webpage.type === "page")
            .map((webpage, index) => (
              <AppListItem
                divider
                slideIn
                isSelected={currentWebpageId === webpage._id}
                icon={<Icon>web</Icon>}
                textPrimary={webpage.title}
                key={webpage._id || index}
                onClick={() => handlePageSelect(webpage._id)}
              ></AppListItem>
            ))}
          {/* NEWs */}
          {webpagesList
            .filter((webpage) => webpage.type === "news")
            .map((webpage, index) => (
              <AppListItem
                divider
                slideIn
                isSelected={currentWebpageId === webpage._id}
                icon={<Icon>brand_awareness</Icon>}
                textPrimary={webpage.title}
                key={webpage._id || index}
                onClick={() => handlePageSelect(webpage._id)}
              ></AppListItem>
            ))}
          {/* STUDIES */}
          {webpagesList
            .filter((webpage) => webpage.type === "study")
            .map((webpage, index) => (
              <AppListItem
                divider
                slideIn
                isSelected={currentWebpageId === webpage._id}
                icon={<Icon>school</Icon>}
                textPrimary={webpage.title}
                key={webpage._id || index}
                onClick={() => handlePageSelect(webpage._id)}
              ></AppListItem>
            ))}
        </>
      ) : (
        <div style={{ padding: "1rem", textAlign: "center" }}>
          {webpagesList ? (
            <Typography variant="body2" color="textSecondary">
              No webpages available
            </Typography>
          ) : (
            <CircularProgress />
          )}
        </div>
      )}
    </List>
  );
};

export default WebpageList;
