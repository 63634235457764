// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Redux
import { consumersSelectors } from "../../redux/reducers/consumers";
import { supervisionSelectors } from "../../redux/reducers/supervision";
// -------------------------------------------------
// Helper functions
import { str2color } from "../../supportFunc/str2color";
import { alphabet } from "../../supportFunc/alphabet";
import { unique } from "../../supportFunc/unique";
// -------------------------------------------------
// Basic elements
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
// =================================================
// FUNCTIONAL COMPONENT
const MessagesEditConversation = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "messages.Messages_EditConversation",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state)
  );
  const supervisionList = useSelector((state) =>
    supervisionSelectors.selectAll(state)
  );
  // -----------------------------------------------
  // Local State
  const [searchQuery, setSearchQuery] = useState("");
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleAddRecipient = (userId, role) => {
    switch (role) {
      case "participant":
        props.setObj((prevState) => {
          let newState = JSON.parse(JSON.stringify(prevState));
          newState.userIdList = [];
          newState.userIdList.push(userId);
          // Add all supervisors
          let supervisors = supervisionList
            .filter((s) => s.studyId === props.obj.studyId)
            .filter(
              (s) =>
                s.isAllParticipants ||
                s.userIdList.some((uId) => uId === userId)
            )
            .map((s) => s.userId);
          newState.userIdList = [...newState.userIdList, ...supervisors];
          return newState;
        });
        break;
      case "supervisor":
        props.setObj((prevState) => {
          let newState = JSON.parse(JSON.stringify(prevState));
          newState.userIdList.push(userId);
          return newState;
        });
        break;
      default:
        return;
    }
  };
  // -----------------------------------------------
  // Deletes a recipient from local state
  const handleDeleteRecipient = (userId) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.userIdList = newState.userIdList.filter((uid) => uid !== userId);
      return newState;
    });
  };
  // -----------------------------------------------
  const stringAvatar = (id, name) => {
    const abbrev = name.trim().split(" ");
    return {
      sx: {
        bgcolor: str2color(id),
      },
      children:
        abbrev.length === 1
          ? `${name[0].toUpperCase()}`
          : `${name.split(" ")[0][0].toUpperCase()}${name
              .split(" ")[1][0]
              .toUpperCase()}`,
    };
  };
  // -----------------------------------------------
  // Extracts the participant from recipient list
  const getParticipantFromUserIdList = (userIdList) => {
    const participantId = userIdList.filter(
      (uId) => !supervisionList.some((s) => s.userId === uId)
    );
    if (participantId && participantId[0]) {
      return participantId[0];
    } else {
      return null;
    }
  };
  // -----------------------------------------------
  // Filter users enrolled in a study
  const studyFilter = (consumer) => {
    return (
      consumer.studyEnrollmentList &&
      consumer.studyEnrollmentList[0] &&
      consumer.studyEnrollmentList[0].studyId === props.obj.studyId
    );
  };
  // -----------------------------------------------
  // Apply the search query
  const searchFilter = (consumer) => {
    let UID, PID;
    UID = consumer.userNumber.toLowerCase();
    PID =
      consumer.studyEnrollmentList &&
      consumer.studyEnrollmentList[0] &&
      consumer.studyEnrollmentList[0].userStudyNumber.toLowerCase();
    if (consumer.name) {
      return (
        (UID && UID.includes(searchQuery.toLowerCase())) ||
        (PID && PID.includes(searchQuery.toLowerCase())) ||
        consumer.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        consumer.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else {
      return (
        (UID && UID.includes(searchQuery.toLowerCase())) ||
        (PID && PID.includes(searchQuery.toLowerCase())) ||
        consumer.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  };
  // ===============================================
  // RENDER COMPONENT
  return props.obj ? (
    <Grid container direction="column" className="h-100">
      <Grid
        item
        className={
          isXS ? "m-2 mb-0 flex-grow-0" : "m-2 mb-0 flex-grow-0 fix-width-625px"
        }
      >
        <Paper className="p-3">
          {getParticipantFromUserIdList(props.obj.userIdList) && (
            <Chip
              avatar={
                <Avatar
                  {...stringAvatar(
                    consumerEntities[
                      getParticipantFromUserIdList(props.obj.userIdList)
                    ]._id,
                    consumerEntities[
                      getParticipantFromUserIdList(props.obj.userIdList)
                    ].email
                  )}
                  className="text-white"
                />
              }
              label={
                consumerEntities[
                  getParticipantFromUserIdList(props.obj.userIdList)
                ].email
              }
            />
          )}
          {props.isNewConversation && (
            <TextField
              fullWidth
              label={t("Search")}
              size="small"
              value={searchQuery ? searchQuery : ""}
              color="secondary"
              variant="filled"
              className="bg-white answer-text-green mt-2"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          )}
        </Paper>
      </Grid>
      {props.isNewConversation && (
        <Grid
          item
          className={
            isXS
              ? "m-2 my-0 flex-grow-1 overflow-scroll"
              : "m-2 my-0 flex-grow-1 overflow-scroll fix-width-625px"
          }
          sx={{ flexBasis: "1px" }}
        >
          <Paper className="px-3 py-0 h-100">
            <List
              sx={{
                width: "100%",
                position: "relative",
                overflow: "auto",
                maxHeight: "100%",
              }}
              subheader={<li />}
            >
              {alphabet.map(
                (x, i) =>
                  i < 26 &&
                  Object.values(consumerEntities)
                    .filter((consumer) => consumer.deletedOn === null)
                    .filter((consumer) => consumer.email[0] === x)
                    .filter(studyFilter)
                    .filter(searchFilter).length > 0 && (
                    <li key={`section-${x}`}>
                      <ul className="p-0">
                        <ListSubheader className="p-0">
                          {x.toUpperCase()}
                        </ListSubheader>
                        {Object.values(consumerEntities)
                          .filter((consumer) => consumer.deletedOn === null)
                          .filter((consumer) => consumer.email[0] === x)
                          .filter(studyFilter)
                          .filter(searchFilter)
                          .map((consumer) => (
                            <React.Fragment key={consumer._id}>
                              <ListItem className="p-0">
                                <ListItemButton
                                  className={
                                    props.obj.userIdList.find(
                                      (userId) => userId === consumer._id
                                    ) !== undefined
                                      ? "bg-list-item-selected m-0"
                                      : "m-0"
                                  }
                                  onClick={() =>
                                    props.obj.userIdList.find(
                                      (userId) => userId === consumer._id
                                    ) === undefined &&
                                    handleAddRecipient(
                                      consumer._id,
                                      "participant"
                                    )
                                  }
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      {...stringAvatar(
                                        consumer._id,
                                        consumer.name
                                          ? consumer.name
                                          : consumer.email
                                      )}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={consumer.email}
                                    secondary={`${unique([
                                      consumer.userNumber,
                                      consumer.studyEnrollmentList[0]
                                        .userStudyNumber,
                                    ])}`}
                                    className="m-0"
                                  />
                                </ListItemButton>
                              </ListItem>
                              <Divider />
                            </React.Fragment>
                          ))}
                      </ul>
                    </li>
                  )
              )}
            </List>
          </Paper>
        </Grid>
      )}
      <Grid
        item
        className={isXS ? "m-2 flex-grow-0" : "m-2 flex-grow-0 fix-width-625px"}
      >
        <Paper className="p-3">
          <Typography variant="h3">Supervisors</Typography>
          <List>
            {supervisionList
              .filter((s) => s.studyId === props.obj.studyId)
              .map((s) => (
                <React.Fragment key={s._id}>
                  <ListItem
                    key={s._id}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        color="secondary"
                        onChange={(e) =>
                          e.target.checked
                            ? handleAddRecipient(s.userId, "supervisor")
                            : handleDeleteRecipient(s.userId)
                        }
                        checked={
                          props.obj.userIdList.find(
                            (userId) => userId === s.userId
                          ) !== undefined
                        }
                      />
                    }
                    className="p-0"
                  >
                    <ListItemButton
                      className={
                        props.obj.userIdList.find(
                          (userId) => userId === s.userId
                        ) !== undefined
                          ? "bg-list-item-selected m-0"
                          : "m-0"
                      }
                      onClick={() =>
                        props.obj.userIdList.find(
                          (userId) => userId === s.userId
                        ) === undefined
                          ? handleAddRecipient(s.userId, "supervisor")
                          : handleDeleteRecipient(s.userId)
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          {...stringAvatar(
                            s.userId,
                            consumerEntities[s.userId].name
                              ? consumerEntities[s.userId].name
                              : consumerEntities[s.userId].email
                          )}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          consumerEntities[s.userId].name
                            ? `${consumerEntities[s.userId].name} <${
                                consumerEntities[s.userId].email
                              }>`
                            : consumerEntities[s.userId].email
                        }
                        secondary={s.role}
                        className="m-0"
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default MessagesEditConversation;
