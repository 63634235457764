// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Redux
import { consumersSelectors } from "../redux/reducers/consumers";
// -------------------------------------------------
// Components
import AppListItem from "../components/App_ListItem";
// -------------------------------------------------
// Basic elements
import List from "@mui/material/List";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
// =================================================
// FUNCTIONAL COMPONENT
const InvestigatorsColumnContent = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "Investigators_ColumnContent" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // History
  const navigate = useNavigate();
  // -----------------------------------------------
  // Redux
  const currentUser = useSelector((state) => state.user.currentUser);
  const consumerList = useSelector((state) =>
    consumersSelectors.selectAll(state)
  ).filter((consumer) => consumer.deletedOn === null);
  const ranks = ["superuser", "admin"];
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the URL search query to the study and consumer identifier
  const handleSetCurrentConsumer = (consumerId) => {
    let el;
    el = document.querySelector("#scroll-top-app");
    if (el) {
      el.scrollIntoView({ block: "end" });
    }
    navigate(`/investigators?consumerId=${consumerId}`, { replace: true });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <List disablePadding>
      {consumerList.length === 0 ? (
        <Fade in={true}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className="my-3 text-center">
              <img
                src="images/icon-train-end.png"
                alt="No users"
                className="w-50"
              />
            </Grid>
            <Grid item>
              <Typography variant="overline">{ t("No users") }</Typography>
            </Grid>
          </Grid>
        </Fade>
      ) : (
        ranks.map((rank) => (
          <React.Fragment key={rank}>
            <AppListItem
              divider
              slideIn
              textPrimary={
                <Typography noWrap variant="h5" className="text-capitalize">
                  {`${rank}s`}
                </Typography>
              }
              className="bg-light"
            />
            <Collapse in={true} timeout="auto" unmountOnExit>
              <List disablePadding>
                {consumerList
                  .filter((consumer) => consumer.primaryRole === rank)
                  .map((consumer) => (
                    <AppListItem
                      key={consumer._id}
                      divider
                      slideIn
                      isSelected={
                        props.currentConsumer &&
                        consumer._id === props.currentConsumer._id
                      }
                      onClick={() => handleSetCurrentConsumer(consumer._id)}
                      textPrimary={
                        consumer._id === currentUser._id
                          ? "You"
                          : consumer.email
                      }
                    />
                  ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))
      )}
    </List>
  );
};
// =================================================
// EXPORT COMPONENT
export default InvestigatorsColumnContent;
