// ########################################
// FUNCTION
// ----------------------------------------
export const getAliasContainingId = (aliasList, id, keepAsArray) => {
  const aliasIds = Object.keys(aliasList).filter((i) => i.includes(id));
  const alias = aliasIds.map((i) => aliasList[i].alias);
  if (keepAsArray === true) {
    return alias;
  }
  if (alias.length === 1) {
    return alias[0];
  } else {
    return alias;
  }
};
