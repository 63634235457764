// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import { useEffect } from "react";
import { useDispatch } from "react-redux";
// -------------------------------------------------
// Redux
import { setCurrentPage } from "../redux/reducers/ui";
// -------------------------------------------------
// Contexts
import { useLocation } from "react-router-dom";
// =================================================
// FUNCTIONAL COMPONENT
const SetCurrentPage = () => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  // -----------------------------------------------
  // Get the location
  const location = useLocation();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Set the current page based on the pathname
  useEffect(() => {
    switch (location.pathname) {
      case "/":
        dispatch(setCurrentPage({ currentPage: "" }));
        break;
      case "/tasks":
        dispatch(setCurrentPage({ currentPage: "Tasks" }));
        break;
      case "/results":
        dispatch(setCurrentPage({ currentPage: "Results" }));
        break;
      case "/account":
        dispatch(setCurrentPage({ currentPage: "Account" }));
        break;
      case "/surveys":
        dispatch(setCurrentPage({ currentPage: "Surveys" }));
        break;
      case "/studies":
        dispatch(setCurrentPage({ currentPage: "Studies" }));
        break;
      case "/volunteers":
        dispatch(setCurrentPage({ currentPage: "Volunteers" }));
        break;
      case "/investigators":
        dispatch(setCurrentPage({ currentPage: "Investigators" }));
        break;
      case "/cmseditor":
        dispatch(setCurrentPage({ currentPage: "CMS Editor" }));
        break;
      case "/messages":
        dispatch(setCurrentPage({ currentPage: "Messages" }));
        break;
      case "/welcome":
        dispatch(setCurrentPage({ currentPage: "Welcome" }));
        break;
      default:
        dispatch(setCurrentPage({ currentPage: "" }));
        break;
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  return null;
};
// =================================================
// EXPORT COMPONENT
// export default Header;
export default SetCurrentPage;
