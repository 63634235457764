// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Add from "@mui/icons-material/Add";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/icons-material/Link";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditArticleColumns = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditArticleColumns",
  });
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (idx, key, value) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[idx][key] = value;
      return newState;
    });
  };
  const handleAddColumn = () => {
    props.setObj((prevState) => {
      let newState = [
        ...prevState,
        {
          _id: uuid(),
          text: "",
          inputLabel: "",
        },
      ];
      return newState;
    });
  };
  const handleMoveColumn = (idx, direction) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let element = newState[idx];
      newState.splice(idx, 1);
      newState.splice(idx + direction, 0, element);
      return newState;
    });
  };
  const handleDeleteColumn = (idx) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.splice(idx, 1);
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAddColumn();
        }}
      >
        <Button type="submit" className="d-none" />
        <Grid container alignItems="center">
          <Grid item xs={12} className="mb-2">
            <Typography variant="h4">{t("Columns")}</Typography>
          </Grid>
          <Grid item xs={4} className="pe-2 mb-2">
            {t("Column label")}
          </Grid>
          {props.hasInputLabel && (
            <Grid item xs={3} className="pe-2 mb-2">
              {t("Input label (optional)")}
            </Grid>
          )}
          <Grid item xs={5} className="pe-2 mb-2" />
          {props.obj.map((o, i) => (
            <React.Fragment key={`answer-${i}`}>
              <Grid item xs={4} className="pe-2 mb-2">
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  type="text"
                  name="text"
                  variant="filled"
                  value={o.text}
                  color="secondary"
                  className="answer-text-green"
                  onChange={(e) =>
                    handleSetObj(i, e.target.name, e.target.value)
                  }
                />
              </Grid>
              {props.hasInputLabel && (
                <Grid item xs={3} className="pe-2 mb-2">
                  <TextField
                    fullWidth
                    hiddenLabel
                    size="small"
                    type="text"
                    name="inputLabel"
                    variant="filled"
                    value={o.inputLabel ? o.inputLabel : ""}
                    color="secondary"
                    className="answer-text-green"
                    onChange={(e) =>
                      handleSetObj(i, e.target.name, e.target.value)
                    }
                  />
                </Grid>
              )}
              <Grid item xs={5} className="mb-2">
                {props.obj.length > 1 && (
                  <Tooltip arrow title={t("Up")} placement="left">
                    <span>
                      <IconButton
                        disabled={i === 0}
                        onClick={(e) => handleMoveColumn(i, -1)}
                      >
                        <ArrowUpward />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {props.obj.length > 1 && (
                  <Tooltip
                    arrow
                    title={t("Down")}
                    placement={i === 0 ? "top" : "bottom"}
                  >
                    <span>
                      <IconButton
                        disabled={i === props.obj.length - 1}
                        onClick={(e) => handleMoveColumn(i, 1)}
                      >
                        <ArrowDownward />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {props.obj.length > 1 && (
                  <Tooltip
                    arrow
                    title={
                      (props.dependencies &&
                        Object.keys(props.dependencies).some((key) =>
                          key.includes(`${o._id}`)
                        )) ||
                      (props.scoring &&
                        props.scoring.some(
                          (score) =>
                            (score.arithmetic &&
                              score.arithmetic.some((a) =>
                                a.aliasId.includes(o._id)
                              )) ||
                            (score.grouping &&
                              score.grouping.aliasId.includes(o._id))
                        ))
                        ? t("Can't delete")
                        : t("Delete")
                    }
                    placement="right"
                  >
                    <span>
                      <IconButton
                        disabled={
                          (props.dependencies &&
                            Object.keys(props.dependencies).some((key) =>
                              key.includes(`${o._id}`)
                            )) ||
                          (props.scoring &&
                            props.scoring.some(
                              (score) =>
                                (score.arithmetic &&
                                  score.arithmetic.some((a) =>
                                    a.aliasId.includes(o._id)
                                  )) ||
                                (score.grouping &&
                                  score.grouping.aliasId.includes(o._id))
                            ))
                        }
                        onClick={(e) => {
                          handleDeleteColumn(i);
                        }}
                      >
                        <DeleteForever />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {props.dependencies &&
                  Object.keys(props.dependencies).some((key) =>
                    key.includes(`${o._id}`)
                  ) && (
                    <Tooltip arrow title={t("dependent")} placement="top">
                      <Link color="warning" />
                    </Tooltip>
                  )}
                {props.scoring &&
                  props.scoring.some(
                    (score) =>
                      (score.arithmetic &&
                        score.arithmetic.some((a) =>
                          a.aliasId.includes(o._id)
                        )) ||
                      (score.grouping && score.grouping.aliasId.includes(o._id))
                  ) && (
                    <Tooltip arrow title={t("scored")} placement="top">
                      <Link color="success" />
                    </Tooltip>
                  )}
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Divider>
              <ButtonGroup>
                <Button
                  variant="outlined"
                  size="small"
                  color="inherit"
                  startIcon={<Add />}
                  className="border-dashed"
                  onClick={() => handleAddColumn()}
                >
                  {t("Add column")}
                </Button>
              </ButtonGroup>
            </Divider>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditArticleColumns;
