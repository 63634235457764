// Webpage.js
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useAuth } from "../contexts/auth";
import Footer from "../components/Footer";
import PageNotFound from "../pages/PageNotFound";
import {
  fetchWebpageByUrl,
  setCurrentWebpage,
  publicWebpageSelectors,
} from "../redux/reducers/publicWebpages";
import AppRoot from "../components/App_Root";
import HomeTemplate from "../components/pages/HomeTemplate";
import PageTemplate from "../components/pages/PageTemplate";
import StudyTemplate from "../components/pages/StudyTemplate";

const Webpage = () => {
  const { currentAuth } = useAuth();
  const isXS = useSelector((state) => state.ui.isXS);
  const location = useLocation();
  const dispatch = useDispatch();
  const webpageStatus = useSelector((state) => state.publicWebpages.status);
  const webpageList = useSelector((state) =>
    publicWebpageSelectors.selectAll(state)
  );
  const currentWebpage = useSelector(
    (state) => state.publicWebpages.currentWebpage
  );

  useEffect(() => {
    if (
      webpageList.some(
        (page) => page.url === location.pathname.replace("/", "")
      )
    ) {
      dispatch(
        setCurrentWebpage({
          page: webpageList.find(
            (page) => page.url === location.pathname.replace("/", "")
          ),
        })
      );
    } else {
      dispatch(fetchWebpageByUrl(location.pathname.replace("/", "")));
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps
  if (webpageStatus === "idle" || webpageStatus === "loading") {
    return <></>;
  }
  if (!currentWebpage) {
    return <PageNotFound />;
  }
  return (
    <AppRoot
      forceNavDrawer={isXS || (!isXS && currentAuth !== null)}
      drawerVariant="temporary"
    >
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE} |{" "}
          {currentWebpage && currentWebpage.title}
        </title>
        <meta
          name="description"
          content={currentWebpage && currentWebpage.description}
        />
      </Helmet>
      {currentWebpage && currentWebpage.type === "home" ? (
        <HomeTemplate currentWebpage={currentWebpage} />
      ) : currentWebpage && currentWebpage.type === "page" ? (
        <PageTemplate currentWebpage={currentWebpage} />
      ) : currentWebpage && currentWebpage.type === "study" ? (
        <StudyTemplate currentWebpage={currentWebpage} />
      ) : (
        <PageNotFound />
      )}
      <Footer />
    </AppRoot>
  );
};

export default Webpage;
