// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { DateTime } from "luxon";
// -------------------------------------------------
// Redux
import { studiesSelectors } from "../../redux/reducers/studies";
import { surveysSelectors } from "../../redux/reducers/surveys";
import { toggleCloak } from "../../redux/reducers/user";
// -------------------------------------------------
// Basic elements
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// -----------------------------------------------
// Icons
import SvgIcon from "@mui/material/SvgIcon";
import EditIcon from "@mui/icons-material/Edit";
import { mdiGhost } from "@mdi/js";
// -------------------------------------------------
// Support functions
import { str2color } from "../../supportFunc/str2color";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const VolunteersContentMeasurementItem = ({
  ticket,
  isExpired,
  isOverdue,
  isCompleted,
  handleEditTicket,
  currentConsumer,
}) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local
  const [anchorElEdit, setAnchorElEdit] = useState(null);
  const isOpenEditMenu = Boolean(anchorElEdit);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const isMD = useSelector((state) => state.ui.isMD);
  const surveyEntities = useSelector((state) =>
    surveysSelectors.selectEntities(state)
  );
  const currentStudyId = useSelector((state) => state.consumers.currentStudyId);
  const currentStudy = useSelector(
    (state) =>
      currentStudyId && studiesSelectors.selectById(state, currentStudyId)
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  const handleToggleEditMenu = (el) => {
    setAnchorElEdit(el);
  };
  // -----------------------------------------------
  const getDateAvailableStr = (d) => {
    if (d.split("T")[1] === "00:00") {
      return "-";
    }
    return DateTime.fromISO(d).toFormat("H:mm");
  };
  const getDateDueStr = (d) => {
    let format = "d LLL H:mm";
    if (d.split("T")[1] === "00:00") {
      format = "d LLL";
    }
    return DateTime.fromISO(d).toFormat(format);
  };
  const getDateOverdueStr = (d) => {
    let format = "d LLL H:mm";
    return DateTime.fromISO(d).toFormat(format);
  };
  // -------------------------------------------------
  // Enable cloaking
  const handleCloak = () => {
    dispatch(toggleCloak({ cloak: true, currentUser: currentConsumer }));
  };
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      {/* ================================================== */}
      {/* MENU TO EDIT A SINGLE TICKET OR THE ENTIRE SERIES  */}
      <Menu
        id="edit-ticket-menu"
        anchorEl={anchorElEdit}
        open={isOpenEditMenu}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        onClose={() => {
          handleToggleEditMenu(null);
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleToggleEditMenu(null);
            handleEditTicket(ticket, "occurence");
          }}
        >
          Edit occurence
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleToggleEditMenu(null);
            handleEditTicket(ticket, "series");
          }}
        >
          Edit series
        </MenuItem>
      </Menu>
      <Grid container wrap="nowrap" className="w-100 my-1">
        <Grid
          item
          className="px-2 overflow-hidden"
          sx={{ minWidth: "75px", maxWidth: "75px" }}
        >
          <Grid
            wrap="nowrap"
            container
            direction="column"
            alignItems="flex-end"
          >
            <Grid item>
              <Typography
                noWrap
                variant="caption"
                color={isExpired ? "red" : isOverdue ? "orangered" : "GrayText"}
                className="text-truncate"
              >
                {isExpired
                  ? "expired on"
                  : isOverdue
                  ? "expires on"
                  : getDateAvailableStr(ticket.dateAvailable)}
              </Typography>
            </Grid>
            {isExpired || isOverdue ? (
              <Grid item>
                <Typography
                  noWrap
                  variant="caption"
                  color={isExpired ? "red" : "orangered"}
                  className="text-truncate"
                >
                  {getDateOverdueStr(ticket.dateExpire)}
                </Typography>
              </Grid>
            ) : (
              <Grid item>
                <Typography
                  noWrap
                  variant="caption"
                  color="CaptionText"
                  className="text-truncate"
                >
                  {ticket.dateDue ? getDateDueStr(ticket.dateDue) : "all day"}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          className="rounded"
          sx={{
            width: "4px",
            minWidth: "4px",
            backgroundColor: str2color(ticket.measurementId),
          }}
        ></Grid>
        <Grid
          item
          className="ps-2 overflow-hidden"
          sx={{ maxWidth: isMD ? "340px" : "auto" }}
        >
          <Typography
            noWrap
            variant="overline"
            color="GrayText"
            className="text-truncate"
            sx={{ lineHeight: "1rem" }}
          >
            {currentStudy.timepointList.find(
              (tp) => tp._id === ticket.timepointId
            )
              ? `TP${
                  currentStudy.timepointList.findIndex(
                    (tp) => tp._id === ticket.timepointId
                  ) + 1
                } ${
                  currentStudy.timepointList.find(
                    (tp) => tp._id === ticket.timepointId
                  ).label
                }`
              : "no timepoint label"}
          </Typography>
          <Typography
            noWrap
            variant="subtitle1"
            className="text-truncate"
            sx={{ lineHeight: "1.5rem" }}
          >
            {surveyEntities[ticket.surveyId]
              ? surveyEntities[ticket.surveyId].name.official
              : "Survey does not exist"}
          </Typography>
        </Grid>
        <Grid item className="flex-grow-1 pe-2">
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            justifyContent="flex-end"
            className="h-100"
          >
            <Grid item>
              <Tooltip arrow title="Edit ticket" placement="top">
                <IconButton
                  size="small"
                  onClick={(e) => {
                    if (ticket.repeat === -1) {
                      handleToggleEditMenu(null);
                      handleEditTicket(ticket, "occurence");
                    } else if (ticket.isSeries) {
                      handleToggleEditMenu(e.currentTarget);
                    } else {
                      handleToggleEditMenu(null);
                      handleEditTicket(ticket, "occurence");
                    }
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Cloak and complete task" placement="top">
                <IconButton
                  component={NavLink}
                  size="small"
                  to={`/tasks?ticketId=${ticket._id}`}
                  onClick={handleCloak}
                >
                  <SvgIcon>
                    <path d={mdiGhost} />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default VolunteersContentMeasurementItem;
