// ########################################
// FUNCTIONS
// ----------------------------------------
// Convert hex to rgb
const hexToRgb = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};
// ----------------------------------------
// Create a color
export const getRGBA = (hex, opacity) => {
  if (hex === "inherit") {
    return null;
  } else {
    const c = hexToRgb(hex);
    if (c) {
      return `rgba(${c.r}, ${c.g}, ${c.b}, ${opacity})`;
    } else {
      return null;
    }
  }
};
