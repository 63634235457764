// =================================================
// IMPORT
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/messages`;
// -------------------------------------------------
// Use 'createEntityAdapter' to store the studies in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  errorMsg: null,
  recipientList: [],
});
// =================================================
// ASYNC API ACTIONS
// -------------------------------------------------
// API fetch all message by the recipient id
export const fetchMessageListByRecipient = createAsyncThunk(
  "messages/fetchMessageListByRecipient",
  async ({ requestingUser, recipientId }) => {
    const response = await client.get(
      `${apiurl}/recipient/${recipientId}`,
      requestingUser
    );
    return response.data;
  }
);
// -------------------------------------------------
// API fetch all message by the recipient id
export const fetchMessageById = createAsyncThunk(
  "messages/fetchMessageById",
  async ({ requestingUser, messageId }) => {
    const response = await client.get(
      `${apiurl}/message/${messageId}`,
      requestingUser
    );
    return response.data;
  }
);
// -------------------------------------------------
// API post a new message
export const postNewMessage = createAsyncThunk(
  "messages/postNewMessage",
  async ({ socket, requestingUser, body }) => {
    // Make the call to the database
    const response = await client.post(
      `${apiurl}/message`,
      requestingUser,
      body
    );
    // Invoke event on server
    socket &&
      socket.emit("posted-new-message", {
        message: response.data.message,
        recipientList: response.data.recipientList,
      });
    // Return the response
    return response.data;
  }
);
// -------------------------------------------------
// API patch a message object
export const patchCurrentMessage = createAsyncThunk(
  "messages/patchCurrentMessage",
  async ({ socket, requestingUser, body }) => {
    // Make the call to the database
    let response;
    if (body.meta && body.meta.setIsRead) {
      response = await client.patch(
        `${apiurl}/recipient/${body.data._id}`,
        requestingUser,
        body
      );
      socket &&
        socket.emit("set-current-message-is-read", {
          messageId: response.data.recipient.messageId,
          userIdList: body.meta.userIdList,
        });
    } else {
      response = await client.patch(
        `${apiurl}/message/${body.data._id}`,
        requestingUser,
        body
      );
      response.data.message.message === "This message was deleted." &&
        socket &&
        socket.emit("deleted-current-message", {
          messageId: response.data.message._id,
        });
    }
    // Return the response
    return response.data;
  }
);
// =================================================
// DEFINE MUTATING ACTIONS
export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    resetMessagesError(state) {
      if (state.status === "failed") {
        state.status = "idle";
        state.errorMsg = null;
      }
    },
    removeMessageById(state, action) {
      if (state.entities[action.payload.messageId]) {
        state.entities[action.payload.messageId].message =
          "This message was deleted.";
      }
    },
    removeMessagesByConversationId(state, action) {
      const ids = state.ids.filter(
        (id) =>
          state.entities[id].conversationId === action.payload.conversationId
      );
      adapter.removeMany(state, ids);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMessageListByRecipient.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMessageListByRecipient.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.messageList &&
          adapter.upsertMany(state, action.payload.messageList);
        if (action.payload.recipientList) {
          state.recipientList = action.payload.recipientList;
        }
      })
      .addCase(fetchMessageListByRecipient.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(fetchMessageById.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.message &&
          adapter.upsertOne(state, action.payload.message);
        if (action.payload.recipientList) {
          action.payload.recipientList.forEach((recipient) => {
            const idx = state.recipientList.findIndex(
              (r) => r._id === recipient._id
            );
            if (idx === -1) {
              state.recipientList.push(recipient);
            } else {
              state.recipientList[idx] = recipient;
            }
          });
        }
      })
      .addCase(fetchMessageById.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(postNewMessage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postNewMessage.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.message &&
          adapter.upsertOne(state, action.payload.message);
        if (action.payload.recipientList) {
          state.recipientList.push(...action.payload.recipientList);
        }
      })
      .addCase(postNewMessage.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(patchCurrentMessage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(patchCurrentMessage.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.message &&
          adapter.upsertOne(state, action.payload.message);
        if (action.payload.recipient) {
          const idx = state.recipientList.findIndex(
            (r) => r._id === action.payload.recipient._id
          );
          state.recipientList[idx] = action.payload.recipient;
        }
      })
      .addCase(patchCurrentMessage.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const {
  resetMessagesError,
  removeMessageById,
  removeMessagesByConversationId,
} = messagesSlice.actions;
// =================================================
// SELECTOR FUNCTIONS
// -------------------------------------------------
export const messagesSelectors = adapter.getSelectors(
  (state) => state.messages
);
// -------------------------------------------------
export const selectMessageListByConversationId = (state, conversationId) => {
  return Object.values(state.messages.entities).filter(
    (message) => message.conversationId === conversationId
  );
};
// =================================================
// EXPORT DEFAULT
export default messagesSlice.reducer;
