// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// -------------------------------------------------
// Component elements
import MainCard from "../components/App_MainCard";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
// -------------------------------------------------
// Basic elements
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// -------------------------------------------------
// Icons
import Logout from "@mui/icons-material/Logout";
import HowToReg from "@mui/icons-material/HowToReg";
// =================================================
// FUNCTIONAL COMPONENT
const AuthVerify = () => {
  const { t } = useTranslation("pages", { keyPrefix: "AuthVerify" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Context state
  const { currentAuth, verifiedAuth } = useAuth();
  // ===============================================
  // RENDER COMPONENT
  if (currentAuth && verifiedAuth) {
    return <Navigate to="/" />;
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>{ process.env.REACT_APP_TITLE } | { t("Verify email") }</title>
      </Helmet>
      <MainCard>
        <Typography className="mb-3" variant="h1">
          { t("Ok, but your email address is not verified yet") }
        </Typography>
        <Typography className="mb-3">
          { t("For security reasons, we need to check that the email address you provided is actually yours.") }
        </Typography>
        <Button
          fullWidth
          component={NavLink}
          to="/user-management?mode=sendVerifyEmailLink"
          variant="contained"
          color="primary"
          startIcon={<HowToReg />}
          className="mb-3 text-white text-decoration-none">
          { t("Verify Account") }
        </Button>
        <Button
          fullWidth
          component={NavLink}
          to="/sign-out"
          variant="outlined"
          color="inherit"
          startIcon={<Logout />}
          className="text-decoration-none">
          { t("Sign Out") }
        </Button>
      </MainCard>
    </React.Fragment>
  );
};
// =================================================
// EXPORT COMPONENT
export default AuthVerify;
