// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../../redux/reducers/ui";
// -------------------------------------------------
// Support functions
import { array2string } from "../../supportFunc/array2string";
// -------------------------------------------------
// Component elements
import SurveysEditPage from "./Surveys_EditPage";
import TopDrawer from "../App_TopDrawer";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// -------------------------------------------------
// Icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Link from "@mui/icons-material/Link";
import Save from "@mui/icons-material/Save";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const SurveysContentPage = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "surveys.Surveys_ContentPage" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const surveysStatus = useSelector((state) => state.surveys.status);
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  // -----------------------------------------------
  // Local state
  const hasDependencies =
    props.page.gridContainerList &&
    props.page.gridContainerList.some(
      (gc) =>
        gc.gridItemList &&
        gc.gridItemList.some(
          (gi) =>
            gi.articleList &&
            gi.articleList.some(
              (article) =>
                (props.dependencies &&
                  Object.keys(props.dependencies).some((dep) =>
                    dep.includes(article._id)
                  )) ||
                (props.scoring &&
                  props.scoring.some(
                    (score) =>
                      (score.arithmetic &&
                        score.arithmetic.some((a) =>
                          a.aliasId.includes(article._id)
                        )) ||
                      (score.grouping &&
                        score.grouping.aliasId.includes(article._id))
                  ))
            )
        )
    );
  const [obj, setObj] = useState(props.page);
  const [deleteMenuIsOpen, setDeleteMenuIsOpen] = useState(false);
  const [deleteMenuAnchor, setDeleteMenuAnchor] = useState(null);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Handles opening the top drawer for changing survey info
  const handleEdit = (id) => {
    dispatch(toggleSecDrawer({ isOpen: true, id }));
  };
  // -----------------------------------------------
  // Resets local state upon closing of the drawer
  useEffect(() => {
    if (!secDrawerIsOpen) {
      setObj(props.page);
    }
  }, [props.page, secDrawerIsOpen]);
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      {/* ================================================== */}
      {/* TOP DRAWER */}
      {obj && (
        <TopDrawer
          id={obj._id}
          title={ t("Edit page") }
          buttons={
            <Button
              disabled={surveysStatus === "loading" || props.disabled}
              color="inherit"
              startIcon={<Save />}
              className="m-2"
              onClick={() =>
                props.handleMutateCurrentSurvey("page", obj, {
                  page: props.pageIdx,
                })
              }
            >
              {surveysStatus === "loading" ? (
                <CircularProgress size="1.5rem" className="text-light" />
              ) : (
                t("Save")
              )}
            </Button>
          }
        >
          <SurveysEditPage
            obj={obj}
            setObj={setObj}
            aliasList={props.aliasList}
            dependencies={props.dependencies}
            scoring={props.scoring}
          />
        </TopDrawer>
      )}
      {/* ================================================== */}
      {/* PAGE SETTINGS*/}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={
          props.disabled
            ? "mb-1"
            : "mb-1 border border-grey border-dashed rounded"
        }
        sx={{ padding: "2px" }}
      >
        {/* ================================================== */}
        {/* PAGE NUMBER */}
        <Grid item>
          <Typography variant="overline" color="slategray" className="ps-2">
            {`page ${props.pageIdx + 1}`}
          </Typography>
        </Grid>
        {!props.disabled && (
          <Grid item>
            {/* ================================================== */}
            {/* PAGE EDIT BUTTONS */}
            <Tooltip arrow title={ t("Edit") } placement="top">
              <span>
                <IconButton
                  disabled={surveysStatus === "loading"}
                  edge="end"
                  className="me-1"
                  onClick={() => handleEdit(props.page._id)}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip arrow title={ t("Copy") } placement="top">
              <span>
                <IconButton
                  disabled={surveysStatus === "loading"}
                  edge="end"
                  className="me-1"
                  onClick={() => props.handleCopy(props.pageIdx, props.page)}
                >
                  <ContentCopy />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              arrow
              title={props.pageIdx === 0 ? t("Can't move up") : t("Move up")}
              placement="top"
            >
              <span>
                <IconButton
                  disabled={surveysStatus === "loading" || props.pageIdx === 0}
                  edge="end"
                  className="me-1"
                  onClick={() => props.handleMove(props.pageIdx, -1)}
                >
                  <ArrowUpward />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              arrow
              title={props.isLast ? t("Can't move down") : t("Move down")}
              placement="top"
            >
              <span>
                <IconButton
                  disabled={surveysStatus === "loading" || props.isLast}
                  edge="end"
                  className="me-1"
                  onClick={() => props.handleMove(props.pageIdx, 1)}
                >
                  <ArrowDownward />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              arrow
              title={hasDependencies ? t("Can't delete") : t("Delete")}
              placement="top"
            >
              <span>
                <IconButton
                  disabled={surveysStatus === "loading" || hasDependencies}
                  edge="end"
                  className="me-0"
                  onClick={(e) => {
                    setDeleteMenuIsOpen(true);
                    setDeleteMenuAnchor(e.currentTarget);
                  }}
                >
                  <DeleteForever />
                </IconButton>
              </span>
            </Tooltip>
            <Menu
              anchorEl={deleteMenuAnchor}
              open={deleteMenuIsOpen}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={() => {
                setDeleteMenuIsOpen(false);
                setDeleteMenuAnchor(null);
              }}
            >
              <MenuItem onClick={() => props.handleDelete(props.pageIdx)}>
                {surveysStatus === "loading" ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  "Delete"
                )}
              </MenuItem>
            </Menu>
          </Grid>
        )}
        {/* ================================================== */}
        {/* PAGE TITLE */}
        {((props.page.title && props.page.title.text) || props.page.logic) && (
          <Grid item xs={12} className="mb-1">
            {props.page.title && (
              <Typography
                variant={props.page.title.variant}
                color={props.page.title.color}
                align={props.page.title.align}
              >
                {props.page.title.text}
              </Typography>
            )}
            {props.page.logic && (
              <Chip
                icon={<Link />}
                label={`conditional on ${array2string(
                  props.page.logic.map((logicGroup) =>
                    logicGroup.itemList.map(
                      (logicItem) =>
                        props.aliasList[logicItem.condition.aliasId].alias
                    )
                  )
                )}`}
                size="small"
                variant="outlined"
                color="info"
                className={
                  props.page.title && props.page.title.text
                    ? "ms-3 border-0"
                    : "border-0"
                }
              />
            )}
          </Grid>
        )}
        {/* ================================================== */}
        {/* PAGE CONTENT */}
        <Grid container className="border border-secondary rounded px-2 pt-2">
          <Grid item xs={12}>
            {props.children}
          </Grid>
        </Grid>
      </Grid>
      {/* ================================================== */}
      {/* ADD PAGE BUTTON */}
      {!props.disabled && <div className="mt-2">{props.ButtonGroup}</div>}
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysContentPage;
