// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { makeStyles } from "@mui/styles";
// -------------------------------------------------
// Basic elements
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
// =================================================
// FUNCTIONAL COMPONENT
const MediaImageBentoBox = (props) => {
  // ===============================================
  // FUNCTIONS
  // -------------------------------------------------
  // Get an image with given dimensions
  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Setup the variable classes
  const useStyles = makeStyles(() => ({
    titleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.33) 75%, rgba(0,0,0,0) 100%)",
    },
  }));
  const classes = useStyles();
  // ===============================================
  // RENDER COMPONENT
  return (
    <ImageList
      variant="quilted"
      gap={1}
      cols={
        props.article.options && props.article.options.numColumns
          ? parseInt(props.article.options.numColumns)
          : 1
      }
      rowHeight={
        props.article.options && props.article.options.rowHeight
          ? parseInt(props.article.options.rowHeight)
          : 250
      }
      className="rounded mb-0"
    >
      {props.article.rowList.map((row) => (
        <ImageListItem
          key={row.url}
          cols={row.options && row.options.colSpan ? row.options.colSpan : 1}
          rows={row.options && row.options.rowSpan ? row.options.rowSpan : 1}
        >
          <img
            {...srcset(
              row.url,
              121,
              row.options && row.options.colSpan ? row.options.colSpan : 1,
              row.options && row.options.rowSpan ? row.options.rowSpan : 1
            )}
            alt={row.text}
            loading="lazy"
          />
          <ImageListItemBar title={row.text} className={classes.titleBar} />
        </ImageListItem>
      ))}
    </ImageList>
  );
};
// =================================================
// EXPORT COMPONENT
export default MediaImageBentoBox;
