// =================================================
// IMPORT
import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
// =================================================
// -------------------------------------------------
// Use 'createEntityAdapter' to store the tickets in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  currentTicket: null,
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null,
});
// =================================================
// DEFINE MUTATING ACTIONS
// -------------------------------------------------
export const publicTicketsSlice = createSlice({
  name: "publicTickets",
  initialState,
  reducers: {
    resetTicketsError(state) {
      if (state.status === "failed") {
        state.status = "idle";
        state.errorMsg = null;
      }
    },
    setCurrentTicketByObject(state, action) {
      state.currentTicket = action.payload.ticket;
      if (action.payload.ticket) {
        state.status = "succeeded";
      } else {
        state.status = "idle";
      }
    },
    updatePublicTicketByIdWithKeyValue(state, action) {
      let existingTicket = state.entities[action.payload.ticketId];
      if (existingTicket) {
        existingTicket[action.payload.key] = action.payload.value;
      }
      if (
        state.currentTicket &&
        state.currentTicket._id === action.payload.ticketId
      ) {
        state.currentTicket[action.payload.key] = action.payload.value;
      }
    },
  },
});
// =================================================
// EXPORT ACTIONS
export const {
  resetTicketsError,
  setCurrentTicketByObject,
  updatePublicTicketByIdWithKeyValue,
} = publicTicketsSlice.actions;
// =================================================
// SELECTOR FUNCTIONS
// -------------------------------------------------
export const publicTicketsSelectors = adapter.getSelectors(
  (state) => state.publicTickets
);
// =================================================
// EXPORT DEFAULT
export default publicTicketsSlice.reducer;
