// Import necessary MUI and React components
import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

// Define the PageNotFound component
const PageNotFound = () => {
  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '5em' }}>
      <Typography variant="h1" gutterBottom>
        404
      </Typography>
      <Typography variant="h4" gutterBottom>
        Page Not Found
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Sorry, the page you are looking for does not exist.
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        Go Home
      </Button>
    </Container>
  );
};

// Export the component
export default PageNotFound;
