// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Component elements
import CmsContent from "./CmsContent";
// -------------------------------------------------
// Basic elements
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

const HomeTemplate = ({ currentWebpage }) => {
  const isXS = useSelector((state) => state.ui.isXS);
  return (
    <Box
      sx={{
        backgroundImage: "url('images/sleepfix/bg-02.png')",
        backgroundOrigin: "border-box",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Container>
        <Grid
          container
          direction={isXS ? "column" : "row"}
          alignItems="center"
          justifyContent="space-around"
          className="py-4"
        >
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: "64px",
              paddingBottom: "32px",
            }}
          >
            <CmsContent
              currentWebpage={currentWebpage}
              fieldname="hero_title"
            />
            <CmsContent
              currentWebpage={currentWebpage}
              fieldname="hero_description"
            />
          </Grid>
          <Grid item xs={12}>
            <CmsContent
              currentWebpage={currentWebpage}
              fieldname="body_description"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HomeTemplate;
