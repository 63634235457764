// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Basic elements
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
// =================================================
// FUNCTIONAL COMPONENT
const ArticleFormControl = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const liveValidation = useSelector((state) => state.form.liveValidation);
  // ===============================================
  // RENDER COMPONENT
  return (
    <FormControl
      required={props.required}
      error={liveValidation && props.validationMsg !== null}
      hiddenLabel={props.hiddenLabel}
      component="fieldset"
      variant="filled"
      className="w-100">
      {props.article.formLabel && (
        <FormLabel
          className="m-0 mb-1"
          sx={props.article.title ? { color: props.article.title.color } : {}}>
          {props.article.formLabel}
        </FormLabel>
      )}
      <FormGroup className="flex-nowrap">{props.children}</FormGroup>
      {liveValidation && (
        <FormHelperText
          className={`text-danger m-0 mt-1 ${props.helperTextAlign}`}>
          {props.validationMsg}
        </FormHelperText>
      )}
    </FormControl>
  );
};
// =================================================
// EXPORT COMPONENT
export default ArticleFormControl;
