import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import {
  fetchWebpageListByType,
  publicWebpageSelectors,
} from "../../redux/reducers/publicWebpages";
import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";

const Studies = (props) => {
  const { t } = useTranslation("pages", { keyPrefix: "StudyCards" });
  const dispatch = useDispatch();
  // Fetch studies from Redux store
  const webpagesStatus = useSelector((state) => state.publicWebpages.status);
  const studies = useSelector((state) =>
    publicWebpageSelectors.selectByType(state, "study")
  );

  // Dispatch action to load studies when component mounts
  useEffect(() => {
    if (webpagesStatus === "idle") {
      dispatch(fetchWebpageListByType("study"));
    }
  }, [webpagesStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  // Render loading state if studies are not available yet
  if (studies === undefined || studies.length === 0) {
    return <div>Loading...</div>;
  }
  return studies.filter((study) => study._id !== props.hide).length ===
    0 ? null : (
    <Container className="px-4 pb-4">
      <Divider className="w-100 py-3">
        <Typography variant="h2" component="h2">
          {props.hide ? t("Other studies") : t("Active studies")}
        </Typography>
      </Divider>
      <Grid container spacing={2}>
        {studies
          .filter((study) => study._id !== props.hide)
          .map((study) => (
            <Grid item xs={12} sm={6} md={4} key={study._id || study.title}>
              <Card sx={{ height: "100%" }}>
                <CardActionArea
                  component={NavLink}
                  to={`/${study.url}`}
                  className="h-100"
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography gutterBottom variant="h3" component="h3">
                      {study.title}
                    </Typography>
                    <Typography variant="body1" color="text.primary">
                      {study.description}
                    </Typography>
                    <Button
                      component={NavLink}
                      to={`/${study.url}`}
                      color="inherit"
                      className="mt-2"
                    >
                      {t("Read more...")}
                    </Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};

export default Studies;
