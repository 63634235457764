// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
// -------------------------------------------------
// Component elements
import TextboxListItem from "./TextboxListItem";
// -------------------------------------------------
// Basic elements
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
// =================================================
// FUNCTIONAL COMPONENT
const TextboxList = (props) => {
  // ===============================================
  // RENDER COMPONENT
  return (
    <Table>
      <TableBody>
        {props.article.rowList.map((row, i) => (
          <TextboxListItem
            key={row._id}
            doShow={props.doShow}
            uneditable={props.uneditable}
            dependencies={props.dependencies}
            aliasList={props.aliasList}
            scoring={props.scoring}
            article={row}
            type={props.type}
            firstColumnWidth={props.article.options.firstColumnWidth}
            articleRequired={props.article.required}
            required={props.article.required || row.required}
            alias={Array.isArray(props.alias) ? props.alias[i] : props.alias}
          />
        ))}
      </TableBody>
    </Table>
  );
};
// =================================================
// EXPORT COMPONENT
export default TextboxList;
