// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Support functions
import { array2string } from "../../supportFunc/array2string";
// -------------------------------------------------
// Redux actions
import { selectSingleResponseByAlias } from "../../redux/reducers/form";
// -------------------------------------------------
// Component elements
import ItemAnswer from "./ItemAnswer";
// -------------------------------------------------
// Basic elements
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Link from "@mui/icons-material/Link";
// =================================================
// FUNCTIONAL COMPONENT
const CheckboxesItem = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "form.CheckboxesItem",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias)
  );
  const isEnabled = useSelector(
    (state) =>
      state.form.isEnabled[props.rowId ? props.rowId : props.article._id]
  );
  const isXS = useSelector((state) => state.ui.isXS);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // When article is disabled, revert back to inital value
  useEffect(() => {
    if (!isEnabled) {
      props.handleSetSingleResponse(props.alias, false);
    }
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  return isEnabled || props.doShow ? (
    <FormControlLabel
      control={
        <Checkbox
          disabled={props.uneditable}
          checked={value}
          onChange={(e) =>
            props.handleSetSingleResponse(props.alias, e.target.checked)
          }
          name={props.alias}
          color="secondary"
        />
      }
      label={
        props.doShow ? (
          <div className="d-flex align-items-center">
            <Chip
              label={
                <Typography variant="overline" color="textSecondary">
                  {props.alias}
                </Typography>
              }
              size="small"
              className="me-2"
            />
            <ItemAnswer text={props.article.text} />
            {props.article.logic && (
              <Tooltip arrow title={t("conditional")} placement="top">
                <Chip
                  icon={<Link />}
                  label={`conditional on ${array2string(
                    props.article.logic.map((logicGroup) =>
                      logicGroup.itemList.map(
                        (logicItem) =>
                          props.aliasList[logicItem.condition.aliasId].alias
                      )
                    )
                  )}`}
                  size="small"
                  variant="outlined"
                  color="info"
                  className="border-white ms-2"
                />
              </Tooltip>
            )}
            {props.dependencies &&
              Object.keys(props.dependencies).some((dep) =>
                dep.includes(props.article._id)
              ) && (
                <Tooltip arrow title={t("dependent")} placement="top">
                  <Link color="warning" fontSize="small" className="ms-2" />
                </Tooltip>
              )}
            {props.scoring &&
              props.scoring.some(
                (score) =>
                  (score.arithmetic &&
                    score.arithmetic.some((a) =>
                      a.aliasId.includes(props.article._id)
                    )) ||
                  (score.grouping &&
                    score.grouping.aliasId.includes(props.article._id))
              ) && (
                <Tooltip arrow title={t("scored")} placement="top">
                  <Link color="success" fontSize="small" className="ms-2" />
                </Tooltip>
              )}
          </div>
        ) : (
          <ItemAnswer text={props.article.text} />
        )
      }
      className={
        isXS && props.lastItem
          ? "answer-option bg-light m-0 p-2 border rounded"
          : isXS
          ? "answer-option bg-light m-0 p-2 border rounded mb-2"
          : "answer-option m-0"
      }
    />
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default CheckboxesItem;
