// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
// -------------------------------------------------
// Components
import TasksListToDoNow from "./tasks/Tasks_ListToDoNow";
import TasksListToDoLater from "./tasks/Tasks_ListToDoLater";
// =================================================
// FUNCTIONAL COMPONENT
const TasksColumnContent = (props) => {
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <TasksListToDoNow />
      <TasksListToDoLater />
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default TasksColumnContent;
