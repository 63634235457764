// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
// -------------------------------------------------
// Component elements
import ArticleFormControl from "./ArticleFormControl";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import { Picker } from "antd-mobile";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// =================================================
// FUNCTIONAL COMPONENT
const TimeSelectorItemMobile = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "form.TimeSelectorItemMobile",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [localValidation, setLocalValidation] = useState(null);
  const [visible, setVisible] = useState(false);
  const [localValue, setLocalValue] = useState(["M"]);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias)
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id]
  );
  // -------------------------------------------------
  // Function to generate a range of values
  const range = (min, max, step) => {
    let arr = [];
    for (let i = min; i <= max; i += step) {
      arr.push(i);
    }
    return arr;
  };
  // -----------------------------------------------
  // CONSTANTS
  const hours = props.article.options.ampm ? range(1, 12, 1) : range(0, 23, 1);
  const minutes = range(0, 59, props.article.options.step);
  const columnList = props.article.options.ampm
    ? [
        hours.map((h) => {
          return { label: `${h}`, value: h };
        }),
        minutes.map((h) => {
          return { label: `${h}`, value: h };
        }),
        [
          { label: "am", value: "am" },
          { label: "pm", value: "pm" },
        ],
      ]
    : [
        hours.map((h) => {
          return { label: `${h}`, value: h };
        }),
        minutes.map((h) => {
          return { label: `${h}`, value: h };
        }),
      ];
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
    // Clear any validation when component unmounts
    return () => {
      dispatch(initValidationByAlias({ alias: props.alias }));
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set value
    if (value !== null) {
      if (value.length === 3) {
        value = DateTime.fromFormat(`${value[0]}:${value[1]} ${value[2]}`, "t")
          .startOf("minute")
          .toISOTime({
            suppressMilliseconds: true,
            suppressSeconds: true,
            includeOffset: false,
          });
      } else {
        value = DateTime.fromFormat(`${value[0]}:${value[1]}`, "T")
          .startOf("minute")
          .toISOTime({
            suppressMilliseconds: true,
            suppressSeconds: true,
            includeOffset: false,
          });
      }
    }
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value })
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value })
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      })
    );
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <ArticleFormControl
      article={props.article}
      required={props.required}
      isInvalid={localValidation && localValidation.isInvalid}
      validationMsg={localValidation && localValidation.msg[props.alias]}
    >
      <TextField
        hiddenLabel
        disabled={props.uneditable}
        name={props.alias}
        value={value ? value : ""}
        color="secondary"
        variant="filled"
        className="answer-text-green mt-1"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AccessTimeIcon />
            </InputAdornment>
          ),
        }}
        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
        onClick={(e) => {
          e.target.blur();
          if (!props.uneditable) {
            setVisible(true);
          }
        }}
      />
      <Picker
        cancelText={<Button color="inherit">{t("Cancel")}</Button>}
        confirmText={
          <Button variant="contained" color="primary">
            {t("Set")}
          </Button>
        }
        columns={columnList}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        value={localValue}
        onConfirm={(newValue) => {
          handleSetSingleResponse(props.alias, newValue);
          setLocalValue(newValue);
        }}
      />
    </ArticleFormControl>
  );
};
// =================================================
// EXPORT COMPONENT
export default TimeSelectorItemMobile;
