// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Support functions
import { getRGBA } from "../supportFunc/getRGBA";
// -------------------------------------------------
// Redux actions
import { setSingleTaskResponseByAlias } from "../redux/reducers/taskResponses";
// -------------------------------------------------
// Basic elements
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
// -------------------------------------------------
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// ------------------------------------------
// Support funct
import { getAliasContainingId } from "../supportFunc/getAliasContainingId";
// =================================================
// FUNCTIONAL COMPONENT
const FormGridItem = (props) => {
  // =================================================
  // VARIABLES
  // -------------------------------------------------
  // Local state
  const [isCollapsed, setIsCollapsed] = useState(
    props.gridItem.options.defaultCollapsed
  );
  const backgroundColor = getRGBA(
    props.gridItem.layout.bgColor,
    props.gridItem.layout.bgOpacity,
    props.gridItem.options.noCard
  );
  // -------------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const isXS = useSelector((state) => state.ui.isXS);
  // Check if this grid item is enabled and in view
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.gridItem._id]
  );
  const viewIdx = useSelector((state) => state.tickets.currentTicket.viewIdx);
  const responseCollection = useSelector(
    (state) => state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) => state.tickets.currentTicket.responseId
  );
  const isInView = useSelector((state) =>
    viewIdx !== null && state.form.views && state.form.views.length > 0
      ? state.form.views[viewIdx].gridItemIds.includes(props.gridItem._id)
      : true
  );
  const doShow = (isEnabled && isInView) || (isEnabled && !isXS);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Handles setting a single response by its alias in the appropriate reducer
  const handleSetSingleResponse = (alias, value) => {
    switch (responseCollection) {
      case "taskResponses":
        dispatch(setSingleTaskResponseByAlias({ responseId, alias, value }));
        break;
      default:
        return;
    }
  };
  // -----------------------------------------------
  // When grid item is disabled, revert back to inital value(s)
  useEffect(() => {
    if (isEnabled === false) {
      props.gridItem.articleList
        .filter((article) => article.category === "question")
        .forEach((article) => {
          article.columnList
            ? article.columnList.forEach((column) => {
                article.rowList.forEach((row) => {
                  handleSetSingleResponse(
                    getAliasContainingId(
                      props.aliasList,
                      `${row._id}_${column._id}`
                    ),
                    article.type === "checkboxes-grid" ? false : null
                  );
                });
              })
            : article.rowList
            ? article.rowList.forEach((row) => {
                handleSetSingleResponse(
                  getAliasContainingId(props.aliasList, row._id),
                  article.type === "checkboxes" ? false : null
                );
              })
            : handleSetSingleResponse(
                getAliasContainingId(props.aliasList, article._id),
                null
              );
        });
    }
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // =================================================
  // RENDER COMPONENT
  // -------------------------------------------------
  return (
    doShow && (
      <Grid
        item
        id="grid-item"
        xs={props.gridItem.layout.width.xs}
        sm={props.gridItem.layout.width.sm}
        md={props.gridItem.layout.width.md}
        lg={props.gridItem.layout.width.lg}
        xl={props.gridItem.layout.width.xl}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            className={props.gridItem.options.isCollapsable ? "px-2" : ""}
          >
            <Grid
              container
              className={
                props.gridItem.options.isCollapsable && !isCollapsed
                  ? "py-1 bg-gradient-secondary rounded-top"
                  : props.gridItem.options.isCollapsable && isCollapsed
                  ? "py-1 bg-gradient-secondary rounded"
                  : ""
              }
              onClick={(e) =>
                props.gridItem.options.isCollapsable &&
                setIsCollapsed(!isCollapsed)
              }
            >
              {/* ================================================== */}
              {/* TITLE */}
              {props.gridItem.title && (
                <Grid
                  item
                  className="flex-grow-1"
                  sx={{
                    paddingLeft: `${props.gridItem.layout.padding.left}rem`,
                    paddingRight: `${props.gridItem.layout.padding.right}rem`,
                  }}
                >
                  <Typography
                    variant={props.gridItem.title.variant}
                    color={props.gridItem.title.color}
                    align={props.gridItem.title.align}
                  >
                    {props.gridItem.title.text}
                  </Typography>
                </Grid>
              )}
              {props.gridItem.options.isCollapsable && (
                <Grid item className="flex-grow-0 pe-2">
                  <IconButton>
                    {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Collapse
              in={!isCollapsed || !props.gridItem.options.isCollapsable}
            >
              {/* ================================================== */}
              {/* CONTENT */}
              {props.gridItem.options.isCard ? (
                <Card
                  className={`${props.gridItem.layout.classNames} ${
                    props.gridItem.options.hasShadow
                      ? "w-100 shadow"
                      : "w-100 shadow-none"
                  }`}
                  sx={{
                    backgroundColor:
                      backgroundColor === null ? "#FFFFFF" : backgroundColor,
                    minHeight: `${props.gridItem.layout.minHeight}px`,
                    paddingLeft: `${props.gridItem.layout.padding.left}rem`,
                    paddingRight: `${props.gridItem.layout.padding.right}rem`,
                    paddingTop: `${props.gridItem.layout.padding.top}rem`,
                    paddingBottom: `${props.gridItem.layout.padding.bottom}rem`,
                    backgroundImage: `url("${props.gridItem.layout.bgImage}")`,
                    backgroundOrigin: props.gridItem.layout.bgImageOrigin,
                    backgroundPosition: props.gridItem.layout.bgImagePosition,
                    backgroundRepeat: props.gridItem.layout.bgImageRepeat,
                    backgroundSize: props.gridItem.layout.bgImageSize,
                  }}
                >
                  {props.children}
                </Card>
              ) : (
                <Grid
                  container
                  alignItems="stretch"
                  className={`rounded ${props.gridItem.layout.classNames}`}
                  sx={{
                    backgroundColor:
                      backgroundColor === null
                        ? "rgba(0, 0, 0, 0)"
                        : backgroundColor,
                    minHeight: `${props.gridItem.layout.minHeight}px`,
                    paddingLeft: `${props.gridItem.layout.padding.left}rem`,
                    paddingRight: `${props.gridItem.layout.padding.right}rem`,
                    paddingTop: `${props.gridItem.layout.padding.top}rem`,
                    paddingBottom: `${props.gridItem.layout.padding.bottom}rem`,
                    backgroundImage: `url("${props.gridItem.layout.bgImage}")`,
                    backgroundOrigin: props.gridItem.layout.bgImageOrigin,
                    backgroundPosition: props.gridItem.layout.bgImagePosition,
                    backgroundRepeat: props.gridItem.layout.bgImageRepeat,
                    backgroundSize: props.gridItem.layout.bgImageSize,
                  }}
                >
                  {props.children}
                </Grid>
              )}
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    )
  );
};
// =================================================
// EXPORT COMPONENT
export default FormGridItem;
