// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
// =================================================
// FUNCTIONAL COMPONENT
const MediaImage = (props) => {
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Setup the variable classes
  const useStyles = makeStyles(() => ({
    bgBlur: {
      position: "relative",
      minHeight: props.article.options.minHeight,
      maxHeight: props.article.options.maxHeight,
      backgroundPosition: props.article.options.position,
      backgroundImage: `url(${props.article.url})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    bg: {
      position: "relative",
      minHeight: props.article.options.minHeight,
      maxHeight: props.article.options.maxHeight,
      backgroundPosition: props.article.options.position,
      backgroundImage: `url(${props.article.url})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    },
    caption: {
      position: "absolute",
      bottom: "0px",
      color: "white",
      background:
        "linear-gradient(to top, rgba(0,0,0,0.66) 0%, rgba(0,0,0,0.33) 66%, rgba(0,0,0,0) 100%)",
    },
  }));
  const classes = useStyles();
  // ===============================================
  // RENDER COMPONENT
  return (
    <Grid
      container
      className={`rounded ${
        props.article.options.blurredBackdrop ? classes.bgBlur : ""
      }`}>
      <Grid
        item
        xs={12}
        className={` ${
          props.article.options.blurredBackdrop && "bd-filt-blur-12px"
        } ${classes.bg}`}>
        {props.article.title && props.article.title.text && (
          <div className={`p-3 w-100 ${classes.caption}`}>
            <Typography
              paragraph={
                props.article.title.variant === "button" ||
                props.article.title.variant === "caption" ||
                props.article.title.variant === "overline"
              }
              variant={props.article.title.variant}
              color={props.article.title.color}
              align={props.article.title.align}
              className="p-0 m-0">
              {props.article.title.text}
            </Typography>
          </div>
        )}
      </Grid>
    </Grid>
  );
};
// =================================================
// EXPORT COMPONENT
export default MediaImage;
