// ########################################
// IMPORT
// ========================================
// Dependencies
import React from "react";
import { NavLink } from "react-router-dom";
// ========================================
// Material UI
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
// ----------------------------------------
// Icons
import Icon from "@mui/material/Icon";
// ########################################
// FUNCTIONAL COMPONENT
const NavigationItem = (props) => {
  // ========================================
  // RETURN
  return (
    <div>
      {props.navItem.divider && <Divider className="my-2" />}
      <div className="me-3">
        <ListItemButton
          component={NavLink}
          to={props.navItem.to}
          key={props.navItem.to}
          className="rounded-pill-right"
          onClick={props.handleCloseNav}
        >
          <ListItemIcon sx={{ minWidth: "38px" }}>
            {props.navItem.badge ? (
              <Badge badgeContent={props.navItem.badge} max={99} color="error">
                <Icon>{props.navItem.icon}</Icon>
              </Badge>
            ) : (
              <Icon>{props.navItem.icon}</Icon>
            )}
          </ListItemIcon>
          <ListItemText
            primary={props.navItem.primary}
            className="text-nowrap"
            sx={{ pr: 2 }}
          />
        </ListItemButton>
      </div>
    </div>
  );
};
// ########################################
// EXPORT
export default NavigationItem;
