// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Component elements
import ItemQuestion from "./ItemQuestion";
import ItemAnswer from "./ItemAnswer";
import CheckboxesGridItem from "./CheckboxesGridItem";
import Checkboxes from "./Checkboxes";
// -------------------------------------------------
// Basic elements
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Slide from "@mui/material/Slide";
// ------------------------------------------
// Support funct
import { getAliasContainingId } from "../../supportFunc/getAliasContainingId";
// =================================================
// FUNCTIONAL COMPONENT
const CheckboxGrid = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  const slideDirection = useSelector((state) => state.form.slideDirection);
  // -----------------------------------------------
  // Setup the variable class for the table cell first colum width
  const useStyles = makeStyles(() => ({
    firstColumn: {
      padding: "0px",
      width: props.article.options.firstColumnWidth,
    },
  }));
  const classes = useStyles();
  // ===============================================
  // RENDER COMPONENT
  return !isXS ? (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className={classes.firstColumn} />
          {props.article.columnList.map((column, i) => (
            <TableCell
              key={`${props.article._id}_column-${i}`}
              className="p-1 text-center align-middle"
            >
              <ItemAnswer text={column.text} />
            </TableCell>
          ))}
        </TableRow>
        {props.article.rowList.map((row) => (
          <CheckboxesGridItem
            key={row._id}
            doShow={props.doShow}
            uneditable={props.uneditable}
            dependencies={props.dependencies}
            aliasList={props.aliasList}
            scoring={props.scoring}
            article={row}
            columnList={props.article.columnList}
            firstColumnWidth={props.article.firstColumnWidth}
            required={props.article.required || row.required}
            alias={getAliasContainingId(
              props.aliasList,
              `${props.article._id}_${row._id}`,
              true
            )}
          />
        ))}
      </TableBody>
    </Table>
  ) : (
    props.article.rowList
      .filter((row) => row._id === props.rowId || props.doShow)
      .map((row) => (
        <Slide
          direction={slideDirection}
          in={true}
          appear={
            (slideDirection === "left" &&
              row._id !== props.article.rowList[0]._id) ||
            (slideDirection === "right" &&
              row._id !==
                props.article.rowList[props.article.rowList.length - 1]._id)
          }
          key={row._id}
        >
          <div>
            <ItemQuestion
              item={row}
              required={false}
              text={row.text}
              doShow={props.doShow}
              dependencies={props.dependencies}
              aliasList={props.aliasList}
              scoring={props.scoring}
              alias={getAliasContainingId(
                props.aliasList,
                `${props.article._id}_${row._id}`,
                true
              )}
              justifyContent="flex-start"
              alignItems="center"
              classNames="p-2"
            />
            <Checkboxes
              doShow={props.doShow}
              uneditable={props.uneditable}
              dependencies={props.dependencies}
              scoring={props.scoring}
              article={row}
              columnList={props.article.columnList}
              required={false}
              alias={getAliasContainingId(
                props.aliasList,
                `${props.article._id}_${row._id}`,
                true
              )}
            />
          </div>
        </Slide>
      ))
  );
};
// =================================================
// EXPORT COMPONENT
export default CheckboxGrid;
