// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
// -------------------------------------------------
// Component elements
import ItemQuestion from "./ItemQuestion";
import ArticleFormControl from "./ArticleFormControl";
import CheckboxesGridCell from "./CheckboxesGridCell";
// -------------------------------------------------
// Redux actions
import { setFormSurveyLogicByAlias } from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
// =================================================
// FUNCTIONAL COMPONENT
const CheckboxGridItem = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id]
  );
  // -----------------------------------------------
  // Setup the variable class for the table cell first colum width
  const useStyles = makeStyles(() => ({
    firstColumn: {
      padding: "0px",
      paddingRight: "8px",
      width: props.firstColumnWidth,
    },
  }));
  const classes = useStyles();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // When article is disabled, revert back to inital value
    if (!isEnabled) {
      props.alias.forEach((a) => handleSetSingleResponse(a, false));
      return;
    }
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value })
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value })
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // If the item is disabled, we don't have to set 'hasStarted' or do validation
    if (!isEnabled) {
      return;
    }
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      })
    );
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    (isEnabled || props.doShow) && (
      <TableRow className="answer-row">
        <TableCell className={classes.firstColumn}>
          <ArticleFormControl
            article={props.article}
            required={false}
            isInvalid={false}
            helperTextAlign="text-start"
          >
            <ItemQuestion
              item={props.article}
              required={false}
              text={props.article.text}
              doShow={props.doShow}
              dependencies={props.dependencies}
              aliasList={props.aliasList}
              scoring={props.scoring}
              alias={props.alias}
              justifyContent="flex-start"
              alignItems="center"
            />
          </ArticleFormControl>
        </TableCell>
        {props.columnList.map((column, i) => (
          <CheckboxesGridCell
            key={column._id}
            uneditable={props.uneditable}
            article={props.article}
            required={false}
            alias={Array.isArray(props.alias) ? props.alias[i] : props.alias}
            handleSetSingleResponse={handleSetSingleResponse}
          />
        ))}
      </TableRow>
    )
  );
};
// =================================================
// EXPORT COMPONENT
export default CheckboxGridItem;
