// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// =================================================
// FUNCTIONAL COMPONENT
const VolunteersEditCurrentEnrollment = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "volunteers.Volunteers_EditCurrentEnrollment",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (e) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[e.target.name] = e.target.value;
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="overline" className="font-weight-bold">
            {t("Participant identifier")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            hiddenLabel
            size="small"
            type="text"
            name="userStudyNumber"
            value={props.obj.userStudyNumber ? props.obj.userStudyNumber : ""}
            color="secondary"
            variant="filled"
            className="answer-text-green mt-1 mb-3"
            onChange={handleSetObj}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" className="font-weight-bold">
            {t("Group")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="filled" className="answer-text-green mt-1 mb-3">
            <Select
              hiddenLabel
              size="small"
              color="secondary"
              name="groupId"
              value={props.obj.groupId ? props.obj.groupId : ""}
              onChange={handleSetObj}
            >
              <MenuItem value="n.a.">{t("No group")}</MenuItem>
              {props.groupList.map((group) => (
                <MenuItem key={group._id} value={group._id}>
                  {group.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" className="font-weight-bold">
            {t("Group history")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {props.groupHistory.map((group, i) => (
            <List disablePadding key={i}>
              <ListItem
                divider={i < props.groupHistory.length - 1}
                secondaryAction={group.assignmentDate}
                className="p-0"
              >
                <ListItemText
                  primary={
                    <Chip
                      label={
                        group.groupId === "n.a."
                          ? t("No group")
                          : props.groupList.find((g) => g._id === group.groupId)
                              .label
                      }
                      size="small"
                    />
                  }
                />
              </ListItem>
            </List>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};
// =================================================
// EXPORT COMPONENT
export default VolunteersEditCurrentEnrollment;
