// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Component elements
import ItemQuestion from "./ItemQuestion";
import ItemAnswer from "./ItemAnswer";
import RadioGridItem from "./RadioGridItem";
import RadioItem from "./RadioItem";
// -------------------------------------------------
// Basic elements
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Slide from "@mui/material/Slide";
// ------------------------------------------
// Support funct
import { getAliasContainingId } from "../../supportFunc/getAliasContainingId";
// =================================================
// FUNCTIONAL COMPONENT
const RadioGrid = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  const slideDirection = useSelector((state) => state.form.slideDirection);
  // -----------------------------------------------
  // Setup the variable class for the table cell first colum width
  const useStyles = makeStyles(() => ({
    firstColumn: {
      padding: "0px",
      width: props.article.options.firstColumnWidth,
    },
  }));
  const classes = useStyles();
  // ===============================================
  // RENDER COMPONENT
  return !isXS ? (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className={classes.firstColumn} />
          {props.article.answerList.map((answer, i) => (
            <TableCell
              key={`${props.article._id}_answerList-${answer.value}-${i}`}
              className="p-1 text-center align-middle"
            >
              <ItemAnswer text={answer.text} />
            </TableCell>
          ))}
        </TableRow>
        {props.article.rowList.map((row, i) => (
          <RadioGridItem
            key={row._id}
            doShow={props.doShow}
            uneditable={props.uneditable}
            dependencies={props.dependencies}
            aliasList={props.aliasList}
            scoring={props.scoring}
            article={row}
            answerList={props.article.answerList}
            firstColumnWidth={props.article.options.firstColumnWidth}
            articleRequired={props.article.required}
            required={props.article.required || row.required}
            alias={Array.isArray(props.alias) ? props.alias[i] : props.alias}
          />
        ))}
      </TableBody>
    </Table>
  ) : (
    props.article.rowList
      .filter((row) => row._id === props.rowId || props.doShow)
      .map((row, i) => (
        <Slide
          direction={slideDirection}
          in={true}
          appear={
            (slideDirection === "left" &&
              row._id !== props.article.rowList[0]._id) ||
            (slideDirection === "right" &&
              row._id !==
                props.article.rowList[props.article.rowList.length - 1]._id)
          }
          key={row._id}
        >
          <div>
            <ItemQuestion
              item={row}
              required={!props.article.required && row.required}
              text={row.text}
              doShow={props.doShow}
              dependencies={props.dependencies}
              aliasList={props.aliasList}
              scoring={props.scoring}
              name={getAliasContainingId(props.aliasList, row._id, false)}
              alias={getAliasContainingId(props.aliasList, row._id, false)}
              justifyContent="flex-start"
              alignItems="center"
              classNames="p-2"
            />
            <RadioItem
              doShow={props.doShow}
              uneditable={props.uneditable}
              article={row}
              answerList={props.article.answerList}
              required={props.article.required || row.required}
              alias={getAliasContainingId(props.aliasList, row._id, false)}
              forceRow={props.article.options.forceRow}
            />
          </div>
        </Slide>
      ))
  );
};
// =================================================
// EXPORT COMPONENT
export default RadioGrid;
