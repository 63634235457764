// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
// -------------------------------------------------
// Component elements
import ItemQuestion from "./ItemQuestion";
import ItemAnswer from "./ItemAnswer";
import TextboxGridCell from "./TextboxGridCell";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  setFormSurveyLogicByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
// -------------------------------------------------
// Basic elements
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Slide from "@mui/material/Slide";
// =================================================
// FUNCTIONAL COMPONENT
const TextboxGridItem = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id]
  );
  const isXS = useSelector((state) => state.ui.isXS);
  const slideDirection = useSelector((state) => state.form.slideDirection);
  // -----------------------------------------------
  // Setup the variable class for the table cell first colum width
  const useStyles = makeStyles(() => ({
    firstColumn: {
      padding: "0px",
      paddingRight: "8px",
      width: props.firstColumnWidth,
    },
  }));
  const classes = useStyles();
  // ===============================================
  // FUNCTIONS
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({
            responseId,
            alias,
            value,
            disablemetadata: true,
          })
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value })
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
  };
  // -----------------------------------------------
  useEffect(() => {
    // If the item is now disabled, remove any prior response
    if (!isEnabled) {
      props.alias.forEach((a) => {
        handleSetSingleResponse(a, null);
      });
      return;
    }
    // Clear any validation when component unmounts
    return () => {
      props.alias.forEach((a) => {
        dispatch(initValidationByAlias({ alias: a }));
      });
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  return (isEnabled || props.doShow) && !isXS ? (
    <TableRow className="answer-row">
      <TableCell className={classes.firstColumn}>
        <ItemQuestion
          item={props.article}
          required={!props.articleRequired && props.required}
          text={props.article.text}
          doShow={props.doShow}
          dependencies={props.dependencies}
          aliasList={props.aliasList}
          scoring={props.scoring}
          alias={props.alias}
          justifyContent="flex-start"
          alignItems="center"
        />
      </TableCell>
      {props.columnList.map((column, i) => (
        <TextboxGridCell
          key={`${props.article._id}_${column._id}`}
          doShow={props.doShow}
          uneditable={props.uneditable}
          isLastColumn={
            column._id === props.columnList[props.columnList.length - 1]._id
          }
          article={props.article}
          type={props.type}
          required={props.required}
          articleRequired={props.articleRequired}
          inputLabel={column.inputLabel}
          alias={Array.isArray(props.alias) ? props.alias[i] : props.alias}
        />
      ))}
    </TableRow>
  ) : (isEnabled || props.doShow) && isXS ? (
    <Slide
      direction={slideDirection}
      in={true}
      appear={
        (slideDirection === "left" && !props.isFirstItem) ||
        (slideDirection === "right" && !props.isLastItem)
      }
    >
      <TableBody>
        <TableRow>
          <TableCell colSpan={2} className={classes.firstColumn}>
            <ItemQuestion
              name={props.alias[0]}
              item={props.article}
              required={!props.articleRequired && props.required}
              text={props.article.text}
              doShow={props.doShow}
              dependencies={props.dependencies}
              aliasList={props.aliasList}
              scoring={props.scoring}
              alias={props.alias}
              justifyContent="flex-start"
              alignItems="center"
              classNames="p-2"
            />
          </TableCell>
        </TableRow>
        {props.columnList.map((column, i) => (
          <TableRow key={column._id}>
            <TableCell className={classes.firstColumn}>
              <ItemAnswer text={column.text} />
            </TableCell>
            <TextboxGridCell
              doShow={props.doShow}
              uneditable={props.uneditable}
              isLastColumn={true}
              article={props.article}
              type={props.type}
              required={props.required}
              articleRequired={props.articleRequired}
              inputLabel={column.inputLabel}
              alias={Array.isArray(props.alias) ? props.alias[i] : props.alias}
            />
          </TableRow>
        ))}
      </TableBody>
    </Slide>
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default TextboxGridItem;
