// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Components
import StudiesEditTimepointSurveys from "./Studies_EditTimepointSurveys";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// -------------------------------------------------
// Icons
import Icon from "@mui/material/Icon";
// =================================================
// FUNCTIONAL COMPONENT
const StudiesEditTimepoint = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "studies.Studies_EditTimepoint",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local State
  const [measurementList, setMeasurementList] = useState(
    props.obj.measurementList
  );
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (key, value) => {
    if (
      value === undefined ||
      value === null ||
      value === "" ||
      value.length === 0
    ) {
      value = null;
    }
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState = { ...newState, [key]: value };
      if (!newState.label) {
        props.setHasErrors(true);
      } else {
        props.setHasErrors(false);
      }
      return newState;
    });
  };
  const handleSetObjStart = (key, value) => {
    props.setObj((prevState) => {
      // Deep copy current state to new object
      let newState = JSON.parse(JSON.stringify(prevState));
      // Check what we need to change
      if (key === "type") {
        // eslint-disable-next-line
        switch (value) {
          case "manual":
            newState = {
              ...newState,
              start: {
                ...newState.start,
                [key]: value,
                dayOfTheWeek: null,
                delay: null,
              },
            };
            break;
          case "days":
            newState = {
              ...newState,
              start: {
                ...newState.start,
                [key]: value,
                dayOfTheWeek: null,
                delay: 1,
              },
            };
            break;
          case "weekday":
            newState = {
              ...newState,
              start: {
                ...newState.start,
                [key]: value,
                dayOfTheWeek: "Mon",
                delay: 1,
              },
            };
            break;
        }
      } else {
        newState = { ...newState, start: { ...newState.start, [key]: value } };
      }
      return newState;
    });
  };
  // -----------------------------------------------
  // Sets the local state
  const handleSetGroupIds = (e) => {
    const {
      target: { value },
    } = e;
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let newGroupIds = typeof value === "string" ? value.split(",") : value;
      if (newGroupIds.length === 0) {
        newGroupIds = null;
      }
      newState.groupIdList = newGroupIds;
      return newState;
    });
  };
  // -----------------------------------------------
  // Whenever the value of any of the keys change, update the parent object
  useEffect(() => {
    props.setObj((prevState) => ({
      ...prevState,
      measurementList,
    }));
  }, [measurementList]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" className="pb-2">
              {t("Timepoint label")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              hiddenLabel
              error={!props.obj.label}
              helperText={!props.obj.label ? "Please specify a label" : ""}
              type="text"
              name="label"
              variant="filled"
              value={props.obj.label ? props.obj.label : ""}
              color="secondary"
              className="mb-3 answer-text-green"
              onChange={(e) => handleSetObj(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" className="pb-2">
              {props.obj.start.type === "manual"
                ? "Timepoint starts manually"
                : props.obj.start.type === "weekday"
                ? `Timepoint starts on the ${props.ordinalSuffixOf(
                    props.obj.start.delay
                  )} ${props.obj.start.dayOfTheWeek} since ${
                    props.idx === 0 ? "enrollment" : "previous timepoint onset"
                  }`
                : props.obj.start.delay === 1
                ? `Timepoint starts on the same day as ${
                    props.idx === 0 ? "enrollment" : "previous timepoint onset"
                  }`
                : props.obj.start.delay === 2
                ? `Timepoint starts on the day after ${
                    props.idx === 0 ? "enrollment" : "previous timepoint onset"
                  }`
                : `Timepoint starts on the ${props.ordinalSuffixOf(
                    props.obj.start.delay
                  )} day since ${
                    props.idx === 0 ? "enrollment" : "previous timepoint onset"
                  }`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              value={props.obj.start.type}
              exclusive
              size="small"
              onChange={(e, newStartType) =>
                newStartType && handleSetObjStart("type", newStartType)
              }
            >
              <ToggleButton value="manual">{t("Manual")}</ToggleButton>
              <ToggleButton value="days">
                {props.idx === 0
                  ? t("Days since enrollment")
                  : t("previous timepoint onset")}
              </ToggleButton>
              <ToggleButton value="weekday">
                {t("Day of the week")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {(props.obj.start.type === "days" ||
            props.obj.start.type === "weekday") && (
            <Grid item xs={3} className="pt-2">
              <TextField
                hiddenLabel
                type="number"
                name="delay"
                variant="filled"
                size="small"
                value={props.obj.start.delay ? props.obj.start.delay : ""}
                color="secondary"
                InputProps={{
                  inputProps: { min: 1 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon className="me-2 align-middle" fontSize="small">
                        schedule_send
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                className="answer-text-green"
                onChange={(e) =>
                  handleSetObjStart("delay", parseInt(e.target.value))
                }
                onWheel={(e) => e.target.blur()}
              />
              {(props.obj.start.delay === null ||
                props.obj.start.delay === undefined ||
                props.obj.start.delay === "") && (
                <Typography variant="caption" color="error">
                  {t("Please specify start delay.")}
                </Typography>
              )}
            </Grid>
          )}
          {props.obj.start.type === "weekday" && (
            <Grid item xs={3} className="ps-2 pt-2">
              <ToggleButtonGroup
                value={props.obj.start.dayOfTheWeek}
                exclusive
                size="small"
                onChange={(e, newDayOfTheWeek) =>
                  newDayOfTheWeek &&
                  handleSetObjStart("dayOfTheWeek", newDayOfTheWeek)
                }
              >
                <ToggleButton value="Mon">{t("Mon")}</ToggleButton>
                <ToggleButton value="Tue">{t("Tue")}</ToggleButton>
                <ToggleButton value="Wed">{t("Wed")}</ToggleButton>
                <ToggleButton value="Thu">{t("Thu")}</ToggleButton>
                <ToggleButton value="Fri">{t("Fri")}</ToggleButton>
                <ToggleButton value="Sat">{t("Sat")}</ToggleButton>
                <ToggleButton value="Sun">{t("Sun")}</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}
        </Grid>
      </Paper>
      <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" className="pb-2">
              {t("Assign groups to this timepoint")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <Select
                multiple
                displayEmpty
                hiddenLabel
                size="small"
                value={props.obj.groupIdList ? props.obj.groupIdList : []}
                onChange={handleSetGroupIds}
                input={<FilledInput color="secondary" />}
                renderValue={(selected) => {
                  if (!selected || selected.length === 0) {
                    return <Chip label={t("All participants")} />;
                  }
                  return selected.map((groupId) => (
                    <Chip
                      key={groupId}
                      label={
                        props.currentGroupList.find(
                          (group) => group._id === groupId
                        ).label
                      }
                      className="me-2"
                    />
                  ));
                }}
              >
                {props.currentGroupList.map((group) => (
                  <MenuItem key={group._id} value={group._id}>
                    {group.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <StudiesEditTimepointSurveys
              obj={measurementList}
              setObj={setMeasurementList}
              setHasErrors={props.setHasErrors}
              currentGroupList={props.currentGroupList}
              surveyEntities={props.surveyEntities}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default StudiesEditTimepoint;
