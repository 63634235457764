// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Component elements
import SurveysEditTitle from "./Surveys_EditArticleTitle";
import SurveysEditLogic from "./Surveys_EditLogic";
import SurveysEditLayout from "./Surveys_EditLayout";
import SurveysEditOptions from "./Surveys_EditOptions";
// -------------------------------------------------
// Helper functions
import { defaultOptions } from "../../supportFunc/defaultSurveys";
// -------------------------------------------------
// Basic elements
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditGridItem = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditGridItem",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [title, setTitle] = useState(props.obj.title);
  const [tabIdx, setTabIdx] = useState(0);
  const [logic, setLogic] = useState(props.obj.logic);
  const [layout, setLayout] = useState(props.obj.layout);
  const [options, setOptions] = useState(props.obj.options);
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Whenever the value of any of the keys change, update the parent object
  useEffect(() => {
    props.setObj((prevState) => ({
      ...prevState,
      title,
      logic,
      layout,
      options,
    }));
  }, [title, logic, layout, options]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  return (
    <Box>
      <Tabs
        variant="fullWidth"
        value={tabIdx}
        className="bg-white border-bottom border-light"
        onChange={(e, newTabIndex) => {
          setTabIdx(newTabIndex);
        }}
      >
        <Tab label={t("Title")} id="tab_grid_container_settings" />
        <Tab
          label={
            props.obj.logic ? (
              <Badge color="info" variant="dot">
                {t("Logic")}
              </Badge>
            ) : (
              t("Logic")
            )
          }
          id="tab_grid_container_logic"
        />
        <Tab label={t("Layout")} id="tab_grid_container_layout" />
        <Tab label={t("Options")} id="tab_grid_container_options" />
      </Tabs>
      {/* ----------------------------------------------- */}
      {/* TITLE TAB */}
      <Paper
        className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}
        hidden={tabIdx !== 0}
      >
        <SurveysEditTitle
          showOptions
          label={t("Box title")}
          obj={title}
          setObj={setTitle}
        />
      </Paper>
      {/* ----------------------------------------------- */}
      {/* LOGIC TAB */}
      <Paper
        className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}
        hidden={tabIdx !== 1}
      >
        <SurveysEditLogic
          obj={logic}
          setObj={setLogic}
          aliasList={
            props.aliasList &&
            Object.keys(props.aliasList)
              .filter(
                (key) =>
                  props.aliasList[key].gridItemId !== props.obj._id &&
                  props.aliasList[key].type !== "duration" &&
                  props.aliasList[key].type !== "date-time" &&
                  props.aliasList[key].type !== "date" &&
                  props.aliasList[key].type !== "time"
              )
              .reduce((obj, key) => {
                obj[key] = props.aliasList[key];
                return obj;
              }, {})
          }
        />
      </Paper>
      {/* ----------------------------------------------- */}
      {/* LAYOUT TAB */}
      <Paper
        className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}
        hidden={tabIdx !== 2}
      >
        <SurveysEditLayout obj={layout} setObj={setLayout} />
      </Paper>
      {/* ----------------------------------------------- */}
      {/* Options TAB */}
      <Paper
        className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}
        hidden={tabIdx !== 3}
      >
        {options ? (
          <SurveysEditOptions
            obj={options}
            setObj={setOptions}
            options={defaultOptions["gridContainer"]}
          />
        ) : (
          <Typography variant="h5" align="center">
            {t("No options available")}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditGridItem;
