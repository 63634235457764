// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
import { unique } from "../../supportFunc/unique";
// -------------------------------------------------
// Component elements
import ItemQuestion from "./ItemQuestion";
import ItemAnswer from "./ItemAnswer";
import ArticleFormControl from "./ArticleFormControl";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const DropdownMenuListItem = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "form.DropdownMenuListItem",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [localValidation, setLocalValidation] = useState(null);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias)
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id]
  );
  // -----------------------------------------------
  // Setup the variable class for the table cell first colum width
  const useStyles = makeStyles(() => ({
    firstColumn: {
      padding: "0px",
      paddingRight: "8px",
      width: props.firstColumnWidth,
    },
  }));
  const classes = useStyles();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize validation upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // If the item is now disabled, remove any prior response
    if (!isEnabled) {
      handleSetSingleResponse(props.alias, null);
      return; // We can return since the component will unmount which will initialize the validation item
    }
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
    // Clear any validation when component unmounts
    return () => {
      dispatch(initValidationByAlias({ alias: props.alias }));
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value })
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value })
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // If the item is disabled, we don't have to set 'hasStarted' or do validation
    if (!isEnabled) {
      return;
    }
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      })
    );
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // ===============================================
  // SUB-COMPONENT
  const MenuItems = () => {
    const groupList = unique(
      props.answerList.map((answer) => (answer.group ? answer.group : ""))
    );
    const itemList = (g) => {
      return props.answerList.map((answer, j) => {
        return !answer.group || answer.group === g ? (
          <MenuItem
            key={`dropdown-item_${props.alias}_${g}_${answer.value}-${j}]`}
            value={
              props.article.reverseValue
                ? props.answerList[props.answerList.length - j - 1].value
                : answer.value
            }
          >
            <div className="d-flex">
              {props.doShow && (
                <Chip
                  label={
                    <Typography variant="overline" color="textSecondary">
                      {props.article.reverseValue
                        ? props.answerList[props.answerList.length - j - 1]
                            .value
                        : answer.value}
                    </Typography>
                  }
                  variant={props.article.reverseValue ? "outlined" : "filled"}
                  size="small"
                  className="me-2"
                />
              )}
              <ItemAnswer text={answer.text} />
            </div>
          </MenuItem>
        ) : null;
      });
    };
    return groupList.map((group, i) => {
      return [
        <ListSubheader key={`dropdown-item_${props.alias}_${group}_${i}`}>
          {group}
        </ListSubheader>,
        itemList(group),
      ];
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    (isEnabled || props.doShow) && (
      <>
        <TableRow className="answer-row">
          <TableCell className={classes.firstColumn}>
            <ItemQuestion
              item={props.article}
              required={
                !props.articleRequired &&
                !props.article.inputLabel &&
                props.required
              }
              text={props.article.text}
              doShow={props.doShow}
              dependencies={props.dependencies}
              aliasList={props.aliasList}
              scoring={props.scoring}
              alias={props.alias}
              justifyContent="flex-start"
              alignItems="center"
            />
          </TableCell>
          <TableCell className="py-1 px-0">
            <ArticleFormControl
              article={props.article}
              required={!props.articleRequired && props.required}
              isInvalid={localValidation && localValidation.isInvalid}
              validationMsg={
                localValidation && localValidation.msg[props.alias]
              }
              hiddenLabel={props.article.inputLabel ? false : true}
            >
              {props.article.inputLabel && (
                <InputLabel
                  id={`input-label_${props.alias}`}
                  color="secondary"
                  className={props.article.formLabel ? "top-24px" : ""}
                >
                  {props.article.inputLabel}
                </InputLabel>
              )}
              <Select
                disabled={props.uneditable}
                name={props.alias}
                value={value ? value : ""}
                label={props.article.inputLabel}
                labelId={`input-label_${props.alias}`}
                color="secondary"
                onChange={(e) =>
                  handleSetSingleResponse(props.alias, e.target.value)
                }
                className="answer-text-green"
              >
                <MenuItem value="">
                  <em>{value ? t("Clear") : t("Select")}</em>
                </MenuItem>
                {MenuItems()}
              </Select>
            </ArticleFormControl>
          </TableCell>
        </TableRow>
        {props.doShow && props.isLast && (
          <TableRow>
            <TableCell className={classes.firstColumn} />
            <TableCell className="p-2">
              <div className="shadow rounded">{MenuItems()}</div>
            </TableCell>
          </TableRow>
        )}
      </>
    )
  );
};
// =================================================
// EXPORT COMPONENT
export default DropdownMenuListItem;
