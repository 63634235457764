// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../supportFunc/colors";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
// =================================================
// FUNCTIONAL COMPONENT
const ColorSelector = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "form.ColorSelector" });
  // Local variable
  const [selectOpen, setSelectOpen] = useState(false);
  const colorGroupList = [
    "inherit",
    "custom",
    "black",
    "white",
    "gray",
    "red",
    "pink",
    "purple",
    "blue",
    "green",
    "yellow",
    "orange",
    "brown",
  ];
  // ===============================================
  // RENDER COMPONENT
  return (
    <Grid container>
      <Grid item>
        <FormControl variant="filled" size="small" color="secondary">
          <InputLabel htmlFor="color-select">{ t("Color") }</InputLabel>
          <Select
            size="small"
            value={
              colors.find((c) => c.hex === props.selColor)
                ? props.selColor
                : "custom"
            }
            id="color-select"
            label={ t("Color") }
            sx={{ minWidth: "175px" }}
            onOpen={() => setSelectOpen(true)}
            onClose={() => setSelectOpen(false)}
            onChange={props.setSelColor}
          >
            {colorGroupList.map((group) =>
              colors
                .filter((color) => color.families[0] === group)
                .map((color) => (
                  <MenuItem key={color.name} value={color.hex}>
                    {color.name !== "INHERIT" && (
                      <Chip
                        size="small"
                        className="me-2"
                        sx={{
                          backgroundColor:
                            color.hex === "custom" ? props.selColor : color.hex,
                          minWidth: "24px",
                        }}
                      />
                    )}
                    {selectOpen ? (
                      <>
                        <div className="w-100 pe-2">
                          <Typography variant="h6">
                            {color.name.toLowerCase()}
                          </Typography>
                        </div>
                        <div>
                          <Typography variant="overline">
                            {color.hex}
                          </Typography>
                        </div>
                      </>
                    ) : (
                      color.name
                    )}
                  </MenuItem>
                ))
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item className="ps-2">
        <TextField
          fullWidth
          label={ t("Hex") }
          size="small"
          type="text"
          name="hex"
          variant="filled"
          value={props.selColor}
          color="secondary"
          onChange={props.setSelColor}
        />
      </Grid>
    </Grid>
  );
};
// =================================================
// EXPORT COMPONENT
export default ColorSelector;
