// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
// -------------------------------------------------
// Component elements
import AppRoot from "../components/App_Root";
// -------------------------------------------------
// Basic elements
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// =================================================
// TODO: finalise this page
// =================================================
// FUNCTIONAL COMPONENT
const Welcome = () => {
  const { t } = useTranslation("pages", { keyPrefix: "Welcome" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  const isSM = useSelector((state) => state.ui.isSM);
  // ===============================================
  // RENDER COMPONENT
  return (
    <AppRoot>
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE} | {t("Welcome")}
        </title>
      </Helmet>
      <Toolbar />
      {!isXS && !isSM && (
        <Grid item xs={12}>
          <Typography variant="h2" className="my-2">
            {t("Welcome")}
          </Typography>
        </Grid>
      )}
      <Grid container className="px-1">
        <Grid item xs={12} md={11} lg={9} xl={7}>
          <Paper>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={6} className="text-center">
                <img
                  src="images/icon-welcome.jpg"
                  alt={t("Welcome to the Australian Sleep Registry")}
                  className="w-100"
                />
              </Grid>
              <Grid item className="p-3" xs={12} sm={6}>
                <Typography className="mb-3" variant="h2">
                  {t("We're glad to have you as a research volunteer.")}
                </Typography>
                <Typography paragraph>
                  {t("Start filling out surveys right now.")}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={NavLink}
                  to="/tasks"
                >
                  Get Started
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </AppRoot>
  );
};
// =================================================
// EXPORT COMPONENT
export default Welcome;
