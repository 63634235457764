// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
// -------------------------------------------------
// Basic elements
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
// -------------------------------------------------
// Transitions
import Slide from "@mui/material/Slide";
// =================================================
// FUNCTIONAL COMPONENT
const AppListItem = (props) => {
  // ===============================================
  // RENDER COMPONENT
  return (
    <Slide
      direction="right"
      appear={false}
      in={props.slideIn}
      mountOnEnter
      unmountOnExit
    >
      <div>
        <ListItem
          button={props.noButton ? false : true}
          disabled={props.disabled}
          className={
            props.isSelected
              ? `px-2 bg-list-item-selected ${props.className} `
              : `px-2 ${props.className} `
          }
          onClick={props.onClick}
        >
          <ListItemIcon sx={{ minWidth: "8px" }} className="me-2">
            {props.icon}
          </ListItemIcon>
          <ListItemText
            primary={props.textPrimary}
            secondary={props.textSecondary}
            primaryTypographyProps={{
              fontWeight: 500,
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
            secondaryTypographyProps={{
              variant: "body2",
              fontWeight: 400,
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          />
          {props.iconEnd}
        </ListItem>
        {props.divider && <Divider light={true} />}
      </div>
    </Slide>
  );
};
// =================================================
// EXPORT COMPONENT
export default AppListItem;
