// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
// Components
import MainCard from "../components/App_MainCard";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
// =================================================
// FUNCTIONAL COMPONENT
const Loading = () => {
  const { t } = useTranslation("pages", { keyPrefix: "Loading" });
  // ===============================================
  // RENDER COMPONENT
  return (
    <React.Fragment>
      <Helmet>
        <title>{ process.env.REACT_APP_TITLE } | { t("Loading") }...</title>
      </Helmet>
      <MainCard isLoading noFooter>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          className="h-100">
          <Grid item xs={3} sm={3} md={3}>
            <img
              alt={ t("Waiting for the return") }
              src="images/icon-wait.gif"
              className="w-100 max-width-501px"
            />
          </Grid>
          <Grid item>{ t("Loading") }...</Grid>
        </Grid>
      </MainCard>
    </React.Fragment>
  );
};
// =================================================
// EXPORT COMPONENT
export default Loading;
