// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
// -------------------------------------------------
// Component elements
import ArticleFormControl from "./ArticleFormControl";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  appendValidation,
  setFormSurveyLogicByAlias,
  selectMultipleResponsesByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const SliderHorizontalItem = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const values = useSelector((state) =>
    state.tickets.currentTicket
      ? selectMultipleResponsesByAlias(state, props.alias)
      : props.article.rowList.map(() => null)
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId
  );
  const isEnabled = useSelector((state) =>
    state.tickets.currentTicket ? state.form.isEnabled[props.article._id] : true
  );
  // -----------------------------------------------
  // Local state
  const [localValidation, setLocalValidation] = useState(null);
  // Local state to make it easier to handle the slider values
  const [hasSetValues, setHasSetValues] = useState(
    values ? values.some((val) => val !== null) : false
  );
  const testNumberReg = new RegExp("/^d+$/");
  const [localValues, setLocalValues] = useState(
    values
      ? values.map((val, i) => {
          return val === null &&
            testNumberReg.test(props.article.rowList[i].text)
            ? parseInt(props.article.rowList[i].text)
            : val === null
            ? props.article.options.min
            : val;
        })
      : props.article.rowList.map(() => props.article.options.min)
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // When article is disabled, revert back to inital value
    if (!isEnabled) {
      handleSetMultipleResponses(
        null,
        values.map(() => null)
      );
      return;
    }
    // Validate answers
    const thisAlias =
      typeof props.alias === "string" ? props.alias : props.alias[0];
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      thisAlias,
      values[0]
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
    // Clear any validation when component unmounts
    return () => {
      dispatch(initValidationByAlias({ alias: thisAlias }));
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Initualize when items change (when building survey)
  useEffect(() => {
    if (!props.doShow) {
      return; // not building survey, return
    }
    setHasSetValues(false);
    setLocalValues(
      props.article.rowList.map((row) =>
        row.text && testNumberReg.test(row.text)
          ? parseInt(row.text)
          : props.article.options.min
      )
    );
  }, [props.article.rowList]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetMultipleResponses = (e, newValues) => {
    props.article.rowList.forEach(async (row, i) => {
      switch (responseCollection) {
        case "taskResponses":
          await dispatch(
            setSingleTaskResponseByAlias({
              responseId,
              alias: Array.isArray(props.alias) ? props.alias[i] : props.alias,
              value: newValues[i],
            })
          );
          break;
        case "previewResponses":
          await dispatch(
            setSinglePreviewResponseByAlias({
              responseId,
              alias: Array.isArray(props.alias) ? props.alias[i] : props.alias,
              value: newValues[i],
            })
          );
          break;
        default:
          return;
      }
      // Evaluate survey logic after this response
      dispatch(
        setFormSurveyLogicByAlias({
          alias: Array.isArray(props.alias) ? props.alias[i] : props.alias,
        })
      );
    });
    setLocalValues(newValues);
    // If the item is disabled, we don't have to set 'hasStarted' or do validation
    if (!isEnabled) {
      return;
    }
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      })
    );
    // Validate answers
    props.alias.forEach((a, i) => {
      const validation = validateSurveyItem(
        props.required,
        props.article.validation,
        a,
        newValues[i]
      );
      if (validation) {
        setLocalValidation(validation);
        dispatch(appendValidation({ validation }));
      }
    });
  };
  // -------------------------------------------------
  // Generate objects array for tick-marks
  const generateMarks = (min, max, intervals, unit) => {
    let marks = [];
    for (let i = min; i <= max; i += intervals) {
      marks.push({ value: i, label: unit ? `${i}${unit}` : i });
    }
    return marks;
  };
  // -------------------------------------------------
  // Compute marks if needed
  const marks = props.article.options.marks
    ? generateMarks(
        props.article.options.min,
        props.article.options.max,
        props.article.options.intervals,
        props.article.options.unit
      )
    : false;
  // -------------------------------------------------
  // Handle call to set value and change the color of the slider
  const handleSetLocalValues = (e, newValue) => {
    setHasSetValues(true);
    setLocalValues(newValue);
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <Grid container wrap="nowrap" alignItems="center">
      {props.article.options.startAdornment && (
        <Grid item className="flex-grow-0 pe-1">
          <Typography className="font-size-085rem">
            {props.article.options.startAdornment}
          </Typography>
        </Grid>
      )}
      <Grid item className="flex-grow-1">
        <ArticleFormControl
          article={props.article}
          required={props.required}
          isInvalid={localValidation && localValidation.isInvalid}
          validationMsg={localValidation && localValidation.msg[props.alias[0]]}
        >
          <div className="ms-3 me-4">
            {props.doShow &&
              props.article.rowList.map((row, i) => (
                <React.Fragment key={row._id}>
                  <Chip
                    label={
                      <Typography variant="overline" color="textSecondary">
                        {Array.isArray(props.alias)
                          ? props.alias[i]
                          : props.alias}
                      </Typography>
                    }
                    size="small"
                    className="me-3"
                  />
                </React.Fragment>
              ))}
            <Slider
              disabled={props.uneditable}
              name={props.alias[0]}
              label={props.article.inputLabel}
              value={localValues}
              marks={marks}
              step={props.article.options.step}
              min={props.article.options.min}
              max={props.article.options.max}
              color={"secondary"}
              valueLabelDisplay="auto"
              className={!hasSetValues ? "MuiSlider-colorGrey" : ""}
              onChange={handleSetLocalValues}
              onChangeCommitted={handleSetMultipleResponses}
            />
          </div>
        </ArticleFormControl>
      </Grid>
      {props.article.options.endAdornment && (
        <Grid item className="flex-grow-0 ps-1">
          <Typography className="font-size-085rem">
            {props.article.options.endAdornment}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
// =================================================
// EXPORT COMPONENT
export default SliderHorizontalItem;
