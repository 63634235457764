// =================================================
// IMPORT
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/public/surveys`;
// -------------------------------------------------
// Use 'createEntityAdapter' to store the response data in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  translateSurvey: null,
  status: "idle", // 'idle' | 'loading' | 'partial' | 'succeeded' | 'failed',
  maxStatus: "idle",
  error: null,
});
// =================================================
// ASYNC API ACTIONS
// -------------------------------------------------
// API fetch a survey by its id
export const fetchPublicSurveyById = createAsyncThunk(
  "publicSurveys/fetchPublicSurveyById",
  async ({ surveyId, upsert }, thunk) => {
    const state = thunk.getState();
    // If not upsert and survey id does not exist, then retun null
    if (!upsert && !state.publicSurveys.entities[surveyId]) {
      return { survey: null };
    }
    const response = await client.get(`${apiurl}/${surveyId}`);
    return response.data;
  }
);
// =================================================
// DEFINE MUTATING ACTIONS
export const publicSurveySlice = createSlice({
  name: "publicSurveys",
  initialState,
  reducers: {
    resetSurveysError(state) {
      if (state.status === "failed") {
        state.status = "idle";
        state.maxStatus = "idle";
        state.errorMsg = null;
      }
    },
    setSurveysStatus(state, action) {
      state.status = action.payload.status;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPublicSurveyById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPublicSurveyById.fulfilled, (state, action) => {
        state.status =
          state.maxStatus === "succeeded" ? "succeeded" : "partial";
        action.payload.survey &&
          adapter.upsertOne(state, action.payload.survey);
      })
      .addCase(fetchPublicSurveyById.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const { setSurveysStatus, resetSurveysError } =
  publicSurveySlice.actions;
// =================================================
// SELECTOR FUNCTIONS
// -------------------------------------------------
export const publicSurveysSelectors = adapter.getSelectors(
  (state) => state.publicSurveys
);
// =================================================
// EXPORT DEFAULT
export default publicSurveySlice.reducer;
