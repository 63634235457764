// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
// -------------------------------------------------
// Components
import ColorSelector from "../form/ColorSelector";
import FontSelector from "../form/FontSelector";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material//FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material//FormatAlignRight";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditArticleTitle = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditArticleTitle",
  });
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (key, value) => {
    props.setObj((prevState) => {
      let newState = { ...prevState };
      newState = { ...newState, [key]: value };
      if (newState && newState.text === "") {
        newState = null;
      } else if (newState && newState.text !== "") {
        if (!newState.align) {
          newState.align = "left";
        }
        if (!newState.variant) {
          newState.variant = "h3";
        }
        if (!newState.color) {
          newState.color = "inherit";
        }
      }
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            label={props.label}
            type="text"
            name="text"
            variant="filled"
            value={props.obj ? props.obj.text : ""}
            color="secondary"
            className="answer-text-green"
            onChange={(e) => handleSetObj(e.target.name, e.target.value)}
          />
        </Grid>
        {props.obj && props.showOptions && (
          <>
            <Grid item xs="auto" className="mt-3 me-3">
              <ToggleButtonGroup
                exclusive
                name="align"
                value={props.obj.align}
                onChange={(e, val) => val && handleSetObj("align", val)}
              >
                <Tooltip arrow title={t("align left")} placement="top">
                  <ToggleButton
                    value="left"
                    className={props.obj.align === "left" ? "bg-grey" : ""}
                  >
                    <FormatAlignLeftIcon />
                  </ToggleButton>
                </Tooltip>
                <Tooltip arrow title={t("align center")} placement="top">
                  <ToggleButton
                    value="center"
                    className={props.obj.align === "center" ? "bg-grey" : ""}
                  >
                    <FormatAlignCenterIcon />
                  </ToggleButton>
                </Tooltip>
                <Tooltip arrow title={t("align right")} placement="top">
                  <ToggleButton
                    value="right"
                    className={props.obj.align === "right" ? "bg-grey" : ""}
                  >
                    <FormatAlignRightIcon />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs="auto" className="mt-3 me-3">
              <FontSelector
                selFont={props.obj.variant}
                setSelFont={(e) => handleSetObj("variant", e.target.value)}
              />
            </Grid>
            <Grid item xs="auto" className="mt-3">
              <ColorSelector
                selColor={props.obj.color}
                setSelColor={(e) => handleSetObj("color", e.target.value)}
              />
            </Grid>
          </>
        )}
      </Grid>
      {props.showDivider && <Divider className="my-3" />}
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditArticleTitle;
