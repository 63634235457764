// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// -------------------------------------------------
// Authentication API functions
import auth from "../api-routes/auth";
// -------------------------------------------------
// Component elements
import MainCard from "../components/App_MainCard";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
// -------------------------------------------------
// Basic elements
import {
  Alert,
  Checkbox,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Link,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
// -------------------------------------------------
// Icons
import PersonAdd from "@mui/icons-material/PersonAdd";
// =================================================
// FUNCTIONAL COMPONENT
const AuthRegister = () => {
  const { t } = useTranslation("pages", { keyPrefix: "AuthRegister" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Context state
  const { currentAuth, verifiedAuth } = useAuth();
  // -----------------------------------------------
  // Local state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [clickWrap, setClickWrap] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Executes when the user presses the form submit button
  const handleRegistration = async (e) => {
    e.preventDefault();
    // Check that the password is not empty (is not explicitly checked by firebase)
    if (!email || email === "") {
      setErrorMsg(t("Please type your email address below."));
      return;
    }
    if (!password || password === "") {
      setErrorMsg(t("Please type your password below."));
      return;
    }
    // Set loading and init errors to 'null'
    setIsLoading(true);
    setErrorMsg(null);
    // Call auth action
    try {
      // Try registering the user
      await auth.createUser(email, password);
    } catch (error) {
      // An  error occurred, set state
      setIsLoading(false);
      setErrorMsg(auth.parseErrorMessage(error));
    }
  };
  // ===============================================
  // RENDER COMPONENT
  if (currentAuth && verifiedAuth) {
    return <Navigate to="/" />;
  } else if (currentAuth && !verifiedAuth) {
    return <Navigate to="/user-management?mode=sendVerifyEmailLink" />;
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE} | {t("Create new account")}
        </title>
      </Helmet>
      <MainCard isLoading={isLoading}>
        <Typography className="mb-2" variant="h1">
          {t("Join the Australian Sleep Registry")}
        </Typography>
        <Typography className="mb-3" variant="h3" color="textSecondary">
          {t("Create a new account")}
        </Typography>
        {errorMsg !== null && (
          <Alert severity="warning" className="align-items-center mb-3">
            {errorMsg}
          </Alert>
        )}
        <form noValidate onSubmit={handleRegistration}>
          <TextField
            required
            fullWidth
            disabled={isLoading}
            id="email"
            type="email"
            name="email"
            label={t("Email Address")}
            autoComplete="username"
            variant="filled"
            color="secondary"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            required
            fullWidth
            disabled={isLoading}
            id="password"
            type="password"
            name="password"
            label={t("Password")}
            autoComplete="new-password"
            variant="filled"
            color="secondary"
            margin="normal"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={clickWrap}
                color="secondary"
                name="clickwrap"
                onChange={(e) => {
                  setClickWrap(e.target.checked);
                }}
              />
            }
            label={
              <Typography variant="body2">
                I have read and agree to the{" "}
                <Link
                  href="terms"
                  variant="body2"
                  target="_blank"
                  rel="noopener"
                  underline="none"
                >
                  Terms of Use
                </Link>{" "}
                and{" "}
                <Link
                  href="privacy"
                  variant="body2"
                  target="_blank"
                  rel="noopener"
                  underline="none"
                >
                  Privacy Policy
                </Link>
                .
              </Typography>
            }
          />
          <LoadingButton
            fullWidth
            disabled={!clickWrap}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<PersonAdd />}
            type="submit"
            variant="contained"
            color={isLoading === "loading" ? "secondary" : "primary"}
            className="my-2"
          >
            {t("Register")}
          </LoadingButton>
        </form>
        <Grid container justifyContent="space-around">
          <Grid item className="mt-2 text-center">
            <NavLink
              to="/sign-in"
              className="text-secondary text-decoration-none"
            >
              <Typography variant="body2">
                {t("Already have an account? Sign in")}
              </Typography>
            </NavLink>
          </Grid>
          <Grid item className="mt-2 text-center">
            <NavLink
              to="/user-management?mode=sendResetPasswordLink"
              className="text-secondary text-decoration-none"
            >
              <Typography variant="body2">{t("Forgot password?")}</Typography>
            </NavLink>
          </Grid>
        </Grid>
      </MainCard>
    </React.Fragment>
  );
};
// =================================================
// EXPORT COMPONENT
export default AuthRegister;
