import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { toggleSecDrawer } from "../../redux/reducers/ui";

import {
  ListItem,
  ListItemText,
  Grid,
  Card,
  Typography,
  Button,
} from "@mui/material";

import TopDrawer from "../App_TopDrawer";

import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import Save from "@mui/icons-material/Save";

import CmsEditorEditFields from "./CmsEditor_EditFields";
import CmsContent from "../pages/CmsContent";

const CmsEditorContentFields = (props) => {
  // currentWebpage, handleSave, propsInfo
  const dispatch = useDispatch();
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);

  const handleEditWebpageContent = () => {
    dispatch(toggleSecDrawer({ isOpen: true, id: "CmsEditor_content-fields" }));
  };

  const [contentList, setContentList] = useState(props.contentList);

  // Update contentList when props.contentList changes
  useEffect(() => {
    setContentList(props.contentList);
  }, [props.contentList]);

  // -----------------------------------------------
  // Resets local state upon closing of the drawer
  useEffect(() => {
    if (!secDrawerIsOpen) {
      setContentList(contentList);
    }
  }, [contentList, secDrawerIsOpen]);

  return (
    <>
      {/* ================================================== */}
      {/* TOP DRAWER */}
      <TopDrawer
        id={"CmsEditor_content-fields"}
        title="Webpage Content"
        buttons={
          <Button
            color="inherit"
            startIcon={<Save />}
            className="m-2"
            onClick={() => {
              props.handleSave({ contentList: contentList });
              dispatch(
                toggleSecDrawer({
                  isOpen: false,
                  id: "CmsEditor_content-fields",
                })
              );
            }}
          >
            Save
          </Button>
        }
      >
        <CmsEditorEditFields obj={contentList} setObj={setContentList} />
      </TopDrawer>

      {/* SECTION SHOWING CURRENT INFO */}
      <Card className="mb-3">
        <List disablePadding className="px-3 pt-2">
          <ListItem
            disablePadding
            secondaryAction={
              <Tooltip arrow title="Edit" placement="top">
                <IconButton edge="end" onClick={handleEditWebpageContent}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            }
          >
            <ListItemText
              primary={<Typography variant="h3">CMS content</Typography>}
            />
          </ListItem>
        </List>
        <List disablePadding className="px-3">
          <ListItem divider>
            <Grid container>
              {contentList.map((item, index) => (
                <Grid container alignItems="center" key={index}>
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary={item.fieldname}
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={9} sm="auto">
                    <CmsContent
                      contentList={contentList}
                      fieldname={item.fieldname}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </ListItem>
        </List>
      </Card>
    </>
  );
};

export default CmsEditorContentFields;
