// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
// -------------------------------------------------
// Basic elements
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// =================================================
// FUNCTIONAL COMPONENT
const OpacitySelector = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "form.OpacitySelector" });
  // ===============================================
  // RENDER COMPONENT
  return (
    <FormControl variant="filled" size="small" color="secondary">
      <InputLabel htmlFor="color-select">{ t("Opacity") }</InputLabel>
      <Select
        size="small"
        value={props.selOpacity}
        id="opacity-select"
        label={ t("Opacity") }
        sx={{ minWidth: "175px" }}
        onChange={props.setSelOpacity}>
        <MenuItem value={1}>100</MenuItem>
        <MenuItem value={0.9}>90</MenuItem>
        <MenuItem value={0.75}>75</MenuItem>
        <MenuItem value={0.66}>66</MenuItem>
        <MenuItem value={0.5}>50</MenuItem>
        <MenuItem value={0.33}>33</MenuItem>
        <MenuItem value={0.25}>25</MenuItem>
        <MenuItem value={0.1}>10</MenuItem>
        <MenuItem value={0}>0</MenuItem>
      </Select>
    </FormControl>
  );
};
// =================================================
// EXPORT COMPONENT
export default OpacitySelector;
