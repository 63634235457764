// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { v4 as uuid } from "uuid";
import { makeStyles } from "@mui/styles";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
// =================================================
// FUNCTIONAL COMPONENT
const MediaVideoYouTube = (props) => {
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Setup the variable classes
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      height: "100%",
      backgroundColor: "black",
    },
    iFrame: {
      minHeight: props.article.options.minHeight,
      maxHeight: props.article.options.maxHeight,
    },
  }));
  const classes = useStyles();
  // ===============================================
  // RENDER COMPONENT
  return (
    <Grid container alignContent="center" className={`${classes.root} rounded`}>
      <iframe
        title={uuid()}
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${props.article.url}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={props.article.options.allowFullSacreen}
        autoPlay={props.article.options.autoplay}
        controls={props.article.options.controls}
        loop={props.article.options.loop}
        modestbranding={1}
        className={classes.iFrame}
      />
    </Grid>
  );
};
// =================================================
// EXPORT COMPONENT
export default MediaVideoYouTube;
