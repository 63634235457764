// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTime, Duration } from "luxon";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Add from "@mui/icons-material/Add";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import MobileTimePicker from "@mui/lab/MobileTimePicker";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { TransitionGroup } from "react-transition-group";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditScoreGrouping = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditScoreGrouping",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  const [idx, setIdx] = useState(null);
  const [open, setOpen] = useState(null); // Dialog for duration form input
  const [duration, setDuration] = useState({
    min: { days: null, hours: null, minutes: null, seconds: null },
    max: { days: null, hours: null, minutes: null, seconds: null },
  });
  const [currentType, setCurrentType] = useState(
    props.aliasList[props.obj.aliasId]
      ? props.aliasList[props.obj.aliasId].type
      : null
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (key, value) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[key] = value;
      if (key === "aliasId" && currentType !== props.aliasList[value].type) {
        newState.categoryList =
          props.aliasList[value].type === "time"
            ? [{ label: "", min: "00:00", max: "12:00" }]
            : props.aliasList[value].type === "duration"
            ? [{ label: "", min: "PT0S", max: "PT0S" }]
            : [{ label: "", min: "0", max: "1" }];
        props.aliasList[value].type === "duration" &&
          setDuration({
            min: Duration.fromISO("PT0S").toObject(),
            max: Duration.fromISO("PT0S").toObject(),
          });
      }
      if (key === "aliasId") {
        setCurrentType(props.aliasList[value].type);
      }
      return newState;
    });
  };
  const handleSetCategory = (i, key, value) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.categoryList[i][key] = value;
      return newState;
    });
  };
  const handleAddItem = () => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.categoryList.splice(newState.categoryList.length, 0, {
        min:
          currentType === "time"
            ? "12:00"
            : currentType === "duration"
            ? "PT0S"
            : "1",
        max:
          currentType === "time"
            ? "23:59"
            : currentType === "duration"
            ? "PT0S"
            : "2",
        label: "",
        feedback: "",
      });
      currentType === "duration" &&
        setDuration({
          min: Duration.fromISO("PT0S").toObject(),
          max: Duration.fromISO("PT0S").toObject(),
        });
      return newState;
    });
  };
  const handleMoveItem = (idx, direction) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let element = newState.categoryList[idx];
      newState.categoryList.splice(idx, 1);
      newState.categoryList.splice(idx + direction, 0, element);
      return newState;
    });
  };
  const handleDeleteItem = (idx) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.categoryList.splice(idx, 1);
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return currentType === null ? (
    "Could not find alias"
  ) : (
    <>
      {/* Dialog to show Duration form input */}
      {[t("min"), t("max")].map((key) => (
        <Dialog key={key} open={open === key} onClose={() => setOpen(null)}>
          <DialogTitle>
            {t("Set {{ type }} duration", { type: key })}
          </DialogTitle>
          <DialogContent>
            <Grid container>
              {[t("days"), t("hours"), t("minutes"), t("seconds")].map(
                (dur) => (
                  <Grid key={dur} item xs={3} className="pe-2">
                    <TextField
                      fullWidth
                      label={dur}
                      type="number"
                      name={dur}
                      variant="filled"
                      value={duration[key][dur] ? duration[key][dur] : ""}
                      size="small"
                      color="secondary"
                      className="answer-text-green"
                      onChange={(e) =>
                        setDuration((prevState) => {
                          let newState = JSON.parse(JSON.stringify(prevState));
                          newState[key][dur] =
                            e.target.value === "" ? null : e.target.value;
                          return newState;
                        })
                      }
                      onWheel={(e) => e.target.blur()}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                setOpen(null);
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={(e) => {
                handleSetCategory(
                  idx,
                  key,
                  Duration.fromObject(duration[key]).toISO()
                );
                setOpen(null);
              }}
            >
              {t("OK")}
            </Button>
          </DialogActions>
        </Dialog>
      ))}
      {/* Select which item to do the classifcation on */}
      <Typography variant="h4" className="mb-3">
        {t("Grouping into categories")}
      </Typography>
      <Typography>{t("Item")}</Typography>
      <FormControl fullWidth variant="filled" color="secondary" size="small">
        <Select
          autoWidth
          hiddenLabel
          name="aliasId"
          value={props.obj.aliasId}
          className="answer-text-green"
          color="secondary"
          onChange={(e) => {
            handleSetObj("aliasId", e.target.value);
            handleSetObj(
              "articleId",
              props.aliasList[e.target.value].articleId
            );
            handleSetObj("type", props.aliasList[e.target.value].type);
          }}
        >
          {props.aliasList &&
            Object.keys(props.aliasList).map((key) => (
              <MenuItem key={key} value={key}>
                <Chip
                  label={
                    <Typography variant="overline" color="textSecondary">
                      {props.aliasList[key].alias}
                    </Typography>
                  }
                  size="small"
                  className="me-2"
                />
                {props.aliasList[key].title}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Typography className="mt-3">{t("Categories")}</Typography>
      <Card className="bg-light px-2 pt-2 w-100 border border-secondary shadow-none">
        <TransitionGroup>
          {props.obj.categoryList.map((o, i) => (
            <Collapse key={`surveys_edit-score-item-${i}`}>
              <div
                className={
                  i < props.obj.categoryList.length - 1
                    ? "border-bottom mb-2"
                    : ""
                }
              >
                <Grid container className="pb-2">
                  {/* MIN */}
                  <Grid item xs={3} className="pe-2">
                    {currentType === "time" ? (
                      <MobileTimePicker
                        value={DateTime.fromISO(`2020-01-01T${o.min}`)}
                        toolbarTitle={t("Min time (incl)")}
                        label={t("Min time (incl)")}
                        ampm={false}
                        ampmInClock={false}
                        minutesStep={1}
                        showTodayButton={false}
                        showToolbar={true}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            label={t("Min time (incl)")}
                            variant="filled"
                            size="small"
                            color="secondary"
                            {...params}
                          />
                        )}
                        DialogProps={{ id: "time-picker" }}
                        onChange={(newValue) =>
                          handleSetCategory(
                            i,
                            "min",
                            newValue.startOf("second").toISOTime({
                              includeOffset: false,
                              suppressMilliseconds: true,
                              suppressSeconds: true,
                            })
                          )
                        }
                      />
                    ) : (
                      <TextField
                        fullWidth
                        label={t("Min (incl)")}
                        type={currentType === "duration" ? "text" : "number"}
                        name="min"
                        variant="filled"
                        value={o.min ? o.min : ""}
                        size="small"
                        color="secondary"
                        className="answer-text-green"
                        onClick={(e) => {
                          if (currentType === "duration") {
                            setDuration((prevState) => {
                              return {
                                ...prevState,
                                min: Duration.fromISO(o.min).toObject(),
                              };
                            });
                            setOpen("min");
                            setIdx(i);
                          }
                        }}
                        onChange={(e) =>
                          currentType !== "duration" &&
                          handleSetCategory(i, e.target.name, e.target.value)
                        }
                      />
                    )}
                  </Grid>
                  {/* MAX */}
                  <Grid item xs={3} className="pe-2">
                    {currentType === "time" ? (
                      <MobileTimePicker
                        value={DateTime.fromISO(`2020-01-01T${o.max}`)}
                        toolbarTitle={t("Max time (excl)")}
                        label={t("Max time (excl)")}
                        ampm={false}
                        ampmInClock={false}
                        minutesStep={1}
                        showTodayButton={false}
                        showToolbar={true}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            label={t("Max time (excl)")}
                            variant="filled"
                            size="small"
                            color="secondary"
                            {...params}
                          />
                        )}
                        DialogProps={{ id: "time-picker" }}
                        onChange={(newValue) =>
                          handleSetCategory(
                            i,
                            "max",
                            newValue.startOf("second").toISOTime({
                              includeOffset: false,
                              suppressMilliseconds: true,
                              suppressSeconds: true,
                            })
                          )
                        }
                      />
                    ) : (
                      <TextField
                        fullWidth
                        label={t("Max (excl)")}
                        type={currentType === "duration" ? "text" : "number"}
                        name="max"
                        variant="filled"
                        value={o.max ? o.max : ""}
                        size="small"
                        color="secondary"
                        className="answer-text-green"
                        onClick={(e) => {
                          if (currentType === "duration") {
                            setDuration((prevState) => {
                              return {
                                ...prevState,
                                max: Duration.fromISO(o.max).toObject(),
                              };
                            });
                            setOpen("max");
                            setIdx(i);
                          }
                        }}
                        onChange={(e) =>
                          currentType !== "duration" &&
                          handleSetCategory(i, e.target.name, e.target.value)
                        }
                      />
                    )}
                  </Grid>
                  {props.toLabel ? (
                    <Grid item xs={3} className="pe-2">
                      {/* LABEL */}
                      <TextField
                        fullWidth
                        label={t("Label")}
                        type="text"
                        name="label"
                        variant="filled"
                        value={o.label ? o.label : ""}
                        size="small"
                        color="secondary"
                        className="answer-text-green"
                        onChange={(e) =>
                          handleSetCategory(i, e.target.name, e.target.value)
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={3} className="pe-2">
                      {/* VALUE */}
                      <TextField
                        fullWidth
                        label={t("Value")}
                        type="number"
                        name="value"
                        variant="filled"
                        value={o.value ? o.value : ""}
                        size="small"
                        color="secondary"
                        className="answer-text-green"
                        onChange={(e) =>
                          handleSetCategory(i, e.target.name, e.target.value)
                        }
                        onWheel={(e) => e.target.blur()}
                      />
                    </Grid>
                  )}
                  {/* MOVE AND DELETE BUTTONS */}
                  <Grid item xs={3} align="right">
                    {props.obj.categoryList.length > 1 && (
                      <Tooltip
                        arrow
                        title={t("Up")}
                        placement={i === 0 ? "top" : "bottom"}
                      >
                        <span>
                          <IconButton
                            disabled={i === 0}
                            onClick={(e) => handleMoveItem(i, -1)}
                          >
                            <ArrowUpward />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                    {props.obj.categoryList.length > 1 && (
                      <Tooltip
                        arrow
                        title={t("Down")}
                        placement={i === 0 ? "top" : "bottom"}
                      >
                        <span>
                          <IconButton
                            disabled={i === props.obj.categoryList.length - 1}
                            onClick={(e) => handleMoveItem(i, 1)}
                          >
                            <ArrowDownward />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                    {props.obj.categories.length > 1 && (
                      <Tooltip arrow title={t("Delete")} placement="right">
                        <span>
                          <IconButton onClick={(e) => handleDeleteItem(i)}>
                            <DeleteForever />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item xs={9} className="pt-2 pe-2">
                    {/* FEEDBACK */}
                    <TextField
                      fullWidth
                      label="Feedback"
                      name="feedback"
                      variant="filled"
                      value={o.feedback ? o.feedback : ""}
                      size="small"
                      color="secondary"
                      className="answer-text-green"
                      onChange={(e) =>
                        handleSetCategory(i, e.target.name, e.target.value)
                      }
                      onWheel={(e) => e.target.blur()}
                    />
                  </Grid>
                </Grid>
              </div>
            </Collapse>
          ))}
        </TransitionGroup>
        <Divider className="mb-2">
          <ButtonGroup>
            <Button
              variant="outlined"
              size="small"
              color="inherit"
              startIcon={<Add />}
              className="border-dashed"
              onClick={() => handleAddItem()}
            >
              {t("Add item")}
            </Button>
          </ButtonGroup>
        </Divider>
      </Card>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditScoreGrouping;
