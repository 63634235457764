// ########################################
// IMPORT
// ========================================
// Dependencies
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// ########################################
// Create Firebase authentication app
const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});
// ########################################
// EXPORT
export const fbAuth = getAuth();
export default app;
