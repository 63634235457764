// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Component elements
import ArticleFormControl from "./ArticleFormControl";
import CheckboxesItem from "./CheckboxesItem";
// -------------------------------------------------
// Redux actions
import { setFormSurveyLogicByAlias } from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// =================================================
// FUNCTIONAL COMPONENT
const Checkboxes = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id]
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // When article is disabled, revert back to inital value
    if (!isEnabled) {
      props.alias.forEach((a) => {
        handleSetSingleResponse(a, false);
      });
      return;
    }
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value })
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value })
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // If the item is disabled, we don't have to set 'hasStarted' or do validation
    if (!isEnabled) {
      return;
    }
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      })
    );
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <ArticleFormControl
      article={props.article}
      required={props.required}
      isInvalid={false}
    >
      {props.columnList
        ? props.columnList.map((column, i) => (
            <CheckboxesItem
              key={column._id}
              doShow={props.doShow}
              uneditable={props.uneditable}
              dependencies={props.dependencies}
              aliasList={props.aliasList}
              scoring={props.scoring}
              article={column}
              rowId={props.article._id}
              alias={Array.isArray(props.alias) ? props.alias[i] : props.alias}
              lastItem={i === props.columnList.length - 1}
              handleSetSingleResponse={handleSetSingleResponse}
            />
          ))
        : props.article.rowList.map((row, i) => (
            <CheckboxesItem
              key={row._id}
              doShow={props.doShow}
              uneditable={props.uneditable}
              dependencies={props.dependencies}
              aliasList={props.aliasList}
              scoring={props.scoring}
              article={row}
              alias={Array.isArray(props.alias) ? props.alias[i] : props.alias}
              lastItem={i === props.article.rowList.length - 1}
              handleSetSingleResponse={handleSetSingleResponse}
            />
          ))}
    </ArticleFormControl>
  );
};
// =================================================
// EXPORT COMPONENT
export default Checkboxes;
