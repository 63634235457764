// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Components
import AppRoot from "../components/App_Root";
import ScrollColumn from "../components/App_ScrollColumn";
import ContentPanel from "../components/App_ContentPanel";
import VolunteersColumnContent from "../components/Volunteers_ColumnContent";
import VolunteersHeader from "../components/Volunteers_Header";
import VolunteersContent from "../components/Volunteers_Content";
import VolunteersInvite from "../components/volunteers/Volunteers_Invite";
// -------------------------------------------------
// Redux
import { togglePrimDrawer, toggleSecDrawer } from "../redux/reducers/ui";
import { fetchStudyList, studiesSelectors } from "../redux/reducers/studies";
import { fetchSurveyList } from "../redux/reducers/surveys";
import {
  fetchConsumerList,
  consumersSelectors,
  setCurrentStudyId,
  patchCurrentConsumer,
} from "../redux/reducers/consumers";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// -------------------------------------------------
// Icons
import AddCircle from "@mui/icons-material/AddCircle";
import School from "@mui/icons-material/School";
// =================================================
// FUNCTIONAL COMPONENT
const Volunteers = () => {
  const { t } = useTranslation("pages", { keyPrefix: "Volunteers" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Contexts
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Local state
  const [currentConsumerId, setCurrentConsumerId] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const primDrawerIsOpen = useSelector((state) => state.ui.primDrawerIsOpen);
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  const currentRole = useSelector(
    (state) => state.user.currentUser && state.user.currentUser.primaryRole
  );
  const studiesStatus = useSelector((state) => state.studies.status);
  const surveysStatus = useSelector((state) => state.surveys.status);
  const consumersStatus = useSelector((state) => state.consumers.status);
  const currentConsumer = useSelector((state) =>
    consumersSelectors.selectById(state, currentConsumerId)
  );
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state)
  );
  const studyList = useSelector((state) => studiesSelectors.selectAll(state));
  // -----------------------------------------------
  // Browser URL location
  const location = useLocation();
  const navigate = useNavigate();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Upon render, get the studies list and set the redux state
  useEffect(() => {
    // Init
    let enrolledStudyId;
    // Fetch all studies from DB if not done yet
    if (studiesStatus === "idle") {
      dispatch(fetchStudyList({ requestingUser: currentAuth }));
    }
    // Fetch all surveys from DB if not done yet
    if (surveysStatus === "idle" || surveysStatus === "partial") {
      dispatch(fetchSurveyList({ requestingUser: currentAuth }));
    }
    // Fetch all consumers from DB if not done yet
    if (consumersStatus === "idle") {
      dispatch(fetchConsumerList({ requestingUser: currentAuth }));
    }
    // Get the study-id from URL and get the current study
    if (consumersStatus === "succeeded") {
      const searchParams = new URLSearchParams(location.search);
      const inviteInStudyId = searchParams.get("inviteInStudyId");
      const studyId = searchParams.get("studyId");
      const consumerId = searchParams.get("consumerId");
      if (inviteInStudyId) {
        dispatch(setCurrentStudyId({ currentStudyId: inviteInStudyId }));
        if (primDrawerIsOpen) {
          dispatch(togglePrimDrawer({ isOpen: false }));
        }
        if (!secDrawerIsOpen) {
          dispatch(toggleSecDrawer({ isOpen: true, id: "invite-volunteers" }));
        }
      } else if (consumerId) {
        // First check that the study ID in the URL is the same as the enrolled study
        enrolledStudyId =
          consumerEntities[consumerId] &&
          consumerEntities[consumerId].studyEnrollmentList.length > 0 &&
          consumerEntities[consumerId].studyEnrollmentList[0].studyId;
        if (enrolledStudyId && enrolledStudyId !== studyId) {
          navigate(
            `/volunteers?studyId=${enrolledStudyId}&consumerId=${consumerId}`,
            { replace: true }
          );
          return;
        }
        // Ok study id is the same as the enrolled study
        setCurrentConsumerId(consumerId);
        dispatch(setCurrentStudyId({ currentStudyId: enrolledStudyId }));
        if (!primDrawerIsOpen) {
          dispatch(togglePrimDrawer({ isOpen: true }));
        }
      } else {
        if (primDrawerIsOpen) {
          dispatch(togglePrimDrawer({ isOpen: false }));
        }
        if (secDrawerIsOpen) {
          dispatch(toggleSecDrawer({ isOpen: false }));
        }
      }
    }
  }, [studiesStatus, consumersStatus, location.search]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Handles saving the current consumer to the database
  const handlePatchCurrentConsumer = async (newConsumer) => {
    // Update the database
    await dispatch(
      patchCurrentConsumer({
        socket,
        requestingUser: currentAuth,
        body: {
          data: newConsumer,
        },
      })
    );
    // Close the drawer
    if (secDrawerIsOpen) {
      dispatch(toggleSecDrawer({ isOpen: false }));
    }
  };
  // ===============================================
  // SUB-COMPONENTS
  // -----------------------------------------------
  const ColumnHeader =
    currentRole &&
    (currentRole === "superuser" ||
      currentRole === "researcher" ||
      currentRole === "assistant") ? (
      <>
        <Tooltip arrow title={ t("Invite") } placement="top">
          <IconButton
            color="inherit"
            onClick={(e) => {
              setMenuIsOpen(true);
              setMenuAnchor(e.currentTarget);
            }}
          >
            <AddCircle />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={menuAnchor}
          open={menuIsOpen}
          onClose={() => {
            setMenuIsOpen(false);
            setMenuAnchor(null);
          }}
        >
          {studyList.map((study) => (
            <MenuItem
              key={study._id}
              onClick={() => {
                dispatch(setCurrentStudyId({ currentStudyId: study._id }));
                dispatch(
                  toggleSecDrawer({ isOpen: true, id: "invite-volunteers" })
                );
                setMenuIsOpen(false);
              }}
            >
              <ListItemIcon>
                <School fontSize="small" />
              </ListItemIcon>
              <ListItemText>{study.name}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </>
    ) : null;
  // ===============================================
  // RENDER COMPONENT
  return (
    <AppRoot header={ColumnHeader}>
      <Helmet>
        <title>{ process.env.REACT_APP_TITLE } | { t("Volunteers") }</title>
      </Helmet>
      <VolunteersInvite
        handlePatchCurrentConsumer={handlePatchCurrentConsumer}
      />
      <Grid container className="w-100" wrap="nowrap">
        {/* Scroll column */}
        <ScrollColumn
          header={ColumnHeader}
          isLoading={consumersStatus === "loading"}
        >
          <VolunteersColumnContent currentConsumer={currentConsumer} />
        </ScrollColumn>
        {/* Content panel */}
        <ContentPanel
          title={
            currentConsumer
              ? `${currentConsumer.email} (${currentConsumer.userNumber})`
              : null
          }
          isLoading={consumersStatus === "loading"}
          buttons={<VolunteersHeader currentConsumer={currentConsumer} />}
        >
          <VolunteersContent
            currentConsumer={currentConsumer}
            handlePatchCurrentConsumer={handlePatchCurrentConsumer}
          />
        </ContentPanel>
      </Grid>
    </AppRoot>
  );
};
// =================================================
// EXPORT COMPONENT
export default Volunteers;
