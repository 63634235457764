// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { initSocket } from "../api-routes/client";
// -----------------------------------------------
// Contexts
import { useAuth } from "./auth";
// import auth from "../api-routes/auth";
// -----------------------------------------------
// Redux
import { fetchUser } from "../redux/reducers/user";
import {
  fetchTicketById,
  fetchTicketsFromOneUserAndStudy,
  fetchTicketListFromOwnedStudies,
  removeTicketsFromOneUserAndStudy,
  removeTicketById,
} from "../redux/reducers/tickets";
import { fetchConsumerById } from "../redux/reducers/consumers";
import { fetchNotificationListByUserId } from "../redux/reducers/notifications";
import {
  fetchStudyById,
  fetchStudyList,
  resetStudyList,
  removeStudyById,
} from "../redux/reducers/studies";
import {
  fetchSupervisionById,
  removeSupervisionById,
} from "../redux/reducers/supervision";
import { setAlert } from "../redux/reducers/ui";
import { fetchTaskResponseById } from "../redux/reducers/taskResponses";
import { fetchSurveyById, removeSurveyById } from "../redux/reducers/surveys";
import {
  fetchMessageById,
  fetchMessageListByRecipient,
  removeMessageById,
  removeMessagesByConversationId,
} from "../redux/reducers/messages";
import {
  fetchConversationById,
  removeConversationById,
} from "../redux/reducers/conversations";
// =================================================
// CREATE CONTEXT
const SocketContext = React.createContext();
// -------------------------------------------------
// USE_CONTEXT FUNCTION
export function useSocket() {
  return useContext(SocketContext);
}
// -------------------------------------------------
// PROVIDER FUNCTION
export function SocketProvider({ children }) {
  // =================================================
  // VARIABLES
  // -----------------------------------------------
  // Context
  const { currentAuth } = useAuth();
  // -----------------------------------------------
  // Local state
  const [status, setStatus] = useState("idle");
  const [socket, setSocket] = useState(null);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const supervisionIdList = useSelector((state) => state.supervision.ids);
  // =================================================
  // FUNCTIONS
  // -----------------------------------------------
  // Once the information in the Redux store is populated, create the Socket connection
  useEffect(() => {
    if (!currentUser) {
      // -----------------------------------------------
      // Disconnect socket if there was one
      if (socket) {
        console.log("SOCKET: disconnecting...");
        socket.disconnect();
      }
      return;
    }
    // -----------------------------------------------
    // Create the connection if it does not exist yet
    if (status === "idle") {
      // -----------------------------------------------
      // Establish connection
      console.log(`SOCKET: init`);
      setStatus("init");
      setSocket(initSocket());
    } else if (status === "init") {
      // -----------------------------------------------
      // Connection established, create event listeners
      console.log(`SOCKET: create-listeners`);
      // -----------------------------------------------
      // Display message on connect
      socket.on("connect", () => {
        // -----------------------------------------------
        console.log(`SOCKET: connected ${socket.id}`);
      });
      // =================================================
      // Display errors
      // -----------------------------------------------
      socket.io.on("error", (error) => {
        console.log("******");
        console.log("SOCKET: error");
        console.log(error);
        console.log("******");
        socket.disconnect();
        dispatch(
          setAlert({
            type: "snackbar",
            variant: "warning",
            message: "Real-time connection lost. Please refresh browser.",
          })
        );
      });
      // -----------------------------------------------
      socket.io.on("connect_error", (error) => {
        console.log("******");
        console.log("SOCKET: connect_error");
        console.log(error);
        console.log("******");
        socket.disconnect();
        dispatch(
          setAlert({
            type: "snackbar",
            variant: "warning",
            message: "Real-time connection lost. Please refresh browser.",
          })
        );
      });
      // -----------------------------------------------
      socket.io.on("reconnect_error", (error) => {
        console.log("******");
        console.log("SOCKET: reconnect_error");
        console.log(error);
        console.log("******");
        socket.disconnect();
        dispatch(
          setAlert({
            type: "snackbar",
            variant: "warning",
            message: "Real-time connection lost. Please refresh browser.",
          })
        );
      });
      // -----------------------------------------------
      socket.io.on("reconnect_attempt", () => {
        console.log("SOCKET: reconnecting...");
      });
      // -----------------------------------------------
      socket.io.on("reconnect", () => {
        console.log("SOCKET: reconnected");
      });
      // -----------------------------------------------
      // Remove listeners when disconnected
      socket.on("disconnect", () => {
        console.log(`SOCKET: disconnected`);
      });
      // =================================================
      // Listen to events
      // -----------------------------------------------
      // USER/CONSUMER SLICE
      // -----------------------------------------------
      // Reload the current consumer (but only if you are that user or you're an investigator)
      socket.on("fetch-consumer-by-id", ({ userId }) => {
        if (userId === currentUser._id) {
          dispatch(
            fetchUser({
              requestingUser: currentAuth,
              userId,
            })
          );
        }
        if (
          currentUser &&
          (currentUser.primaryRole === "superuser" ||
            currentUser.primaryRole === "admin")
        ) {
          // Reload the user information
          dispatch(
            fetchConsumerById({
              requestingUser: currentAuth,
              userId,
            })
          );
        }
      });
      // -----------------------------------------------
      // NOTIFICATIONS SLICE
      // -----------------------------------------------
      // Reload the notification list
      socket.on(
        "fetch-notification-list-by-user-id",
        ({ userId, userIdList }) => {
          if (currentUser && userId && currentUser._id === userId) {
            dispatch(
              fetchNotificationListByUserId({
                requestingUser: currentAuth,
                userId,
              })
            );
          } else if (
            currentUser &&
            userIdList &&
            userIdList.some((uid) => uid === currentUser._id)
          ) {
            dispatch(
              fetchNotificationListByUserId({
                requestingUser: currentAuth,
                userId: currentUser._id,
              })
            );
          }
        }
      );
      // -----------------------------------------------
      // STUDIES SLICE
      // -----------------------------------------------
      // Reload the study list
      socket.on("reset-study-list", ({ userId }) => {
        if (!userId || userId === currentUser._id) {
          dispatch(resetStudyList());
          dispatch(fetchStudyList({ requestingUser: currentAuth }));
        }
      });
      // Fetch a study by its id, but only if you're the owner or a super user, or its the default study
      socket.on(
        "fetch-study-by-id",
        ({ isDefaultStudy, studyId, userIdList }) => {
          if (
            userIdList.includes(currentUser._id) ||
            (currentUser && currentUser.primaryRole === "superuser") ||
            (currentUser &&
              currentUser.primaryRole === "admin" &&
              isDefaultStudy)
          ) {
            // Reload the user information
            dispatch(
              fetchStudyById({
                requestingUser: currentAuth,
                studyId,
              })
            );
          }
        }
      );
      // Removes a study from the Studies entities
      // Is only relevant for investigators since studies cannot be deleted if
      // no volunteers are enrolled, and volunteers only load enrolled study
      socket.on("delete-study-by-id", ({ studyId }) => {
        // Remove the study from the study entities
        if (
          currentUser &&
          (currentUser.primaryRole === "superuser" ||
            currentUser.primaryRole === "admin")
        ) {
          dispatch(removeStudyById({ studyId }));
        }
      });
      // -----------------------------------------------
      // SUPERVISION SLICE
      // -----------------------------------------------
      // Reload a survey with the id
      socket.on("fetch-supervision-by-id", ({ userId, supervisionId }) => {
        if (
          (currentUser && currentUser._id === userId) ||
          supervisionIdList.some((sid) => sid === supervisionId)
        ) {
          dispatch(
            fetchSupervisionById({
              requestingUser: currentAuth,
              supervisionId,
            })
          );
        }
      });
      // -----------------------------------------------
      // Removes a supervision doc from the Supervision entities
      socket.on("delete-supervision-by-id", ({ supervisionIdList }) => {
        dispatch(removeSupervisionById({ supervisionIdList }));
      });
      // -----------------------------------------------
      // SURVEYS SLICE
      // -----------------------------------------------
      // Reload a survey with the id
      socket.on("fetch-survey-by-id", ({ surveyId }) => {
        dispatch(
          fetchSurveyById({
            requestingUser: currentAuth,
            surveyId,
            upsert: false, // Only fetch if it exists already
          })
        );
      });
      // -----------------------------------------------
      // Removes a survey from the Surveys entities
      socket.on("delete-survey-by-id", ({ surveyId }) => {
        dispatch(removeSurveyById({ surveyId }));
      });
      // -----------------------------------------------
      // TASK RESPONSES SLICE
      // -----------------------------------------------
      // Reloads a task response
      socket.on("fetch-task-response-by-id", ({ taskId }) => {
        // Fetch a task response
        if (
          currentUser &&
          (currentUser.primaryRole === "superuser" ||
            currentUser.primaryRole === "admin")
        ) {
          dispatch(
            fetchTaskResponseById({
              requestingUser: currentAuth,
              taskId,
            })
          );
        }
      });
      // -----------------------------------------------
      // TICKETS SLICE
      // -----------------------------------------------
      // Reload a ticket with the id
      socket.on("fetch-ticket-by-id", ({ ticketId }) => {
        dispatch(
          fetchTicketById({
            requestingUser: currentAuth,
            ticketId,
            upsert:
              false || (currentUser && currentUser.primaryRole === "superuser"), // Only fetch if it exists already or if you're a superuser
          })
        );
      });
      // -----------------------------------------------
      // Fetches tickets from a study (e.g. when an investigator is added to a study)
      socket.on("fetch-tickets-from-owned-studies", () => {
        if (
          currentUser &&
          (currentUser.primaryRole === "superuser" ||
            currentUser.primaryRole === "admin")
        ) {
          dispatch(
            fetchTicketListFromOwnedStudies({
              requestingUser: currentAuth,
              userId: currentUser._id,
            })
          );
        }
      });
      // -----------------------------------------------
      // Reload tickets (if you are an owner)
      socket.on("fetch-ticket-list-by-user-ids", ({ userIdList }) => {
        // If current user is an investigator, re-load all tickets
        if (
          currentUser &&
          (currentUser.primaryRole === "superuser" ||
            currentUser.primaryRole === "admin")
        ) {
          dispatch(
            fetchTicketListFromOwnedStudies({
              requestingUser: currentAuth,
              userId: currentUser._id,
            })
          );
        } else if (userIdList.includes(currentUser._id)) {
          // If current user is not an investigator, load their own tickets
          dispatch(
            fetchTicketsFromOneUserAndStudy({
              requestingUser: currentAuth,
              userId: currentUser._id,
              studyId: currentUser.studyEnrollmentList[0].studyId,
            })
          );
        }
      });
      // -----------------------------------------------
      // Removes a set of tickets of one user and one study
      socket.on("delete-tickets-from-user-and-study", ({ ticketIds }) => {
        dispatch(removeTicketsFromOneUserAndStudy({ ticketIds }));
      });
      // -----------------------------------------------
      // Removes a ticket from the Tickets entities
      socket.on("delete-ticket-by-id", ({ ticketId }) => {
        dispatch(removeTicketById({ ticketId }));
      });
      // -----------------------------------------------
      // MESSAGES SLICE
      // -----------------------------------------------
      // Reload a recipient by the message id
      socket.on(
        "fetch-recipient-by-message-id",
        ({ messageId, userIdList }) => {
          if (userIdList.some((id) => id === currentUser._id)) {
            dispatch(
              fetchMessageById({
                requestingUser: currentAuth,
                messageId,
              })
            );
          }
        }
      );
      // -----------------------------------------------
      // Reload a message by the recipient id
      socket.on(
        "fetch-message-by-id-and-recipient",
        ({ messageId, userId }) => {
          if (currentUser._id === userId) {
            dispatch(
              fetchMessageById({
                requestingUser: currentAuth,
                messageId,
              })
            );
          }
        }
      );
      // Delete a message if the message id exists in the store
      socket.on("delete-message-by-id", ({ messageId }) => {
        dispatch(removeMessageById({ messageId }));
      });
      // -----------------------------------------------
      // CONVERSATIONS SLICE
      // -----------------------------------------------
      // Fetch a conversation and all messages if a new owner has been added
      socket.on(
        "fetch-current-conversation",
        async ({ conversationId, currentRecipientList }) => {
          if (currentRecipientList.includes(currentUser._id)) {
            // Fetch the conversation
            const res = await dispatch(
              fetchConversationById({
                requestingUser: currentAuth,
                conversationId,
              })
            );
            // Fetch all messages by recipient (easier than figuring out which message to fetch)
            dispatch(
              fetchMessageListByRecipient({
                requestingUser: currentAuth,
                recipientId: currentUser._id,
              })
            );
            // Fetch any new consumer objects if required
            res.payload.conversation.userIdList.forEach((userId) =>
              dispatch(
                fetchConsumerById({
                  requestingUser: currentAuth,
                  userId,
                })
              )
            );
          }
        }
      );
      // -----------------------------------------------
      // Delete a conversation from the store if a user is removed
      socket.on(
        "delete-current-conversation",
        ({ conversationId, removedRecipientList }) => {
          if (removedRecipientList.includes(currentUser._id)) {
            dispatch(removeConversationById({ conversationId }));
            dispatch(removeMessagesByConversationId({ conversationId }));
          }
        }
      );
      // -----------------------------------------------
      // Delete a conversation from the store if the conversation is removed
      socket.on("delete-conversation-by-id", ({ conversationId }) => {
        dispatch(removeConversationById({ conversationId }));
        dispatch(removeMessagesByConversationId({ conversationId }));
      });
      // =================================================
      // END OF EVENTS
      // -----------------------------------------------
      // Set status to succeeded
      setStatus("succeeded");
    }
  }, [status, currentUser]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Return the Provider
  return (
    <SocketContext.Provider value={{ socket }}>
      {currentUser && socket
        ? children
        : !currentUser && !socket
        ? children
        : !currentUser && socket.disconnected
        ? children
        : null}
    </SocketContext.Provider>
  );
}
