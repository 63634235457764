// ########################################
// IMPORT
// ========================================
// Dependencies
import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// ========================================
// Redux
import { togglePrimDrawer } from "../redux/reducers/ui";
// ========================================
// Material UI
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
// ########################################
// FUNCTIONAL COMPONENT
const ContentPanel = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Location
  const location = useLocation();
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const isXS = useSelector((state) => state.ui.isXS);
  const isSM = useSelector((state) => state.ui.isSM);
  const primDrawerIsOpen = useSelector((state) => state.ui.primDrawerIsOpen);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Closes the drawer
  const handleCloseDrawer = () => {
    if (primDrawerIsOpen) {
      dispatch(togglePrimDrawer({ isOpen: false }));
    }
  };
  // ========================================
  // RETURN
  return isXS ? (
    <Drawer
      anchor="right"
      open={primDrawerIsOpen}
      onClose={handleCloseDrawer}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      PaperProps={{ className: "w-100 bg-grey" }}
    >
      <Grid
        id={props.id ? props.id : "ContentPanel"}
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        className="py-2 mb-2 bg-gradient-primary bd-filt-blur-5px shadow-sm fixed-top"
      >
        <Grid item>
          <IconButton
            edge="start"
            component={NavLink}
            to={location.pathname}
            className="text-white ms-2"
            onClick={handleCloseDrawer}
          >
            <Icon>chevron_left</Icon>
          </IconButton>
        </Grid>
        <Grid item className="flex-grow-1 overflow-hidden">
          <Typography
            noWrap
            variant="h3"
            className={!props.title || props.title === "." ? "opacity-0" : ""}
          >
            {props.title ? props.title : "."}
          </Typography>
        </Grid>
        <Grid item className="flex-grow-0 pe-2">
          {props.buttons}
        </Grid>
      </Grid>
      <span id="scroll-top" className="pt-55px" />
      <div className="px-2 pt-2">{props.children}</div>
      <span id="scroll-bottom" />
    </Drawer>
  ) : (
    <Grid
      item
      className="flex-grow-1 overflow-hidden"
      xs={12}
      sm={12}
      md={12}
      lg={9}
      xl={7}
    >
      <Grid
        container
        id={props.id ? props.id : "ContentPanel"}
        direction="column"
        wrap="nowrap"
        className="vh-100 w-100 px-2 overflow-auto"
      >
        {(props.title || !isSM) && <Toolbar id="scroll-top-app" />}
        <Grid item className="flex-grow-0">
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            className="fix-width-100pct"
          >
            <Grid item className="flex-grow-1 overflow-hidden">
              <Typography
                variant="h2"
                className={
                  !props.title || props.title === "."
                    ? "my-2 opacity-0"
                    : "my-2"
                }
              >
                {props.title ? props.title : "."}
              </Typography>
            </Grid>
            <Grid item className="flex-grow-0">
              {props.buttons}
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="flex-grow-1 rounded">
          {props.isLoading && (
            <LinearProgress
              color="secondary"
              className="rounded lin-prog-margin-4px"
            />
          )}
          {props.children}
        </Grid>
      </Grid>
    </Grid>
  );
};
// ########################################
// EXPORT
export default ContentPanel;
