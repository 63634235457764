// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Component elements
import SurveysEditOptions from "./Surveys_EditOptions";
import SurveysEditLogic from "./Surveys_EditLogic";
// -------------------------------------------------
// Helper functions
import {
  defaultOptions,
  defaultRow,
  defaultRowOptions,
  defaultRowValidation,
} from "../../supportFunc/defaultSurveys";
import { array2string } from "../../supportFunc/array2string";
// -------------------------------------------------
// Basic elements
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Add from "@mui/icons-material/Add";
import Edit from "@mui/icons-material/Edit";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/icons-material/Link";
import RuleIcon from "@mui/icons-material/Rule";
import SurveysEditValidation from "./Surveys_EditValidation";
// =================================================
// FUNCTIONAL COMPONENT
const SurveyEditArticleRows = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditArticleRows",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  const canBeRequired =
    props.type === "radio-grid" ||
    props.type === "dropdown-menu-list" ||
    props.type === "dropdown-menu-grid" ||
    props.type === "textbox-list" ||
    props.type === "number-list" ||
    props.type === "textbox-grid" ||
    props.type === "number-grid";
  const hasURL = props.type === "image-bento-box";
  const hasText = props.type === "image-bento-box";
  const hasFormLabel = props.type !== "image-bento-box";
  const hasInputLabel =
    props.type === "dropdown-menu-list" ||
    props.type === "textbox-list" ||
    props.type === "number-list" ||
    props.type === "textbox-grid" ||
    props.type === "number-grid";
  const canBeEncrypted =
    props.type === "textbox-list" || props.type === "textbox-grid";
  const canBeEdited = props.type !== "slider-horizontal";
  const canBeReverseValued =
    props.type === "radio-grid" ||
    props.type === "dropdown-menu-list" ||
    props.type === "dropdown-menu-grid";
  const hasOptions =
    props.type === "textbox-list" ||
    props.type === "number-list" ||
    props.type === "textbox-grid" ||
    props.type === "number-grid" ||
    props.type === "image-bento-box";
  const hasDefaultValue = props.type === "slider-horizontal";
  const hasValidation =
    props.type === "textbox-list" ||
    props.type === "number-list" ||
    props.type === "textbox-grid" ||
    props.type === "number-grid";
  // -----------------------------------------------
  // Local state
  const [currentType, setCurrentType] = useState(null);
  const [tabIdx, setTabIdx] = useState(0);
  const [editItemIdx, setEditItemIdx] = useState(null);
  const [options, setOptions] = useState(null);
  const [validation, setValidation] = useState(null);
  const [logic, setLogic] = useState(null);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Whenever the value of any of the keys change, update the parent object
  useEffect(() => {
    // If the type has changed, then close the edit window
    if (currentType !== props.type) {
      setCurrentType(props.type);
      setEditItemIdx(null);
      return;
    }
    // Do return when the component initially renders
    if (editItemIdx == null) {
      return;
    }
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[editItemIdx] = {
        ...newState[editItemIdx],
        options,
        validation,
        logic,
      };
      return newState;
    });
  }, [props.type, options, validation, logic]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (idx, key, value) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[idx][key] = value;
      return newState;
    });
  };
  const handleAddItem = () => {
    props.setObj((prevState) => {
      let newState = [
        ...prevState,
        {
          ...defaultRow,
          validation: hasValidation
            ? defaultRowValidation[props.type].map((val) => {
                return {
                  type: val.type,
                  test: val.default,
                  errorMsg: val.errorMsg,
                };
              })
            : null,
          options: defaultRowOptions[props.type],
          _id: uuid(),
        },
      ];
      return newState;
    });
  };
  const handleMoveItem = (idx, direction) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let element = newState[idx];
      newState.splice(idx, 1);
      newState.splice(idx + direction, 0, element);
      return newState;
    });
  };
  const handleDeleteItem = (idx) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.splice(idx, 1);
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAddItem();
        }}
      >
        <Button type="submit" className="d-none" />
        <Grid container alignItems="center">
          <Grid item xs={12} className="mb-2">
            <Typography variant="h4">
              {props.type === "image-bento-box" ? t("Images") : t("Row items")}
            </Typography>
          </Grid>
          <Grid item xs={5} className="mb-2">
            <Typography variant="caption">
              {hasDefaultValue ? "Default value" : hasURL ? "URL" : "Label"}
            </Typography>
          </Grid>
          {canBeReverseValued && (
            <Grid item xs={1} className="mb-2">
              <Typography variant="caption" className="d-block rotate-90deg">
                {t("Reverse")}
              </Typography>
            </Grid>
          )}
          <Grid item xs={6} className="mb-2">
            {canBeRequired && !props.required && (
              <Typography variant="caption">{t("Required")}</Typography>
            )}
          </Grid>
          {props.obj.map((o, i) => (
            <React.Fragment key={`row-${i}`}>
              <Grid item xs={5} className="mb-2 pe-2">
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  type="text"
                  name={hasURL ? "url" : "text"}
                  variant="filled"
                  value={hasURL && o.url ? o.url : o.text ? o.text : ""}
                  color="secondary"
                  className="answer-text-green"
                  onChange={(e) =>
                    // Must be a String to enable the tranlate toolbox
                    handleSetObj(i, e.target.name, e.target.value)
                  }
                />
              </Grid>
              {canBeReverseValued && (
                <Grid item xs={1} className="mb-2">
                  <Switch
                    size="small"
                    color="secondary"
                    checked={o.reverseValue}
                    onChange={(e) =>
                      handleSetObj(i, "reverseValue", e.target.checked)
                    }
                  />
                </Grid>
              )}
              <Grid item xs={6} className="mb-2">
                {canBeRequired && !props.required && (
                  <Switch
                    size="small"
                    color="secondary"
                    checked={o.required}
                    onChange={(e) =>
                      handleSetObj(i, "required", e.target.checked)
                    }
                  />
                )}
                {canBeEdited && (
                  <Tooltip arrow title={t("Options")} placement="left">
                    <IconButton
                      onClick={(e) => {
                        if (editItemIdx === i) {
                          setEditItemIdx(null);
                          setOptions(null);
                          setValidation(null);
                          setLogic(null);
                        } else {
                          setEditItemIdx(i);
                          setOptions(o.options);
                          setValidation(o.validation);
                          setLogic(o.logic);
                          setTabIdx(0);
                        }
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                )}
                {props.obj.length > 1 && canBeEdited && (
                  <span>
                    <IconButton
                      disabled={i === 0}
                      onClick={(e) => handleMoveItem(i, -1)}
                    >
                      <ArrowUpward />
                    </IconButton>
                  </span>
                )}
                {props.obj.length > 1 && canBeEdited && (
                  <span>
                    <IconButton
                      disabled={i === props.obj.length - 1}
                      onClick={(e) => handleMoveItem(i, 1)}
                    >
                      <ArrowDownward />
                    </IconButton>
                  </span>
                )}
                {props.obj.length > 1 && (
                  <Tooltip
                    arrow
                    title={
                      (props.dependencies &&
                        Object.keys(props.dependencies).some((key) =>
                          key.includes(`${props.articleId}_${o._id}`)
                        )) ||
                      (props.scoring &&
                        props.scoring.some(
                          (score) =>
                            (score.arithmetic &&
                              score.arithmetic.some((a) =>
                                a.aliasId.includes(o._id)
                              )) ||
                            (score.grouping &&
                              score.grouping.aliasId.includes(o._id))
                        ))
                        ? t("Can't delete")
                        : t("Delete")
                    }
                    placement="top"
                  >
                    <span>
                      <IconButton
                        disabled={
                          (props.dependencies &&
                            Object.keys(props.dependencies).some((key) =>
                              key.includes(`${props.articleId}_${o._id}`)
                            )) ||
                          (props.scoring &&
                            props.scoring.some(
                              (score) =>
                                (score.arithmetic &&
                                  score.arithmetic.some((a) =>
                                    a.aliasId.includes(o._id)
                                  )) ||
                                (score.grouping &&
                                  score.grouping.aliasId.includes(o._id))
                            ))
                        }
                        onClick={(e) => {
                          handleDeleteItem(i);
                          if (editItemIdx === i) {
                            setEditItemIdx(null);
                            setOptions(null);
                            setValidation(null);
                            setLogic(null);
                            setTabIdx(0);
                          }
                        }}
                      >
                        <DeleteForever />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {o.logic && (
                  <Tooltip
                    arrow
                    title={`conditional on ${array2string(
                      o.logic.map((logicGroup) =>
                        logicGroup.itemList.map(
                          (logicItem) =>
                            props.aliasList[logicItem.condition.aliasId].alias
                        )
                      )
                    )}`}
                    placement="top"
                    className="me-2"
                  >
                    <Link color="info" />
                  </Tooltip>
                )}
                {props.dependencies &&
                  Object.keys(props.dependencies).some((key) =>
                    key.includes(`${props.articleId}_${o._id}`)
                  ) && (
                    <Tooltip
                      arrow
                      title={t("dependent")}
                      placement="top"
                      className="me-2"
                    >
                      <Link color="warning" />
                    </Tooltip>
                  )}
                {props.scoring &&
                  props.scoring.some(
                    (score) =>
                      (score.arithmetic &&
                        score.arithmetic.some((a) =>
                          a.aliasId.includes(o._id)
                        )) ||
                      (score.grouping && score.grouping.aliasId.includes(o._id))
                  ) && (
                    <Tooltip arrow title="scored" placement="top">
                      <Link color="success" />
                    </Tooltip>
                  )}
                {o.validation && o.validation.some((val) => val.test) && (
                  <Tooltip
                    arrow
                    title={
                      <span>
                        {o.validation.map((val) => (
                          <span
                            key={val}
                            className="d-block"
                          >{`${val.errorMsg} `}</span>
                        ))}
                      </span>
                    }
                    placement="top"
                  >
                    <RuleIcon color="inherit" />
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={12}>
                <Collapse in={editItemIdx === i}>
                  <Paper className="bg-light border border-secondary shadow-none mb-2">
                    <Tabs
                      variant="fullWidth"
                      value={!hasOptions && tabIdx > 2 ? setTabIdx(0) : tabIdx}
                      className="bg-white border-bottom border-light"
                      onChange={(e, newTabIndex) => {
                        setTabIdx(newTabIndex);
                      }}
                    >
                      <Tab label={t("Item")} id={`tab_item-${i}_settings`} />
                      <Tab
                        label={
                          o.logic ? (
                            <Badge color="info" variant="dot">
                              {t("Logic")}&nbsp;&nbsp;
                            </Badge>
                          ) : (
                            t("Logic")
                          )
                        }
                        id={`tab_item-${i}_logic`}
                      />
                      <Tab
                        disabled
                        label={t("Validation")}
                        id={`tab_item-${i}_validation`}
                      />
                      {hasOptions && (
                        <Tab
                          label={t("Options")}
                          id={`tab_item-${i}_options`}
                        />
                      )}
                    </Tabs>
                    <Collapse in={tabIdx === 0}>
                      <Paper className="bg-light p-3">
                        {hasText && (
                          <TextField
                            fullWidth
                            label={t("Caption")}
                            size="small"
                            type="text"
                            name="text"
                            variant="filled"
                            value={o.text ? o.text : ""}
                            color="secondary"
                            className="answer-text-green"
                            onChange={(e) =>
                              // Must be a String to enable the tranlate toolbox
                              handleSetObj(i, e.target.name, e.target.value)
                            }
                          />
                        )}
                        {canBeEncrypted && (
                          <FormControlLabel
                            control={
                              <Switch
                                color="secondary"
                                checked={o.encrypt === true ? true : false}
                                onChange={(e) =>
                                  handleSetObj(i, "encrypt", e.target.checked)
                                }
                              />
                            }
                            label={
                              o.encrypt === true
                                ? "Answer encryped"
                                : "Answer not encryped"
                            }
                            className="mb-1"
                          />
                        )}
                        {hasFormLabel && (
                          <TextField
                            fullWidth
                            label={t("Form label")}
                            size="small"
                            type="text"
                            name="formLabel"
                            variant="filled"
                            value={o.formLabel ? o.formLabel : ""}
                            color="secondary"
                            className="answer-text-green"
                            onChange={(e) =>
                              e.target.value === ""
                                ? handleSetObj(i, e.target.name, null)
                                : handleSetObj(i, e.target.name, e.target.value)
                            }
                          />
                        )}
                        {hasInputLabel && (
                          <TextField
                            fullWidth
                            label={t("Placeholder input label")}
                            size="small"
                            type="text"
                            name="inputLabel"
                            variant="filled"
                            value={o.inputLabel ? o.inputLabel : ""}
                            color="secondary"
                            className="answer-text-green mt-3"
                            onChange={(e) =>
                              e.target.value === ""
                                ? handleSetObj(i, e.target.name, null)
                                : handleSetObj(i, e.target.name, e.target.value)
                            }
                          />
                        )}
                      </Paper>
                    </Collapse>
                    <Collapse in={tabIdx === 1}>
                      <Paper className="bg-light p-3">
                        <SurveysEditLogic
                          obj={logic}
                          setObj={setLogic}
                          aliasList={
                            props.aliasList &&
                            Object.keys(props.aliasList)
                              .filter(
                                (key) =>
                                  props.aliasList[key].articleId !==
                                  props.articleId
                              )
                              .reduce((obj, key) => {
                                obj[key] = props.aliasList[key];
                                return obj;
                              }, {})
                          }
                        />
                      </Paper>
                    </Collapse>
                    {hasValidation && (
                      <Collapse in={tabIdx === 2}>
                        <Paper className="bg-light p-3">
                          <SurveysEditValidation
                            articleType={props.type}
                            obj={validation}
                            setObj={setValidation}
                          />
                        </Paper>
                      </Collapse>
                    )}
                    {hasOptions && (
                      <Collapse in={tabIdx === 3}>
                        <Paper className="bg-light p-3">
                          <SurveysEditOptions
                            obj={options}
                            setObj={setOptions}
                            options={
                              props.type === "textbox-list" ||
                              props.type === "number-list" ||
                              props.type === "textbox-grid" ||
                              props.type === "number-grid"
                                ? defaultOptions["textbox"]
                                : props.type === "image-bento-box"
                                ? defaultOptions["image-bento-box-row"]
                                : null
                            }
                          />
                        </Paper>
                      </Collapse>
                    )}
                  </Paper>
                </Collapse>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Divider>
              <ButtonGroup>
                <Button
                  variant="outlined"
                  size="small"
                  color="inherit"
                  startIcon={<Add />}
                  className="border-dashed"
                  onClick={() => handleAddItem()}
                >
                  {t("Add item")}
                </Button>
              </ButtonGroup>
            </Divider>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveyEditArticleRows;
