// =================================================
// IMPORT
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/notifications`;
// -------------------------------------------------
// Use 'createEntityAdapter' to store this slice in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  errorMsg: null,
});
// =================================================
// ASYNC API ACTIONS
// -------------------------------------------------
// API fetch all supervision documents by the user id
export const fetchNotificationListByUserId = createAsyncThunk(
  "notifications/fetchNotificationListByUserId",
  async ({ requestingUser, userId }) => {
    const response = await client.get(
      `${apiurl}/userId/${userId}`,
      requestingUser
    );
    return response.data;
  }
);
// -------------------------------------------------
// API patch a notification object
export const patchCurrentNotification = createAsyncThunk(
  "notifications/patchCurrentNotification",
  async ({ requestingUser, body }) => {
    // Make the call to the database
    const response = await client.patch(
      `${apiurl}/${body.data._id}`,
      requestingUser,
      body
    );
    // Return the response
    return response.data;
  }
);
// -------------------------------------------------
// API post a set of notifications
export const postNotificationList = createAsyncThunk(
  "notifications/postNotificationList",
  async ({ socket, requestingUser, body }) => {
    // Make the call to the database
    const response = await client.post(apiurl, requestingUser, body);
    // Invoke event on server
    socket &&
      socket.emit("posted-notification-list", {
        notificationList: response.data.notificationList,
      });
    // Return the response
    return response.data;
  }
);
// -------------------------------------------------
// API delete a study object
export const deleteCurrentNotification = createAsyncThunk(
  "notifications/deleteCurrentNotification",
  async ({ requestingUser, notificationId }) => {
    // Make the call to the database
    const response = await client.delete(
      `${apiurl}/${notificationId}`,
      requestingUser
    );
    // Return the response
    return response.data;
  }
);
// =================================================
// DEFINE MUTATING ACTIONS
export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    resetNotificationError(state) {
      if (state.status === "failed") {
        state.status = "idle";
        state.errorMsg = null;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNotificationListByUserId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotificationListByUserId.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.notificationList &&
          adapter.upsertMany(state, action.payload.notificationList);
      })
      .addCase(fetchNotificationListByUserId.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(patchCurrentNotification.pending, (state) => {
        state.status = "loading";
      })
      .addCase(patchCurrentNotification.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.notification &&
          adapter.upsertOne(state, action.payload.notification);
      })
      .addCase(patchCurrentNotification.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(deleteCurrentNotification.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCurrentNotification.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.notificationId &&
          adapter.removeOne(state, action.payload.notificationId);
      })
      .addCase(deleteCurrentNotification.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const { resetNotificationError } = notificationsSlice.actions;
// =================================================
// SELECTOR FUNCTIONS
// -------------------------------------------------
export const notificationsSelectors = adapter.getSelectors(
  (state) => state.notifications
);
// =================================================
// EXPORT DEFAULT
export default notificationsSlice.reducer;
