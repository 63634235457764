// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Redux
import { studiesSelectors } from "../redux/reducers/studies";
import { consumersSelectors } from "../redux/reducers/consumers";
import { taskResponsesSelectors } from "../redux/reducers/taskResponses";
// -------------------------------------------------
// Components
import AppListItem from "../components/App_ListItem";
// -------------------------------------------------
// Basic elements
import List from "@mui/material/List";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// -------------------------------------------------
// Icons
import Star from "@mui/icons-material/Star";
import Wifi from "@mui/icons-material/Wifi";
import Tooltip from "@mui/material/Tooltip";
// =================================================
// FUNCTIONAL COMPONENT
const StudiesColumnContent = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "Studies_ColumnContent",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const defaultStudyId = useSelector((state) =>
    state.studies.defaultStudy ? state.studies.defaultStudy._id : null
  );
  const studyList = useSelector((state) =>
    studiesSelectors.selectAll(state)
  ).sort((a, b) => (a._id === defaultStudyId ? -1 : 1));
  const consumerList = useSelector((state) =>
    consumersSelectors.selectAll(state)
  );
  const taskResponseList = useSelector((state) =>
    taskResponsesSelectors.selectAll(state)
  );
  // -----------------------------------------------
  // For navigating
  const navigate = useNavigate();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the URL search query to the study identifier
  const handleSetCurrentStudy = (id) => {
    let el;
    el = document.querySelector("#scroll-top-app");
    if (el) {
      el.scrollIntoView({ block: "end" });
    }
    navigate(`/studies?studyId=${id}`, { replace: true });
  };
  // calculate the number of enrolled volunteers
  const getEnrolledVolunteers = (studyId) => {
    return consumerList
      .filter((consumer) => consumer.deletedOn === null)
      .filter((consumer) => consumer.primaryRole === "participant")
      .filter(
        (consumer) =>
          consumer.studyEnrollmentList.length > 0 &&
          consumer.studyEnrollmentList[0].studyId === studyId
      );
  };
  // calculate the number of responses
  const getStudyResponses = (studyId) => {
    return taskResponseList.filter(
      (response) => response.data.studyId === studyId
    );
  };
  const getStudyUserNameOrUserNumber = (consumerId) => {
    return consumerId &&
      consumerList.find((consumer) => consumer._id === consumerId) &&
      consumerList.find((consumer) => consumer._id === consumerId).name
      ? consumerList.find((consumer) => consumer._id === consumerId).name
      : consumerList.find((consumer) => consumer._id === consumerId)
      ? consumerList.find((consumer) => consumer._id === consumerId).email
      : "";
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <List disablePadding>
      {studyList.length === 0 ? (
        <Fade in={true}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className="my-3 text-center">
              <img
                src="images/icon-train-end.png"
                alt="No studies"
                className="w-50"
              />
            </Grid>
            <Grid item>
              <Typography variant="overline">{t("No studies")}</Typography>
            </Grid>
          </Grid>
        </Fade>
      ) : (
        studyList.map((study) => (
          <AppListItem
            key={study._id}
            divider
            slideIn
            isSelected={study._id === props.currentStudyId}
            onClick={() => {
              handleSetCurrentStudy(study._id);
            }}
            icon={
              study._id === defaultStudyId && (
                <Star fontSize="small" className="text-warning" />
              )
            }
            textPrimary={study.name}
            textSecondary={
              <>
                {(getEnrolledVolunteers(study._id).length > 0 ||
                  getStudyResponses(study._id).length > 0) && (
                  <Tooltip arrow title={t("live")} placement="top">
                    <Wifi color="primary" className="font-size-085rem me-1" />
                  </Tooltip>
                )}
                {getStudyUserNameOrUserNumber(study.userIdList[0]) &&
                  getStudyUserNameOrUserNumber(study.userIdList[0])}
              </>
            }
          />
        ))
      )}
    </List>
  );
};
// =================================================
// EXPORT COMPONENT
export default StudiesColumnContent;
