// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// -------------------------------------------------
// Component elements
import AppDrawerPlaceholder from "./App_DrawerPlaceholder";
import AppListItem from "../components/App_ListItem";
import InvestigatorsContentInformation from "./volunteers/Volunteers_ContentInformation";
// -------------------------------------------------
// Redux
import { studiesSelectors } from "../redux/reducers/studies";
import { supervisionSelectors } from "../redux/reducers/supervision";
import { consumersSelectors } from "../redux/reducers/consumers";
// -------------------------------------------------
// Basic elements
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import Icon from "@mui/material/Icon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const InvestigatorsContent = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "Investigators_Content",
  });
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Router
  const navigate = useNavigate();
  // -----------------------------------------------
  // Local state
  const [roleChanged, setRoleChanged] = useState(false);
  const [isOpenSupervisorMenu, setIsOpenSupervisorMenu] = useState({});
  const [obj, setObj] = useState(props.currentConsumer);
  const [filter, setFilter] = useState("");
  // -----------------------------------------------
  // Redux
  const consumersStatus = useSelector((state) => state.consumers.status);
  const consumerList = useSelector((state) =>
    consumersSelectors.selectAll(state)
  );
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state)
  );
  const currentUser = useSelector((state) => state.user.currentUser);
  const studyList = useSelector((state) => studiesSelectors.selectAll(state));
  const supervisionList = useSelector((state) =>
    supervisionSelectors.selectAll(state)
  );
  // ===============================================
  // FUCNTIONS
  // -----------------------------------------------
  // Re-initialize the local object when consumer role changes
  useEffect(() => {
    setObj(props.currentConsumer);
    setIsOpenSupervisorMenu({});
    setRoleChanged(false);
    setFilter("");
  }, [props.currentConsumer]);
  // -----------------------------------------------
  const getSupervisionNum = (studyId) => {
    return supervisionList
      .filter((supervision) => supervision.studyId === studyId)
      .filter((supervision) => supervision.userId === props.currentConsumer._id)
      .map((supervision) =>
        supervision.isAllParticipants
          ? consumerList.filter(
              (user) =>
                user.primaryRole === "participant" &&
                user.studyEnrollmentList &&
                user.studyEnrollmentList[0] &&
                user.studyEnrollmentList[0].studyId === studyId
            ).length
          : supervision.userIdList.length
      );
  };
  // -----------------------------------------------
  // Changes the local state
  const handleSetObj = (value) => {
    setObj((prevState) => {
      setRoleChanged(true);
      return {
        ...prevState,
        primaryRole: value,
      };
    });
  };
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return !props.currentConsumer || !obj ? (
    <AppDrawerPlaceholder
      imageURL="images/icon-choose.png"
      imageAlt={t("Choose an investigator from the list")}
      title={t("Choose an investigator from the list")}
    />
  ) : (
    <div className="mb-2">
      <InvestigatorsContentInformation
        currentConsumer={props.currentConsumer}
      />
      {((currentUser && currentUser.primaryRole === "superuser") ||
        (currentUser &&
          currentUser.primaryRole === "admin" &&
          obj.primaryRole !== "superuser")) && (
        <Card className="mb-3 px-3 pt-3">
          <Typography variant="h3">{t("Change role")}</Typography>
          <FormControl
            fullWidth
            component="fieldset"
            variant="filled"
            className="my-3"
          >
            <InputLabel id="new-role-label" color="secondary">
              Role
            </InputLabel>
            <Select
              variant="filled"
              name="new-role"
              value={obj ? obj.primaryRole : "participant"}
              label={t("Role")}
              labelId="new-role-label"
              color="secondary"
              onChange={(e) => handleSetObj(e.target.value)}
              className="answer-text-green"
            >
              {currentUser.primaryRole === "superuser" && (
                <MenuItem value="superuser">
                  <Chip label="Superuser" className="me-2" />
                </MenuItem>
              )}
              <MenuItem value="admin">
                <Chip label="admin" className="me-2" />
              </MenuItem>
              <MenuItem value="participant">
                <Chip label="participant" className="me-2" />
                <Typography variant="caption">
                  Revoke all administrator rights
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
          {/* TODO make a diagram instead of text */}
          <Alert severity="info" className="mb-3">
            {obj.primaryRole === "superuser" ? (
              <Typography variant="caption">
                TODO: explain the super user rights.
              </Typography>
            ) : obj.primaryRole === "admin" ? (
              <Typography variant="caption">
                TODO: explain the admin rights.
              </Typography>
            ) : obj.primaryRole === "participant" ? (
              <Typography variant="caption">
                TODO: explain the participant rights.
              </Typography>
            ) : null}
          </Alert>
          <Button
            disabled={!roleChanged || consumersStatus === "loading"}
            className="mb-3"
            type="button"
            variant="contained"
            color={roleChanged ? "primary" : "secondary"}
            onClick={() => {
              props.handlePatchCurrentConsumer(
                props.currentConsumer._id,
                obj.primaryRole
              );
              setRoleChanged(false);
            }}
          >
            {consumersStatus === "loading" ? (
              <CircularProgress size="1.5rem" className="text-light" />
            ) : (
              t("Save")
            )}
          </Button>
        </Card>
      )}
      {currentUser._id !== props.currentConsumer._id &&
        studyList.length > 0 && (
          <Card className="mb-3 px-3 pt-3">
            <Typography variant="h3" className="mb-3">
              {t("Shared studies")}
            </Typography>
            {studyList.filter((study) =>
              study.userIdList.includes(props.currentConsumer._id)
            ).length === 0 ? (
              <Typography
                variant="body2"
                color="textSecondary"
                className="mb-3"
              >
                {t("No shared studies")}
              </Typography>
            ) : (
              studyList
                .filter((study) =>
                  study.userIdList.includes(props.currentConsumer._id)
                )
                .map((study) => (
                  <Chip
                    key={study._id}
                    label={study.acronym}
                    className="me-2 mb-3"
                    onClick={() =>
                      navigate(`/studies?studyId=${study._id}`, {
                        replace: false,
                      })
                    }
                  />
                ))
            )}
          </Card>
        )}
      <Card className="mb-3 p-0">
        <Grid container alignItems="center" className="pe-2">
          <Grid item className="flex-grow-1">
            <Typography variant="h3" className="mb-3 px-3 pt-3">
              Supervisor of
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              size="small"
              name="Filter"
              type="text"
              label="Filter"
              value={filter}
              color="secondary"
              variant="filled"
              sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
              onChange={(e) => {
                studyList.forEach((study) =>
                  setIsOpenSupervisorMenu((prevState) => {
                    return {
                      ...prevState,
                      [study._id]: true,
                    };
                  })
                );
                setFilter(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        {studyList.filter((study) =>
          study.userIdList.some(
            (userId) => userId === props.currentConsumer._id
          )
        ).length > 0 ? (
          studyList
            .filter((study) =>
              study.userIdList.some(
                (userId) => userId === props.currentConsumer._id
              )
            )
            .map((study) => (
              <React.Fragment key={study._id}>
                <AppListItem
                  slideIn
                  icon={
                    <Badge
                      badgeContent={getSupervisionNum(study._id)}
                      className="bg-light"
                    />
                  }
                  iconEnd={
                    isOpenSupervisorMenu[study._id] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  }
                  key={study._id}
                  textPrimary={study.name}
                  className="bg-light px-4"
                  onClick={() =>
                    setIsOpenSupervisorMenu((prevState) => {
                      return {
                        ...prevState,
                        [study._id]: prevState[study._id] ? false : true,
                      };
                    })
                  }
                />
                {supervisionList
                  .filter((supervision) => supervision.studyId === study._id)
                  .filter(
                    (supervision) =>
                      supervision.userId === props.currentConsumer._id
                  )
                  .map((supervision) =>
                    supervision.isAllParticipants
                      ? consumerList
                          .filter(
                            (user) =>
                              user.email.includes(filter) ||
                              (user.name && user.name.includes(filter))
                          )
                          .filter((user) => user.primaryRole === "participant")
                          .filter(
                            (user) =>
                              user.studyEnrollmentList &&
                              user.studyEnrollmentList[0] &&
                              user.studyEnrollmentList[0].studyId === study._id
                          )
                          .map((user) => (
                            <Collapse
                              key={user._id}
                              in={isOpenSupervisorMenu[study._id]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <AppListItem
                                slideIn={isOpenSupervisorMenu[study._id]}
                                icon={<Icon>person</Icon>}
                                textSecondary={user.email}
                                className="px-3"
                                onClick={() =>
                                  navigate(
                                    `/volunteers?studyId=${study._id}&consumerId=${user._id}`,
                                    {
                                      replace: false,
                                    }
                                  )
                                }
                              />
                            </Collapse>
                          ))
                      : supervision.userIdList.map((userId) => (
                          <Collapse
                            key={userId}
                            in={isOpenSupervisorMenu[study._id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <AppListItem
                              slideIn
                              icon={<Icon>person</Icon>}
                              textSecondary={consumerEntities[userId].email}
                              className="px-3"
                              onClick={() =>
                                navigate(
                                  `/volunteers?studyId=${study._id}&consumerId=${userId}`,
                                  {
                                    replace: false,
                                  }
                                )
                              }
                            />
                          </Collapse>
                        ))
                  )}
              </React.Fragment>
            ))
        ) : (
          <Typography
            variant="body2"
            color="textSecondary"
            className="mt-2 px-3 pb-2"
          >
            Investigator is not assigned to a study
          </Typography>
        )}
      </Card>
    </div>
  );
};
// =================================================
// EXPORT COMPONENT
export default InvestigatorsContent;
