// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Components
import AppRoot from "../components/App_Root";
import ScrollColumn from "../components/App_ScrollColumn";
import ContentPanel from "../components/App_ContentPanel";
import TopDrawer from "../components/App_TopDrawer";
import MessagesColumnContent from "../components/Messages_ColumnContent";
import MessagesHeader from "../components/Messages_Header";
import MessagesContent from "../components/Messages_Content";
import MessagesEditConversation from "../components/messages/Messages_EditConversation";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../redux/reducers/ui";
import {
  conversationsSelectors,
  fetchConversationList,
  patchCurrentConversation,
  deleteCurrentConversation,
} from "../redux/reducers/conversations";
import {
  selectMessageListByConversationId,
  fetchMessageListByRecipient,
  postNewMessage,
  patchCurrentMessage,
} from "../redux/reducers/messages";
import { togglePrimDrawer } from "../redux/reducers/ui";
import {
  fetchConsumerList,
  fetchConsumerById,
  consumersSelectors,
} from "../redux/reducers/consumers";
import { fetchStudyList, studiesSelectors } from "../redux/reducers/studies";
import { supervisionSelectors } from "../redux/reducers/supervision";
// -------------------------------------------------
// Helper functions
import { unique } from "../supportFunc/unique";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
// -------------------------------------------------
// Icons
import AddCircle from "@mui/icons-material/AddCircle";
import School from "@mui/icons-material/School";
// =================================================
// FUNCTIONAL COMPONENT
const Messages = () => {
  const { t } = useTranslation("pages", { keyPrefix: "Messages" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Contexts
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Local state
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isNewConversation, setIsNewConversation] = useState(false);
  const [newConversation, setNewConversation] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const primDrawerIsOpen = useSelector((state) => state.ui.primDrawerIsOpen);
  const currentUser = useSelector((state) => state.user.currentUser);
  const currentConversation = useSelector((state) =>
    conversationsSelectors.selectById(state, conversationId)
  );
  const currentMessageList = useSelector((state) =>
    selectMessageListByConversationId(
      state,
      currentConversation ? currentConversation._id : null
    )
  );
  const recipientList = useSelector((state) => state.messages.recipientList);
  const conversationsStatus = useSelector(
    (state) => state.conversations.status
  );
  const studiesStatus = useSelector((state) => state.studies.status);
  const messagesStatus = useSelector((state) => state.messages.status);
  const consumersStatus = useSelector((state) => state.consumers.status);
  const conversationList = useSelector((state) =>
    conversationsSelectors.selectAll(state)
  );
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state)
  );
  const studyList = useSelector((state) => studiesSelectors.selectAll(state));
  const supervisionList = useSelector((state) =>
    supervisionSelectors.selectAll(state)
  );
  // -----------------------------------------------
  // Browser URL location
  const location = useLocation();
  const navigate = useNavigate();
  // ===============================================
  // FUNCTIONS
  const includesParticipant = (userIdList) => {
    return (
      userIdList &&
      userIdList.some(
        (uId) => consumerEntities[uId].primaryRole === "participant"
      )
    );
  };
  // -----------------------------------------------
  // Upon render, get the conversations list and set the redux state
  useEffect(() => {
    // Fetch all studies from DB if not done yet (admins only)
    if (
      currentUser &&
      currentUser.primaryRole !== "participant" &&
      studiesStatus === "idle"
    ) {
      dispatch(fetchStudyList({ requestingUser: currentAuth }));
    }
    // Fetch all conversations that the requesting user is owner of
    if (currentUser && conversationsStatus === "idle") {
      dispatch(
        fetchConversationList({
          requestingUser: currentAuth,
          userId: currentUser._id,
        })
      );
    }
    // Fetch all messages the current user is recipient of
    if (currentUser && messagesStatus === "idle") {
      dispatch(
        fetchMessageListByRecipient({
          requestingUser: currentAuth,
          recipientId: currentUser._id,
        })
      );
    }
    // Fetch all consumers from DB if not done yet
    if (conversationsStatus === "succeeded" && consumersStatus === "idle") {
      if (
        currentUser &&
        (currentUser.primaryRole === "superuser" ||
          currentUser.primaryRole === "admin")
      ) {
        dispatch(fetchConsumerList({ requestingUser: currentAuth }));
      } else {
        let consumerIds = [];
        conversationList.forEach(
          (conversation) =>
            (consumerIds = [...consumerIds, ...conversation.userIdList])
        );
        consumerIds = unique(consumerIds);
        consumerIds.forEach((consumerId) =>
          dispatch(
            fetchConsumerById({
              requestingUser: currentAuth,
              userId: consumerId,
            })
          )
        );
      }
    }
    // Get the study-id from URL and get the current study
    if (conversationsStatus === "succeeded") {
      const searchParams = new URLSearchParams(location.search);
      setConversationId(searchParams.get("conversationId"));
      if (searchParams.get("conversationId") && !primDrawerIsOpen) {
        dispatch(togglePrimDrawer({ isOpen: true }));
      } else if (primDrawerIsOpen) {
        dispatch(togglePrimDrawer({ isOpen: false }));
      }
    }
  }, [conversationsStatus, consumersStatus, location.search]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Once the messages of a conversation are rendered, set the read boolean to true
  useEffect(() => {
    if (currentMessageList.length > 0) {
      handleSetConversationMessageListRead();
    }
  }, [currentConversation]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Adds new study to the database and sets the current study to this new study
  const handleAddNewConversation = (studyId) => {
    const supervisors = supervisionList.filter(
      (s) => s.isAllParticipants && s.studyId === studyId
    );
    const userIdList = unique([
      ...supervisors.map((s) => s.userId),
      currentUser._id,
    ]);
    const newId = uuid();
    setIsNewConversation(true);
    setConversationId(newId);
    setNewConversation({
      _id: newId,
      userIdList: userIdList,
      studyId,
    });
    dispatch(
      toggleSecDrawer({ isOpen: true, id: "messages_new-conversation" })
    );
    navigate("/messages", { replace: true });
  };
  // -----------------------------------------------
  // Construct the note message for when a user is added or removed
  const getAddedRemovedMsg = (userId, operation) => {
    return `${
      consumerEntities[currentUser._id] &&
      consumerEntities[currentUser._id].name
        ? consumerEntities[currentUser._id].name
        : consumerEntities[currentUser._id]
        ? consumerEntities[currentUser._id].userNumber
        : "John Doe"
    } ${operation} ${
      consumerEntities[userId] && consumerEntities[userId].name
        ? consumerEntities[userId].name
        : consumerEntities[userId]
        ? consumerEntities[userId].userNumber
        : "John Doe"
    }`;
  };
  // -----------------------------------------------
  // Posts a new note messagae to the database
  const handlePostNewNoteMessage = (userId, operation) => {
    // Patch the messages to the database
    dispatch(
      postNewMessage({
        socket,
        requestingUser: currentAuth,
        body: {
          data: {
            userId: currentUser._id,
            conversationId: newConversation._id,
            isNote: true,
            message: getAddedRemovedMsg(userId, operation),
          },
          meta: {
            userIdList: newConversation.userIdList,
          },
        },
      })
    );
  };
  // -----------------------------------------------
  // Adds new conversation to the database and sets the current conversation
  const handlePatchConversation = async (newConversation) => {
    // Check which group members have been added
    let newRecipientList = currentConversation
      ? // Find the user that exist in the new converation but not in the current one
        newConversation.userIdList.filter(
          (userId) =>
            currentConversation.userIdList.find((id) => id === userId)
              ? false // user is already in the current conversation
              : true // user is newly added
        )
      : newConversation.userIdList; // Current conversation did not exist, just get all users in the current conversation
    // Check which group members have been removed
    let removedRecipientList = currentConversation
      ? // Find the users that exist in the current conversation but not in the new one
        currentConversation.userIdList.filter(
          (userId) =>
            newConversation.userIdList.find((id) => id === userId)
              ? false // userId is still in the new conversation
              : true // userId is no longer in the new conversation
        )
      : [];
    // Patch a message to the database for each new group member
    newRecipientList.forEach((userId) =>
      handlePostNewNoteMessage(userId, "added")
    );
    removedRecipientList.forEach((userId) =>
      handlePostNewNoteMessage(userId, "removed")
    );
    // Patch the conversation object on the database
    const res = await dispatch(
      patchCurrentConversation({
        socket,
        requestingUser: currentAuth,
        body: {
          data: newConversation,
          meta: {
            currentRecipientList: newConversation.userIdList,
            removedRecipientList,
          },
        },
      })
    );
    navigate(`/messages?conversationId=${res.payload.conversation._id}`, {
      replace: true,
    });
  };
  // -----------------------------------------------
  // Sets the read to false for all messages in this conversation
  const handleSetConversationMessageListRead = async () => {
    let newRecipient, recipient;
    for (const msg of currentMessageList) {
      recipient = recipientList.find(
        (r) => r.messageId === msg._id && r.userId === currentUser._id
      );
      if (recipient && !recipient.isRead) {
        newRecipient = JSON.parse(JSON.stringify(recipient));
        newRecipient.isRead = true;
        await dispatch(
          patchCurrentMessage({
            socket,
            requestingUser: currentAuth,
            body: {
              data: newRecipient,
              meta: {
                setIsRead: true,
                userIdList: currentConversation.userIdList,
              },
            },
          })
        );
      }
    }
  };
  // -----------------------------------------------
  // Deletes a conversation from the database
  const handleDeleteConversation = async () => {
    await dispatch(
      deleteCurrentConversation({
        socket,
        requestingUser: currentAuth,
        conversationId: currentConversation._id,
      })
    );
    navigate("/messages", { replace: true });
  };
  // ===============================================
  // SUB-COMPONENTS
  // -----------------------------------------------
  const ColumnHeader =
    currentUser &&
    (currentUser.primaryRole === "superuser" ||
      currentUser.primaryRole === "admin") ? (
      <>
        <Tooltip arrow title={t("Add new conversation")} placement="top">
          <IconButton
            color="inherit"
            onClick={(e) => {
              setMenuIsOpen(true);
              setMenuAnchor(e.currentTarget);
            }}
            //
          >
            <AddCircle />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={menuAnchor}
          open={menuIsOpen}
          onClose={() => {
            setMenuIsOpen(false);
            setMenuAnchor(null);
          }}
        >
          {studyList.map((study) => (
            <MenuItem
              key={study._id}
              onClick={() => {
                handleAddNewConversation(study._id);
                setMenuIsOpen(false);
              }}
            >
              <ListItemIcon>
                <School fontSize="small" />
              </ListItemIcon>
              <ListItemText>{study.acronym}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </>
    ) : null;
  // ===============================================
  // RENDER COMPONENT
  return (
    <AppRoot header={ColumnHeader}>
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE} | {t("Messages")}
        </title>
      </Helmet>
      <TopDrawer
        id="messages_new-conversation"
        title="Message participant"
        buttons={
          <Button
            disabled={
              !includesParticipant(
                newConversation && newConversation.userIdList
              )
            }
            color="inherit"
            startIcon={<AddCircle />}
            className="m-2"
            onClick={() => {
              handlePatchConversation(newConversation);
              dispatch(toggleSecDrawer({ open: false }));
            }}
          >
            {conversationsStatus === "loading" ? (
              <CircularProgress size="1.5rem" className="text-light" />
            ) : (
              "Ok"
            )}
          </Button>
        }
      >
        <MessagesEditConversation
          isNewConversation={isNewConversation}
          obj={newConversation}
          setObj={setNewConversation}
        />
      </TopDrawer>
      <Grid container className="w-100" wrap="nowrap">
        {/* Scroll column */}
        <ScrollColumn
          header={ColumnHeader}
          isLoading={conversationsStatus === "loading"}
        >
          <MessagesColumnContent
            currentConversationId={
              currentConversation && currentConversation._id
            }
          />
        </ScrollColumn>
        {/* Content panel */}
        <ContentPanel
          title={currentConversation && "."}
          isLoading={conversationsStatus === "loading"}
          buttons={
            <MessagesHeader
              currentConversationId={
                currentConversation && currentConversation._id
              }
            />
          }
        >
          <MessagesContent
            currentConversation={currentConversation}
            obj={newConversation}
            setObj={setNewConversation}
            setIsNewConversation={setIsNewConversation}
            handleDeleteConversation={handleDeleteConversation}
            handleSetConversationMessageListRead={
              handleSetConversationMessageListRead
            }
          />
        </ContentPanel>
      </Grid>
    </AppRoot>
  );
};
// =================================================
// EXPORT COMPONENT
export default Messages;
