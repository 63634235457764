// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Redux
import { surveysSelectors } from "../redux/reducers/surveys";
import { ticketsSelectors } from "../redux/reducers/tickets";
// -------------------------------------------------
// Component elements
import AppDrawerPlaceholder from "./App_DrawerPlaceholder";
import FormRoot from "./Form_Root";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const TasksContent = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "Tasks_Content" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const ticketList = useSelector((state) => ticketsSelectors.selectAll(state));
  const currentTicket = useSelector((state) => state.tickets.currentTicket);
  const currentSurvey = useSelector(
    (state) =>
      currentTicket &&
      surveysSelectors.selectById(state, currentTicket.surveyId)
  );
  const apiStatus = useSelector((state) => state.tickets.status);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Scroll to top each time a new survey is loaded
  useEffect(() => {
    // Scroll to top
    const scrollChild = document.getElementById("scroll-child");
    if (scrollChild) {
      scrollChild.scrollTop = 0;
    }
  }, []);
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  const hasTasksToDo = ticketList.some(
    (ticket) =>
      !ticket.isCompleted &&
      DateTime.fromISO(ticket.dateAvailable).diffNow().valueOf() < 0 &&
      DateTime.fromISO(ticket.dateExpire ? ticket.dateExpire : "2199-12-31")
        .diffNow()
        .valueOf() > 0
  );
  return !currentTicket ? (
    <AppDrawerPlaceholder
      imageURL={
        apiStatus === "loading"
          ? "images/icon-wait.gif"
          : !hasTasksToDo
          ? "images/icon-tick-done.png"
          : "images/icon-choose.png"
      }
      imageAlt={
        apiStatus === "loading"
          ? t("Loading, please wait...")
          : !hasTasksToDo
          ? t("All tasks done!")
          : t("Choose a survey from the list")
      }
      title={
        apiStatus === "loading"
          ? t("Loading, please wait...")
          : !hasTasksToDo
          ? t("All tasks done! Thanks.")
          : t("Choose a survey from the list")
      }
    />
  ) : currentSurvey ? (
    <FormRoot />
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default TasksContent;
