// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
// -------------------------------------------------
// Component elements
import ArticleInstructions from "./form/ArticleInstructions";
import ArticleQuestion from "./form/ArticleQuestion";
// =================================================
// FUNCTIONAL COMPONENT
const FormArticleQuestionSet = (props) => {
  // ===============================================
  // RENDER COMPONENT
  // -------------------------------------------------
  return (
    <div>
      {props.article.instructions &&
        props.article.instructions.text &&
        props.article.instructions.position === "top" && (
          <ArticleInstructions
            text={props.article.instructions.text}
            variant={props.article.instructions.variant}
          />
        )}
      {((props.article.title && props.article.title.text) ||
        (props.article.content && props.article.content.text)) && (
        <ArticleQuestion
          text={
            props.article.title == null
              ? null
              : props.article.required &&
                !props.article.formLabel &&
                props.article.type !== "checkboxes" &&
                props.article.type !== "checkboxes-grid"
              ? `${props.article.title.text} *`
              : props.article.title.text
          }
          variant={props.article.title && props.article.title.variant}
          color={props.article.title && props.article.title.color}
          align={props.article.title && props.article.title.align}
          subquestion={props.article.content}
        />
      )}
      {props.article.instructions &&
        props.article.instructions.text &&
        props.article.instructions.position === "bottom" && (
          <ArticleInstructions
            text={props.article.instructions.text}
            variant={props.article.instructions.variant}
          />
        )}
    </div>
  );
};
// =================================================
// EXPORT COMPONENT
export default FormArticleQuestionSet;
