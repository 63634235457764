// ########################################
// IMPORT
// ========================================
// Dependencies
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
  verifyBeforeUpdateEmail,
  updateEmail,
  checkActionCode,
  sendEmailVerification,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode,
  onAuthStateChanged,
  EmailAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { fbAuth } from "../firebase";
import { DateTime } from "luxon";
// ########################################
// FUNCTIONS
// =================================================
// Create function handles for all Firebase functions
// Whenever you want to migrate to another service, change these functions
// ========================================
const auth = {};
// ========================================
// PARSE_ERROR_MESSAGE: returns a readable error message for error codes
auth.parseErrorMessage = (error) => {
  switch (error.code) {
    case "auth/weak-password":
      return "Password should contain at least 6 characters.";
    case "auth/email-already-in-use":
    case "auth/email-already-exists":
      return "This email is already in use. Is it your account perhaps? Did you forget your password?";
    case "auth/missing-email":
    case "auth/missing-new-email":
      return "Please type your email address below.";
    case "auth/invalid-email":
    case "auth/invalid-new-email":
      return "The provided email is not a valid email address.";
    case "auth/user-not-found":
      return "The provided email is not listed in our records.";
    case "auth/user-disabled":
      return "Sorry, but your account has been suspended indefinately.";
    case "auth/invalid-login-credentials":
    case "auth/wrong-password":
      return "The provided login credentials do not match our records.";
    case "auth/expired-action-code":
      return "The link contains a code that has expired. Please try again.";
    case "auth/invalid-action-code":
      return "The link contains a code that is not valid or has been used already. Please try again.";
    case "auth/account-exists-with-different-credential":
      return "Email already associated with another account.";
    case "auth/requires-recent-login":
      return "It has been too long since you've last authenticated yourself. Please go back to 'Account' and try again.";
    case "auth/user-token-expired":
      return "The account information is expired. Please sign-in again.";
    default:
      return error.message;
  }
};
// ========================================
// Event listener callback function when authentication object changes
auth.onAuthStateChanged = (callback) => {
  return onAuthStateChanged(fbAuth, callback());
};
// ========================================
// Creates a new user account with email address and password.
// On successful creation of the user account, this user will also be signed in to your application.
// User account creation can fail if the account already exists or the password is invalid.
auth.createUser = (email, password) => {
  return createUserWithEmailAndPassword(fbAuth, email, password);
};
// ========================================
// Returns a credential object.
auth.getCredential = (email, password) => {
  return EmailAuthProvider.credential(email, password);
};
// ========================================
// Asynchronously signs in with the given credentials.
auth.reauthenticateWithCredential = (user, credential) => {
  return reauthenticateWithCredential(user, credential);
};
// ========================================
// Asynchronously signs in using an email and password.
// Fails with an error if the email address and password do not match.
auth.signIn = async (email, password) => {
  await setPersistence(fbAuth, browserLocalPersistence);
  return signInWithEmailAndPassword(fbAuth, email, password);
};
// ========================================
// Signs out the current user.
auth.signOut = () => {
  return signOut(fbAuth);
};
// ========================================
// Sends a password reset email to the given email address.
auth.sendPasswordResetEmail = (email) => {
  return sendPasswordResetEmail(fbAuth, email);
};
// ========================================
// Updates the password of a user
auth.updatePassword = (user, password) => {
  return updatePassword(user, password);
};
// ========================================
// Updates the email address of a user
auth.sendEmailUpdateEmail = (user, email) => {
  return verifyBeforeUpdateEmail(user, email);
};
// ========================================
// Updates the email address of a user
auth.updateEmail = (user, email) => {
  return updateEmail(user, email);
};
// ========================================
// Checks a verification code sent to the user by email or other out-of-band mechanism.
// Returns metadata about the code.
auth.checkActionCode = (code) => {
  return checkActionCode(fbAuth, code);
};
// ========================================
// Checks a password reset code sent to the user by email.
// Returns the user's email address if valid.
auth.verifyPasswordResetCode = (actionCode) => {
  return verifyPasswordResetCode(fbAuth, actionCode);
};
// ========================================
// Completes the password reset process, given an action code and new password.
auth.confirmPasswordReset = (actionCode, newPassword) => {
  return confirmPasswordReset(fbAuth, actionCode, newPassword);
};
// ========================================
// Sends an email with a link to verify the email address of a user.
auth.sendEmailVerification = (user) => {
  return sendEmailVerification(user);
};
// ========================================
// Applies a verification code sent to the user by email.
auth.verifyEmail = (actionCode) => {
  // Try to apply the email verification code.
  return applyActionCode(fbAuth, actionCode);
};
// ========================================
// Applies a verification code to restore the previous email
auth.recoverEmail = (actionCode) => {
  // Try to apply the email verification code.
  return applyActionCode(fbAuth, actionCode);
};
// ========================================
// Deletes the user from the Auth database.
auth.deleteUser = (user) => {
  return user.delete();
};
// ========================================
// Get a JSON Web Token from user for server-side authentication
auth.getJWT = (user, forceRefresh) => {
  // Update the JWT if the expiration time has lapsed
  if (
    DateTime.fromMillis(user.stsTokenManager.expirationTime)
      .diffNow()
      .valueOf() < 30000
  ) {
    console.log(
      `AUTH: JWT refreshed, expired at ${DateTime.fromMillis(
        user.stsTokenManager.expirationTime
      )
        .startOf("second")
        .toISO({ suppressMilliseconds: true, includeOffset: false })}`
    );
    return user.getIdToken(/* forceRefresh */ true);
  } else if (forceRefresh === true) {
    console.log(
      `AUTH: JWT forced refresh, expired at ${DateTime.fromMillis(
        user.stsTokenManager.expirationTime
      )
        .startOf("second")
        .toISO({ suppressMilliseconds: true, includeOffset: false })}`
    );
    return user.getIdToken(/* forceRefresh */ true);
  } else {
    return user.getIdToken();
  }
};
// ========================================
// Object containing all authorization functions
export default auth;
