// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
const { DateTime } = require("luxon");
const {
  getStudyEnrollmentTimepoints,
} = require("./getStudyEnrollmentTimepoints");
// =================================================
// EXPORT FUNCTION
// -----------------------------------------------
// Unshifts the user enrollment field with a new study enrollment
export const unshiftUserStudyEnrollment = (user, study) => {
  // Initialize some variables
  let newUser = JSON.parse(JSON.stringify(user));
  // Add the new study enrollment to the start of the array
  newUser.studyEnrollmentList.unshift({
    studyId: study._id,
    userStudyNumber: newUser.userNumber,
    enrollmentDate: DateTime.now().toFormat("yyyy-LL-dd"),
    groupAssignmentList: [],
    timepointAssignmentList: getStudyEnrollmentTimepoints(study),
  });
  // Copy the user object, assign the new study enrollment and update DB
  return newUser;
};
