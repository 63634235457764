// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import VerticalAlignTop from "@mui/icons-material/VerticalAlignTop";
import VerticalAlignBottom from "@mui/icons-material/VerticalAlignBottom";
import Info from "@mui/icons-material/Info";
import Check from "@mui/icons-material/Check";
import Dangerous from "@mui/icons-material/Dangerous";
import Warning from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";
import Collapse from "@mui/material/Collapse";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditArticleInstructions = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditArticleInstructions",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local State
  const [isEnabled, setIsEnabled] = useState(props.obj ? true : false);
  const [memory, setMemory] = useState(props.obj);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (key, value) => {
    props.setObj((prevState) => {
      let newState = { ...prevState };
      newState = { ...newState, [key]: value };
      return newState;
    });
  };
  // -----------------------------------------------
  // Turns on and off the content
  const handleToggleIsEnabled = (e) => {
    setIsEnabled(e.target.checked);
    if (e.target.checked) {
      props.setObj(
        memory
          ? memory
          : {
              text: "",
              position: "top",
              variant: "info",
            }
      );
    } else {
      setMemory(props.obj);
      props.setObj(null);
    }
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <Switch
            color="secondary"
            checked={isEnabled}
            onChange={(e) => handleToggleIsEnabled(e)}
          />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h4">{t("Instruction box")}</Typography>
        </Grid>
      </Grid>
      <Collapse in={isEnabled}>
        <Grid container alignItems="center" className="mt-2">
          <Grid item xs={12} className="mb-3">
            <TextField
              fullWidth
              multiline
              label={t("Instructions")}
              type="text"
              name="text"
              variant="filled"
              value={props.obj ? props.obj.text : ""}
              color="secondary"
              className="answer-text-green"
              onChange={(e) => handleSetObj(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={3} className="pe-2">
            <ToggleButtonGroup
              fullWidth
              exclusive
              name="position"
              value={props.obj ? props.obj.position : "top"}
              onChange={(e, val) => val && handleSetObj("position", val)}
            >
              <Tooltip arrow title={t("position top")} placement="top">
                <ToggleButton
                  value="top"
                  className={
                    props.obj && props.obj.position === "top" ? "bg-grey" : ""
                  }
                >
                  <VerticalAlignTop />
                </ToggleButton>
              </Tooltip>
              <Tooltip arrow title={t("position bottom")} placement="top">
                <ToggleButton
                  value="bottom"
                  className={
                    props.obj && props.obj.position === "bottom"
                      ? "bg-grey"
                      : ""
                  }
                >
                  <VerticalAlignBottom />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={9} className="ps-2">
            <ToggleButtonGroup
              fullWidth
              exclusive
              name="variant"
              value={props.obj ? props.obj.variant : ""}
              onChange={(e, val) => val && handleSetObj("variant", val)}
            >
              <Tooltip arrow title={t("Info")} placement="top">
                <ToggleButton
                  value="info"
                  className={
                    props.obj && props.obj.variant === "info" ? "bg-info" : ""
                  }
                >
                  <Info />
                </ToggleButton>
              </Tooltip>
              <Tooltip arrow title={t("Success")} placement="top">
                <ToggleButton
                  value="success"
                  className={
                    props.obj && props.obj.variant === "success"
                      ? "bg-success"
                      : ""
                  }
                >
                  <Check />
                </ToggleButton>
              </Tooltip>
              <Tooltip arrow title={t("Error")} placement="top">
                <ToggleButton
                  value="error"
                  className={
                    props.obj && props.obj.variant === "error"
                      ? "bg-danger"
                      : ""
                  }
                >
                  <Dangerous />
                </ToggleButton>
              </Tooltip>
              <Tooltip arrow title={t("Warning")} placement="top">
                <ToggleButton
                  value="warning"
                  className={
                    props.obj && props.obj.variant === "warning"
                      ? "bg-warning"
                      : ""
                  }
                >
                  <Warning />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditArticleInstructions;
