// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Redux
import { consumersSelectors } from "../redux/reducers/consumers";
import { conversationsSelectors } from "../redux/reducers/conversations";
import { messagesSelectors } from "../redux/reducers/messages";
// -------------------------------------------------
// Components
import AppListItem from "./App_ListItem";
// -------------------------------------------------
// Basic elements
import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
// =================================================
// FUNCTIONAL COMPONENT
const MessagesColumnContent = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "Messages_ColumnContent",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const userId = useSelector(
    (state) => state.user.currentUser && state.user.currentUser._id
  );
  const conversationList = useSelector((state) =>
    conversationsSelectors.selectAll(state)
  ).sort((a, b) => (a.updatedAt <= b.updatedAt ? -1 : 1));
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state)
  );
  const messsagesStatus = useSelector((state) => state.messages.status);
  const messageList = useSelector((state) =>
    messagesSelectors.selectAll(state)
  );
  const recipientList = useSelector((state) => state.messages.recipientList);
  // -----------------------------------------------
  // Browser URL location
  const navigate = useNavigate();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Gets the last message of a conversation
  const getLastMessage = (conversationId) => {
    return messageList
      .filter((message) => message.conversationId === conversationId)
      .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))[0];
  };
  // -----------------------------------------------
  // Gets the last message of a conversation
  const getUnreadCount = (conversationId) => {
    return messageList
      .filter((message) => message.conversationId === conversationId)
      .filter((message) =>
        recipientList.some(
          (r) => r.messageId === message._id && !r.isRead && !message.isNote
        )
      ).length;
  };
  // -----------------------------------------------
  // Sets the URL search query to the study identifier
  const handleSetCurrentConversation = (id) => {
    let el;
    el = document.querySelector("#scroll-top-app");
    if (el) {
      el.scrollIntoView({ block: "end" });
    }
    navigate(`/messages?conversationId=${id}`, { replace: true });
  };
  const getConversationRecipients = (consumerIds) => {
    let rList =
      consumerIds &&
      consumerIds.map((id) => {
        if (id === userId) {
          return null;
        } else if (consumerEntities[id]) {
          if (consumerEntities[id].name) {
            return consumerEntities[id].name;
          } else {
            return consumerEntities[id].userNumber;
          }
        } else {
          return "John Doe";
        }
      });
    rList = rList.filter((recipient) => recipient !== null).join(", ");
    return rList === "" ? null : rList;
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <List disablePadding>
      {conversationList.length === 0 ? (
        <Fade in={true}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className="my-3 text-center">
              <img
                src="images/icon-train-end.png"
                alt="No conversations"
                className="w-50"
              />
            </Grid>
            <Grid item>
              <Typography variant="overline">{t("No messages")}</Typography>
            </Grid>
          </Grid>
        </Fade>
      ) : (
        conversationList
          .sort((a, b) =>
            getLastMessage(a._id) &&
            getLastMessage(b._id) &&
            getLastMessage(a._id).createdAt < getLastMessage(b._id).createdAt
              ? 1
              : -1
          )
          .map((conversation) => (
            <AppListItem
              key={conversation._id}
              divider
              slideIn
              isSelected={conversation._id === props.currentConversationId}
              icon={
                getUnreadCount(conversation._id) > 0 && (
                  <Badge variant="dot" color="error" className="mx-2" />
                )
              }
              textPrimary={
                getConversationRecipients(conversation.userIdList) ? (
                  <Typography className="fw-bold mb-1 text-truncate">
                    {getConversationRecipients(conversation.userIdList)}
                  </Typography>
                ) : (
                  <Skeleton />
                )
              }
              textSecondary={
                messsagesStatus === "loading" ? (
                  <Skeleton />
                ) : messageList.filter(
                    (message) => message.conversationId === conversation._id
                  ).length > 0 ? (
                  `${DateTime.fromISO(
                    getLastMessage(conversation._id).createdAt
                  ).toRelativeCalendar()} – ${
                    getLastMessage(conversation._id).message
                  }`
                ) : (
                  <i>{t("No messages")}</i>
                )
              }
              onClick={() => handleSetCurrentConversation(conversation._id)}
            />
          ))
      )}
    </List>
  );
};
// =================================================
// EXPORT COMPONENT
export default MessagesColumnContent;
