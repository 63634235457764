// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../redux/reducers/ui";
// -------------------------------------------------
// Basic elements
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const TopDrawer = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "App_TopDrawer" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const isXS = useSelector((state) => state.ui.isXS);
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  const secDrawerId = useSelector((state) => state.ui.secDrawerId);
  // ===============================================
  // RENDER COMPONENT
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={secDrawerIsOpen && secDrawerId === props.id}
      PaperProps={{
        className: "bg-grey",
        sx: { width: props.width && !isXS ? props.width : "auto" },
      }}
      className="z-index-1300"
      onClose={() => {
        props.onClose && props.onClose();
        dispatch(toggleSecDrawer({ isOpen: false, id: null }));
      }}
    >
      <Grid container direction="column" wrap="nowrap" className="h-100">
        <Grid item className="flex-grow-0">
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            className={
              isXS
                ? "bg-gradient-primary bd-filt-blur-5px shadow-sm fixed-top fix-width-100vw"
                : "bg-gradient-primary bd-filt-blur-5px shadow-sm"
            }
          >
            <Grid item className="flex-grow-1 overflow-hidden">
              <Typography noWrap variant="h3" className="mx-3">
                {props.title}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                className="m-2"
                startIcon={<Icon>clear</Icon>}
                color="inherit"
                onClick={() =>
                  dispatch(toggleSecDrawer({ isOpen: false, id: null }))
                }
              >
                {t("Cancel")}
              </Button>
            </Grid>
            {props.buttons}
          </Grid>
        </Grid>
        <Grid
          item
          className={
            isXS ? "flex-grow-1 pt-55px fix-width-100vw" : "flex-grow-1 w-100"
          }
        >
          <span id="scroll-top-preview" className="pt-55px" />
          {props.children}
          <span id="scroll-bottom-preview" />
        </Grid>
      </Grid>
    </Drawer>
  );
};
// =================================================
// EXPORT COMPONENT
export default TopDrawer;
