// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// -------------------------------------------------
// Authentication API functions
import auth from "../api-routes/auth";
// -------------------------------------------------
// Component elements
import MainCard from "../components/App_MainCard";
// -------------------------------------------------
// Basic elements
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const AuthSignOut = () => {
  const { t } = useTranslation("pages", { keyPrefix: "AuthSignOut" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [doRedirect, setDoRedirect] = useState(false);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Executes as soon as component renders
  useEffect(() => {
    handleSignOut();
    setDoRedirect(true);
  }, []);
  // -----------------------------------------------
  const handleSignOut = async () => {
    await auth.signOut();
  };
  // ===============================================
  // RENDER COMPONENT
  if (doRedirect) {
    return <Navigate to="/" />;
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE} | {t("Signing out, please wait...")}
        </title>
      </Helmet>
      <MainCard isLoading={true}>
        <Typography variant="h1" className="mb-2">
          {t("Please wait...")}
        </Typography>
        <Typography variant="h3" color="textSecondary">
          {t("You're being signed out.")}
        </Typography>
      </MainCard>
    </React.Fragment>
  );
};
// =================================================
// EXPORT COMPONENT
export default AuthSignOut;
