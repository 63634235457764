// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Navigate } from "react-router-dom";
// -------------------------------------------------
// Authentication API functions
import auth from "../api-routes/auth";
// -------------------------------------------------
// Component elements
import MainCard from "../components/App_MainCard";
import { Helmet } from "react-helmet";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
// -------------------------------------------------
// Basic elements
import {
  Alert,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
// -------------------------------------------------
// Icons
import Login from "@mui/icons-material/Login";
// =================================================
// FUNCTIONAL COMPONENT
const AuthSignIn = (props) => {
  const { t } = useTranslation("pages", { keyPrefix: "AuthSignIn" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Context state
  const { currentAuth, verifiedAuth, authStatus } = useAuth();
  // -----------------------------------------------
  // Local state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Executes when the user presses the form submit button
  const handleSignIn = async (e) => {
    // Prevent default updating
    e.preventDefault();
    // Check that the password is not empty (is not explicitly checked by firebase)
    if (!email || email === "") {
      setErrorMsg(t("Please type your email address below."));
      return;
    }
    if (!password || password === "") {
      setErrorMsg(t("Please type your password below."));
      return;
    }
    // Set loading and init errors to 'null'
    setIsLoading(true);
    setErrorMsg(null);
    try {
      await auth.signIn(email, password);
    } catch (error) {
      setIsLoading(false);
      setErrorMsg(auth.parseErrorMessage(error));
    }
  };
  // ===============================================
  // RENDER COMPONENT
  if (currentAuth && verifiedAuth) {
    return <Navigate to="/" />;
  } else if (currentAuth && !verifiedAuth) {
    return <Navigate to="/ask-to-verify-email" />;
  }
  return props.contentOnly ? (
    <>
      <Typography variant="h3" className="mb-3" color="textSecondary">
        {t("Sign in with an existing account")}
      </Typography>
      {errorMsg !== null && (
        <Alert severity="warning" className="align-items-center mb-3">
          {errorMsg}
        </Alert>
      )}
      <form noValidate onSubmit={handleSignIn}>
        <TextField
          required
          fullWidth
          disabled={isLoading}
          variant="filled"
          color="secondary"
          id="email"
          type="email"
          label={t("Email Address")}
          name="email"
          autoComplete="username"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <TextField
          required
          fullWidth
          disabled={isLoading}
          variant="filled"
          color="secondary"
          margin="normal"
          label={t("Password")}
          type="password"
          id="password"
          name="current-password"
          autoComplete="current-password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <LoadingButton
          fullWidth
          loading={isLoading}
          loadingPosition="start"
          startIcon={<Login />}
          type="submit"
          variant="contained"
          color={authStatus === "loading" ? "secondary" : "primary"}
          className="my-2"
        >
          {t("Sign in")}
        </LoadingButton>
      </form>
      <Grid container>
        <Grid item xs={12} className="text-center">
          <NavLink
            to="/user-management?mode=sendResetPasswordLink"
            className="text-secondary text-decoration-none"
          >
            {t("Forgot password?")}
          </NavLink>
          <Divider className="my-3" />
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            component={NavLink}
            to="/register"
          >
            {t("Create new account")}
          </Button>
        </Grid>
      </Grid>
    </>
  ) : (
    <React.Fragment>
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE} | {t("Sign in")}
        </title>
      </Helmet>
      <MainCard isLoading={isLoading}>
        <Typography variant="h3" className="mb-3" color="textSecondary">
          {t("Sign in with an existing account")}
        </Typography>
        {errorMsg !== null && (
          <Alert severity="warning" className="align-items-center mb-3">
            {errorMsg}
          </Alert>
        )}
        <form noValidate onSubmit={handleSignIn}>
          <TextField
            required
            fullWidth
            disabled={isLoading}
            variant="filled"
            color="secondary"
            id="email"
            type="email"
            label={t("Email Address")}
            name="email"
            autoComplete="username"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            required
            fullWidth
            disabled={isLoading}
            variant="filled"
            color="secondary"
            margin="normal"
            label={t("Password")}
            type="password"
            id="password"
            name="current-password"
            autoComplete="current-password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <LoadingButton
            fullWidth
            loading={isLoading}
            loadingPosition="start"
            startIcon={<Login />}
            type="submit"
            variant="contained"
            color={authStatus === "loading" ? "secondary" : "primary"}
            className="my-2"
          >
            {t("Sign in")}
          </LoadingButton>
        </form>
        <Grid container>
          <Grid item xs={12} className="text-center">
            <NavLink
              to="/user-management?mode=sendResetPasswordLink"
              className="text-secondary text-decoration-none"
            >
              {t("Forgot password?")}
            </NavLink>
            <Divider className="my-3" />
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              component={NavLink}
              to="/register"
            >
              {t("Create new account")}
            </Button>
          </Grid>
        </Grid>
      </MainCard>
    </React.Fragment>
  );
};
// =================================================
// EXPORT COMPONENT
export default AuthSignIn;
