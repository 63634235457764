// ===============================================
// SURVEY LOGIC
// -----------------------------------------------
// Returns all aliases that are dependencies for other questions
export const setSurveyDependencies = (survey) => {
  let dependencies = {};
  survey.pageList &&
    survey.pageList.forEach((page) => {
      page.logic &&
        page.logic.forEach((logicGroup) => {
          logicGroup.itemList.forEach((logicItem) => {
            dependencies[logicItem.condition.aliasId] =
              survey.aliasList[logicItem.condition.aliasId].alias;
          });
        });
      page.gridContainerList &&
        page.gridContainerList.forEach((gc) => {
          gc.logic &&
            gc.logic.forEach((logicGroup) => {
              logicGroup.itemList.forEach((logicItem) => {
                dependencies[logicItem.condition.aliasId] =
                  survey.aliasList[logicItem.condition.aliasId].alias;
              });
            });
          gc.gridItemList &&
            gc.gridItemList.forEach((gi) => {
              gi.logic &&
                gi.logic.forEach((logicGroup) => {
                  logicGroup.itemList.forEach((logicItem) => {
                    dependencies[logicItem.condition.aliasId] =
                      survey.aliasList[logicItem.condition.aliasId].alias;
                  });
                });
              gi.articleList &&
                gi.articleList.forEach((article) => {
                  article.logic &&
                    article.logic.forEach((logicGroup) => {
                      logicGroup.itemList.forEach((logicItem) => {
                        dependencies[logicItem.condition.aliasId] =
                          survey.aliasList[logicItem.condition.aliasId].alias;
                      });
                    });
                  article.rowList &&
                    article.rowList.forEach((row) => {
                      row.logic &&
                        row.logic.forEach((logicGroup) => {
                          logicGroup.itemList.forEach((logicItem) => {
                            dependencies[logicItem.condition.aliasId] =
                              survey.aliasList[
                                logicItem.condition.aliasId
                              ].alias;
                          });
                        });
                    });
                });
            });
        });
    });
  if (Object.values(dependencies).length === 0) {
    dependencies = null;
  }
  survey.dependencies = dependencies;
  return survey;
};
