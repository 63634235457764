// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// -------------------------------------------------
// Redux actions
import { setAlert } from "../redux/reducers/ui";
import { resetConsumerError } from "../redux/reducers/consumers";
import { resetFormError } from "../redux/reducers/form";
import { resetStudiesError } from "../redux/reducers/studies";
import { resetSurveysError } from "../redux/reducers/surveys";
import { resetTaskResponsesError } from "../redux/reducers/taskResponses";
import { resetTicketsError } from "../redux/reducers/tickets";
import { resetUserError } from "../redux/reducers/user";
// -------------------------------------------------
// Basic elements
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
// -------------------------------------------------
// Define slide transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// =================================================
// FUNCTIONAL COMPONENT
const AlertMessage = () => {
  const { t } = useTranslation("components", { keyPrefix: "App_AlertMessage" });
  // =================================================
  // VARIABLES
  // -------------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.ui.alert);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Closes the dialog
  const handleAlertClose = (response, functionHandle) => {
    // Reset the alert to null
    dispatch(setAlert(null));
    // Reset all error states in redux
    dispatch(resetConsumerError());
    dispatch(resetFormError());
    dispatch(resetStudiesError());
    dispatch(resetSurveysError());
    dispatch(resetTaskResponsesError());
    dispatch(resetTicketsError());
    dispatch(resetUserError());
    // If the user specified a function to be called, call it now with the respose as input
    if (functionHandle && functionHandle !== "backdropClick") {
      functionHandle(response);
    }
  };
  // ===============================================
  // RENDER COMPONENT
  if (!alert) {
    return null;
  } else if (alert) {
    return alert.type === "dialog" && alert.message ? (
      <Dialog
        open
        disableEscapeKeyDown
        keepMounted
        TransitionComponent={Transition}
        onClose={handleAlertClose}
      >
        <DialogTitle>{alert.title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{alert.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {alert.responseOptions ? (
            alert.responseOptions.map((option) => (
              <Button
                key={option.label}
                id={option.label}
                variant={option.defaultOption ? "contained" : "outlined"}
                onClick={(e) => {
                  handleAlertClose(e.target.id, option.functionHandle);
                }}
                color="primary"
              >
                {option.label}
              </Button>
            ))
          ) : (
            <Button
              variant="contained"
              onClick={(e) => {
                handleAlertClose(e.target.id, null);
              }}
              color="primary"
            >
              { t("OK") }
            </Button>
          )}
        </DialogActions>
      </Dialog>
    ) : alert.type === "snackbar" && alert.message ? (
      <Snackbar
        open
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={alert.duration ? alert.duration : 10000}
        className="shadow-lg"
        onClose={(e) => {
          handleAlertClose();
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={(e) => {
            handleAlertClose();
          }}
          severity={alert.variant}
        >
          <Typography>{alert.message}</Typography>
        </Alert>
      </Snackbar>
    ) : null;
  }
};
// =================================================
// EXPORT COMPONENT
export default AlertMessage;
