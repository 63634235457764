// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Redux
import { conversationsSelectors } from "../redux/reducers/conversations";
import { consumersSelectors } from "../redux/reducers/consumers";
// -------------------------------------------------
// Support functions
import { str2color } from "../supportFunc/str2color";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
// =================================================
// FUNCTIONAL COMPONENT
const MessagesHeader = (props) => {
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Redux
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state)
  );
  const currentConversation = useSelector((state) =>
    conversationsSelectors.selectById(state, props.currentConversationId)
  );
  // ===============================================
  // FUNCTIONS
  // -------------------------------------------------
  const stringAvatar = (id, name) => {
    if (!name) {
      return null;
    }
    const abbrev = name.trim().split(" ");
    return {
      sx: {
        bgcolor: str2color(id),
      },
      children:
        abbrev.length === 1
          ? `${name[0].toUpperCase()}`
          : `${name.split(" ")[0][0].toUpperCase()}${name
              .split(" ")[1][0]
              .toUpperCase()}`,
    };
  };
  // ===============================================
  // RENDER COMPONENT
  // -------------------------------------------------
  return currentConversation && consumerEntities ? (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className="w-100"
    >
      <Grid item className="pe-2">
        <AvatarGroup max={4}>
          {currentConversation.userIdList.map(
            (id) =>
              consumerEntities[id] && (
                <Avatar
                  key={id}
                  {...stringAvatar(
                    id,
                    consumerEntities[id].name
                      ? consumerEntities[id].name
                      : consumerEntities[id].email
                  )}
                />
              )
          )}
        </AvatarGroup>
      </Grid>
    </Grid>
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default MessagesHeader;
