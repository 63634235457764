// =================================================
// IMPORT
const convert = require("@csstools/convert-colors");
// =================================================
// FUNCTION
// -------------------------------------------------
// Add leading zeros
export const str2color = (string) => {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  color = convert.hex2hsl(color);
  if (color[2] > 50) {
    color[1] = 65;
    color[2] = 50;
  }
  color = convert.hsl2hex(...color);
  /* eslint-enable no-bitwise */
  return color;
};
