// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Component elements
import AppDrawerPlaceholder from "./App_DrawerPlaceholder";
import SurveysContentInfo from "./surveys/Surveys_ContentInfo";
import SurveysContentPage from "./surveys/Surveys_ContentPage";
import SurveysContentGridContainer from "./surveys/Surveys_ContentGridContainer";
import SurveysContentGridItem from "./surveys/Surveys_ContentGridItem";
import SurveysContentArticle from "./surveys/Surveys_ContentArticle";
import SurveysContentScoring from "./surveys/Surveys_ContentScoring";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Redux
import { patchCurrentSurvey } from "../redux/reducers/surveys";
import { toggleSecDrawer } from "../redux/reducers/ui";
// -------------------------------------------------
// Helper functions
import { defaultSurvey } from "../supportFunc/defaultSurveys";
import { setSurveyAliasList } from "../supportFunc/setSurveyAliasList";
import { setSurveyDependencies } from "../supportFunc/setSurveyDependencies";
import { getAliasContainingId } from "../supportFunc/getAliasContainingId";
// -------------------------------------------------
// Basic elements
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { TransitionGroup } from "react-transition-group";
// -------------------------------------------------
// Icons
import Add from "@mui/icons-material/Add";
import SurveysContentTranslate from "./surveys/Surveys_ContentTranslate";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const SurveysContent = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "Surveys_Content" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Context
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const surveysStatus = useSelector((state) => state.surveys.status);
  const translateSurvey = useSelector((state) => state.surveys.translateSurvey);
  // ===============================================
  // FUNCTION: MUTATE PAGE
  // -----------------------------------------------
  // Handles adding a new page
  const handleAddPage = (pageIdx) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    let newPage = JSON.parse(JSON.stringify(defaultSurvey.page));
    newPage._id = uuid();
    newPage.gridContainerList[0]._id = uuid();
    newPage.gridContainerList[0].gridItemList[0]._id = uuid();
    if (!newSurvey.pageList) {
      newSurvey.pageList = [newPage];
    } else {
      newSurvey.pageList.splice(pageIdx + 1, 0, newPage);
    }
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // -----------------------------------------------
  // Handles copying a page
  const handleCopyPage = (pageIdx, p) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    p = JSON.parse(JSON.stringify(p));
    p.gridContainerList &&
      p.gridContainerList.forEach((gc) => {
        gc.gridItemList &&
          gc.gridItemList.forEach((gi) => {
            gi.articleList &&
              gi.articleList.forEach((a) => {
                a.columnList
                  ? a.columnList.forEach((c) => {
                      a.rowList.forEach((i) => {
                        i._id = uuid();
                      });
                      c._id = uuid();
                    })
                  : a.rowList
                  ? a.rowList.forEach((i) => {
                      i._id = uuid();
                    })
                  : (a._id = uuid());
                a._id = uuid();
              });
            gi._id = uuid();
          });
        gc._id = uuid();
      });
    p._id = uuid();
    newSurvey.pageList.splice(pageIdx + 1, 0, p);
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // -----------------------------------------------
  // Handles moving a page
  const handleMovePage = (pageIdx, direction) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    let element = newSurvey.pageList[pageIdx];
    newSurvey.pageList.splice(pageIdx, 1);
    newSurvey.pageList.splice(pageIdx + direction, 0, element);
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // -----------------------------------------------
  // Handles deleting a page
  const handleDeletePage = (pageIdx) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    newSurvey.pageList.splice(pageIdx, 1);
    if (newSurvey.pageList.length === 0) {
      newSurvey.pageList = null;
    }
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // ===============================================
  // FUNCTION: MUTATE GRID CONTAINER
  // -----------------------------------------------
  // Handles adding a new grid container
  const handleAddGridContainer = (pageIdx, gcIdx) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    let newGrid = JSON.parse(
      JSON.stringify(defaultSurvey.page.gridContainerList[0])
    );
    newGrid._id = uuid();
    newGrid.gridItemList[0]._id = uuid();
    if (!newSurvey.pageList[pageIdx].gridContainerList) {
      newSurvey.pageList[pageIdx].gridContainerList = [newGrid];
    } else {
      newSurvey.pageList[pageIdx].gridContainerList.splice(
        gcIdx + 1,
        0,
        newGrid
      );
    }
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // -----------------------------------------------
  // Handles copying a grid container
  const handleCopyGridContainer = (pageIdx, gcIdx, gc) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    gc = JSON.parse(JSON.stringify(gc));
    gc.gridItemList &&
      gc.gridItemList.forEach((gi) => {
        gi.articleList &&
          gi.articleList.forEach((a) => {
            a.columnList
              ? a.columnList.forEach((c) => {
                  a.rowList.forEach((i) => {
                    i._id = uuid();
                  });
                  c._id = uuid();
                })
              : a.rowList
              ? a.rowList.forEach((i) => {
                  i._id = uuid();
                })
              : (a._id = uuid());
            a._id = uuid();
          });
        gi._id = uuid();
      });
    gc._id = uuid();
    newSurvey.pageList[pageIdx].gridContainerList.splice(gcIdx + 1, 0, gc);
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // -----------------------------------------------
  // Handles moving a grid container
  const handleMoveGridContainer = (pageIdx, gcIdx, direction) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    let el = newSurvey.pageList[pageIdx].gridContainerList[gcIdx];
    newSurvey.pageList[pageIdx].gridContainerList.splice(gcIdx, 1);
    newSurvey.pageList[pageIdx].gridContainerList.splice(
      gcIdx + direction,
      0,
      el
    );
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // -----------------------------------------------
  // Handles deleting a grid container
  const handleDeleteGridContainer = (pageIdx, gcIdx) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    newSurvey.pageList[pageIdx].gridContainerList.splice(gcIdx, 1);
    if (newSurvey.pageList[pageIdx].gridContainerList.length === 0) {
      newSurvey.pageList[pageIdx].gridContainerList = null;
    }
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // ===============================================
  // FUNCTION: MUTATE GRID ITEM
  // -----------------------------------------------
  // Handles adding a new grid item
  const handleAddGridItem = (pageIdx, gcIdx, giIdx) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    let newGrid = JSON.parse(
      JSON.stringify(defaultSurvey.page.gridContainerList[0].gridItemList[0])
    );
    newGrid._id = uuid();
    if (!newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList) {
      newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList = [
        newGrid,
      ];
    } else {
      newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList.splice(
        giIdx + 1,
        0,
        newGrid
      );
    }
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // -----------------------------------------------
  // Handles copying a grid item
  const handleCopyGridItem = (pageIdx, gcIdx, giIdx, gi) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    gi = JSON.parse(JSON.stringify(gi));
    gi.articleList &&
      gi.articleList.forEach((a) => {
        a.columnList
          ? a.columnList.forEach((c) => {
              a.rowList.forEach((i) => {
                i._id = uuid();
              });
              c._id = uuid();
            })
          : a.rowList
          ? a.rowList.forEach((i) => {
              i._id = uuid();
            })
          : (a._id = uuid());
        a._id = uuid();
      });
    gi._id = uuid();
    newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList.splice(
      giIdx + 1,
      0,
      gi
    );
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // -----------------------------------------------
  // Handles moving a grid item
  const handleMoveGridItem = (pageIdx, gcIdx, giIdx, direction) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    let el =
      newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList[giIdx];
    newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList.splice(
      giIdx,
      1
    );
    newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList.splice(
      giIdx + direction,
      0,
      el
    );
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // -----------------------------------------------
  // Handles deleting a grid item
  const handleDeleteGridItem = (pageIdx, gcIdx, giIdx) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList.splice(
      giIdx,
      1
    );
    if (
      newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList
        .length === 0
    ) {
      newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList = null;
    }
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // ===============================================
  // FUNCTION: MUTATE ARTICLE
  // -----------------------------------------------
  // Handles copying an article
  const handleCopyArticle = (pageIdx, gcIdx, giIdx, aIdx, a) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    a = JSON.parse(JSON.stringify(a));
    a._id = uuid();
    a.columnList
      ? a.columnList.forEach((c) => {
          a.rowList.forEach((i) => {
            i._id = uuid();
          });
          c._id = uuid();
        })
      : a.rowList
      ? a.rowList.forEach((i) => {
          i._id = uuid();
        })
      : (a._id = uuid());
    newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList[
      giIdx
    ].articleList.splice(aIdx + 1, 0, a);
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // -----------------------------------------------
  // Handles moving a article
  const handleMoveArticle = (pageIdx, gcIdx, giIdx, aIdx, direction) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    let el =
      newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList[giIdx]
        .articleList[aIdx];
    newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList[
      giIdx
    ].articleList.splice(aIdx, 1);
    newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList[
      giIdx
    ].articleList.splice(aIdx + direction, 0, el);
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // -----------------------------------------------
  // Handles deleting a article
  const handleDeleteArticle = (pageIdx, gcIdx, giIdx, aIdx) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList[
      giIdx
    ].articleList.splice(aIdx, 1);
    if (
      newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList[giIdx]
        .articleList.length === 0
    ) {
      newSurvey.pageList[pageIdx].gridContainerList[gcIdx].gridItemList[
        giIdx
      ].articleList = null;
    }
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // ===============================================
  // FUNCTION: MUTATE SCORING
  // -----------------------------------------------
  // Handles copying an article
  const handleCopyScore = (idx, s) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    const alias = getAliasContainingId(props.currentSurvey.aliasList, s._id);
    s = JSON.parse(JSON.stringify(s));
    s._id = uuid();
    s.alias = `${alias}_COPY`;
    newSurvey.scoring.splice(idx + 1, 0, s);
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // -----------------------------------------------
  // Handles deleting a article
  const handleDeleteScore = (idx) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    newSurvey.scoring.splice(idx, 1);
    if (newSurvey.scoring.length === 0) {
      newSurvey.scoring = null;
    }
    // Update the database
    handlePatchCurrentSurvey(newSurvey);
  };
  // ===============================================
  // FUNCTION: MUTATES THE CURRENT SURVEY
  // -----------------------------------------------
  // Edits the current survey after it's been edited in the top drawer
  const handleMutateCurrentSurvey = async (method, obj, idx) => {
    // Init vars
    let currentArticleList, currentArticle, currentScore, defaultGridItem;
    // Make a copy of the survey object
    let newSurvey = JSON.parse(JSON.stringify(props.currentSurvey));
    // Check what to change
    switch (method) {
      case "info":
        // Replace the info key values
        newSurvey = {
          ...newSurvey,
          name: { official: obj.officialName, display: obj.displayName },
          acronym: obj.acronym,
          version: obj.version,
          PDF: obj.PDF,
          DOI: obj.DOI,
        };
        break;
      case "page":
        // Replace the page
        newSurvey.pageList[idx.page] = obj;
        break;
      case "gridContainer":
        // Replace the gridContainer
        newSurvey.pageList[idx.page].gridContainerList[idx.gridContainer] = obj;
        break;
      case "gridItem":
        // Replace the gridItem
        newSurvey.pageList[idx.page].gridContainerList[
          idx.gridContainer
        ].gridItemList[idx.gridItem] = obj;
        break;
      case "article":
        // Check if we need to insert or replace the article
        currentArticleList =
          newSurvey.pageList[idx.page].gridContainerList[idx.gridContainer]
            .gridItemList[idx.gridItem].articleList;
        currentArticle = currentArticleList
          ? currentArticleList[idx.article]
          : null;
        if (currentArticle && currentArticle._id === obj._id) {
          // Replace
          newSurvey.pageList[idx.page].gridContainerList[
            idx.gridContainer
          ].gridItemList[idx.gridItem].articleList[idx.article] = obj;
        } else {
          // Insert, but first check if we need a new grid item
          if (currentArticleList === null) {
            // ... ok, grid item is empty, insert here
            newSurvey.pageList[idx.page].gridContainerList[
              idx.gridContainer
            ].gridItemList[idx.gridItem].articleList = [obj];
          } else {
            // .. ah, grid item is not empty, append a new one and insert article here
            defaultGridItem = JSON.parse(
              JSON.stringify(
                defaultSurvey.page.gridContainerList[0].gridItemList[0]
              )
            );
            defaultGridItem._id = uuid();
            defaultGridItem.articleList = [obj];
            newSurvey.pageList[idx.page].gridContainerList[
              idx.gridContainer
            ].gridItemList.splice(idx.gridItem + 1, 0, defaultGridItem);
          }
        }
        break;
      case "scoring":
        // Check scoring rule and if we need to insert or replace the scoring rule
        currentScore = newSurvey.scoring && newSurvey.scoring[idx];
        if (
          obj.method === "classification-value" ||
          obj.method === "classification-label"
        ) {
          obj.arithmetic = null;
        } else {
          obj.grouping = null;
        }
        if (currentScore && currentScore._id === obj._id) {
          // Replace
          newSurvey.scoring[idx] = obj;
        } else if (currentScore) {
          // insert
          newSurvey.scoring.splice(idx + 1, 0, obj);
        } else {
          // create
          newSurvey.scoring = [obj];
        }
        break;
      case "translate":
        newSurvey = obj;
        break;
      default:
        return;
    }
    handlePatchCurrentSurvey(newSurvey);
  };
  // ===============================================
  // FUNCTION: STORE TO DB
  // -----------------------------------------------
  // Handles saving the current survey to the database
  const handlePatchCurrentSurvey = async (newSurvey) => {
    const survey = setSurveyDependencies(setSurveyAliasList(newSurvey));
    // Update the database
    await dispatch(
      patchCurrentSurvey({
        socket,
        requestingUser: currentAuth,
        body: {
          data: survey,
        },
      })
    );
    // Close the drawer
    dispatch(toggleSecDrawer({ isOpen: false }));
  };
  // ===============================================
  // SUB COMPONENTS
  // -----------------------------------------------
  const PageButtonGroup = ({ pageIdx }) =>
    props.currentSurvey.folder !== "archive" &&
    currentUser &&
    (currentUser.primaryRole === "superuser" ||
      currentUser.primaryRole === "admin") ? (
      <Divider className="mb-3">
        <ButtonGroup>
          <Button
            disabled={surveysStatus === "loading"}
            variant="outlined"
            size="small"
            color="inherit"
            startIcon={<Add />}
            className="border-dashed"
            onClick={() => handleAddPage(pageIdx)}
          >
            { t("Add page") }
          </Button>
        </ButtonGroup>
      </Divider>
    ) : null;
  // -----------------------------------------------
  const GridContainerButtonGroup = ({ pageIdx, gridContainerIdx }) =>
    props.currentSurvey.folder !== "archive" &&
    currentUser &&
    (currentUser.primaryRole === "superuser" ||
      currentUser.primaryRole === "admin") ? (
      <Divider className="mb-2">
        <ButtonGroup>
          <Button
            disabled={surveysStatus === "loading"}
            variant="outlined"
            size="small"
            color="inherit"
            startIcon={<Add />}
            className="border-dashed"
            onClick={() => handleAddGridContainer(pageIdx, gridContainerIdx)}
          >
            { t("Add container") }
          </Button>
        </ButtonGroup>
      </Divider>
    ) : null;
  // -----------------------------------------------
  const GridItemButtonGroup = ({ pageIdx, gridContainerIdx, gridItemIdx }) =>
    props.currentSurvey.folder !== "archive" &&
    currentUser &&
    (currentUser.primaryRole === "superuser" ||
      currentUser.primaryRole === "admin") ? (
      <Divider className="mb-1">
        <ButtonGroup>
          <Button
            disabled={surveysStatus === "loading"}
            variant="outlined"
            size="small"
            color="inherit"
            startIcon={<Add />}
            className="border-dashed bg-transparant-white"
            onClick={() =>
              handleAddGridItem(pageIdx, gridContainerIdx, gridItemIdx)
            }
          >
            { t("Add empty box") }
          </Button>
        </ButtonGroup>
      </Divider>
    ) : null;
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return !props.currentSurvey ? (
    <AppDrawerPlaceholder
      imageURL={
        surveysStatus === "loading"
          ? "images/icon-wait.gif"
          : "images/icon-choose.png"
      }
      imageAlt={
        surveysStatus === "loading"
          ? t("Loading, please wait...")
          : t("Choose a survey from the list")
      }
      title={
        surveysStatus === "loading"
          ? t("Loading, please wait...")
          : t("Choose a survey from the list")
      }
    />
  ) : (
    <div className="mb-2">
      {/* ================================================== */}
      {/* LANGUAGE */}
      {!props.print2pdf && (
        <SurveysContentTranslate
          surveyId={props.currentSurvey._id}
          translateSurvey={translateSurvey}
          handleMutateCurrentSurvey={handleMutateCurrentSurvey}
        />
      )}
      {/* ================================================== */}
      {/* SURVEY INFO */}
      <SurveysContentInfo
        disabled={
          props.print2pdf ||
          props.currentSurvey.folder === "archive" ||
          (currentUser &&
            currentUser.primaryRole !== "superuser" &&
            currentUser.primaryRole !== "admin")
        }
        isLive={props.currentSurvey.isLive}
        info={{
          officialName: props.currentSurvey.name.official,
          displayName: props.currentSurvey.name.display,
          acronym: props.currentSurvey.acronym,
          createdAt: props.currentSurvey.createdAt,
          version: props.currentSurvey.version,
          PDF: props.currentSurvey.PDF,
          DOI: props.currentSurvey.DOI,
        }}
        handleMutateCurrentSurvey={handleMutateCurrentSurvey}
      />
      <TransitionGroup>
        {!props.currentSurvey.pageList ||
        props.currentSurvey.pageList.length === 0 ? (
          <Collapse>
            <PageButtonGroup pageIdx={0} />
          </Collapse>
        ) : (
          props.currentSurvey.pageList.map((page, i) => (
            <Collapse key={page._id}>
              <SurveysContentPage
                disabled={
                  props.print2pdf ||
                  props.currentSurvey.folder === "archive" ||
                  props.currentSurvey.isLive ||
                  (currentUser &&
                    currentUser.primaryRole !== "superuser" &&
                    currentUser.primaryRole !== "admin")
                }
                page={page}
                pageIdx={i}
                aliasList={props.currentSurvey.aliasList}
                ButtonGroup={<PageButtonGroup pageIdx={i} />}
                dependencies={props.currentSurvey.dependencies}
                scoring={props.currentSurvey.scoring}
                isLast={i === props.currentSurvey.pageList.length - 1}
                handleCopy={handleCopyPage}
                handleMove={handleMovePage}
                handleDelete={handleDeletePage}
                handleMutateCurrentSurvey={handleMutateCurrentSurvey}
              >
                <TransitionGroup>
                  {!page.gridContainerList ||
                  page.gridContainerList.length === 0 ? (
                    <Collapse>
                      <GridContainerButtonGroup
                        pageIdx={i}
                        gridContainerIdx={0}
                      />
                    </Collapse>
                  ) : (
                    page.gridContainerList.map((gc, j) => (
                      <Collapse key={gc._id}>
                        <SurveysContentGridContainer
                          disabled={
                            props.print2pdf ||
                            props.currentSurvey.folder === "archive" ||
                            props.currentSurvey.isLive ||
                            (currentUser &&
                              currentUser.primaryRole !== "superuser" &&
                              currentUser.primaryRole !== "admin")
                          }
                          gridContainer={gc}
                          pageIdx={i}
                          gridContainerIdx={j}
                          aliasList={props.currentSurvey.aliasList}
                          ButtonGroup={
                            <GridContainerButtonGroup
                              pageIdx={i}
                              gridContainerIdx={j}
                            />
                          }
                          dependencies={props.currentSurvey.dependencies}
                          scoring={props.currentSurvey.scoring}
                          isLast={j === page.gridContainerList.length - 1}
                          handleCopy={handleCopyGridContainer}
                          handleMove={handleMoveGridContainer}
                          handleDelete={handleDeleteGridContainer}
                          handleMutateCurrentSurvey={handleMutateCurrentSurvey}
                        >
                          {!gc.gridItemList || gc.gridItemList.length === 0 ? (
                            <GridItemButtonGroup
                              pageIdx={i}
                              gridContainerIdx={j}
                              gridItemIdx={0}
                            />
                          ) : (
                            gc.gridItemList.map((gi, k) => (
                              <SurveysContentGridItem
                                key={gi._id}
                                disabled={
                                  props.print2pdf ||
                                  props.currentSurvey.folder === "archive" ||
                                  props.currentSurvey.isLive ||
                                  (currentUser &&
                                    currentUser.primaryRole !== "superuser" &&
                                    currentUser.primaryRole !== "admin")
                                }
                                gridItem={gi}
                                pageIdx={i}
                                gridContainerIdx={j}
                                gridItemIdx={k}
                                aliasList={props.currentSurvey.aliasList}
                                ButtonGroup={
                                  <GridItemButtonGroup
                                    pageIdx={i}
                                    gridContainerIdx={j}
                                    gridItemIdx={k}
                                  />
                                }
                                dependencies={props.currentSurvey.dependencies}
                                scoring={props.currentSurvey.scoring}
                                isLast={k === gc.gridItemList.length - 1}
                                handleCopy={handleCopyGridItem}
                                handleMove={handleMoveGridItem}
                                handleDelete={handleDeleteGridItem}
                                handleMutateCurrentSurvey={
                                  handleMutateCurrentSurvey
                                }
                              >
                                {!gi.articleList ||
                                gi.articleList.length === 0 ? (
                                  <SurveysContentArticle
                                    disabled={
                                      props.print2pdf ||
                                      props.currentSurvey.folder ===
                                        "archive" ||
                                      props.currentSurvey.isLive ||
                                      (currentUser &&
                                        currentUser.primaryRole !==
                                          "superuser" &&
                                        currentUser.primaryRole !== "admin")
                                    }
                                    isLast={true}
                                    article={null}
                                    pageIdx={i}
                                    gridContainerIdx={j}
                                    gridItemIdx={k}
                                    articleIdx={0}
                                    aliasList={props.currentSurvey.aliasList}
                                    dependencies={
                                      props.currentSurvey.dependencies
                                    }
                                    scoring={props.currentSurvey.scoring}
                                    handleMutateCurrentSurvey={
                                      handleMutateCurrentSurvey
                                    }
                                  />
                                ) : (
                                  gi.articleList.map((article, m) => (
                                    <SurveysContentArticle
                                      key={article._id}
                                      disabled={
                                        props.print2pdf ||
                                        props.currentSurvey.folder ===
                                          "archive" ||
                                        props.currentSurvey.isLive ||
                                        (currentUser &&
                                          currentUser.primaryRole !==
                                            "superuser" &&
                                          currentUser.primaryRole !== "admin")
                                      }
                                      isLast={gi.articleList.length - 1 === m}
                                      article={article}
                                      pageIdx={i}
                                      gridContainerIdx={j}
                                      gridItemIdx={k}
                                      articleIdx={m}
                                      aliasList={props.currentSurvey.aliasList}
                                      dependencies={
                                        props.currentSurvey.dependencies
                                      }
                                      scoring={props.currentSurvey.scoring}
                                      handleCopy={handleCopyArticle}
                                      handleMove={handleMoveArticle}
                                      handleDelete={handleDeleteArticle}
                                      handleMutateCurrentSurvey={
                                        handleMutateCurrentSurvey
                                      }
                                    />
                                  ))
                                )}
                              </SurveysContentGridItem>
                            ))
                          )}
                        </SurveysContentGridContainer>
                      </Collapse>
                    ))
                  )}
                </TransitionGroup>
              </SurveysContentPage>
            </Collapse>
          ))
        )}
      </TransitionGroup>
      <Paper>
        <div>
          <Typography variant="h3" className="px-3 py-3">
            { t("Scoring rules") }
          </Typography>
          <TransitionGroup>
            {!props.currentSurvey.scoring ? (
              <Collapse>
                <SurveysContentScoring
                  disabled={
                    props.print2pdf ||
                    props.currentSurvey.folder === "archive" ||
                    props.currentSurvey.isLive ||
                    (currentUser &&
                      currentUser.primaryRole !== "superuser" &&
                      currentUser.primaryRole !== "admin")
                  }
                  obj={null}
                  idx={0}
                  acronym={props.currentSurvey.acronym}
                  aliasList={props.currentSurvey.aliasList}
                  dependencies={props.currentSurvey.dependencies}
                  scoring={props.currentSurvey.scoring}
                  handleMutateCurrentSurvey={handleMutateCurrentSurvey}
                />
              </Collapse>
            ) : (
              props.currentSurvey.scoring.map((score, i) => (
                <Collapse key={score._id}>
                  <SurveysContentScoring
                    disabled={
                      props.print2pdf ||
                      props.currentSurvey.folder === "archive" ||
                      props.currentSurvey.isLive ||
                      (currentUser &&
                        currentUser.primaryRole !== "superuser" &&
                        currentUser.primaryRole !== "admin")
                    }
                    obj={score}
                    idx={i}
                    acronym={props.currentSurvey.acronym}
                    aliasList={props.currentSurvey.aliasList}
                    dependencies={props.currentSurvey.dependencies}
                    scoring={props.currentSurvey.scoring}
                    isLast={i === props.currentSurvey.scoring.length - 1}
                    handleCopy={handleCopyScore}
                    handleDelete={handleDeleteScore}
                    handleMutateCurrentSurvey={handleMutateCurrentSurvey}
                  />
                </Collapse>
              ))
            )}
          </TransitionGroup>
        </div>
      </Paper>
    </div>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysContent;
