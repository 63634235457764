// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditTranslate = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const languages = ["en", "nl", "de"];
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (type, lang, params) => {
    let newState, newTrans;
    switch (type) {
      case "name.official":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(JSON.stringify(newState.name.official));
          newTrans[lang] = params.value;
          newState.name.official = newTrans;
          return newState;
        });
        break;
      case "name.display":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(JSON.stringify(newState.name.display));
          newTrans[lang] = params.value;
          newState.name.display = newTrans;
          return newState;
        });
        break;
      case "page.title.text":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(newState.pageList[params.pageIdx].title.text)
          );
          newTrans[lang] = params.value;
          newState.pageList[params.pageIdx].title.text = newTrans;
          return newState;
        });
        break;
      case "gc.title.text":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.pageList[params.pageIdx].gridContainerList[params.gcIdx]
                .title.text
            )
          );
          newTrans[lang] = params.value;
          newState.pageList[params.pageIdx].gridContainerList[
            params.gcIdx
          ].title.text = newTrans;
          return newState;
        });
        break;
      case "gi.title.text":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.pageList[params.pageIdx].gridContainerList[params.gcIdx]
                .gridItemList[params.giIdx].title.text
            )
          );
          newTrans[lang] = params.value;
          newState.pageList[params.pageIdx].gridContainerList[
            params.gcIdx
          ].gridItemList[params.giIdx].title.text = newTrans;
          return newState;
        });
        break;
      case "article.formLabel":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.pageList[params.pageIdx].gridContainerList[params.gcIdx]
                .gridItemList[params.giIdx].articleList[params.articleIdx]
                .formLabel
            )
          );
          newTrans[lang] = params.value;
          newState.pageList[params.pageIdx].gridContainerList[
            params.gcIdx
          ].gridItemList[params.giIdx].articleList[
            params.articleIdx
          ].formLabel = newTrans;
          return newState;
        });
        break;
      case "article.inputLabel":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.pageList[params.pageIdx].gridContainerList[params.gcIdx]
                .gridItemList[params.giIdx].articleList[params.articleIdx]
                .inputLabel
            )
          );
          newTrans[lang] = params.value;
          newState.pageList[params.pageIdx].gridContainerList[
            params.gcIdx
          ].gridItemList[params.giIdx].articleList[
            params.articleIdx
          ].inputLabel = newTrans;
          return newState;
        });
        break;
      case "article.title.text":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.pageList[params.pageIdx].gridContainerList[params.gcIdx]
                .gridItemList[params.giIdx].articleList[params.articleIdx].title
                .text
            )
          );
          newTrans[lang] = params.value;
          newState.pageList[params.pageIdx].gridContainerList[
            params.gcIdx
          ].gridItemList[params.giIdx].articleList[
            params.articleIdx
          ].title.text = newTrans;
          return newState;
        });
        break;
      case "article.content.text":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.pageList[params.pageIdx].gridContainerList[params.gcIdx]
                .gridItemList[params.giIdx].articleList[params.articleIdx]
                .content.text
            )
          );
          newTrans[lang] = params.value;
          newState.pageList[params.pageIdx].gridContainerList[
            params.gcIdx
          ].gridItemList[params.giIdx].articleList[
            params.articleIdx
          ].content.text = newTrans;
          return newState;
        });
        break;
      case "answer.text":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.pageList[params.pageIdx].gridContainerList[params.gcIdx]
                .gridItemList[params.giIdx].articleList[params.articleIdx]
                .answerList[params.answerIdx].text
            )
          );
          newTrans[lang] = params.value;
          newState.pageList[params.pageIdx].gridContainerList[
            params.gcIdx
          ].gridItemList[params.giIdx].articleList[
            params.articleIdx
          ].answerList[params.answerIdx].text = newTrans;
          return newState;
        });
        break;
      case "answer.group":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.pageList[params.pageIdx].gridContainerList[params.gcIdx]
                .gridItemList[params.giIdx].articleList[params.articleIdx]
                .answerList[params.answerIdx].group
            )
          );
          newTrans[lang] = params.value;
          newState.pageList[params.pageIdx].gridContainerList[
            params.gcIdx
          ].gridItemList[params.giIdx].articleList[
            params.articleIdx
          ].answerList[params.answerIdx].group = newTrans;
          return newState;
        });
        break;
      case "row.text":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.pageList[params.pageIdx].gridContainerList[params.gcIdx]
                .gridItemList[params.giIdx].articleList[params.articleIdx]
                .rowList[params.rowIdx].text
            )
          );
          newTrans[lang] = params.value;
          newState.pageList[params.pageIdx].gridContainerList[
            params.gcIdx
          ].gridItemList[params.giIdx].articleList[params.articleIdx].rowList[
            params.rowIdx
          ].text = newTrans;
          return newState;
        });
        break;
      case "col.text":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.pageList[params.pageIdx].gridContainerList[params.gcIdx]
                .gridItemList[params.giIdx].articleList[params.articleIdx]
                .columnList[params.colIdx].text
            )
          );
          newTrans[lang] = params.value;
          newState.pageList[params.pageIdx].gridContainerList[
            params.gcIdx
          ].gridItemList[params.giIdx].articleList[
            params.articleIdx
          ].columnList[params.colIdx].text = newTrans;
          return newState;
        });
        break;
      case "col.inputLabel":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.pageList[params.pageIdx].gridContainerList[params.gcIdx]
                .gridItemList[params.giIdx].articleList[params.articleIdx]
                .columnList[params.colIdx].inputLabel
            )
          );
          newTrans[lang] = params.value;
          newState.pageList[params.pageIdx].gridContainerList[
            params.gcIdx
          ].gridItemList[params.giIdx].articleList[
            params.articleIdx
          ].columnList[params.colIdx].inputLabel = newTrans;
          return newState;
        });
        break;
      case "validation.errorMsg":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.pageList[params.pageIdx].gridContainerList[params.gcIdx]
                .gridItemList[params.giIdx].articleList[params.articleIdx]
                .validation[params.validationIdx].errorMsg
            )
          );
          newTrans[lang] = params.value;
          newState.pageList[params.pageIdx].gridContainerList[
            params.gcIdx
          ].gridItemList[params.giIdx].articleList[
            params.articleIdx
          ].validation[params.validationIdx].errorMsg = newTrans;
          return newState;
        });
        break;
      case "score.category.label":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.scoring[params.scoreIdx].grouping.categoryList[
                params.labelIdx
              ].label
            )
          );
          newTrans[lang] = params.value;
          newState.scoring[params.scoreIdx].grouping.categoryList[
            params.labelIdx
          ].label = newTrans;
          return newState;
        });
        break;
      case "score.category.feedback":
        props.setObj((prevState) => {
          newState = JSON.parse(JSON.stringify(prevState));
          newTrans = JSON.parse(
            JSON.stringify(
              newState.scoring[params.scoreIdx].grouping.categoryList[
                params.labelIdx
              ].feedback
            )
          );
          newTrans[lang] = params.value;
          newState.scoring[params.scoreIdx].grouping.categoryList[
            params.labelIdx
          ].feedback = newTrans;
          return newState;
        });
        break;
      default:
        return;
    }
  };
  // ===============================================
  // SUB-COMPONENTs
  const TranslateRow = (key, values, fieldname, changeFcnOptions) => {
    return (
      ((values && values.en !== null) || Object.keys(values).length > 1) && (
        <Grid container className="mb-2">
          {languages.map((lang) => (
            <Grid item xs key={`${key}-${fieldname}-${lang}`} className="px-2">
              <TextField
                fullWidth
                multiline
                label={`${fieldname
                  .replaceAll(".", " ")
                  .toUpperCase()} ${lang.toUpperCase()}`}
                size="small"
                type="text"
                value={values && values[lang] ? values[lang] : ""}
                color="secondary"
                variant="filled"
                className="answer-text-green"
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                }}
                onChange={(e) => {
                  handleSetObj(fieldname, lang, {
                    ...changeFcnOptions,
                    value: e.target.value,
                  });
                }}
              />
            </Grid>
          ))}
        </Grid>
      )
    );
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <Box>
      <Paper className="mt-2 mx-2 px-1 pt-3 pb-1">
        <Grid container alignItems="center">
          {TranslateRow(
            `survey-official-name`,
            props.obj.name.official,
            "name.official",
            {}
          )}
          {TranslateRow(
            `survey-display-name`,
            props.obj.name.display,
            "name.display",
            {}
          )}
        </Grid>
      </Paper>
      {/* ----------------------------------------------- */}
      {/* PAGES */}
      <Box className="mx-2 px-1 pt-3 pb-1">
        {props.obj.pageList.map((page, i) => (
          <Grid
            container
            key={page._id}
            alignItems="center"
            className="border border-secondary rounded bg-transparant-grey pb-2 mb-2"
          >
            <Grid item xs={12}>
              <Typography variant="overline" color="slategray" className="ps-2">
                {`page ${i + 1}`}
              </Typography>
            </Grid>
            {page.title && page.title.text && (
              <Grid item className="flex-grow-1">
                <Grid container>
                  {TranslateRow(
                    `${page._id}-title`,
                    page.title.text,
                    "page.title.text",
                    {
                      pageIdx: i,
                    }
                  )}
                </Grid>
              </Grid>
            )}
            {page.gridContainerList.map((gc, j) => (
              <Grid
                container
                key={gc._id}
                alignItems="center"
                className="border border-secondary border-dashed rounded bg-transparant-grey pb-2 mt-2 mx-2"
              >
                <Grid item xs={12}>
                  <Typography
                    variant="overline"
                    color="slategray"
                    className="ps-2"
                  >
                    {`container ${j + 1}`}
                  </Typography>
                </Grid>
                {gc.title &&
                  TranslateRow(
                    `${gc._id}-title`,
                    gc.title.text,
                    "gc.title.text",
                    {
                      pageIdx: i,
                      gcIdx: j,
                    }
                  )}
                <Card className="w-100 mx-2 p-2 pb-0">
                  {gc.gridItemList.map((gi, k) => (
                    <>
                      {gi.title &&
                        TranslateRow(
                          `${gi._id}-title`,
                          gi.title.text,
                          "gi.title.text",
                          {
                            pageIdx: i,
                            gcIdx: j,
                            giIdx: k,
                          }
                        )}
                      <Grid
                        container
                        key={gi._id}
                        alignItems="center"
                        className="border rounded mb-2"
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="overline"
                            color="slategray"
                            className="ps-2"
                          >
                            {`article box ${k + 1}`}
                          </Typography>
                        </Grid>
                        {gi.articleList.map((article, m) => (
                          <Grid item className="flex-grow-1">
                            {/* FORMLABEL */}
                            {article.formLabel &&
                              TranslateRow(
                                `${article._id}-formlabel`,
                                article.formLabel,
                                "article.formLabel",
                                {
                                  pageIdx: i,
                                  gcIdx: j,
                                  giIdx: k,
                                  articleIdx: m,
                                }
                              )}
                            {/* INPUTLABEL */}
                            {article.inputLabel &&
                              TranslateRow(
                                `${article._id}-inputlabel`,
                                article.inputLabel,
                                "article.inputLabel",
                                {
                                  pageIdx: i,
                                  gcIdx: j,
                                  giIdx: k,
                                  articleIdx: m,
                                }
                              )}
                            {/* TITLE */}
                            {article.title &&
                              TranslateRow(
                                `${article._id}-title`,
                                article.title.text,
                                "article.title.text",
                                {
                                  pageIdx: i,
                                  gcIdx: j,
                                  giIdx: k,
                                  articleIdx: m,
                                }
                              )}
                            {/* CONTENT.TEXT */}
                            {article.content &&
                              TranslateRow(
                                `${article._id}-content`,
                                article.content.text,
                                "article.content.text",
                                {
                                  pageIdx: i,
                                  gcIdx: j,
                                  giIdx: k,
                                  articleIdx: m,
                                }
                              )}
                            {/* INSTRUCTIONS.TEXT */}
                            {article.instructions &&
                              TranslateRow(
                                `${article._id}-instructions`,
                                article.instructions.text,
                                "article.instructions.text",
                                {
                                  pageIdx: i,
                                  gcIdx: j,
                                  giIdx: k,
                                  articleIdx: m,
                                }
                              )}
                            {/* ROWS */}
                            {article.rowList && (
                              <Grid container>
                                <Grid
                                  item
                                  className="flex-grow-1 border border-dashed rounded mx-2 mb-2"
                                >
                                  {article.rowList.map((row, r) => (
                                    <React.Fragment key={row._id}>
                                      <Grid item xs={12}>
                                        <Typography
                                          variant="overline"
                                          color="slategray"
                                          className="ps-2"
                                        >
                                          {`row ${r + 1}`}
                                        </Typography>
                                      </Grid>
                                      {/* ROW.FORMLABEL */}
                                      {TranslateRow(
                                        `${row._id}-formLabel`,
                                        row.formLabel,
                                        "row.formLabel",
                                        {
                                          pageIdx: i,
                                          gcIdx: j,
                                          giIdx: k,
                                          articleIdx: m,
                                          rowIdx: r,
                                        }
                                      )}
                                      {/* ROW.INPUTLABEL */}
                                      {TranslateRow(
                                        `${row._id}-inputLabel`,
                                        row.inputLabel,
                                        "row.inputLabel",
                                        {
                                          pageIdx: i,
                                          gcIdx: j,
                                          giIdx: k,
                                          articleIdx: m,
                                          rowIdx: r,
                                        }
                                      )}
                                      {/* ROW.TEXT */}
                                      {TranslateRow(
                                        `${row._id}-text`,
                                        row.text,
                                        "row.text",
                                        {
                                          pageIdx: i,
                                          gcIdx: j,
                                          giIdx: k,
                                          articleIdx: m,
                                          rowIdx: r,
                                        }
                                      )}
                                    </React.Fragment>
                                  ))}
                                </Grid>
                              </Grid>
                            )}
                            {/* COLUMNS */}
                            {article.columnList && (
                              <Grid container>
                                <Grid
                                  item
                                  className="flex-grow-1 border border-dashed rounded mx-2 mb-2"
                                >
                                  {article.columnList.map((col, c) => (
                                    <React.Fragment key={col._id}>
                                      <Grid item xs={12}>
                                        <Typography
                                          variant="overline"
                                          color="slategray"
                                          className="ps-2"
                                        >
                                          {`column ${c + 1}`}
                                        </Typography>
                                      </Grid>
                                      {/* COL.INPUTLABEL */}
                                      {TranslateRow(
                                        `${col._id}-inputLabel`,
                                        col.inputLabel,
                                        "col.inputLabel",
                                        {
                                          pageIdx: i,
                                          gcIdx: j,
                                          giIdx: k,
                                          articleIdx: m,
                                          colIdx: c,
                                        }
                                      )}
                                      {/* COL.TEXT */}
                                      {TranslateRow(
                                        `${col._id}-text`,
                                        col.text,
                                        "col.text",
                                        {
                                          pageIdx: i,
                                          gcIdx: j,
                                          giIdx: k,
                                          articleIdx: m,
                                          colIdx: c,
                                        }
                                      )}
                                    </React.Fragment>
                                  ))}
                                </Grid>
                              </Grid>
                            )}
                            {/* ANSWERS */}
                            {article.answerList && (
                              <Grid container>
                                <Grid
                                  item
                                  className="flex-grow-1 border border-dashed rounded mx-2 mb-2"
                                >
                                  {article.answerList.map((answer, a) => (
                                    <React.Fragment
                                      key={`${article._id}-answer-${a}`}
                                    >
                                      <Grid item xs={12}>
                                        <Typography
                                          variant="overline"
                                          color="slategray"
                                          className="ps-2"
                                        >
                                          {`answer option ${a + 1}`}
                                        </Typography>
                                      </Grid>
                                      {/* COL.INPUTLABEL */}
                                      {TranslateRow(
                                        `answer-${answer.value}-text`,
                                        answer.text,
                                        "answer.text",
                                        {
                                          pageIdx: i,
                                          gcIdx: j,
                                          giIdx: k,
                                          articleIdx: m,
                                          answerIdx: a,
                                        }
                                      )}
                                      {/* COL.TEXT */}
                                      {TranslateRow(
                                        `answer-${answer.value}-group`,
                                        answer.group,
                                        "answer.group",
                                        {
                                          pageIdx: i,
                                          gcIdx: j,
                                          giIdx: k,
                                          articleIdx: m,
                                          answerIdx: a,
                                        }
                                      )}
                                    </React.Fragment>
                                  ))}
                                </Grid>
                              </Grid>
                            )}
                            {/* VALIDATION */}
                            {article.validation && (
                              <Grid container>
                                <Grid
                                  item
                                  className="flex-grow-1 border border-dashed rounded mx-2 mb-2"
                                >
                                  {article.validation.map(
                                    (validation, v) =>
                                      validation.test && (
                                        <React.Fragment
                                          key={`${article._id}-validation-${v}`}
                                        >
                                          <Grid item xs={12}>
                                            <Typography
                                              variant="overline"
                                              color="slategray"
                                              className="ps-2"
                                            >
                                              validation error message
                                            </Typography>
                                          </Grid>
                                          {/* COL.INPUTLABEL */}
                                          {TranslateRow(
                                            `validation-${v}-error-message`,
                                            validation.errorMsg,
                                            "validation.errorMsg",
                                            {
                                              pageIdx: i,
                                              gcIdx: j,
                                              giIdx: k,
                                              articleIdx: m,
                                              validationIdx: v,
                                            }
                                          )}
                                        </React.Fragment>
                                      )
                                  )}
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  ))}
                </Card>
              </Grid>
            ))}
          </Grid>
        ))}
        {props.obj.scoring &&
          props.obj.scoring.filter((score) => score.method !== "arithmetic")
            .length > 0 && (
            <Paper className="mt-2 px-2 pt-1 pb-1">
              <Grid container>
                {props.obj.scoring.map((score, i) =>
                  score.method === "classification-label" ? (
                    <React.Fragment key={`score-${score._id}`}>
                      <Grid item xs={12}>
                        <Typography
                          variant="overline"
                          color="slategray"
                          className="ps-2"
                        >
                          {`Score classification to label ${i + 1}`}
                        </Typography>
                      </Grid>
                      {score.grouping.categoryList.map((cat, j) => (
                        <React.Fragment key={`score-${score._id}-label-${j}`}>
                          {TranslateRow(
                            `score-${score._id}-label`,
                            cat.label,
                            "score.category.label",
                            {
                              scoreIdx: i,
                              labelIdx: j,
                            }
                          )}
                          {TranslateRow(
                            `score-${score._id}-feedback`,
                            cat.feedback,
                            "score.category.feedback",
                            {
                              scoreIdx: i,
                              labelIdx: j,
                            }
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : score.method === "classification-value" ? (
                    <React.Fragment key={`score-${score._id}`}>
                      <Grid item xs={12}>
                        <Typography
                          variant="overline"
                          color="slategray"
                          className="ps-2"
                        >
                          {`Score classification to value ${i + 1}`}
                        </Typography>
                      </Grid>
                      {score.grouping.categoryList.map((cat, j) => (
                        <React.Fragment key={`score-${score._id}-value-${j}`}>
                          {TranslateRow(
                            `score-${score._id}-feedback`,
                            cat.feedback,
                            "score.category.feedback",
                            {
                              scoreIdx: i,
                              labelIdx: j,
                            }
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : null
                )}
              </Grid>
            </Paper>
          )}
      </Box>
    </Box>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditTranslate;
