// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Component elements
import ItemQuestion from "./ItemQuestion";
import DropdownMenuItem from "./DropdownMenuItem";
import DropdownMenuListItem from "./DropdownMenuListItem";
// -------------------------------------------------
// Basic elements
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Slide from "@mui/material/Slide";
// ------------------------------------------
// Support funct
import { getAliasContainingId } from "../../supportFunc/getAliasContainingId";
// =================================================
// FUNCTIONAL COMPONENT
const DropdownMenuList = (props) => {
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  const slideDirection = useSelector((state) => state.form.slideDirection);
  // ===============================================
  // RENDER COMPONENT
  return !isXS ? (
    <Table>
      <TableBody>
        {props.article.rowList.map((row, i) => (
          <DropdownMenuListItem
            key={row._id}
            doShow={props.doShow}
            uneditable={props.uneditable}
            isLast={i === props.article.rowList.length - 1}
            dependencies={props.dependencies}
            aliasList={props.aliasList}
            scoring={props.scoring}
            article={row}
            answerList={props.article.answerList}
            firstColumnWidth={props.article.options.firstColumnWidth}
            articleRequired={props.article.required}
            required={props.article.required || row.required}
            alias={Array.isArray(props.alias) ? props.alias[i] : props.alias}
          />
        ))}
      </TableBody>
    </Table>
  ) : (
    props.article.rowList
      .filter((row) => row._id === props.rowId || props.doShow)
      .map((row, i) => (
        <Slide
          direction={slideDirection}
          in={true}
          appear={
            (slideDirection === "left" &&
              row._id !== props.article.rowList[0]._id) ||
            (slideDirection === "right" &&
              row._id !==
                props.article.rowList[props.article.rowList.length - 1]._id)
          }
          key={row._id}
        >
          <div>
            <ItemQuestion
              name={getAliasContainingId(props.aliasList, row._id, false)}
              item={row}
              required={!props.article.required && row.required}
              text={row.text}
              doShow={props.doShow}
              dependencies={props.dependencies}
              aliasList={props.aliasList}
              scoring={props.scoring}
              alias={getAliasContainingId(props.aliasList, row._id, false)}
              justifyContent="flex-start"
              alignItems="center"
              classNames="p-2"
            />
            <DropdownMenuItem
              doShow={props.doShow}
              uneditable={props.uneditable}
              isLast={
                i ===
                props.article.rowList.filter(
                  (row) => row._id === props.rowId || props.doShow
                ).length -
                  1
              }
              article={row}
              answerList={props.article.answerList}
              required={props.article.required || row.required}
              alias={getAliasContainingId(props.aliasList, row._id, false)}
            />
          </div>
        </Slide>
      ))
  );
};
// =================================================
// EXPORT COMPONENT
export default DropdownMenuList;
