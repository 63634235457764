import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Grid from "@mui/material/Grid";
import { Tooltip, IconButton } from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";

import {
  fetchWebpageList,
  webpageSelectors,
  patchCurrentWebpage,
  deleteCurrentWebpage,
} from "../redux/reducers/webpages";

import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";

import ScrollColumn from "../components/App_ScrollColumn";
import CmsEditorContent from "../components/CmsEditor_Content";
import CmsEditorHeader from "../components/CmsEditor_Header";
import WebpageList from "../components/CmsEditor_ColumnContent";
import AppRoot from "../components/App_Root";
import ContentPanel from "../components/App_ContentPanel";
import AddWebpageDialog from "../components/CmsEditor/AddWebpageDialog";

const CMSEditor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [webpageId, setWebpageId] = useState(null);
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  const webpagesStatus = useSelector((state) => state.webpages.status);
  const webpagesList = useSelector(webpageSelectors.selectAll);

  // No need to keep separate state for currentWebpageID, use webpageId from URL params instead
  const currentWebpage = useMemo(() => {
    return webpagesList.find((webpage) => webpage._id === webpageId);
  }, [webpagesList, webpageId]);

  useEffect(() => {
    if (webpagesStatus === "idle" || webpagesStatus === "partial") {
      dispatch(fetchWebpageList({ socket, requestingUser: currentAuth }));
    }
    // Get the study-id from URL and get the current study
    if (webpagesStatus === "succeeded") {
      const searchParams = new URLSearchParams(location.search);
      const webpageId = searchParams.get("webpageId");
      if (webpageId) {
        setWebpageId(webpageId);
      } else {
        setWebpageId(null);
      }
    }
  }, [webpagesStatus, dispatch, currentAuth, location.search, socket]);

  const handlePageSelect = (webpageId) => {
    setWebpageId(webpageId);
    navigate(`/cmseditor?webpageId=${webpageId}`, { replace: true });
  };

  const handleMutateCurrentWebpage = async (updates) => {
    if (!currentWebpage) return;

    const updatedWebpage = { ...currentWebpage, ...updates };
    await dispatch(
      patchCurrentWebpage({
        socket,
        requestingUser: currentAuth,
        body: { data: updatedWebpage },
      })
    );
  };

  const handleDeleteWebpage = async () => {
    if (!currentWebpage) return;

    await dispatch(
      deleteCurrentWebpage({
        socket,
        requestingUser: currentAuth,
        webpageId: currentWebpage._id,
      })
    );

    navigate("/cmseditor", { replace: true }); // navigate back to some default route after deletion
  };

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const WebpageListColumnHeader = () => (
    <>
      <Tooltip arrow title="Add new website" placement="top">
        <IconButton
          color="inherit"
          onClick={() => {
            setMenuIsOpen(true);
          }}
        >
          <AddCircle />
        </IconButton>
      </Tooltip>
      <AddWebpageDialog
        isOpen={menuIsOpen}
        onClose={() => setMenuIsOpen(false)}
      />
    </>
  );

  return (
    <AppRoot>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE} | CMS Editor</title>
      </Helmet>
      <Grid container className="w-100" wrap="nowrap">
        <ScrollColumn
          header={<WebpageListColumnHeader />}
          isLoading={webpagesStatus === "loading"}
        >
          <WebpageList
            currentWebpageId={webpageId}
            webpagesList={webpagesList}
            handlePageSelect={handlePageSelect}
          />
        </ScrollColumn>

        <ContentPanel
          title={currentWebpage ? currentWebpage.title : null}
          buttons={
            <CmsEditorHeader
              handleDelete={handleDeleteWebpage}
              currentWebpage={currentWebpage}
            />
          }
        >
          <CmsEditorContent
            currentWebpage={currentWebpage}
            handleSave={handleMutateCurrentWebpage}
            webpagesStatus={webpagesStatus}
          />
        </ContentPanel>
      </Grid>
    </AppRoot>
  );
};

export default CMSEditor;
