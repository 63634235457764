// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
// -------------------------------------------------
// Redux
import { toggleCloak } from "../redux/reducers/user";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// -------------------------------------------------
// Icons
import SvgIcon from "@mui/material/SvgIcon";
import { mdiGhost } from "@mdi/js";
// =================================================
// FUNCTIONAL COMPONENT
const VolunteersHeader = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "volunteers.Volunteers_Header" });
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Redux
  const dispatch = useDispatch();
  // ===============================================
  // FUNCTIONS
  // -------------------------------------------------
  // Enable cloaking
  const handleCloak = () => {
    dispatch(toggleCloak({ cloak: true, currentUser: props.currentConsumer }));
  };
  // ===============================================
  // RENDER COMPONENT
  // -------------------------------------------------
  return props.currentConsumer ? (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className="w-100">
      <Grid item />
      <Grid item>
        <Tooltip arrow title={ t("Cloak as volunteer") } placement="top">
          <span>
            <IconButton
              component={NavLink}
              color="inherit"
              to="/tasks"
              className="mx-3"
              onClick={handleCloak}>
              <SvgIcon>
                <path d={mdiGhost} />
              </SvgIcon>
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default VolunteersHeader;
