// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
// -------------------------------------------------
// Basic elements
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditLogicSelectAnswerMenu = (props) => {
  // ===============================================
  // RENDER COMPONENT
  return props.operator === "CONT" || props.operator === "NCONT" ? (
    <TextField
      fullWidth
      hiddenLabel
      type="text"
      name="text"
      variant="filled"
      size="small"
      value={props.value ? props.value : ""}
      color="secondary"
      className="answer-text-green pt-1"
      onChange={props.onChange}
    />
  ) : props.answerList &&
    (props.operator === "EQ" ||
      props.operator === "NEQ" ||
      props.operator === "GT" ||
      props.operator === "GTEQ" ||
      props.operator === "LT" ||
      props.operator === "LTEQ") ? (
    <FormControl fullWidth variant="filled" color="secondary" size="small">
      <Select
        autoWidth
        hiddenLabel
        name="item"
        value={props.value}
        className="answer-text-green"
        color="secondary"
        onChange={props.onChange}
      >
        answerList
        {props.answerList.map((answer, i) => (
          <MenuItem
            key={`edit-logic-answer-menu_item-${i}`}
            value={answer.value}
          >
            <Chip
              label={
                <Typography variant="overline" color="textSecondary">
                  {answer.value}
                </Typography>
              }
              variant={props.reverseValue ? "outlined" : "filled"}
              size="small"
              className="me-2"
            />
            {answer.text}
          </MenuItem>
        ))}
        answerList
      </Select>
    </FormControl>
  ) : !props.answerList &&
    (props.operator === "EQ" ||
      props.operator === "NEQ" ||
      props.operator === "GT" ||
      props.operator === "GTEQ" ||
      props.operator === "LT" ||
      props.operator === "LTEQ") ? (
    <TextField
      fullWidth
      hiddenLabel
      type="number"
      name="text"
      variant="filled"
      size="small"
      value={props.value ? props.value : ""}
      color="secondary"
      className="answer-text-green pt-1"
      onChange={props.onChange}
      onWheel={(e) => e.target.blur()}
    />
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditLogicSelectAnswerMenu;
