// =================================================
// IMPORT
const { unique } = require("./unique");
const { findNestedObject } = require("./findNestedObject");
// =================================================
// SUB FUNCTIONS
// -------------------------------------------------
// Determines if the current row is the last row of the current article
const isLast = (key, survey, parentId, childId) => {
  if (!parentId || !childId) {
    return true;
  }
  const parent = findNestedObject(survey.pageList, parentId);
  return parent[key] && parent[key][parent[key].length - 1]._id === childId;
};
// -------------------------------------------------
// Get the next view
const nextViewIdx = (survey, index, view) => {
  // Deep copy the object so we're not mutating the original
  const idx = JSON.parse(JSON.stringify(index));
  if (
    !isLast(
      "rowList",
      survey,
      view.articleIds[view.articleIds.length - 1],
      view.rowId
    )
  ) {
    // Increment the row
    idx.row = idx.row + 1;
  } else if (
    !isLast(
      "articleList",
      survey,
      view.gridItemIds[view.gridItemIds.length - 1],
      view.articleIds[view.articleIds.length - 1]
    )
  ) {
    // Increment the article
    idx.article = idx.article + 1;
    idx.row = 0;
  } else if (
    !isLast(
      "gridItemList",
      survey,
      view.gridContainerIds[view.gridContainerIds.length - 1],
      view.gridItemIds[view.gridItemIds.length - 1]
    )
  ) {
    // Increment the griditem
    idx.gridItem = idx.gridItem + 1;
    idx.article = 0;
    idx.row = 0;
  } else if (
    !isLast(
      "gridContainerList",
      survey,
      view.pageId,
      view.gridContainerIds[view.gridContainerIds.length - 1]
    )
  ) {
    // Increment the grid container
    idx.gridContainer = idx.gridContainer + 1;
    idx.gridItem = 0;
    idx.article = 0;
    idx.row = 0;
  } else if (
    survey.pageList.length > 0 &&
    survey.pageList[survey.pageList.length - 1]._id !== view.pageId
  ) {
    // Increment the page
    idx.page = idx.page + 1;
    idx.gridContainer = 0;
    idx.gridItem = 0;
    idx.article = 0;
    idx.row = 0;
  } else {
    idx.page = null; // End of survey
  }
  return idx;
};
// =================================================
// EXPORT FUNCTION
// -------------------------------------------------
// Sets the identifiers of the items to show
export const getSurveyViews = (survey) => {
  // -------------------------------------------------
  // Check if we have valid input
  if (!survey) {
    return -1;
  }
  // -------------------------------------------------
  // Ok valid input, calculate the views
  // -------------------------------------------------
  // Init vars
  let idx = {
    page: 0,
    gridContainer: 0,
    gridItem: 0,
    article: 0,
    row: 0,
    counter: 0,
  };
  let endOfSurvey = false;
  let views = [];
  let newView, page, gridContainer, gridItem, article, row, peekIdx;
  // -------------------------------------------------
  // Create views for all pages, grids, articles and rows
  while (!endOfSurvey) {
    // Counter
    idx.counter = idx.counter + 1;
    // Extract the current page, grids, article etc
    page = survey.pageList && survey.pageList[idx.page];
    gridContainer =
      page &&
      page.gridContainerList &&
      page.gridContainerList[idx.gridContainer];
    gridItem =
      gridContainer &&
      gridContainer.gridItemList &&
      gridContainer.gridItemList[idx.gridItem];
    article =
      gridItem && gridItem.articleList && gridItem.articleList[idx.article];
    row = null;
    // Assemble the new view
    newView = {
      pageId: page && page._id,
      gridContainerIds: gridContainer ? [gridContainer._id] : [],
      gridItemIds: gridItem ? [gridItem._id] : [],
      articleIds: article ? [article._id] : [],
      rowId: row ? row._id : null,
    };
    // -------------------------------------------------
    // Check the article category: "media" or "question"
    if (article && article.category === "media") {
      // -------------------------------------------------
      // MEDIA ARTICLE
      // -------------------------------------------------
      // Subsequent media articles on a page are shown in one view
      let nextArticleIsMedia = true;
      let nextGridItem, nextArticle;
      // Accrue all articles for as long as they are media types
      while (nextArticleIsMedia) {
        // -------------------------------------------------
        // Have a sneek-peek at the next index to see if the next article is media too
        peekIdx = nextViewIdx(survey, idx, newView);
        // -------------------------------------------------
        // If there are no more pages or the next view index is on a new page, then break from while loop
        if (peekIdx.page === null || peekIdx.page !== idx.page) {
          nextArticleIsMedia = false;
        } else {
          // -------------------------------------------------
          // ... ok we're still on the same page
          // Check if the next article is media too
          nextGridItem =
            survey.pageList &&
            survey.pageList[peekIdx.page].gridContainerList &&
            survey.pageList[peekIdx.page].gridContainerList[
              peekIdx.gridContainer
            ].gridItemList &&
            survey.pageList[peekIdx.page].gridContainerList[
              peekIdx.gridContainer
            ].gridItemList.length > 0
              ? survey.pageList[peekIdx.page].gridContainerList[
                  peekIdx.gridContainer
                ].gridItemList[peekIdx.gridItem]
              : null;
          nextArticle =
            nextGridItem &&
            nextGridItem.articleList &&
            nextGridItem.articleList.length > 0
              ? nextGridItem.articleList[peekIdx.article]
              : null;
          if (nextArticle && nextArticle.category !== "media") {
            // -------------------------------------------------
            // The next article was not a media type, break from the while loop
            nextArticleIsMedia = false;
          } else {
            // -------------------------------------------------
            // Push the grid container id to the new view
            newView.gridContainerIds.push(
              survey.pageList[peekIdx.page].gridContainerList[
                peekIdx.gridContainer
              ]._id
            );
            newView.gridContainerIds = unique(newView.gridContainerIds);
            // Push the griditem id to the new view
            newView.gridItemIds.push(nextGridItem ? nextGridItem._id : null);
            newView.gridItemIds = unique(newView.gridItemIds);
            // Push the article id to the new view
            newView.articleIds.push(nextArticle ? nextArticle._id : null);
            newView.articleIds = unique(newView.articleIds);
            // -------------------------------------------------
            // Overwrite the index and continue with the next iteration in the while loop
            idx = JSON.parse(JSON.stringify(peekIdx));
          }
        }
      }
    } else if (article && article.category === "question") {
      // -------------------------------------------------
      // QUESTION ARTICLE
      // -------------------------------------------------
      // If the article has rows, show one row at a time (except for checkboxes, textbox-lists and sliders)
      // Set which row should be shown
      newView.rowId =
        article.type === "checkboxes" ||
        article.type === "textbox-list" ||
        article.type === "slider-horizontal"
          ? null // Exceptions
          : article.rowList // If there are articles, show the one row only
          ? article.rowList[idx.row]._id
          : null; // There are no rows, so row id is null
    }
    // -------------------------------------------------
    // Add the new view to the array of views
    views.push(newView);
    // -------------------------------------------------
    // Increment the indices to assemble the next view
    idx = nextViewIdx(survey, idx, newView);
    // -------------------------------------------------
    // Check if we have reached the end
    endOfSurvey = idx.page === null;
    // -------------------------------------------------
    // Insurance policy to make sure while loop does not run forever
    if (idx.counter > 9999) {
      console.log(
        "Critical unexpected error, got stuck in while loop in 'getSurveyViews'."
      );
      return -1; // Something has gone wrong, return error code (-1)
    }
  }
  // -------------------------------------------------
  // Reached the end, return the views
  return views;
};
