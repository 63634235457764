// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { DateTime } from "luxon";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
// -------------------------------------------------
// Components
import AppRoot from "../components/App_Root";
import ScrollColumn from "../components/App_ScrollColumn";
import ContentPanel from "../components/App_ContentPanel";
import TasksColumnHeader from "../components/Tasks_ColumnHeader";
import TasksColumnContent from "../components/Tasks_ColumnContent";
import TasksContent from "../components/Tasks_Content";
// -------------------------------------------------
// Redux
import { togglePrimDrawer } from "../redux/reducers/ui";
import {
  setCurrentTicketById,
  setCurrentTicketByObject,
  ticketsSelectors,
} from "../redux/reducers/tickets";
import { fetchSurveyById, surveysSelectors } from "../redux/reducers/surveys";
import { resetFormState } from "../redux/reducers/form";
import { setAlert } from "../redux/reducers/ui";
// -------------------------------------------------
// Support functions
import { unique } from "../supportFunc/unique";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";

// =================================================
// FUNCTIONAL COMPONENT
const Tasks = () => {
  const { t } = useTranslation("pages", { keyPrefix: "Tasks" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // context
  const { currentAuth } = useAuth();
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const primDrawerIsOpen = useSelector((state) => state.ui.primDrawerIsOpen);
  const currentUser = useSelector((state) => state.user.currentUser);
  const ghostUser = useSelector((state) => state.user.ghostUser);
  const ticketStatus = useSelector((state) => state.tickets.status);
  const currentTicket = useSelector((state) => state.tickets.currentTicket);
  const ticketEntities = useSelector((state) =>
    ticketsSelectors.selectEntities(state)
  );
  const surveyEntities = useSelector((state) =>
    surveysSelectors.selectEntities(state)
  );
  const currentSurvey = useSelector(
    (state) =>
      currentTicket &&
      surveysSelectors.selectById(state, currentTicket.surveyId)
  );
  // -----------------------------------------------
  // Browser URL location
  const location = useLocation();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Upon render, get the ticketlist and set the redux state
  useEffect(() => {
    // Get the ticket-id from URL and get the current ticket
    if (
      ticketStatus === "succeeded" &&
      Object.keys(ticketEntities).length > 0
    ) {
      // -----------------------------------------------
      // Check if all required surveys are loaded
      // First extract all the unique survey id's from all the tickets
      const surveyIds = unique(
        Object.values(ticketEntities).map((ticket) => ticket.surveyId)
      );
      // Fetch all surveys from the tickets, if not already loaded
      surveyIds.forEach(
        (id) =>
          !surveyEntities[id] &&
          dispatch(
            fetchSurveyById({
              requestingUser: currentAuth,
              surveyId: id,
              upsert: true,
            })
          )
      );
      // -----------------------------------------------
      // Get the ticket Id from URL
      const searchParams = new URLSearchParams(location.search);
      const ticketId = searchParams.get("ticketId");
      if (ticketId) {
        // Check if the ticket exists
        if (!ticketEntities[ticketId]) {
          dispatch(
            setAlert({
              type: "snackbar",
              variant: "error",
              message: t("Could not find the requested ticket."),
              duration: 6000,
            })
          );
          dispatch(setCurrentTicketByObject({ ticket: null }));
          dispatch(resetFormState());
          if (primDrawerIsOpen) {
            dispatch(togglePrimDrawer({ isOpen: false }));
          }
          return;
        }
        // Check if the user is the owner of the ticket
        if (ticketEntities[ticketId].userId !== currentUser._id) {
          dispatch(
            setAlert({
              type: "snackbar",
              variant: "error",
              message: t("The requested ticket is not yours."),
              duration: 6000,
            })
          );
          dispatch(setCurrentTicketByObject({ ticket: null }));
          dispatch(resetFormState());
          if (primDrawerIsOpen) {
            dispatch(togglePrimDrawer({ isOpen: false }));
          }
          return;
        }
        // Check if the ticket is available and not expired
        // But only if not cloacked as a ghostUser
        if (
          (DateTime.fromISO(ticketEntities[ticketId].dateAvailable)
            .diffNow()
            .valueOf() > 0 ||
            (ticketEntities[ticketId].dateExpire &&
              DateTime.fromISO(ticketEntities[ticketId].dateExpire)
                .diffNow()
                .valueOf() < 0)) &&
          ghostUser === null
        ) {
          dispatch(
            setAlert({
              type: "snackbar",
              variant: "error",
              message: t("The requested ticket is not currently available."),
              duration: 6000,
            })
          );
          dispatch(setCurrentTicketByObject({ ticket: null }));
          dispatch(resetFormState());
          if (primDrawerIsOpen) {
            dispatch(togglePrimDrawer({ isOpen: false }));
          }
          return;
        }
        if (
          !currentTicket ||
          (currentTicket && currentTicket._id !== ticketId)
        ) {
          // Ticket exists, set current ticket if it is not already set
          dispatch(setCurrentTicketById({ ticketId }));
          dispatch(resetFormState());
          if (!primDrawerIsOpen) {
            dispatch(togglePrimDrawer({ isOpen: true }));
          }
        }
      } else {
        dispatch(setCurrentTicketByObject({ ticket: null }));
        dispatch(resetFormState());
        if (primDrawerIsOpen) {
          dispatch(togglePrimDrawer({ isOpen: false }));
        }
      }
    }
  }, [ticketStatus, location.search]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // SUB-COMPONENTS
  // -----------------------------------------------
  const ColumnHeader = <TasksColumnHeader />;
  // ===============================================
  // RENDER COMPONENT
  return (
    <AppRoot header={ColumnHeader}>
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE} | {t("Tasks")}
        </title>
      </Helmet>
      <Grid container className="w-100" wrap="nowrap">
        {/* Scroll column */}
        <ScrollColumn
          header={ColumnHeader}
          isLoading={ticketStatus === "loading"}
        >
          <TasksColumnContent />
        </ScrollColumn>
        {/* Content panel */}
        <ContentPanel
          isLoading={ticketStatus === "loading"}
          title={currentSurvey ? currentSurvey.name.display : null}
        >
          <TasksContent />
        </ContentPanel>
      </Grid>
    </AppRoot>
  );
};
// =================================================
// EXPORT COMPONENT
export default Tasks;
