// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
// -------------------------------------------------
// Components
import AppMainCardFooter from "./App_MainCardFooter";
// -------------------------------------------------
// Basic elements
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
// -------------------------------------------------
// Icons
import ChevronLeft from "@mui/icons-material/ChevronLeft";
// =================================================
// FUNCTIONAL COMPONENT
const MainCard = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "App_MainCard" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  // ===============================================
  // RENDER COMPONENT
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={isXS ? "h-100" : "vh-100"}
    >
      <Grid item xs={12} sm="auto">
        <Card className="shadow-lg w-100 max-width-501px">
          <CardHeader
            title={
              <Grid container wrap="nowrap" alignItems="center">
                <Grid item>
                  <img
                    alt={process.env.REACT_APP_TITLE}
                    src="logo42-inv.png"
                    className="rounded me-2"
                  />
                </Grid>
                <Grid item sx={{ width: "1px", paddingTop: "1px" }}>
                  <Typography
                    variant="overline"
                    component="div"
                    sx={{ lineHeight: "0.9rem" }}
                  >
                    {process.env.REACT_APP_TITLE}
                  </Typography>
                </Grid>
              </Grid>
            }
            action={
              props.hasAction && (
                <Button
                  component={NavLink}
                  to="/account"
                  color="inherit"
                  className="text-secondary text-decoration-none"
                >
                  <ChevronLeft />
                  <Typography variant="overline">{t("Account")}</Typography>
                </Button>
              )
            }
          />
          <Divider />
          <CardContent>
            {props.isLoading && (
              <LinearProgress
                color="secondary"
                className="lin-prog-margin-card"
              />
            )}
            {props.children}
          </CardContent>
        </Card>
        {!props.noFooter && <AppMainCardFooter />}
      </Grid>
    </Grid>
  );
};
// =================================================
// EXPORT COMPONENT
export default MainCard;
