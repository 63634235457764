// ########################################
// IMPORT
import { createSlice } from "@reduxjs/toolkit";
// ########################################
// INIT STATE
const initialState = {
  language: "en",
  currentPage: null,
  navIsOpen: false,
  primDrawerIsOpen: false,
  secDrawerIsOpen: false,
  secDrawerId: false,
  screenSize: document.documentElement.clientWidth,
  isXS: document.documentElement.clientWidth <= 675,
  isSM:
    document.documentElement.clientWidth > 675 &&
    document.documentElement.clientWidth <= 960,
  isMD:
    document.documentElement.clientWidth > 960 &&
    document.documentElement.clientWidth <= 1280,
  isLG:
    document.documentElement.clientWidth > 1280 &&
    document.documentElement.clientWidth <= 1920,
  isXL: document.documentElement.clientWidth > 1920,
  alert: null,
};
// ########################################
// DEFINE MUTATING ACTIONS
export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload.currentPage;
    },
    toggleNav(state, action) {
      state.navIsOpen = action.payload.isOpen;
    },
    togglePrimDrawer(state, action) {
      state.primDrawerIsOpen = action.payload.isOpen;
    },
    toggleSecDrawer(state, action) {
      state.secDrawerIsOpen = action.payload.isOpen;
      state.secDrawerId = action.payload.id;
    },
    setScreenSize(state) {
      state.screenSize = document.documentElement.clientWidth;
      state.isXS = document.documentElement.clientWidth <= 675;
      state.isSM =
        document.documentElement.clientWidth > 675 &&
        document.documentElement.clientWidth <= 960;
      state.isMD =
        document.documentElement.clientWidth > 960 &&
        document.documentElement.clientWidth <= 1280;
      state.isLG =
        document.documentElement.clientWidth > 1280 &&
        document.documentElement.clientWidth <= 1920;
      state.isXL = document.documentElement.clientWidth > 1920;
    },
    setAlert(state, action) {
      if (action.payload) {
        state.alert = {};
        state.alert.type = action.payload.type;
        state.alert.variant = action.payload.variant;
        state.alert.title = action.payload.title;
        state.alert.message = action.payload.message;
        state.alert.responseOptions = action.payload.responseOptions;
        state.alert.duration = action.payload.duration;
      } else {
        state.alert = null;
      }
    },
  },
});
// =================================================
// EXPORT ACTIONS
export const {
  setCurrentPage,
  toggleNav,
  togglePrimDrawer,
  toggleSecDrawer,
  setScreenSize,
  setAlert,
} = uiSlice.actions;
// =================================================
// EXPORT DEFAULT
export default uiSlice.reducer;
