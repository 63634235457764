// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Redux
import { setAlert } from "../redux/reducers/ui";
// -------------------------------------------------
// Basic elements
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
// =================================================
// FUNCTIONAL COMPONENT
const TasksColumnHeader = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "Tasks_ColumnHeader" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const isXS = useSelector((state) => state.ui.isXS);
  const isSM = useSelector((state) => state.ui.isSM);
  // -----------------------------------------------
  // Local state
  let timeoutId = null;
  const [clockTime, setClockTime] = useState(DateTime.now().toFormat("t"));
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Set the timeout clock
  useEffect(() => {
    // Set the interval at which to reload the clock
    handleSetClockTime();
    // Clean-up upon unmounting
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Handles setting the clock to the current time
  const handleSetClockTime = () => {
    setClockTime(DateTime.now().toFormat("H:mm a"));
    // Set next reload
    timeoutId = setTimeout(
      handleSetClockTime,
      DateTime.now()
        .startOf("minute")
        .plus({ minute: 1 })
        .diff(DateTime.now())
        .valueOf()
    );
  };
  // -----------------------------------------------
  // Opens a modal to explain the time
  const handleSetModalExplainer = () => {
    dispatch(
      setAlert({
        type: "dialog",
        title: DateTime.now().toFormat("d LLL yyyy h:mm a"),
        message:
          "The task-list is scheduled according to your local time on your device. If this time is not correct, than the tasks will not be scheduled correctly. The time may be incorrect because your device time is not correct, or you are using an internet connection with a VPN.",
      })
    );
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <Tooltip arrow title={ t("Your local time") } placement="top">
      <div
        className={
          !isXS && !isSM
            ? "bg-light border border-secondary rounded cursor-pointer"
            : "border border-light rounded cursor-pointer"
        }
        onClick={handleSetModalExplainer}>
        <Typography variant="caption" className="m-0 p-1 d-inline text-nowrap">
          {clockTime}
        </Typography>
      </div>
    </Tooltip>
  );
};
// =================================================
// EXPORT COMPONENT
export default TasksColumnHeader;
