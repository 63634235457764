import React from "react";
import { useEffect, useState } from "react";
import CmsEditorContentInfo from "./CmsEditor/CmsEditor_ContentInfo";
import CmsEditorContentFields from "./CmsEditor/CmsEditor_ContentFields";
import AppDrawerPlaceholder from "./App_DrawerPlaceholder";

const CmsEditorContent = ({ currentWebpage, webpagesStatus, handleSave }) => {
  const [contentList, setContentList] = useState(
    currentWebpage ? currentWebpage.contentList : []
  );

  const [info, setInfo] = useState(
    currentWebpage
      ? {
          title: currentWebpage.title,
          url: currentWebpage.url,
          description: currentWebpage.description,
          status: currentWebpage.status,
          type: currentWebpage.type,
          updatedAt: currentWebpage.updatedAt,
        }
      : {
          title: "",
          url: "",
          description: "",
          status: "",
          type: "",
          updatedAt: null,
        }
  );

  useEffect(() => {
    if (currentWebpage) {
      setContentList(currentWebpage.contentList);
    } else {
      setContentList([]);
    }

    if (currentWebpage) {
      setInfo({
        title: currentWebpage.title,
        url: currentWebpage.url,
        description: currentWebpage.description,
        status: currentWebpage.status,
        type: currentWebpage.type,
        updatedAt: currentWebpage.updatedAt,
      });
    } else {
      setInfo({
        title: "",
        url: "",
        description: "",
        status: "",
        type: "",
        updatedAt: null,
      });
    }
  }, [currentWebpage]);

  return !currentWebpage ? (
    <AppDrawerPlaceholder
      imageURL={
        webpagesStatus === "loading"
          ? "images/icon-wait.gif"
          : "images/icon-choose.png"
      }
      imageAlt={
        webpagesStatus === "loading"
          ? "Loading, please wait..."
          : "Choose a webpage from the list"
      }
      title={
        webpagesStatus === "loading"
          ? "Loading, please wait..."
          : "Choose a webpage from the list"
      }
    />
  ) : (
    <>
      <CmsEditorContentInfo
        currentWebpage={currentWebpage}
        handleSave={handleSave}
        info={info}
      />
      <CmsEditorContentFields
        handleSave={handleSave}
        contentList={contentList}
      />
    </>
  );
};

export default CmsEditorContent;
