import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import components_en from "./translations/en/components.json";
import components_nl from "./translations/nl/components.json";
import pages_en from "./translations/en/pages.json";
import pages_nl from "./translations/nl/pages.json";
import errors_en from "./translations/en/errors.json";
import errors_nl from "./translations/nl/errors.json";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    lng: process.env.REACT_APP_LANGUAGE || "en",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        components: components_en,
        pages: pages_en,
        errors: errors_en,
      },
      nl: {
        components: components_nl,
        pages: pages_nl,
        errors: errors_nl,
      },
    },
  });

export default i18n;
