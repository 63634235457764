// ########################################
// IMPORT
// ----------------------------------------
// Dependencies
const { DateTime } = require("luxon");
// ########################################
// EXPORT FUNCTION
// ----------------------------------------
// Calculates study schema
export const getStudyEnrollmentTimepoints = (
  study,
  currentEnrollmentDate,
  currentTimepointList
) => {
  // ----------------------------------------
  // Initialize some variables
  let timepointAssignmentList = [];
  let add;
  let nextWeekday;
  let thisDate;
  let prevDate = currentEnrollmentDate
    ? currentEnrollmentDate
    : DateTime.now().toISODate();
  // ----------------------------------------
  // For each timepoint...
  study.timepointList.forEach((timepoint, i) => {
    // ----------------------------------------
    // Get start date for each timepoint
    if (timepoint.start.type === "manual") {
      thisDate = currentTimepointList
        ? currentTimepointList[i].startDate
        : null;
    } else if (timepoint.start.type === "days") {
      thisDate = DateTime.fromISO(prevDate)
        .plus({ days: timepoint.start.delay - 1 })
        .toISODate();
    } else if (timepoint.start.type === "weekday") {
      nextWeekday = parseInt(
        DateTime.fromFormat(timepoint.start.dayOfTheWeek, "EEE").toFormat("c")
      );
      if (parseInt(DateTime.now().toFormat("c")) >= nextWeekday) {
        add = 1;
      } else {
        add = 0;
      }
      thisDate = DateTime.fromISO(prevDate)
        .plus({ weeks: timepoint.start.delay - 1 + add })
        .set({ weekday: nextWeekday })
        .toISODate();
    }
    // ----------------------------------------
    // Push the timepoint object to the array
    timepointAssignmentList.push({
      timepointId: timepoint._id,
      startDate: thisDate,
    });
    // ----------------------------------------
    // Set the previous date to the current date
    prevDate = thisDate;
  });
  return timepointAssignmentList;
};
