// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
// -------------------------------------------------
// Basic elements
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// =================================================
// FUNCTIONAL COMPONENT
const AppMainCardFooter = () => {
  const { t } = useTranslation("components", {
    keyPrefix: "App_MainCardFooter",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Context
  const { currentAuth } = useAuth();
  // ===============================================
  // RENDER COMPONENT
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      className="pt-3"
    >
      <Grid item className="text-center">
        <Button
          component={NavLink}
          to="/"
          color="inherit"
          className="px-3 mb-1"
        >
          {t("Home")}
        </Button>
      </Grid>
      <Grid item className="text-center">
        {!currentAuth ? (
          <Button
            component={NavLink}
            to="/sign-in"
            color="inherit"
            className="px-3 mb-1"
          >
            {t("Sign In")}
          </Button>
        ) : (
          <Button
            component={NavLink}
            to="/sign-out"
            color="inherit"
            className="px-3 mb-1"
          >
            {t("Sign Out")}
          </Button>
        )}
      </Grid>
      <Grid item className="text-center">
        {!currentAuth && (
          <Button
            component={NavLink}
            to="/register"
            color="inherit"
            className="px-3 mb-1"
          >
            {t("Register")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
// =================================================
// EXPORT COMPONENT
export default AppMainCardFooter;
