// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import MarkdownText from "./MarkdownText";
// -------------------------------------------------
// Support functions
import { array2string } from "../../supportFunc/array2string";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Link from "@mui/icons-material/Link";
import RuleIcon from "@mui/icons-material/Rule";
import Tooltip from "@mui/material/Tooltip";
// =================================================
// FUNCTIONAL COMPONENT
const ItemQuestion = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "form.ItemQuestion",
  });
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Get scored aliases
  const getScoredAliasList = () => {
    const aliasList =
      props.scoring &&
      props.scoring.map(
        (score) =>
          (score.arithmetic &&
          score.arithmetic.some((a) => a.aliasId.includes(props.item._id))
            ? score.alias
            : null) ||
          (score.grouping && score.grouping.aliasId.includes(props.item._id)
            ? score.alias
            : null)
      );
    return array2string(aliasList);
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <Grid
      container
      direction="row"
      justifyContent={props.justifyContent}
      alignItems={props.alignItems}
    >
      {props.doShow && (
        <span>
          {Object.values(props.aliasList)
            .filter((a) => a.rowId === props.item._id)
            .map((a) => (
              <Chip
                key={a.alias}
                label={
                  <Typography variant="overline" color="textSecondary">
                    {a.alias}
                  </Typography>
                }
                size="small"
                className="me-1"
              />
            ))}
          {props.item.logic !== null && (
            <Tooltip
              arrow
              title={`conditional on ${array2string(
                props.item.logic.map((logicGroup) =>
                  logicGroup.itemList.map(
                    (logicItem) =>
                      props.aliasList[logicItem.condition.aliasId].alias
                  )
                )
              )}`}
              placement="top"
            >
              <Chip
                icon={<Link />}
                label=""
                size="small"
                variant="outlined"
                color="info"
                className="border-0"
              />
            </Tooltip>
          )}
          {props.dependencies &&
            Object.keys(props.dependencies).some((dep) =>
              dep.includes(props.item._id)
            ) && (
              <Tooltip arrow title={t("dependent")} placement="top">
                <Link color="warning" fontSize="small" className="me-1" />
              </Tooltip>
            )}
          {props.item.validation &&
            props.item.validation.some((val) => val.test) && (
              <Tooltip
                arrow
                title={
                  <span>
                    {props.item.validation
                      .filter((val) => val.errorMsg)
                      .map((val, i) => (
                        <span
                          key={`item-${props.item._id}_validation-${i}`}
                          className="d-block"
                        >{`${val.errorMsg} `}</span>
                      ))}
                  </span>
                }
                placement="top"
              >
                <RuleIcon color="inherit" className="me-1" />
              </Tooltip>
            )}
          {props.scoring &&
            props.scoring.some(
              (score) =>
                (score.arithmetic &&
                  score.arithmetic.some((a) =>
                    a.aliasId.includes(props.item._id)
                  )) ||
                (score.grouping &&
                  score.grouping.aliasId.includes(props.item._id))
            ) && (
              <Chip
                icon={<Link />}
                label={t("scored in {{ scoredAliasList }}", {
                  scoredAliasList: getScoredAliasList(),
                })}
                size="small"
                variant="outlined"
                color="success"
                className="border-0 ms-2"
              />
            )}
        </span>
      )}
      <Typography
        variant="body1"
        textAlign={props.justifyContent === "flex-start" ? "left" : "right"}
        className={`item-question font-weight-bold ${props.classNames}`}
      >
        <MarkdownText>
          {props.required && !props.item.formLabel
            ? `${props.text} *`
            : props.text}
        </MarkdownText>
        {props.name && <span name={props.name} />}
      </Typography>
    </Grid>
  );
};
// =================================================
// EXPORT COMPONENT
export default ItemQuestion;
