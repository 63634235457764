// Example study
export const defaultStudies = {
  study: {
    _id: "",
    userIdList: [],
    groupList: [
      {
        _id: "",
        label: "screening",
      },
      {
        _id: "",
        label: "included",
      },
      {
        _id: "",
        label: "excluded",
      },
      {
        _id: "",
        label: "withdrawn",
      },
    ],
    timepointList: [
      {
        _id: "",
        label: "Consent",
        start: {
          type: "days",
          dayOfTheWeek: "Mon",
          delay: 1,
        },
      },
    ],
  },
  timepoint: {
    _id: "",
    label: "New timepoint",
    start: {
      type: "days",
      dayOfTheWeek: "Mon",
      delay: 1,
    },
    groupIdList: null,
    measurementList: null,
  },
};
