// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
import { unique } from "../../supportFunc/unique";
// -------------------------------------------------
// Component elements
import ArticleFormControl from "./ArticleFormControl";
import ItemAnswer from "./ItemAnswer";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
// =================================================
// FUNCTIONAL COMPONENT
const GroupedDropdownMenuItem = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "form.GroupedDropdownMenuItem",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [group, setGroup] = useState(null);
  const [localValidation, setLocalValidation] = useState(null);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias)
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id]
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
    // Clear any validation when component unmounts
    return () => {
      dispatch(initValidationByAlias({ alias: props.alias }));
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value })
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value })
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      })
    );
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // ===============================================
  // SUB-COMPONENTS
  const GroupItems = () => {
    const groupList = unique(
      props.answerList.map((answer) => (answer.group ? answer.group : ""))
    );
    return groupList.map((group, i) => {
      return [
        <MenuItem
          key={`dropdown-item_${props.alias}_${group}_${i}`}
          value={group}
        >
          {group}
        </MenuItem>,
      ];
    });
  };
  const MenuItems = () => {
    return props.answerList
      .filter((answer) => !answer.group || answer.group === group)
      .map((answer, j) => (
        <MenuItem
          key={`dropdown-item_${props.alias}_${answer.value}-${j}]`}
          value={answer.value}
        >
          <div className="d-flex">
            {props.doShow && (
              <Chip
                label={
                  <Typography variant="overline" color="textSecondary">
                    {answer.value}
                  </Typography>
                }
                size="small"
                className="me-2"
              />
            )}
            <ItemAnswer text={answer.text} />
          </div>
        </MenuItem>
      ));
  };
  const AllItems = () => {
    const groupList = unique(
      props.answerList.map((answer) => (answer.group ? answer.group : ""))
    );
    const itemList = (g) => {
      return props.answerList
        .filter((answer) => !answer.group || answer.group === g)
        .map((answer, j) => (
          <MenuItem
            key={`dropdown-item_${props.alias}_${g}_${answer.value}-${j}]`}
            value={answer.value}
          >
            <div className="d-flex">
              {props.doShow && (
                <Chip
                  label={
                    <Typography variant="overline" color="textSecondary">
                      {answer.value}
                    </Typography>
                  }
                  size="small"
                  className="me-2"
                />
              )}
              <ItemAnswer text={answer.text} />
            </div>
          </MenuItem>
        ));
    };
    return groupList.map((group, i) => {
      return [
        <ListSubheader key={`dropdown-item_${props.alias}_${group}_${i}`}>
          {group}
        </ListSubheader>,
        itemList(group),
      ];
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <FormControl component="fieldset" variant="filled" className="w-100">
        <InputLabel id="input-label_select-group" color="secondary">
          {t("Select group")}
        </InputLabel>
        <Select
          disabled={props.uneditable}
          name={`${props.alias}-groups`}
          value={group ? group : ""}
          label={t("Select group")}
          labelId="input-label_select-group"
          color="secondary"
          onChange={(e) => {
            handleSetSingleResponse(props.alias, null);
            setGroup(e.target.value);
          }}
          className="answer-text-green mb-2"
        >
          <MenuItem value="">
            <em>{group ? t("Clear") : t("Select")}</em>
          </MenuItem>
          {GroupItems()}
        </Select>
      </FormControl>
      <ArticleFormControl
        article={props.article}
        required={props.required}
        isInvalid={localValidation && localValidation.isInvalid}
        hiddenLabel={props.article.inputLabel ? false : true}
        validationMsg={localValidation && localValidation.msg[props.alias]}
      >
        {props.article.inputLabel && (
          <InputLabel
            id={`input-label_${props.alias}`}
            color="secondary"
            className={props.article.formLabel ? "top-24px" : ""}
          >
            {props.article.inputLabel}
          </InputLabel>
        )}
        <Select
          disabled={props.uneditable || group === "" || group === null}
          name={props.alias}
          value={value ? value : ""}
          label={props.article.inputLabel ? props.article.inputLabel : null}
          labelId={
            props.article.inputLabel ? `input-label_${props.alias}` : null
          }
          color="secondary"
          onChange={(e) => {
            handleSetSingleResponse(props.alias, e.target.value);
          }}
          className="answer-text-green"
        >
          <MenuItem value="">
            <em>{value ? t("Clear") : t("Select")}</em>
          </MenuItem>
          {MenuItems()}
        </Select>
        {props.doShow && props.isLast && (
          <div className="shadow rounded m-2">{AllItems()}</div>
        )}
      </ArticleFormControl>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default GroupedDropdownMenuItem;
