// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
// -------------------------------------------------
// Basic elements
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditLogicSelectOperatorMenu = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "surveys.Surveys_EditLogicSelectOperatorMenu" });
  // ===============================================
  // VARIABLES
  //
  // ===============================================
  // RENDER COMPONENT
  return (
    <Select
      autoWidth
      hiddenLabel
      name="item"
      value={props.value}
      className="answer-text-green"
      color="secondary"
      onChange={props.onChange}
    >
      {/* ----------------------------------------------- */}
      {/* Options for 'score', 'number', 'number-list', 'number-grid', 'radio-vertical', 'radio-horizontal', 'radio-grid', 'dropdown-menu', 'grouped-dropdown-menu', 'dropdown-menu-list', 'dropdown-menu-grid', or 'slider-horizontal' */}
      {(props.type === "score" ||
        props.type === "number" ||
        props.type === "number-list" ||
        props.type === "number-grid" ||
        props.type === "radio-vertical" ||
        props.type === "radio-horizontal" ||
        props.type === "radio-grid" ||
        props.type === "dropdown-menu" ||
        props.type === "grouped-dropdown-menu" ||
        props.type === "dropdown-menu-list" ||
        props.type === "dropdown-menu-grid" ||
        props.type === "slider-horizontal") && (
        <MenuItem value="EQ">
          <Chip
            label={
              <Typography variant="overline" color="textSecondary">
                =
              </Typography>
            }
            size="small"
            className="me-2"
          />
          { t("is exactly equal to") }
        </MenuItem>
      )}
      {(props.type === "score" ||
        props.type === "number" ||
        props.type === "number-list" ||
        props.type === "number-grid" ||
        props.type === "radio-vertical" ||
        props.type === "radio-horizontal" ||
        props.type === "radio-grid" ||
        props.type === "dropdown-menu" ||
        props.type === "grouped-dropdown-menu" ||
        props.type === "dropdown-menu-list" ||
        props.type === "dropdown-menu-grid" ||
        props.type === "slider-horizontal") && (
        <MenuItem value="NEQ">
          <Chip
            label={
              <Typography variant="overline" color="textSecondary">
                &ne;
              </Typography>
            }
            size="small"
            className="me-2"
          />
          { t("is not exactly equal to") }
        </MenuItem>
      )}
      {(props.type === "score" ||
        props.type === "number" ||
        props.type === "number-list" ||
        props.type === "number-grid" ||
        props.type === "radio-vertical" ||
        props.type === "radio-horizontal" ||
        props.type === "radio-grid" ||
        props.type === "dropdown-menu" ||
        props.type === "grouped-dropdown-menu" ||
        props.type === "dropdown-menu-list" ||
        props.type === "dropdown-menu-grid" ||
        props.type === "slider-horizontal") && (
        <MenuItem value="GT">
          <Chip
            label={
              <Typography variant="overline" color="textSecondary">
                &gt;
              </Typography>
            }
            size="small"
            className="me-2"
          />
          { t("is greater than") }
        </MenuItem>
      )}
      {(props.type === "score" ||
        props.type === "number" ||
        props.type === "number-list" ||
        props.type === "number-grid" ||
        props.type === "radio-vertical" ||
        props.type === "radio-horizontal" ||
        props.type === "radio-grid" ||
        props.type === "dropdown-menu" ||
        props.type === "grouped-dropdown-menu" ||
        props.type === "dropdown-menu-list" ||
        props.type === "dropdown-menu-grid" ||
        props.type === "slider-horizontal") && (
        <MenuItem value="GTEQ">
          <Chip
            label={
              <Typography variant="overline" color="textSecondary">
                &ge;
              </Typography>
            }
            size="small"
            className="me-2"
          />
          { t("is greater than or equal to") }
        </MenuItem>
      )}
      {(props.type === "score" ||
        props.type === "number" ||
        props.type === "number-list" ||
        props.type === "number-grid" ||
        props.type === "radio-vertical" ||
        props.type === "radio-horizontal" ||
        props.type === "radio-grid" ||
        props.type === "dropdown-menu" ||
        props.type === "grouped-dropdown-menu" ||
        props.type === "dropdown-menu-list" ||
        props.type === "dropdown-menu-grid" ||
        props.type === "slider-horizontal") && (
        <MenuItem value="LT">
          <Chip
            label={
              <Typography variant="overline" color="textSecondary">
                &lt;
              </Typography>
            }
            size="small"
            className="me-2"
          />
          { t("is less than") }
        </MenuItem>
      )}
      {(props.type === "score" ||
        props.type === "number" ||
        props.type === "number-list" ||
        props.type === "number-grid" ||
        props.type === "radio-vertical" ||
        props.type === "radio-horizontal" ||
        props.type === "radio-grid" ||
        props.type === "dropdown-menu" ||
        props.type === "grouped-dropdown-menu" ||
        props.type === "dropdown-menu-list" ||
        props.type === "dropdown-menu-grid" ||
        props.type === "slider-horizontal") && (
        <MenuItem value="LTEQ">
          <Chip
            label={
              <Typography variant="overline" color="textSecondary">
                &le;
              </Typography>
            }
            size="small"
            className="me-2"
          />
          { t("is less than or equal to") }
        </MenuItem>
      )}
      {/* ----------------------------------------------- */}
      {/* Options for 'textbox', 'textbox-list', 'textbox-grid' or 'textbox-long' */}
      {(props.type === "textbox" ||
        props.type === "textbox-long" ||
        props.type === "textbox-list" ||
        props.type === "textbox-grid") && (
        <MenuItem value="CONT">
          <Chip
            label={
              <Typography variant="overline" color="textSecondary">
                &#8857;
              </Typography>
            }
            size="small"
            className="me-2"
          />
          { t("contains") }
        </MenuItem>
      )}
      {(props.type === "textbox" ||
        props.type === "textbox-long" ||
        props.type === "textbox-list" ||
        props.type === "textbox-grid") && (
        <MenuItem value="NCONT">
          <Chip
            label={
              <Typography variant="overline" color="textSecondary">
                &otimes;
              </Typography>
            }
            size="small"
            className="me-2"
          />
          { t("does not contain") }
        </MenuItem>
      )}
      {/* ----------------------------------------------- */}
      {/* Options for all except 'checkboxes' */}
      {(props.type === "number" ||
        props.type === "number-list" ||
        props.type === "number-grid" ||
        props.type === "textbox" ||
        props.type === "textbox-long" ||
        props.type === "textbox-list" ||
        props.type === "textbox-grid" ||
        props.type === "radio-vertical" ||
        props.type === "radio-horizontal" ||
        props.type === "radio-grid" ||
        props.type === "dropdown-menu" ||
        props.type === "grouped-dropdown-menu" ||
        props.type === "dropdown-menu-list" ||
        props.type === "dropdown-menu-grid" ||
        props.type === "slider-horizontal") && (
        <MenuItem value="ANS">
          <Chip
            label={
              <Typography variant="overline" color="textSecondary">
                &#10004;
              </Typography>
            }
            size="small"
            className="me-2"
          />
          { t("is answered") }
        </MenuItem>
      )}
      {(props.type === "number" ||
        props.type === "number-list" ||
        props.type === "number-grid" ||
        props.type === "textbox" ||
        props.type === "textbox-long" ||
        props.type === "textbox-list" ||
        props.type === "textbox-grid" ||
        props.type === "radio-vertical" ||
        props.type === "radio-horizontal" ||
        props.type === "radio-grid" ||
        props.type === "dropdown-menu" ||
        props.type === "grouped-dropdown-menu" ||
        props.type === "dropdown-menu-list" ||
        props.type === "dropdown-menu-grid" ||
        props.type === "slider-horizontal") && (
        <MenuItem value="NANS">
          <Chip
            label={
              <Typography variant="overline" color="textSecondary">
                &#10008;
              </Typography>
            }
            size="small"
            className="me-2"
          />
          { t("is not answered") }
        </MenuItem>
      )}
      {/* ----------------------------------------------- */}
      {/* Options for 'checkboxes' or 'checkboxes-grid' */}
      {(props.type === "checkboxes" || props.type === "checkboxes-grid") && (
        <MenuItem value="EQ">
          <Chip
            label={
              <Typography variant="overline" color="textSecondary">
                &#10004;
              </Typography>
            }
            size="small"
            className="me-2"
          />
          { t("is true") }
        </MenuItem>
      )}
      {(props.type === "checkboxes" || props.type === "checkboxes-grid") && (
        <MenuItem value="NEQ">
          <Chip
            label={
              <Typography variant="overline" color="textSecondary">
                &#10008;
              </Typography>
            }
            size="small"
            className="me-2"
          />
          { t("is false") }
        </MenuItem>
      )}
    </Select>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditLogicSelectOperatorMenu;
