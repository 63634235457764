// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
// -------------------------------------------------
// Basic elements
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Zoom from "@mui/material/Zoom";
// =================================================
// FUNCTIONAL COMPONENT
const AppDrawerPlaceholder = (props) => {
  // ===============================================
  // RENDER COMPONENT
  // -------------------------------------------------
  return (
    <Paper className="h-100">
      <Zoom in={true}>
        <Grid
          container
          className="h-100"
          direction="column"
          wrap="nowrap"
          alignItems="center"
          justifyContent="center">
          <Grid item className="text-center">
            <img
              src={props.imageURL}
              alt={props.imageAlt}
              height="250"
              className="max-width-80pct"
            />
          </Grid>
          <Grid item className="mb-3 text-center">
            <Typography variant="caption">{props.caption}</Typography>
            <Typography variant="h3">{props.title}</Typography>
          </Grid>
        </Grid>
      </Zoom>
    </Paper>
  );
};
// =================================================
// EXPORT COMPONENT
export default AppDrawerPlaceholder;
