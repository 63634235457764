// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditInfo = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditInfo",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (e) => {
    props.setObj((prevState) => {
      let newState = { ...prevState };
      if (e.target.name === "acronym") {
        newState[e.target.name] = e.target.value
          .toUpperCase()
          .replace(/\W/g, "_");
      } else {
        newState[e.target.name] = e.target.value === "" ? null : e.target.value;
      }
      return newState;
    });
  };
  // Survey information keys
  const infoKeys = [
    { key: "officialName", label: t("Official name"), required: true },
    { key: "displayName", label: t("Display name"), required: true },
    { key: "acronym", label: t("Acronym (3-6 characters)"), required: true },
    { key: "version", label: t("Version"), required: true },
    { key: "PDF", label: t("URL to PDF"), required: false },
    { key: "DOI", label: "DOI", required: false },
  ];
  // ===============================================
  // RENDER COMPONENT
  return (
    <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
      <Grid container>
        {infoKeys.map((item) => (
          <React.Fragment key={item.key}>
            <Grid item xs={12}>
              <Typography variant="overline" className="font-weight-bold">
                {item.label}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                hiddenLabel
                error={item.required && !props.obj[item.key]}
                helperText={
                  item.required &&
                  !props.obj[item.key] &&
                  "Please provide a value"
                }
                disabled={props.isLive && item.key === "acronym"}
                size="small"
                type="text"
                name={item.key}
                value={props.obj[item.key] ? props.obj[item.key] : ""}
                color="secondary"
                variant="filled"
                className="bg-white answer-text-green mt-1 mb-3"
                onChange={handleSetObj}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Paper>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditInfo;
