// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Redux
import {
  updateCurrentUserWithKeyValue,
  patchCurrentUser,
} from "../redux/reducers/user";
import { setAlert } from "../redux/reducers/ui";
// -------------------------------------------------
// Component elements
import AppRoot from "../components/App_Root";
// -------------------------------------------------
// Basic elements
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

// =================================================
// FUNCTIONAL COMPONENT
const Account = () => {
  const { t, i18n } = useTranslation("pages", { keyPrefix: "Account" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const isXS = useSelector((state) => state.ui.isXS);
  const isSM = useSelector((state) => state.ui.isSM);
  const currentUser = useSelector((state) => state.user.currentUser);
  const ghostUser = useSelector((state) => state.user.ghostUser);
  const status = useSelector((state) => state.user.status);
  // -----------------------------------------------
  // Contexts
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Local State
  const [nameChanged, setNameChanged] = useState(false);
  const [permissionChanged, setPermissionChanged] = useState(false);
  // -----------------------------------------------
  // URL Location
  const location = useLocation();
  // ===============================================
  // FUNCTIONS
  // Check the URL search for any messages
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const feedback = searchParams.get("feedback");
    if (feedback) {
      dispatch(
        setAlert({
          type: "snackbar",
          variant: "success",
          message: feedback,
          duration: 10000,
        })
      );
    }
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Updates the user in the database when the save button is clicked
  const handlePatchCurrentUser = (e) => {
    e.preventDefault();
    dispatch(
      patchCurrentUser({
        socket,
        requestingUser: currentAuth,
        body: {
          data: currentUser,
        },
      })
    ).then(() => {
      dispatch(
        setAlert({
          type: "snackbar",
          variant: "success",
          message: t("Saved."),
          duration: 6000,
        })
      );
    });
    setNameChanged(false);
    setPermissionChanged(false);
  };
  const handleNameChange = (value) => {
    setNameChanged(true);
    dispatch(updateCurrentUserWithKeyValue({ key: "name", value }));
  };
  const handleConsentChange = (value) => {
    setPermissionChanged(true);
    dispatch(updateCurrentUserWithKeyValue({ key: "consent", value }));
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <AppRoot>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE + " | " + t("Account")}</title>
      </Helmet>
      <Toolbar />
      <Grid container className={isXS || isSM ? "px-2 mt-2" : "pe-2"}>
        {!isXS && !isSM && (
          <Grid item xs={12}>
            <Typography variant="h2" className="my-2">
              {t("Account")}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={9} xl={7} className="mb-3">
          <Paper className="pt-2 px-3">
            <Typography variant="h2" className="mb-1">
              {t("Your account details")}
            </Typography>
            <List>
              {currentUser &&
                (currentUser.primaryRole === "superuser" ||
                  currentUser.primaryRole === "admin") && (
                  <>
                    <Divider />
                    <ListItem>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm="auto">
                          <ListItemText
                            secondary="NAME"
                            className="min-width-125px"
                          />
                        </Grid>
                        <Grid item xs={9} sm="auto">
                          <TextField
                            required
                            fullWidth
                            hiddenLabel
                            variant="filled"
                            size="small"
                            id="name"
                            type="text"
                            name="name"
                            color="secondary"
                            autoComplete="name"
                            defaultValue={
                              currentUser && currentUser.name
                                ? currentUser.name
                                : ""
                            }
                            onChange={(e) => {
                              handleNameChange(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <ListItemSecondaryAction>
                        <Button
                          disabled={!nameChanged || status === "loading"}
                          type="button"
                          variant="contained"
                          color={nameChanged ? "primary" : "secondary"}
                          onClick={handlePatchCurrentUser}
                        >
                          {status === "loading" ? (
                            <CircularProgress
                              size="1.5rem"
                              className="text-light"
                            />
                          ) : (
                            "Save"
                          )}
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </>
                )}
              <Divider />
              <ListItem>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary={t("EMAIL")}
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Typography>{currentUser && currentUser.email}</Typography>
                  </Grid>
                </Grid>
                {!ghostUser && (
                  <ListItemSecondaryAction>
                    <NavLink
                      to="/user-management?mode=askToReauthenticate&continueURL=/user-management?mode=updateEmail"
                      className="text-secondary text-decoration-none"
                    >
                      <Button color="inherit" edge="end">
                        {t("Change Email")}
                      </Button>
                    </NavLink>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <Divider />
              <ListItem>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary={t("PASSWORD")}
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Typography>••••••••</Typography>
                  </Grid>
                </Grid>
                {!ghostUser && (
                  <ListItemSecondaryAction>
                    <NavLink
                      to="/user-management?mode=askToReauthenticate&continueURL=/user-management?mode=updatePassword"
                      className="text-secondary text-decoration-none"
                    >
                      <Button color="inherit" edge="end">
                        {t("Change Password")}
                      </Button>
                    </NavLink>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              {!ghostUser && (
                <>
                  <Divider />
                  <ListItem>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm="auto">
                        <ListItemText
                          secondary={t("LANGUAGE")}
                          className="min-width-125px"
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <FormControl hiddenLabel size="small" variant="filled">
                          <Select
                            name="language"
                            value={currentUser.locale}
                            label={false}
                            color="secondary"
                            onChange={(e) => {
                              currentAuth &&
                                dispatch(
                                  patchCurrentUser({
                                    requestingUser: currentAuth,
                                    body: {
                                      data: {
                                        _id: currentUser._id,
                                        locale: e.target.value,
                                      },
                                    },
                                  })
                                );
                              i18n.changeLanguage("en");
                            }}
                          >
                            <MenuItem value="en">English</MenuItem>
                            <MenuItem value="nl">Nederlands</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </ListItem>
                </>
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={9} xl={7} className="mb-3">
          <Paper className="pt-2 px-3">
            <Typography className="mb-3" variant="h2">
              {t("Change your permissions")}
            </Typography>
            <Divider className="mb-3" />
            <FormGroup column="true">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      currentUser &&
                      currentUser.consent &&
                      currentUser.consent.includes("agreeToContact")
                    }
                    color="secondary"
                    name="agree-to-contact"
                    onChange={(e) => {
                      handleConsentChange({
                        consentType: "agreeToContact",
                        bool: e.target.checked,
                      });
                    }}
                  />
                }
                label={t(
                  "I agree to be contacted to participate in research studies."
                )}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      currentUser &&
                      currentUser.consent &&
                      currentUser.consent.includes("receiveNotifications")
                    }
                    color="secondary"
                    name="receive-notifications"
                    onChange={(e) => {
                      handleConsentChange({
                        consentType: "receiveNotifications",
                        bool: e.target.checked,
                      });
                    }}
                  />
                }
                label={t("I would like to receive email notifications.")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      currentUser &&
                      currentUser.consent &&
                      currentUser.consent.includes("receiveNewsletters")
                    }
                    color="secondary"
                    name="receive-newsletters"
                    onChange={(e) => {
                      handleConsentChange({
                        consentType: "receiveNewsletters",
                        bool: e.target.checked,
                      });
                    }}
                  />
                }
                label={t("I would like to receive email newsletters.")}
              />
            </FormGroup>
            <Button
              disabled={!permissionChanged || status === "loading"}
              className="my-3"
              type="button"
              variant="contained"
              color={permissionChanged ? "primary" : "secondary"}
              onClick={handlePatchCurrentUser}
            >
              {status === "loading" ? (
                <CircularProgress size="1.5rem" className="text-light" />
              ) : (
                t("Save")
              )}
            </Button>
          </Paper>
        </Grid>
        {!ghostUser && (
          <Grid item xs={12} sm={12} md={12} lg={9} xl={7} className="mb-3">
            <Paper className="p-3 pt-2">
              <Typography variant="h2">{t("Remove your account")}</Typography>
              <Typography variant="body2" className="mt-3">
                {t(
                  "You can withdraw your participation at any time without reason. You can permanently delete your account information. Any other information that you have provided prior to your withdrawal will be kept as unidentifiable records."
                )}
              </Typography>
              <Divider className="my-3" />
              <NavLink
                to="/user-management?mode=askToReauthenticate&continueURL=/user-management?mode=confirmToRemoveAccount"
                className="text-secondary text-decoration-none"
              >
                <Button variant="contained" color="primary" edge="end">
                  {t("Permanently remove account")}
                </Button>
              </NavLink>
            </Paper>
          </Grid>
        )}
      </Grid>
    </AppRoot>
  );
};
// =================================================
// EXPORT COMPONENT
export default Account;
