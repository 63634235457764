// =================================================
// FUNCTION
// -------------------------------------------------
// Uses recursion to find an object by it's unique Id
// in a nested array of objects
export const findNestedObject = (arr, id) => {
  const keys = ["gridContainerList", "gridItemList", "articleList", "rowList"];
  // eslint-disable-next-line
  return arr.reduce((result, next) => {
    if (result) {
      return result;
    }
    if (next._id && next._id === id) {
      return next;
    }
    for (let k = 0; k < keys.length; k++) {
      if (Array.isArray(next[keys[k]])) {
        return findNestedObject(next[keys[k]], id);
      }
    }
  }, null);
};
