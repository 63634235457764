// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
// -------------------------------------------------
// Basic elements
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import ColorSelector from "../form/ColorSelector";
import OpacitySelector from "../form/OpacitySelector";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditLayout = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditLayout",
  });
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (key, value) => {
    props.setObj((prevState) => {
      let newState = { ...prevState };
      newState = { ...newState, [key]: value };
      return newState;
    });
  };
  const handleSetGridWidth = (size, value) => {
    props.setObj((prevState) => {
      let newState = { ...prevState };
      newState.width = { ...prevState.width };
      newState.width[size] = value;
      return newState;
    });
  };
  const handleSetRowSpacing = (size, value) => {
    props.setObj((prevState) => {
      let newState = { ...prevState };
      newState.rowSpacing = { ...prevState.rowSpacing };
      newState.rowSpacing[size] = value;
      return newState;
    });
  };
  const handleSetColumnSpacing = (size, value) => {
    props.setObj((prevState) => {
      let newState = { ...prevState };
      newState.columnSpacing = { ...prevState.columnSpacing };
      newState.columnSpacing[size] = value;
      return newState;
    });
  };
  const handleSetPadding = (dir, value) => {
    props.setObj((prevState) => {
      let newState = { ...prevState };
      newState.padding = { ...prevState.padding };
      newState.padding[dir] = value;
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <Typography variant="h3">{t("Layout and styles")}</Typography>
      <Divider className="my-3" />
      {Object.keys(props.obj).map((key, i) =>
        key === "width" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h4" className="mb-2">
                  {t("Width at screen sizes")}
                </Typography>
              </Grid>
              {["xs", "sm", "md", "lg", "xl"].map((size) => (
                <React.Fragment key={size}>
                  <Grid item xs={2} className="mb-2 ps-2">
                    {size.toUpperCase()}
                  </Grid>
                  <Grid item xs={10} className="mb-2">
                    <ToggleButtonGroup
                      exclusive
                      size="small"
                      value={props.obj.width[size]}
                      onChange={(e, value) =>
                        value !== null && handleSetGridWidth(size, value)
                      }
                    >
                      <ToggleButton value={"auto"} sx={{ minWidth: "40px" }}>
                        auto
                      </ToggleButton>
                      <ToggleButton value={1} sx={{ minWidth: "40px" }}>
                        8%
                      </ToggleButton>
                      <ToggleButton value={2} sx={{ minWidth: "40px" }}>
                        17%
                      </ToggleButton>
                      <ToggleButton value={3} sx={{ minWidth: "40px" }}>
                        25%
                      </ToggleButton>
                      <ToggleButton value={4} sx={{ minWidth: "40px" }}>
                        33%
                      </ToggleButton>
                      <ToggleButton value={6} sx={{ minWidth: "40px" }}>
                        50%
                      </ToggleButton>
                      <ToggleButton value={8} sx={{ minWidth: "40px" }}>
                        66%
                      </ToggleButton>
                      <ToggleButton value={9} sx={{ minWidth: "40px" }}>
                        75%
                      </ToggleButton>
                      <ToggleButton value={10} sx={{ minWidth: "40px" }}>
                        83%
                      </ToggleButton>
                      <ToggleButton value={11} sx={{ minWidth: "40px" }}>
                        92%
                      </ToggleButton>
                      <ToggleButton value={12} sx={{ minWidth: "40px" }}>
                        100%
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            {i < Object.keys(props.obj).length - 1 && (
              <Divider className="my-3" />
            )}
          </React.Fragment>
        ) : key === "padding" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h4" className="mb-2">
                  {t("Padding")}
                </Typography>
              </Grid>
              {["left", "right", "top", "bottom"].map((dir) => (
                <React.Fragment key={dir}>
                  <Grid item xs={2} className="mb-2 ps-2">
                    {dir.toUpperCase()}
                  </Grid>
                  <Grid item xs={10} className="mb-2">
                    <ToggleButtonGroup
                      exclusive
                      size="small"
                      value={props.obj.padding[dir]}
                      onChange={(e, value) =>
                        value !== null && handleSetPadding(dir, value)
                      }
                    >
                      <ToggleButton value="0" sx={{ minWidth: "40px" }}>
                        0×
                      </ToggleButton>
                      <ToggleButton value="0.25" sx={{ minWidth: "40px" }}>
                        1×
                      </ToggleButton>
                      <ToggleButton value="0.5" sx={{ minWidth: "40px" }}>
                        2×
                      </ToggleButton>
                      <ToggleButton value="1" sx={{ minWidth: "40px" }}>
                        4×
                      </ToggleButton>
                      <ToggleButton value="2" sx={{ minWidth: "40px" }}>
                        6×
                      </ToggleButton>
                      <ToggleButton value="4" sx={{ minWidth: "40px" }}>
                        12×
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            {i < Object.keys(props.obj).length - 1 && (
              <Divider className="my-3" />
            )}
          </React.Fragment>
        ) : key === "rowSpacing" && props.obj.direction === "row" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h4" className="mb-2">
                  {t("Row spacing")}
                </Typography>
              </Grid>
              {["xs", "sm", "md", "lg", "xl"].map((size) => (
                <React.Fragment key={size}>
                  <Grid item xs={2} className="mb-2 ps-2">
                    {size.toUpperCase()}
                  </Grid>
                  <Grid item xs={10} className="mb-2">
                    <ToggleButtonGroup
                      exclusive
                      size="small"
                      value={props.obj.rowSpacing[size]}
                      onChange={(e, value) =>
                        value !== null && handleSetRowSpacing(size, value)
                      }
                    >
                      <ToggleButton value={0} sx={{ minWidth: "40px" }}>
                        0
                      </ToggleButton>
                      <ToggleButton value={1} sx={{ minWidth: "40px" }}>
                        1
                      </ToggleButton>
                      <ToggleButton value={2} sx={{ minWidth: "40px" }}>
                        2
                      </ToggleButton>
                      <ToggleButton value={3} sx={{ minWidth: "40px" }}>
                        3
                      </ToggleButton>
                      <ToggleButton value={4} sx={{ minWidth: "40px" }}>
                        4
                      </ToggleButton>
                      <ToggleButton value={8} sx={{ minWidth: "40px" }}>
                        8
                      </ToggleButton>
                      <ToggleButton value={12} sx={{ minWidth: "40px" }}>
                        12
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            {i < Object.keys(props.obj).length - 1 && (
              <Divider className="my-3" />
            )}
          </React.Fragment>
        ) : key === "columnSpacing" && props.obj.direction === "row" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h4" className="mb-2">
                  {t("Column spacing")}
                </Typography>
              </Grid>
              {["xs", "sm", "md", "lg", "xl"].map((size) => (
                <React.Fragment key={size}>
                  <Grid item xs={2} className="mb-2 ps-2">
                    {size.toUpperCase()}
                  </Grid>
                  <Grid item xs={10} className="mb-2">
                    <ToggleButtonGroup
                      exclusive
                      size="small"
                      value={props.obj.columnSpacing[size]}
                      onChange={(e, value) =>
                        value !== null && handleSetColumnSpacing(size, value)
                      }
                    >
                      <ToggleButton value={0} sx={{ minWidth: "40px" }}>
                        0
                      </ToggleButton>
                      <ToggleButton value={1} sx={{ minWidth: "40px" }}>
                        1
                      </ToggleButton>
                      <ToggleButton value={2} sx={{ minWidth: "40px" }}>
                        2
                      </ToggleButton>
                      <ToggleButton value={3} sx={{ minWidth: "40px" }}>
                        3
                      </ToggleButton>
                      <ToggleButton value={4} sx={{ minWidth: "40px" }}>
                        4
                      </ToggleButton>
                      <ToggleButton value={8} sx={{ minWidth: "40px" }}>
                        8
                      </ToggleButton>
                      <ToggleButton value={12} sx={{ minWidth: "40px" }}>
                        12
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            {i < Object.keys(props.obj).length - 1 && (
              <Divider className="my-3" />
            )}
          </React.Fragment>
        ) : key === "direction" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Typography variant="h5" className="my-2">
              {t("Direction")}
            </Typography>
            <ToggleButtonGroup
              exclusive
              size="small"
              value={props.obj.direction}
              onChange={(e) => handleSetObj("direction", e.target.value)}
            >
              <ToggleButton value="row">{t("row")}</ToggleButton>
              <ToggleButton value="column">{t("column")}</ToggleButton>
            </ToggleButtonGroup>
            {i < Object.keys(props.obj).length - 1 && (
              <Divider className="my-3" />
            )}
          </React.Fragment>
        ) : key === "justifyContent" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Typography variant="h5" className="my-2">
              {t("Justify content")}
            </Typography>
            <FormControl
              fullWidth
              size="small"
              color="secondary"
              variant="filled"
            >
              <InputLabel htmlFor="justifyContent">{t("Justify")}</InputLabel>
              <Select
                name="justifyContent"
                label={t("Justify")}
                value={props.obj.justifyContent}
                color="secondary"
                onChange={(e) => handleSetObj("justifyContent", e.target.value)}
              >
                {[
                  "flex-start",
                  "center",
                  "flex-end",
                  "space-between",
                  "space-around",
                  "space-evenly",
                ].map((pos) => (
                  <MenuItem key={pos} value={pos}>
                    {pos}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {i < Object.keys(props.obj).length - 1 && (
              <Divider className="my-3" />
            )}
          </React.Fragment>
        ) : key === "alignContent" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Typography variant="h5" className="my-2">
              {t("Align content")}
            </Typography>
            <FormControl
              fullWidth
              size="small"
              color="secondary"
              variant="filled"
            >
              <InputLabel htmlFor="alignContent">{t("Align")}</InputLabel>
              <Select
                name="alignContent"
                label={t("Align")}
                value={props.obj.alignContent}
                color="secondary"
                onChange={(e) => handleSetObj("alignContent", e.target.value)}
              >
                {[
                  "flex-start",
                  "center",
                  "flex-end",
                  "baseline",
                  "space-between",
                  "space-around",
                  "space-evenly",
                  "stretch",
                ].map((pos) => (
                  <MenuItem key={pos} value={pos}>
                    {pos}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {i < Object.keys(props.obj).length - 1 && (
              <Divider className="my-3" />
            )}
          </React.Fragment>
        ) : key === "alignItems" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Typography variant="h5" className="my-2">
              {t("Align items")}
            </Typography>
            <FormControl
              fullWidth
              size="small"
              color="secondary"
              variant="filled"
            >
              <InputLabel htmlFor="alignItems">{t("Align")}</InputLabel>
              <Select
                name="alignItems"
                label={t("Align")}
                value={props.obj.alignItems}
                color="secondary"
                onChange={(e) => handleSetObj("alignItems", e.target.value)}
              >
                {[
                  "flex-start",
                  "center",
                  "flex-end",
                  "stretch",
                  "baseline",
                ].map((pos) => (
                  <MenuItem key={pos} value={pos}>
                    {pos}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {i < Object.keys(props.obj).length - 1 && (
              <Divider className="my-3" />
            )}
          </React.Fragment>
        ) : key === "noDivider" ? (
          <React.Fragment key={`article-options_${key}`}>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={!props.obj.noDivider}
                  onChange={(e) => handleSetObj("noDivider", !e.target.checked)}
                />
              }
              label={
                props.obj.noDivider ? t("No divider shown") : t("Divider shown")
              }
            />
            {i < Object.keys(props.obj).length - 1 && (
              <Divider className="my-3" />
            )}
          </React.Fragment>
        ) : key === "minHeight" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Typography variant="h4">{t("Minimum height")}</Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              type="text"
              name="minHeight"
              variant="filled"
              value={props.obj.minHeight}
              color="secondary"
              className="mt-2 answer-text-green"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{t("px")}</InputAdornment>
                ),
              }}
              onChange={(e) => handleSetObj(e.target.name, e.target.value)}
            />
            {i < Object.keys(props.obj).length - 1 && (
              <Divider className="my-3" />
            )}
          </React.Fragment>
        ) : key === "bgImage" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Typography variant="h4">{t("Background image")}</Typography>
            <Typography variant="caption">{t("URL to image")}</Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              type="text"
              name="bgImage"
              variant="filled"
              value={props.obj.bgImage}
              color="secondary"
              className="mt-2 answer-text-green"
              onChange={(e) => handleSetObj(e.target.name, e.target.value)}
            />
          </React.Fragment>
        ) : key === "bgImageOrigin" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Typography variant="h5" className="my-2">
              {t("Background image origin")}
            </Typography>
            <ToggleButtonGroup
              exclusive
              size="small"
              value={props.obj.bgImageOrigin}
              onChange={(e) => handleSetObj("bgImageOrigin", e.target.value)}
            >
              <ToggleButton value="padding-box">padding box</ToggleButton>
              <ToggleButton value="border-box">border box</ToggleButton>
              <ToggleButton value="content-box">content box</ToggleButton>
            </ToggleButtonGroup>
          </React.Fragment>
        ) : key === "bgImagePosition" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Typography variant="h5" className="my-2">
              {t("Background image position")}
            </Typography>
            <FormControl
              fullWidth
              size="small"
              color="secondary"
              variant="filled"
            >
              <InputLabel htmlFor="bgImagePosition">{t("Position")}</InputLabel>
              <Select
                name="bgImagePosition"
                label={t("Position")}
                value={props.obj.bgImagePosition}
                color="secondary"
                onChange={(e) =>
                  handleSetObj("bgImagePosition", e.target.value)
                }
              >
                {[
                  "left top",
                  "left center",
                  "left bottom",
                  "center top",
                  "center center",
                  "center bottom",
                  "right top",
                  "right center",
                  "right bottom",
                ].map((pos) => (
                  <MenuItem key={pos} value={pos}>
                    {pos}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </React.Fragment>
        ) : key === "bgImageRepeat" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Typography variant="h5" className="my-2">
              {t("Background image repeat")}
            </Typography>
            <FormControl
              fullWidth
              size="small"
              color="secondary"
              variant="filled"
            >
              <InputLabel htmlFor="bgImageRepeat">{t("Repeat")}</InputLabel>
              <Select
                name="bgImageRepeat"
                label={t("Repeat")}
                value={props.obj.bgImageRepeat}
                color="secondary"
                onChange={(e) => handleSetObj("bgImageRepeat", e.target.value)}
              >
                {[
                  "no-repeat",
                  "repeat",
                  "repeat-x",
                  "repeat-y",
                  "space",
                  "round",
                ].map((pos) => (
                  <MenuItem key={pos} value={pos}>
                    {pos}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </React.Fragment>
        ) : key === "bgImageSize" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Typography variant="h5" className="my-2">
              {t("Background image size")}
            </Typography>
            <ToggleButtonGroup
              exclusive
              size="small"
              value={props.obj.bgImageSize}
              onChange={(e) => handleSetObj("bgImageSize", e.target.value)}
            >
              <ToggleButton value="auto" sx={{ minWidth: "40px" }}>
                auto
              </ToggleButton>
              <ToggleButton value="cover" sx={{ minWidth: "40px" }}>
                cover
              </ToggleButton>
              <ToggleButton value="contain" sx={{ minWidth: "40px" }}>
                contain
              </ToggleButton>
            </ToggleButtonGroup>
            {i < Object.keys(props.obj).length - 1 && (
              <Divider className="my-3" />
            )}
          </React.Fragment>
        ) : key === "bgColor" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Typography variant="h4" className="mb-2">
              {t("Background color")}
            </Typography>
            <ColorSelector
              selColor={props.obj.bgColor}
              setSelColor={(e) => handleSetObj("bgColor", e.target.value)}
            />
          </React.Fragment>
        ) : key === "bgOpacity" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Typography variant="h5" className="my-2">
              {t("Background color opacity")}
            </Typography>
            <OpacitySelector
              selOpacity={props.obj.bgOpacity}
              setSelOpacity={(e) => handleSetObj("bgOpacity", e.target.value)}
            />
            {i < Object.keys(props.obj).length - 1 && (
              <Divider className="my-3" />
            )}
          </React.Fragment>
        ) : key === "classNames" ? (
          <React.Fragment key={`article-options_${key}`}>
            <Typography variant="h4">{t("Additional class-names")}</Typography>
            <Typography variant="caption">
              Use any{" "}
              <a
                href="https://getbootstrap.com/docs/5.0/examples/cheatsheet/"
                target="_blank"
                rel="noreferrer"
              >
                Bootstrap-5 class name
              </a>{" "}
              separated by spaces
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              type="text"
              name="classNames"
              variant="filled"
              value={props.obj.classNames}
              color="secondary"
              className="mt-2 answer-text-green"
              onChange={(e) => handleSetObj(e.target.name, e.target.value)}
            />
            {i < Object.keys(props.obj).length - 1 && (
              <Divider className="my-3" />
            )}
          </React.Fragment>
        ) : null
      )}
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditLayout;
