// =================================================
// IMPORT
import {
  current,
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/studies`;
// -------------------------------------------------
// Use 'createEntityAdapter' to store the studies in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  defaultStudy: null,
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null,
});
// =================================================
// ASYNC API ACTIONS
// -------------------------------------------------
// API fetch default study
export const fetchDefaultStudy = createAsyncThunk(
  "studies/fetchDefaultStudy",
  async ({ requestingUser }) => {
    const response = await client.get(
      `${apiurl}/default-study`,
      requestingUser
    );
    return response.data;
  }
);
// -------------------------------------------------
// API patch the default study Id object
export const patchDefaultStudyId = createAsyncThunk(
  "studies/patchDefaultStudyId",
  async ({ socket, requestingUser, body }) => {
    // Make the call to the database
    const response = await client.patch(
      `${apiurl}/default-study`,
      requestingUser,
      body
    );
    // Invoke event on server
    socket && socket.emit("patched-default-study-id");
    // Return the response
    return response.data;
  }
);
// -------------------------------------------------
// API fetch all owned studies
// If SuperUser, will return all studies
// If Admin, will return only owned studies
export const fetchStudyList = createAsyncThunk(
  "studies/fetchStudyList",
  async ({ requestingUser }) => {
    const response = await client.get(apiurl, requestingUser);
    return response.data;
  }
);
// -------------------------------------------------
// API fetch one study by its id
export const fetchStudyById = createAsyncThunk(
  "studies/fetchStudyById",
  async ({ requestingUser, studyId }) => {
    const response = await client.get(`${apiurl}/${studyId}`, requestingUser);
    return response.data;
  }
);
// -------------------------------------------------
// API patch a study object
export const patchCurrentStudy = createAsyncThunk(
  "studies/patchCurrentStudy",
  async ({ socket, requestingUser, body }) => {
    // Make the call to the database
    const response = await client.patch(
      `${apiurl}/${body.data._id}`,
      requestingUser,
      body
    );
    // Invoke event on server
    socket &&
      socket.emit("patched-current-study", {
        isDefaultStudy: body.meta.isDefaultStudy,
        studyId: body.data._id,
        userIdList: body.data.userIdList,
      });
    // Return the response
    return response.data;
  }
);
// -------------------------------------------------
// API delete a study object
export const deleteCurrentStudy = createAsyncThunk(
  "studies/deleteCurrentStudy",
  async ({ socket, requestingUser, studyId }) => {
    // Make the call to the database
    const response = await client.delete(
      `${apiurl}/${studyId}`,
      requestingUser
    );
    // Invoke event on server
    socket && socket.emit("deleted-current-study", { studyId });
    // Return the response
    return response.data;
  }
);
// =================================================
// DEFINE MUTATING ACTIONS
export const studiesSlice = createSlice({
  name: "studies",
  initialState,
  reducers: {
    resetStudiesError(state) {
      if (state.status === "failed") {
        state.status = "idle";
        state.errorMsg = null;
      }
    },
    resetStudyList(state) {
      adapter.removeAll(state);
    },
    removeStudyById(state, action) {
      adapter.removeOne(state, action.payload.studyId);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDefaultStudy.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDefaultStudy.fulfilled, (state, action) => {
        state.status = "partial";
        state.errorMsg = null;
        state.defaultStudy = action.payload.defaultStudy;
        action.payload.defaultStudy &&
          adapter.upsertOne(state, action.payload.defaultStudy);
      })
      .addCase(fetchDefaultStudy.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(patchDefaultStudyId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(patchDefaultStudyId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.errorMsg = null;
        state.defaultStudy =
          current(state).entities[action.payload.defaultStudyId];
      })
      .addCase(patchDefaultStudyId.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(fetchStudyById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStudyById.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.study && adapter.upsertOne(state, action.payload.study);
      })
      .addCase(fetchStudyById.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(fetchStudyList.pending, (state) => {
        state.status = "loading";
        state.errorMsg = null;
      })
      .addCase(fetchStudyList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.errorMsg = null;
        action.payload.studyList &&
          action.payload.studyList.length > 0 &&
          adapter.upsertMany(state, action.payload.studyList);
        state.defaultStudy = action.payload.defaultStudy;
      })
      .addCase(fetchStudyList.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(patchCurrentStudy.pending, (state) => {
        state.status = "loading";
      })
      .addCase(patchCurrentStudy.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.study && adapter.upsertOne(state, action.payload.study);
      })
      .addCase(patchCurrentStudy.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(deleteCurrentStudy.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCurrentStudy.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.studyId &&
          adapter.removeOne(state, action.payload.studyId);
      })
      .addCase(deleteCurrentStudy.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const { resetStudyList, removeStudyById, resetStudiesError } =
  studiesSlice.actions;
// =================================================
// SELECTOR FUNCTIONS
// -------------------------------------------------
export const studiesSelectors = adapter.getSelectors((state) => state.studies);
// =================================================
// EXPORT DEFAULT
export default studiesSlice.reducer;
