// =================================================
// SUBFUNCTIONS: Individual rules
// -------------------------------------------------
// Required articles must be not Null
const checkRequiredValues = (val) => {
  // The value must be not Null
  return Array.isArray(val)
    ? val.some((v) => v === null || v === "")
    : val === null || val === "";
};
// =================================================
// EXPORT FUNCTION
// -------------------------------------------------
// Validates an article
export const validateSurveyItem = (required, validation, alias, value) => {
  // ===============================================
  // Init return object
  let response = { isInvalid: false, msg: { [alias]: null } };
  // ===============================================
  // RULE: Required value must be not Null
  // -----------------------------------------------
  if (required) {
    response.isInvalid = checkRequiredValues(value);
    response.msg[alias] = response.isInvalid
      ? "Required, please answer."
      : null;
  }
  if (response.isInvalid) {
    return response;
  }
  // ===============================================
  // Test all dynamic validation
  let regex;
  if (validation !== null) {
    for (let i = 0; i < validation.length; i++) {
      switch (validation[i].type) {
        case "regexp":
          regex = new RegExp(validation[i].test);
          response.isInvalid = !regex.test(value);
          response.msg[alias] = response.isInvalid
            ? validation[i].errorMsg
            : null;
          break;
        case "minValue":
          response.isInvalid =
            value === null
              ? true
              : parseFloat(value) < parseFloat(validation[i].test);
          response.msg[alias] = response.isInvalid
            ? validation[i].errorMsg
            : null;
          break;
        case "maxValue":
          response.isInvalid =
            value === null
              ? true
              : parseFloat(value) > parseFloat(validation[i].test);
          response.msg[alias] = response.isInvalid
            ? validation[i].errorMsg
            : null;
          break;
        case "minCharCount":
          response.isInvalid =
            value === null
              ? true
              : value.length < parseFloat(validation[i].test);
          response.msg[alias] = response.isInvalid
            ? validation[i].errorMsg
            : null;
          break;
        case "maxCharCount":
          response.isInvalid =
            value === null
              ? true
              : value.length > parseFloat(validation[i].test);
          response.msg[alias] = response.isInvalid
            ? validation[i].errorMsg
            : null;
          break;
        default:
          break;
      }
      if (response.isInvalid) {
        return response;
      }
    }
  }
  // ===============================================
  // Return the validation boolean and messages
  return response;
};
