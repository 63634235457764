// =================================================
// IMPORT
// -------------------------------------------------
const { addLeadingZeros } = require("./addLeadingZeros");
// =================================================
// FUNCTION
// -------------------------------------------------
// Parse a floating-point number of days to human readable text
export const parseDaysClockString = (x) => {
  let days = Math.floor(x);
  let hours = Math.floor((x - days) * 24);
  let minutes = Math.floor(((x - days) * 24 - hours) * 60);
  let seconds = Math.round((((x - days) * 24 - hours) * 60 - minutes) * 60);
  if (seconds >= 60) {
    seconds = seconds - 60;
    minutes = minutes + 1;
  }
  if (minutes >= 60) {
    minutes = minutes - 60;
    hours = hours + 1;
  }
  if (hours >= 24) {
    hours = hours - 24;
    days = days + 1;
  }
  if (seconds === 0) {
    return `day ${days} @ ${addLeadingZeros(hours, 2)}:${addLeadingZeros(
      minutes,
      2
    )}`;
  } else {
    return `day ${days} @ ${addLeadingZeros(hours, 2)}:${addLeadingZeros(
      minutes,
      2
    )}:${addLeadingZeros(seconds, 2)}`;
  }
};
