// =================================================
// FUNCTION
// -----------------------------------------------
// Initialize answer structure
export const initSurveyResponses = (ticket, survey) => {
  if (!ticket || !survey) {
    return -1;
  }
  // Initialize the task meta data
  const initResponses = {};
  initResponses._id = ticket.responseId;
  initResponses.userId = ticket.userId;
  initResponses.studyId = ticket.studyId;
  initResponses.timepointId = ticket.timepointId;
  initResponses.measurementId = ticket.measurementId;
  initResponses.groupId = ticket.groupId;
  initResponses.surveyId = ticket.surveyId;
  initResponses.entryNumber = ticket.entryNumber;
  initResponses.dateAvailable = ticket.dateAvailable;
  initResponses.dateDue = ticket.dateDue;
  initResponses.dateExpire = ticket.dateExpire;
  initResponses.dateStarted = null;
  initResponses.dateCompleted = null;
  initResponses.encryptAliasList = [];
  initResponses.isLocked = false;
  initResponses.data = {};
  initResponses.metadata = [];
  // Set all response values to null or false (for checkboxes)
  survey.pageList &&
    survey.pageList.forEach((page) => {
      page.gridContainerList &&
        page.gridContainerList.forEach((gc) => {
          gc.gridItemList &&
            gc.gridItemList.forEach((gi) => {
              gi.articleList &&
                gi.articleList.forEach((article) => {
                  if (article.category !== "question") {
                    // This article is a media article, not a question
                    return;
                  }
                  // If the article has columns, it's a 2D answer
                  if (article.columnList) {
                    article.rowList.forEach((row) => {
                      article.columnList.forEach((column) => {
                        const aliasId = Object.keys(survey.aliasList).filter(
                          (id) => id.includes(`${row._id}_${column._id}`)
                        );
                        const alias = survey.aliasList[aliasId[0]].alias;
                        initResponses.data[alias] =
                          article.type === "checkboxes-grid" ? false : null;
                        if (row.encrypt) {
                          initResponses.encryptAliasList.push(alias);
                        }
                      });
                    });
                    // If the article has items, it's a 1D answer
                  } else if (article.rowList) {
                    article.rowList.forEach((row) => {
                      const aliasId = Object.keys(survey.aliasList).filter(
                        (id) => id.includes(row._id)
                      );
                      const alias = survey.aliasList[aliasId[0]].alias;
                      initResponses.data[alias] =
                        article.type === "checkboxes" ? false : null;
                      if (row.encrypt) {
                        initResponses.encryptAliasList.push(alias);
                      }
                    });
                    // Else it's a 0D answer
                  } else {
                    const aliasId = Object.keys(survey.aliasList).filter((id) =>
                      id.includes(article._id)
                    );
                    const alias = survey.aliasList[aliasId[0]].alias;
                    initResponses.data[alias] = null;
                    if (article.encrypt) {
                      initResponses.encryptAliasList.push(alias);
                    }
                  }
                });
            });
        });
    });
  survey.scoring &&
    survey.scoring.forEach((score) => {
      const alias = survey.aliasList[score._id].alias;
      initResponses.data[alias] = null;
    });
  return initResponses;
};
